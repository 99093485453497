
html[dir="rtl"] {
  *{letter-spacing: normal !important;}

  .add-dropcap:first-letter {
    float: right;
    margin-right: 0;
    margin-left: 0.1em;
  }

  .header-module ~ .header-module {
    margin-right: 25px;
    margin-left: 0;
  }

  .ld-module-trigger-count {
    margin-left: 0;
    margin-right: 5px;
  }

  .ld-module-dropdown {
    right: auto;
    left: 0;
  }

  figure + .ld-cart-product-details {
    margin-left: 0;
    margin-right: 15px;
  }

  a.remove.ld-cart-product-remove {
    left: auto;
    right: 25px;
  }

  .ld-module-trigger-txt i {
    margin-left: 0;
    margin-right: 0.35em;
  }

  .ld-module-search .ld-module-dropdown {
    right: auto;
    left: -15px;
  }

  .main-nav .children,
  .nav-item-children {
    left: auto;
    right: 0;
    text-align: right;
  }

  .main-nav .children .children,
  .main-nav .children .nav-item-children,
  .nav-item-children .children,
  .nav-item-children .nav-item-children {
    left: auto;
    right: 100%;
  }

  .btn {
    letter-spacing: inherit !important;
    font-weight: 600;
  }

  .btn + .btn {
    margin-left: 0;
    margin-right: 0.75em;
  }

  .btn-txt + .btn-icon {
    margin-left: 0;
    margin-right: 8px;
  }

  .btn-icon-solid.btn-icon-left.btn-icon-left .btn-txt + .btn-icon,
  .btn-icon-bordered.btn-icon-left.btn-icon-left .btn-txt + .btn-icon {
    margin-right: 0;
    margin-left: 20px;
  }

  .one-bullet-list li:before {
    margin-right: 0;
    margin-left: 1em;
  }

  .one-bullet-list .inline-nav li + li {
    margin-left: 0;
    margin-right: 2.5em;
  }

  .flickity-prev-next-button.previous {
    margin-left: 5px;
    margin-right: 0;
  }

  .flickity-prev-next-button.next {
    margin-left: 0;
    margin-right: 5px;
  }


  .ld-carousel-laptop .flickity-prev-next-button.previous {
    left: auto;
    right: 6%;
  }

  .ld-carousel-laptop .flickity-prev-next-button.next {
    right: auto;
    left: 6%;
  }

  .contact-form i {
    left: auto;
    right: calc(1em + 15px);
  }

  .contact-form label {
    margin-right: 0;
    margin-left: 5px;
  }

  .contact-form-inputs-underlined i {
    right: 15px;
  }

  .contact-form-inputs-underlined .liquid-cl {
    left: auto;
    right: 15px;
  }

  .liquid-cl {
    right: calc(15px + 2em);
  }

  .liquid-cl:after {
    left: auto;
    right: 0;
  }

  .liquid-counter-element > span {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .fancy-box-booking .fancy-box-header {
    left: auto;
    right: 1.875em;
  }

  .fancy-box-booking .fancy-box-info:before {
    left: auto;
    right: -60px;
  }

  .fancy-box-classes .trainer i {
    margin-right: 0;
    margin-left: 5px;
  }

  .fancy-box-travel.fancy-box-big .fancy-box-contents {
    right: auto;
    left: 30%;
  }

  .fancy-box-tour .fancy-box-icon i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .fancy-box-offer .fancy-box-image {
    margin-right: 0;
    margin-left: 18px;
  }

  .fancy-box-overlay .fancy-box-contents {
    left: auto;
    right: 0;
  }

  .fancy-title i {
    margin-right: 0;
    margin-left: 0.27em;
  }

  .fancy-title .line-alt {
    margin-right: 0;
    margin-left: 2em;
  }

  .fancy-title .line-alt:last-child {
    margin-left: 0;
    margin-right: 2em;
  }

  .fancy-title-underlined:after {
    left: auto;
    right: 0;
  }

  .liquid-filter-items-label + .filter-list {
    margin-left: 0;
    margin-right: 3.5em;
  }

  .filter-list-style-1 li:before {
    left: auto;
    right: 0;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  .filter-list-style-1 li.active:before, .filter-list-style-1 li:hover:before {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .filter-list-style-1 li.active span, .filter-list-style-1 li:hover span {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }


  .iconbox.text-right {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .iconbox.text-left {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  //.iconbox-inline, .iconbox-side {
  //  -webkit-box-orient: horizontal;
  //  -webkit-box-direction: reverse;
  //  -ms-flex-direction: row-reverse;
  //  flex-direction: row-reverse;
  //}
  .iconbox-side {
    text-align: right;
    .iconbox-icon-wrap {
      margin-left: 15px;
      margin-right: 0;
    }
  }
  .iconbox-inline.text-right,
  .iconbox-side.text-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .liquid-img-group-browser .liquid-img-group-url {
    direction: ltr;
  }

  .lqd-parallax-images .liquid-img-group-single:last-child {
    left: auto;
    right: 30%;
  }

  .lqd-parallax-images-2 .liquid-img-group-container:first-child {
    margin-top: 60px;
  }

  .lqd-parallax-images-2 .liquid-img-group-container:nth-child(2) {
    left: auto;
    right: 20%;
  }

  .lqd-parallax-images-2 .liquid-img-group-container:last-child {
    left: auto;
    right: 36%;
  }

  .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
    left: 10%;
    right: 10%;
  }

  .lqd-parallax-images-3 .liquid-img-group-single:last-child {
    left: 23%;
    right: 13%;
  }

  .lqd-parallax-images-6 .liquid-img-group-single:first-of-type {
    left: auto;
    right: 30%;
  }

  .liquid-lp .mejs__controls {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .liquid-lp.format-link .liquid-lp-title svg {
    margin-right: 0;
    margin-left: 0.625em;
  }

  .liquid-lp:hover .liquid-lp-details-lined:before {
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }

  .liquid-lp:hover .liquid-lp-details-lined > * {
    -webkit-transform: translateX(4em);
    transform: translateX(4em);
  }

  .liquid-lp:hover .liquid-lp-read-more .btn-line {
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }

  .liquid-lp:hover .liquid-lp-read-more .btn-line ~ .btn-txt {
    -webkit-transform: translateX(3.727em);
    transform: translateX(3.727em);
  }

  .liquid-lp:hover .liquid-lp-read-more .btn-line-after {
    -webkit-transform: scaleX(1) translateX(3.727em);
    transform: scaleX(1) translateX(3.727em);
  }

  .liquid-lp-meta > a {
    margin-right: 0;
    margin-left: 20px;
  }

  .liquid-lp-meta svg {
    margin-right: 0;
    margin-left: 5px;
  }

  .liquid-lp-read-more .btn-line-before {
    margin-right: 0;
    margin-left: 1em;
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }

  .liquid-lp-read-more .btn-line-after:after {
    content: '\f053';
    right: auto;
    left: -3px;
  }

  .liquid-lp-read-more .btn-txt ~ .btn-line {
    margin-left: 0;
    margin-right: 1em;
  }

  .liquid-lp-read-more.liquid-lp-read-more-overlay {
    left: auto;
    right: 0;
  }

  .liquid-lp-details-lined {
    margin-left: 0;
    margin-right: 4em;
  }

  .liquid-lp-details-lined:before, .liquid-lp-details-lined:after {
    left: auto;
    right: -4em;
  }

  .liquid-lp-details-lined:before {
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }

  .liquid-lp-details-lined + .liquid-lp-read-more-overlay {
    right: 4em !important;
  }

  .ld-post-author figure {
    margin-right: 0;
    margin-left: 15px;
  }

  .liquid-lp-time-aside > time {
    left: auto;
    right: 0;
  }

  .liquid-lp-time-aside header,
  .liquid-lp-time-aside .liquid-lp-excerpt,
  .liquid-lp-time-aside .liquid-lp-footer {
    margin-left: 0;
    margin-right: 100px;
  }

  .liquid-lp-sp-block .liquid-lp-footer:before {
    left: auto;
    right: 0;
  }

  .ld-lp-carousel-filterable .carousel-nav {
    left: auto !important;
    right: -10px;
  }

  .liquid-lp-small-thumb .liquid-lp-header {
    padding-left: 0 !important;
    padding-right: 1rem;
  }

  .ld-msg-icon {
    margin-right: 0;
    margin-left: 26px;
  }

  .ld-msg-close {
    right: auto;
    left: 30px;
  }

  .liquid-milestone .liquid-milestone-time {
    margin-right: 0;
    margin-left: 15px;
  }

  .ld-pb-top:after {
    left: auto;
    right: 50%;
  }

  .liquid-progressbar-bar {
    left: auto;
    right: 0;
  }

  .liquid-progressbar-percentage {
    right: auto;
    left: 0;
  }

  .liquid-progressbar-title {
    left: auto;
    right: 0;
  }

  .lqd-promo-content {
    padding-left: 0;
    padding-right: 15px;
  }

  .lqd-promo-cat {
    margin-right: 0;
    margin-left: 15px;
  }


  .one-roadmap-mark {
    margin-left: 0;
    margin-right: auto;
  }

  .one-roadmap-bar {
    left: auto;
    right: 0;
  }

  .one-roadmap-item {
    padding-left: 0;
    padding-right: 30px;
  }

  .one-roadmap {
    padding-left: 40px;
    padding-right: 60px;
  }

  .social-icon li {
    margin-right: 0;
  }

  .social-icon.round.social-icon-sm li, .social-icon.semi-round.social-icon-sm li, .social-icon.square.social-icon-sm li, .social-icon.circle.social-icon-sm li {
    margin-right: 0;
    margin-left: 0.3em;
  }

  .ld-sf .submit-text + .submit-icon {
    margin-left: 0;
    margin-right: 0.65em;
  }

  .ld-sf--button-inside [type=submit] {
    right: auto;
    left: 0;
  }

  .ld-sf--button-inside.ld-sf--button-naked [type=submit] {
    padding-right: 0;
    padding-left: 1em;
  }

  .ld-sf--button-inside.button-shrinked [type=submit] {
    right: auto;
    left: 12px;
  }

  .tabs-nav-side .tabs-nav li {
    text-align: right;
  }

  .tabs-nav-side .tabs-nav a:after {
    right: auto;
    left: 0;
  }

  .tabs-nav-icon {
    margin-right: 0;
    margin-left: 0.65em;
  }


  .testimonial .testimonial-details time,
  .testimonial .testimonial-details .star-rating {
    margin-left: 0;
    margin-right: auto;
  }

  .testimonial .testimonial-details time:first-child,
  .testimonial .testimonial-details .star-rating:first-child {
    margin-right: 0;
  }

  .testimonial .avatar {
    margin-right: 0;
    margin-left: 20px;
  }

  .testimonial-quote-filled .testimonial-quote:after {
    left: auto;
    right: calc(60px + 35px - 14px);
  }

  .testimonials-details + .carousel-nav .flickity-prev-next-button.previous {
    left: auto;
    right: 0;
  }

  .testimonials-details + .carousel-nav .flickity-prev-next-button.next {
    left: 0;
    right: auto;
  }


  .lqd-parallax-team-members .liquid-img-group-container:first-child {
    left: auto;
    right: -90%;
  }

  .lqd-parallax-team-members .liquid-img-group-container:last-child {
    left: auto;
    right: -90%;
  }

  .post-meta > span + span {
    margin-left: 0;
    margin-right: 3.25em;
  }

  .blog-single-details-extra .lightbox-link svg {
    margin-right: 0;
    margin-left: 20px;
  }

  .blog-single-details-extra .carousel-thumbs .flickity-prev-next-button.previous {
    left: auto;
    right: -45px;
  }

  .blog-single-details-extra .carousel-thumbs .flickity-prev-next-button.next {
    right: auto;
    left: -45px;
  }

  .post-categories li a,
  .post-categories > a,
  .tags-links li a,
  .tags-links > a {
    margin-right: 0;
    margin-left: 0.2em;
  }

  .post-author figure {
    float: right;
    margin-right: 0;
    margin-left: 30px;
  }

  .post-nav svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .post-nav .nav-previous {
    padding-left: 0;
    padding-right: 30px;
  }

  .post-nav .nav-previous svg {
    left: auto;
    right: -40px;
  }

  .post-nav .nav-previous:hover svg {
    -webkit-transform: translateX(7px) rotate(180deg);
    transform: translateX(7px) rotate(180deg);
  }

  .post-nav .nav-next {
    text-align: left;
    padding-right: 0;
    padding-left: 30px;
  }

  .post-nav .nav-next svg {
    right: auto;
    left: -40px;
  }

  .post-nav .nav-next:hover svg {
    -webkit-transform: translateX(-7px) rotate(180deg);
    transform: translateX(-7px) rotate(180deg);
  }

  .share-links > span {
    margin-right: 0;
    margin-left: 1em;
  }

  .comment-form .input-placeholder {
    left: auto;
    right: 0;
  }

  .comment-author .avatar {
    margin-right: 0;
    margin-left: 18px;
  }

  .comment .comment-extras,
  .comment-content {
    padding-left: 0;
    padding-right: 93px;
  }

  .comment-content {
    margin-top: 0;
  }

  .comment-list ol.children .comment .comment-extras,
  .comment-list ol.children .comment-content {
    padding-left: 0;
    padding-right: 68px;
  }

  .comment-list ol.children .comment-content {
    margin-top: 15px;
  }

  .ld-pf-btn + .ld-pf-btn {
    margin-left: 0 !important;
    margin-right: 0.5rem;
  }

  .ld-pf-btn i.icon-arrow-right-material:before {
    content: "\e92d";
  }

  .pf-extra-arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .pf-info hr {
    left: auto;
    right: 0;
  }

  .pf-related-title .title-shad {
    left: auto;
    right: 0;
  }

  .ld_widget_recent_entries li figure {
    margin-right: 0;
    margin-left: 10px;
  }

  .ld-bsp-img + .ld-bsp-info {
    margin-left: 0;
    margin-right: 17px;
  }

  .flickity-prev-next-button.next{
    left: 30px;
    right: auto !important;
  }
  .flickity-prev-next-button.previous{
    left: auto !important;
    right: 30px;
  }

  h5, .h5{
    line-height: 2rem;
  }
  .text-left{
    text-align: right !important;
  }
  .black-trans-box .box-fly-title{
    right: 15px;
    left: auto !important;
  }

}
@media screen and (min-width: 1200px) {
  html[dir="rtl"] {
    .fancy-title .carousel-nav {
      right: auto;
      left: 0;
    }
    .lqd-promo-reverse .lqd-promo-content {
      padding-right: 0;
      padding-left: 15px;
    }
  }
}

@media screen and (min-width: 992px) {
  html[dir="rtl"] {

    .filter-list-inline li + li {
      margin-left: 0;
      margin-right: 1.45em;
    }

    .tabs-nav-items-bordered .tabs-nav li:last-child {
      border-left-width: 1px;
    }

    .ld-masked-image {
      top: -50vh;
      left: -35vw;
      right: -10vw;
    }

    .lqd-parallax-images .liquid-img-group-single:last-child {
      left: auto;
      right: -45%;
    }

    .lqd-parallax-images-2 .liquid-img-group-container:first-child {
      margin-left: -40%;
      margin-right: 60%;
    }

    .lqd-parallax-images-2 .liquid-img-group-container:nth-child(2) {
      left: auto;
      right: 30%;
    }

    .lqd-parallax-images-2 .liquid-img-group-container:last-child {
      left: auto;
      right: 17%;
    }

    .lqd-parallax-images-3 .liquid-img-group-single:first-child {
      margin-left: 0;
      margin-right: -20%;
    }

    .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
      left: auto;
      right: 0;
    }

    .lqd-parallax-images-3 .liquid-img-group-single:last-child {
      left: auto;
      right: -8%;
    }

    .lqd-parallax-images-4 .liquid-img-group-container:nth-child(2) {
      margin-left: 0;
      margin-right: -30px;
    }

    .lqd-parallax-images-4 .liquid-img-group-container:last-child .liquid-img-group-single {
      margin-left: 0;
      margin-right: -68%;
      left: auto;
      right: -80%;
    }

    .lqd-parallax-images-6 .liquid-img-group-single:first-of-type {
      right: auto;
      left: -45%;
    }

    .lqd-parallax-images-6 .liquid-img-group-single:nth-of-type(2) {
      right: auto;
      left: -5%;
    }

    .lqd-parallax-images-6 .liquid-img-group-single:last-of-type {
      left: auto;
      right: -20%;
    }

    .liquid-img-group-content.content-fixed-left {
      margin-left: 0;
      margin-right: 1.5em;
    }
  }
}

@media (min-width: 768px) {
  html[dir="rtl"] {
    .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
      left: auto;
      right: 20%;
    }

    .lqd-parallax-images-3 .liquid-img-group-single:last-child {
      left: 0;
      right: 22.5%;
    }
  }
}



