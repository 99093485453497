@font-face {
  font-family: 'liquid-icon';
  src: url('../fonts/liquid-icon/liquid-icon.woff') format('woff'),
    url('../fonts/liquid-icon/liquid-icon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'liquid-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-et-desktop:before {
  content: "\ecae";
}
.icon-et-laptop:before {
  content: "\ecaf";
}
.icon-et-tablet:before {
  content: "\ecb0";
}
.icon-et-phone:before {
  content: "\ecb1";
}
.icon-et-adjustments:before {
  content: "\ecb2";
}
.icon-et-alarmclock:before {
  content: "\ecb3";
}
.icon-et-anchor:before {
  content: "\ecb4";
}
.icon-et-aperture:before {
  content: "\ecb5";
}
.icon-et-attachments:before {
  content: "\ecb6";
}
.icon-et-bargraph:before {
  content: "\ecb7";
}
.icon-et-basket:before {
  content: "\ecb8";
}
.icon-et-beaker:before {
  content: "\ecb9";
}
.icon-et-bike:before {
  content: "\ecba";
}
.icon-et-book-open:before {
  content: "\ecbb";
}
.icon-et-briefcase:before {
  content: "\ecbc";
}
.icon-et-browser:before {
  content: "\ecbd";
}
.icon-et-calendar:before {
  content: "\ecbe";
}
.icon-et-camera:before {
  content: "\ecbf";
}
.icon-et-caution:before {
  content: "\ecc0";
}
.icon-et-chat:before {
  content: "\ecc1";
}
.icon-et-circle-compass:before {
  content: "\ecc2";
}
.icon-et-clipboard:before {
  content: "\ecc3";
}
.icon-et-clock:before {
  content: "\ecc4";
}
.icon-et-cloud:before {
  content: "\ecc5";
}
.icon-et-compass:before {
  content: "\ecc6";
}
.icon-et-dial:before {
  content: "\ecc7";
}
.icon-et-document:before {
  content: "\ecc8";
}
.icon-et-documents:before {
  content: "\ecc9";
}
.icon-et-download:before {
  content: "\ecca";
}
.icon-et-edit:before {
  content: "\eccb";
}
.icon-et-envelope:before {
  content: "\eccc";
}
.icon-et-expand:before {
  content: "\eccd";
}
.icon-et-flag:before {
  content: "\ecce";
}
.icon-et-focus:before {
  content: "\eccf";
}
.icon-et-gears:before {
  content: "\ecd0";
}
.icon-et-genius:before {
  content: "\ecd1";
}
.icon-et-gift:before {
  content: "\ecd2";
}
.icon-et-global:before {
  content: "\ecd3";
}
.icon-et-globe:before {
  content: "\ecd4";
}
.icon-et-grid:before {
  content: "\ecd5";
}
.icon-et-hazardous:before {
  content: "\ecd6";
}
.icon-et-heart:before {
  content: "\ecd7";
}
.icon-et-hotairballoon:before {
  content: "\ecd8";
}
.icon-et-hourglass:before {
  content: "\ecd9";
}
.icon-et-key:before {
  content: "\ecda";
}
.icon-et-layers:before {
  content: "\ecdb";
}
.icon-et-lifesaver:before {
  content: "\ecdc";
}
.icon-et-lightbulb:before {
  content: "\ecdd";
}
.icon-et-linegraph:before {
  content: "\ecde";
}
.icon-et-lock:before {
  content: "\ecdf";
}
.icon-et-magnifying-glass:before {
  content: "\ece0";
}
.icon-et-map-pin:before {
  content: "\ece1";
}
.icon-et-map:before {
  content: "\ece2";
}
.icon-et-megaphone:before {
  content: "\ece3";
}
.icon-et-mic:before {
  content: "\ece4";
}
.icon-et-mobile:before {
  content: "\ece5";
}
.icon-et-newspaper:before {
  content: "\ece6";
}
.icon-et-notebook:before {
  content: "\ece7";
}
.icon-et-paintbrush:before {
  content: "\ece8";
}
.icon-et-paperclip:before {
  content: "\ece9";
}
.icon-et-pencil:before {
  content: "\ecea";
}
.icon-et-picture:before {
  content: "\eceb";
}
.icon-et-pictures:before {
  content: "\ecec";
}
.icon-et-video:before {
  content: "\eced";
}
.icon-et-piechart:before {
  content: "\ecee";
}
.icon-et-presentation:before {
  content: "\ecef";
}
.icon-et-pricetags:before {
  content: "\ecf0";
}
.icon-et-printer:before {
  content: "\ecf1";
}
.icon-et-profile-female:before {
  content: "\ecf2";
}
.icon-et-profile-male:before {
  content: "\ecf3";
}
.icon-et-puzzle:before {
  content: "\ecf4";
}
.icon-et-quote:before {
  content: "\ecf5";
}
.icon-et-recycle:before {
  content: "\ecf6";
}
.icon-et-refresh:before {
  content: "\ecf7";
}
.icon-et-ribbon:before {
  content: "\ecf8";
}
.icon-et-scissors:before {
  content: "\ecf9";
}
.icon-et-scope:before {
  content: "\ecfa";
}
.icon-et-search:before {
  content: "\ecfb";
}
.icon-et-shield:before {
  content: "\ecfc";
}
.icon-et-speedometer:before {
  content: "\ecfd";
}
.icon-et-strategy:before {
  content: "\ecfe";
}
.icon-et-streetsign:before {
  content: "\ecff";
}
.icon-et-telescope:before {
  content: "\ed00";
}
.icon-et-toolbox:before {
  content: "\ed01";
}
.icon-et-tools-2:before {
  content: "\ed02";
}
.icon-et-tools:before {
  content: "\ed03";
}
.icon-et-traget:before {
  content: "\ed04";
}
.icon-et-trophy:before {
  content: "\ed05";
}
.icon-et-upload:before {
  content: "\ed06";
}
.icon-et-wallet:before {
  content: "\ed07";
}
.icon-et-wine:before {
  content: "\ed08";
}
.icon-et-sad:before {
  content: "\ed09";
}
.icon-et-happy:before {
  content: "\ed0a";
}
.icon-md-add-circle-outline:before {
  content: "\e955";
}
.icon-md-add-circle:before {
  content: "\e958";
}
.icon-md-add:before {
  content: "\e959";
}
.icon-md-airplane:before {
  content: "\e95a";
}
.icon-md-alarm:before {
  content: "\e95b";
}
.icon-md-albums:before {
  content: "\e95c";
}
.icon-md-alert:before {
  content: "\e95d";
}
.icon-md-american-football:before {
  content: "\e95e";
}
.icon-md-analytics:before {
  content: "\e95f";
}
.icon-md-aperture:before {
  content: "\e960";
}
.icon-md-apps:before {
  content: "\e961";
}
.icon-md-appstore:before {
  content: "\e962";
}
.icon-md-archive:before {
  content: "\e963";
}
.icon-md-arrow-back:before {
  content: "\e96e";
}
.icon-md-arrow-down:before {
  content: "\e96f";
}
.icon-md-arrow-dropdown-circle:before {
  content: "\e976";
}
.icon-md-arrow-dropdown:before {
  content: "\e977";
}
.icon-md-arrow-dropleft-circle:before {
  content: "\e97b";
}
.icon-md-arrow-dropleft:before {
  content: "\e97c";
}
.icon-md-arrow-dropright-circle:before {
  content: "\e97e";
}
.icon-md-arrow-dropright:before {
  content: "\e980";
}
.icon-md-arrow-dropup-circle:before {
  content: "\e986";
}
.icon-md-arrow-dropup:before {
  content: "\e987";
}
.icon-md-arrow-forward:before {
  content: "\e988";
}
.icon-md-arrow-round-back:before {
  content: "\e997";
}
.icon-md-arrow-round-down:before {
  content: "\e99d";
}
.icon-md-arrow-round-forward:before {
  content: "\e99e";
}
.icon-md-arrow-round-up:before {
  content: "\e9ab";
}
.icon-md-arrow-up:before {
  content: "\e9b0";
}
.icon-md-at:before {
  content: "\e9b1";
}
.icon-md-attach:before {
  content: "\e9c8";
}
.icon-md-backspace:before {
  content: "\e9cd";
}
.icon-md-barcode:before {
  content: "\e9d0";
}
.icon-md-baseball:before {
  content: "\e9d1";
}
.icon-md-basket:before {
  content: "\e9d2";
}
.icon-md-basketball:before {
  content: "\e9d3";
}
.icon-md-battery-charging:before {
  content: "\e9d4";
}
.icon-md-battery-dead:before {
  content: "\e9d6";
}
.icon-md-battery-full:before {
  content: "\e9d7";
}
.icon-md-beaker:before {
  content: "\e9db";
}
.icon-md-bed:before {
  content: "\e9dc";
}
.icon-md-beer:before {
  content: "\e9dd";
}
.icon-md-bicycle:before {
  content: "\e9de";
}
.icon-md-bluetooth:before {
  content: "\ea25";
}
.icon-md-boat:before {
  content: "\ea26";
}
.icon-md-body:before {
  content: "\ea2a";
}
.icon-md-bonfire:before {
  content: "\ea2b";
}
.icon-md-book:before {
  content: "\ea2c";
}
.icon-md-bookmark:before {
  content: "\ea2d";
}
.icon-md-bookmarks:before {
  content: "\ea2e";
}
.icon-md-bowtie:before {
  content: "\ea2f";
}
.icon-md-briefcase:before {
  content: "\ea30";
}
.icon-md-browsers:before {
  content: "\ea31";
}
.icon-md-brush:before {
  content: "\ea7e";
}
.icon-md-bug:before {
  content: "\ea7f";
}
.icon-md-build:before {
  content: "\ea82";
}
.icon-md-bulb:before {
  content: "\ea83";
}
.icon-md-bus:before {
  content: "\ea84";
}
.icon-md-business:before {
  content: "\ea8b";
}
.icon-md-cafe:before {
  content: "\ea8c";
}
.icon-md-calculator:before {
  content: "\ea8d";
}
.icon-md-calendar:before {
  content: "\ea90";
}
.icon-md-call:before {
  content: "\ea91";
}
.icon-md-camera:before {
  content: "\ea92";
}
.icon-md-car:before {
  content: "\ea93";
}
.icon-md-card:before {
  content: "\ea94";
}
.icon-md-cart:before {
  content: "\ea95";
}
.icon-md-cash:before {
  content: "\ea96";
}
.icon-md-cellular:before {
  content: "\ea97";
}
.icon-md-chatboxes:before {
  content: "\ea98";
}
.icon-md-chatbubbles:before {
  content: "\ea99";
}
.icon-md-checkbox-outline:before {
  content: "\ea9a";
}
.icon-md-checkbox:before {
  content: "\ea9b";
}
.icon-md-checkmark-circle-outline:before {
  content: "\ea9c";
}
.icon-md-checkmark-circle:before {
  content: "\ea9d";
}
.icon-md-checkmark:before {
  content: "\ea9e";
}
.icon-md-clipboard:before {
  content: "\ea9f";
}
.icon-md-clock:before {
  content: "\eaa0";
}
.icon-md-close-circle-outline:before {
  content: "\eaa1";
}
.icon-md-close-circle:before {
  content: "\eaa2";
}
.icon-md-close:before {
  content: "\eaa3";
}
.icon-md-cloud-circle:before {
  content: "\eaa4";
}
.icon-md-cloud-done:before {
  content: "\eaa5";
}
.icon-md-cloud-download:before {
  content: "\eaa6";
}
.icon-md-cloud-outline:before {
  content: "\eaa7";
}
.icon-md-cloud-upload:before {
  content: "\eaac";
}
.icon-md-cloud:before {
  content: "\eaae";
}
.icon-md-cloudy-night:before {
  content: "\eaaf";
}
.icon-md-cloudy:before {
  content: "\eab0";
}
.icon-md-code-download:before {
  content: "\ebc9";
}
.icon-md-code-working:before {
  content: "\ebca";
}
.icon-md-code:before {
  content: "\ebcc";
}
.icon-md-cog:before {
  content: "\ebcd";
}
.icon-md-color-fill:before {
  content: "\ebce";
}
.icon-md-color-filter:before {
  content: "\ebcf";
}
.icon-md-color-palette:before {
  content: "\ebd0";
}
.icon-md-color-wand:before {
  content: "\ebd1";
}
.icon-md-compass:before {
  content: "\ebd2";
}
.icon-md-construct:before {
  content: "\ebd3";
}
.icon-md-contact:before {
  content: "\ebd4";
}
.icon-md-contacts:before {
  content: "\ebd5";
}
.icon-md-contract:before {
  content: "\ebd6";
}
.icon-md-contrast:before {
  content: "\ebd7";
}
.icon-md-copy:before {
  content: "\ebd8";
}
.icon-md-create:before {
  content: "\ebd9";
}
.icon-md-crop:before {
  content: "\ebda";
}
.icon-md-cube:before {
  content: "\ebdb";
}
.icon-md-cut:before {
  content: "\ebdc";
}
.icon-md-desktop:before {
  content: "\ebdd";
}
.icon-md-disc:before {
  content: "\ebde";
}
.icon-md-document:before {
  content: "\ebdf";
}
.icon-md-done-all:before {
  content: "\ebe0";
}
.icon-md-download:before {
  content: "\ebe1";
}
.icon-md-easel:before {
  content: "\ebe2";
}
.icon-md-egg:before {
  content: "\ebe3";
}
.icon-md-exit:before {
  content: "\ebe4";
}
.icon-md-expand:before {
  content: "\ebe5";
}
.icon-md-eye-off:before {
  content: "\ebe6";
}
.icon-md-eye:before {
  content: "\ebe7";
}
.icon-md-fastforward:before {
  content: "\ebe8";
}
.icon-md-female:before {
  content: "\ebe9";
}
.icon-md-filing:before {
  content: "\ebea";
}
.icon-md-film:before {
  content: "\ebeb";
}
.icon-md-finger-print:before {
  content: "\ebec";
}
.icon-md-fitness:before {
  content: "\ebed";
}
.icon-md-flag:before {
  content: "\ebee";
}
.icon-md-flame:before {
  content: "\ebef";
}
.icon-md-flash-off:before {
  content: "\ebf0";
}
.icon-md-flash:before {
  content: "\ebf1";
}
.icon-md-flashlight:before {
  content: "\ebf2";
}
.icon-md-flask:before {
  content: "\ebf3";
}
.icon-md-flower:before {
  content: "\ebf4";
}
.icon-md-folder-open:before {
  content: "\ebf5";
}
.icon-md-folder:before {
  content: "\ebf6";
}
.icon-md-football:before {
  content: "\ebf7";
}
.icon-md-funnel:before {
  content: "\ebf8";
}
.icon-md-gift:before {
  content: "\ebf9";
}
.icon-md-git-branch:before {
  content: "\ebfa";
}
.icon-md-git-commit:before {
  content: "\ebfb";
}
.icon-md-git-compare:before {
  content: "\ebfc";
}
.icon-md-git-merge:before {
  content: "\ebfd";
}
.icon-md-git-network:before {
  content: "\ebfe";
}
.icon-md-git-pull-request:before {
  content: "\ebff";
}
.icon-md-glasses:before {
  content: "\ec00";
}
.icon-md-globe:before {
  content: "\ec01";
}
.icon-md-grid:before {
  content: "\ec02";
}
.icon-md-hammer:before {
  content: "\ec03";
}
.icon-md-hand:before {
  content: "\ec04";
}
.icon-md-happy:before {
  content: "\ec05";
}
.icon-md-headset:before {
  content: "\ec06";
}
.icon-md-heart-dislike:before {
  content: "\ec07";
}
.icon-md-heart-empty:before {
  content: "\ec08";
}
.icon-md-heart-half:before {
  content: "\ec09";
}
.icon-md-heart:before {
  content: "\ec0a";
}
.icon-md-help-buoy:before {
  content: "\ec0b";
}
.icon-md-help-circle-outline:before {
  content: "\ec0c";
}
.icon-md-help-circle:before {
  content: "\ec0d";
}
.icon-md-help:before {
  content: "\ec0e";
}
.icon-md-home:before {
  content: "\ec0f";
}
.icon-md-hourglass:before {
  content: "\ec10";
}
.icon-md-ice-cream:before {
  content: "\ec11";
}
.icon-md-image:before {
  content: "\ec12";
}
.icon-md-images:before {
  content: "\ec13";
}
.icon-md-infinite:before {
  content: "\ec14";
}
.icon-md-information-circle-outline:before {
  content: "\ec15";
}
.icon-md-information-circle:before {
  content: "\ec16";
}
.icon-md-information:before {
  content: "\ec17";
}
.icon-md-jet:before {
  content: "\ec18";
}
.icon-md-journal:before {
  content: "\ec19";
}
.icon-md-key:before {
  content: "\ec1a";
}
.icon-md-keypad:before {
  content: "\ec1b";
}
.icon-md-laptop:before {
  content: "\ec1c";
}
.icon-md-leaf:before {
  content: "\ec1d";
}
.icon-md-link:before {
  content: "\ec1e";
}
.icon-md-list-box:before {
  content: "\ec1f";
}
.icon-md-list:before {
  content: "\ec20";
}
.icon-md-locate:before {
  content: "\ec21";
}
.icon-md-lock:before {
  content: "\ec22";
}
.icon-md-log-in:before {
  content: "\ec23";
}
.icon-md-log-out:before {
  content: "\ec24";
}
.icon-md-magnet:before {
  content: "\ec25";
}
.icon-md-mail-open:before {
  content: "\ec26";
}
.icon-md-mail-unread:before {
  content: "\ec27";
}
.icon-md-mail:before {
  content: "\ec28";
}
.icon-md-male:before {
  content: "\ec29";
}
.icon-md-man:before {
  content: "\ec2a";
}
.icon-md-map:before {
  content: "\ec2b";
}
.icon-md-medal:before {
  content: "\ec2c";
}
.icon-md-medical:before {
  content: "\ec2d";
}
.icon-md-medkit:before {
  content: "\ec2e";
}
.icon-md-megaphone:before {
  content: "\ec2f";
}
.icon-md-menu:before {
  content: "\ec30";
}
.icon-md-mic-off:before {
  content: "\ec31";
}
.icon-md-mic:before {
  content: "\ec32";
}
.icon-md-microphone:before {
  content: "\ec33";
}
.icon-md-moon:before {
  content: "\ec34";
}
.icon-md-more:before {
  content: "\ec35";
}
.icon-md-move:before {
  content: "\ec36";
}
.icon-md-musical-note:before {
  content: "\ec37";
}
.icon-md-musical-notes:before {
  content: "\ec38";
}
.icon-md-navigate:before {
  content: "\ec39";
}
.icon-md-notifications-off:before {
  content: "\ec3a";
}
.icon-md-notifications-outline:before {
  content: "\ec3b";
}
.icon-md-notifications:before {
  content: "\ec3c";
}
.icon-md-nuclear:before {
  content: "\ec3d";
}
.icon-md-nutrition:before {
  content: "\ec3e";
}
.icon-md-open:before {
  content: "\ec3f";
}
.icon-md-options:before {
  content: "\ec40";
}
.icon-md-outlet:before {
  content: "\ec41";
}
.icon-md-paper-plane:before {
  content: "\ec42";
}
.icon-md-paper:before {
  content: "\ec43";
}
.icon-md-partly-sunny:before {
  content: "\ec44";
}
.icon-md-pause:before {
  content: "\ec45";
}
.icon-md-paw:before {
  content: "\ec46";
}
.icon-md-people:before {
  content: "\ec47";
}
.icon-md-person-add:before {
  content: "\ec48";
}
.icon-md-person:before {
  content: "\ec49";
}
.icon-md-phone-landscape:before {
  content: "\ec4a";
}
.icon-md-phone-portrait:before {
  content: "\ec4b";
}
.icon-md-photos:before {
  content: "\ec4c";
}
.icon-md-pie:before {
  content: "\ec4d";
}
.icon-md-pin:before {
  content: "\ec4e";
}
.icon-md-pint:before {
  content: "\ec4f";
}
.icon-md-pizza:before {
  content: "\ec50";
}
.icon-md-planet:before {
  content: "\ec51";
}
.icon-md-play-circle:before {
  content: "\ec52";
}
.icon-md-play:before {
  content: "\ec53";
}
.icon-md-podium:before {
  content: "\ec54";
}
.icon-md-power:before {
  content: "\ec55";
}
.icon-md-pricetag:before {
  content: "\ec56";
}
.icon-md-pricetags:before {
  content: "\ec57";
}
.icon-md-print:before {
  content: "\ec58";
}
.icon-md-pulse:before {
  content: "\ec59";
}
.icon-md-qr-scanner:before {
  content: "\ec5a";
}
.icon-md-quote:before {
  content: "\ec5b";
}
.icon-md-radio-button-off:before {
  content: "\ec5c";
}
.icon-md-radio-button-on:before {
  content: "\ec5d";
}
.icon-md-radio:before {
  content: "\ec5e";
}
.icon-md-rainy:before {
  content: "\ec5f";
}
.icon-md-recording:before {
  content: "\ec60";
}
.icon-md-redo:before {
  content: "\ec61";
}
.icon-md-refresh-circle:before {
  content: "\ec62";
}
.icon-md-refresh:before {
  content: "\ec63";
}
.icon-md-remove-circle-outline:before {
  content: "\ec64";
}
.icon-md-remove-circle:before {
  content: "\ec65";
}
.icon-md-remove:before {
  content: "\ec66";
}
.icon-md-reorder:before {
  content: "\ec67";
}
.icon-md-repeat:before {
  content: "\ec68";
}
.icon-md-resize:before {
  content: "\ec69";
}
.icon-md-restaurant:before {
  content: "\ec6a";
}
.icon-md-return-left:before {
  content: "\ec6b";
}
.icon-md-return-right:before {
  content: "\ec6c";
}
.icon-md-reverse-camera:before {
  content: "\ec6d";
}
.icon-md-rewind:before {
  content: "\ec6e";
}
.icon-md-ribbon:before {
  content: "\ec6f";
}
.icon-md-rocket:before {
  content: "\ec70";
}
.icon-md-rose:before {
  content: "\ec71";
}
.icon-md-sad:before {
  content: "\ec72";
}
.icon-md-save:before {
  content: "\ec73";
}
.icon-md-school:before {
  content: "\ec74";
}
.icon-md-search:before {
  content: "\ec75";
}
.icon-md-send:before {
  content: "\ec76";
}
.icon-md-settings:before {
  content: "\ec77";
}
.icon-md-share-alt:before {
  content: "\ec78";
}
.icon-md-share:before {
  content: "\ec79";
}
.icon-md-shirt:before {
  content: "\ec7a";
}
.icon-md-shuffle:before {
  content: "\ec7b";
}
.icon-md-skip-backward:before {
  content: "\ec7c";
}
.icon-md-skip-forward:before {
  content: "\ec7d";
}
.icon-md-snow:before {
  content: "\ec7e";
}
.icon-md-speedometer:before {
  content: "\ec7f";
}
.icon-md-square-outline:before {
  content: "\ec80";
}
.icon-md-square:before {
  content: "\ec81";
}
.icon-md-star-half:before {
  content: "\ec82";
}
.icon-md-star-outline:before {
  content: "\ec83";
}
.icon-md-star:before {
  content: "\ec84";
}
.icon-md-stats:before {
  content: "\ec85";
}
.icon-md-stopwatch:before {
  content: "\ec86";
}
.icon-md-subway:before {
  content: "\ec87";
}
.icon-md-sunny:before {
  content: "\ec88";
}
.icon-md-swap:before {
  content: "\ec89";
}
.icon-md-switch:before {
  content: "\ec8a";
}
.icon-md-sync:before {
  content: "\ec8b";
}
.icon-md-tablet-landscape:before {
  content: "\ec8c";
}
.icon-md-tablet-portrait:before {
  content: "\ec8d";
}
.icon-md-tennisball:before {
  content: "\ec8e";
}
.icon-md-text:before {
  content: "\ec8f";
}
.icon-md-thermometer:before {
  content: "\ec90";
}
.icon-md-thumbs-down:before {
  content: "\ec91";
}
.icon-md-thumbs-up:before {
  content: "\ec92";
}
.icon-md-thunderstorm:before {
  content: "\ec93";
}
.icon-md-time:before {
  content: "\ec94";
}
.icon-md-timer:before {
  content: "\ec95";
}
.icon-md-today:before {
  content: "\ec96";
}
.icon-md-train:before {
  content: "\ec97";
}
.icon-md-transgender:before {
  content: "\ec98";
}
.icon-md-trash:before {
  content: "\ec99";
}
.icon-md-trending-down:before {
  content: "\ec9a";
}
.icon-md-trending-up:before {
  content: "\ec9b";
}
.icon-md-trophy:before {
  content: "\ec9c";
}
.icon-md-tv:before {
  content: "\ec9d";
}
.icon-md-umbrella:before {
  content: "\ec9e";
}
.icon-md-undo:before {
  content: "\ec9f";
}
.icon-md-unlock:before {
  content: "\eca0";
}
.icon-md-videocam:before {
  content: "\eca1";
}
.icon-md-volume-high:before {
  content: "\eca2";
}
.icon-md-volume-low:before {
  content: "\eca3";
}
.icon-md-volume-mute:before {
  content: "\eca4";
}
.icon-md-volume-off:before {
  content: "\eca5";
}
.icon-md-walk:before {
  content: "\eca6";
}
.icon-md-wallet:before {
  content: "\eca7";
}
.icon-md-warning:before {
  content: "\eca8";
}
.icon-md-watch:before {
  content: "\eca9";
}
.icon-md-water:before {
  content: "\ecaa";
}
.icon-md-wifi:before {
  content: "\ecab";
}
.icon-md-wine:before {
  content: "\ecac";
}
.icon-md-woman:before {
  content: "\ecad";
}
.icon-num-10:before {
  content: "\e937";
}
.icon-num-9:before {
  content: "\e938";
}
.icon-num-8:before {
  content: "\e939";
}
.icon-num-7:before {
  content: "\e93a";
}
.icon-num-6:before {
  content: "\e93b";
}
.icon-num-5:before {
  content: "\e93c";
}
.icon-num-4:before {
  content: "\e93d";
}
.icon-num-3:before {
  content: "\e93e";
}
.icon-num-2:before {
  content: "\e951";
}
.icon-num-1:before {
  content: "\e954";
}
.icon-arrow-up-material:before {
  content: "\e92c";
}
.icon-arrow-left-material:before {
  content: "\e92d";
}
.icon-arrow-down-material:before {
  content: "\e935";
}
.icon-arrow-right-material:before {
  content: "\e936";
}
.icon-ld-search:before {
  content: "\e92a";
}
.icon-ld-cart:before {
  content: "\e929";
}
.icon-ion-ios-add-circle-outline:before {
  content: "\f100";
}
.icon-ion-ios-add-circle:before {
  content: "\f101";
}
.icon-ion-ios-add:before {
  content: "\f102";
}
.icon-ion-ios-airplane:before {
  content: "\f137";
}
.icon-ion-ios-alarm:before {
  content: "\f3c8";
}
.icon-ion-ios-albums:before {
  content: "\f3ca";
}
.icon-ion-ios-alert:before {
  content: "\f104";
}
.icon-ion-ios-american-football:before {
  content: "\f106";
}
.icon-ion-ios-analytics:before {
  content: "\f3ce";
}
.icon-ion-ios-aperture:before {
  content: "\f108";
}
.icon-ion-ios-apps:before {
  content: "\f10a";
}
.icon-ion-ios-appstore:before {
  content: "\f10c";
}
.icon-ion-ios-archive:before {
  content: "\f10e";
}
.icon-ion-ios-arrow-back:before {
  content: "\f3cf";
}
.icon-ion-ios-arrow-down:before {
  content: "\f3d0";
}
.icon-ion-ios-arrow-dropdown-circle:before {
  content: "\f125";
}
.icon-ion-ios-arrow-dropdown:before {
  content: "\f110";
}
.icon-ion-ios-arrow-dropleft-circle:before {
  content: "\f129";
}
.icon-ion-ios-arrow-dropleft:before {
  content: "\f112";
}
.icon-ion-ios-arrow-dropright-circle:before {
  content: "\f12b";
}
.icon-ion-ios-arrow-dropright:before {
  content: "\f114";
}
.icon-ion-ios-arrow-dropup-circle:before {
  content: "\f12d";
}
.icon-ion-ios-arrow-dropup:before {
  content: "\f116";
}
.icon-ion-ios-arrow-forward:before {
  content: "\f3d1";
}
.icon-ion-ios-arrow-round-back:before {
  content: "\f117";
}
.icon-ion-ios-arrow-round-down:before {
  content: "\f118";
}
.icon-ion-ios-arrow-round-forward:before {
  content: "\f119";
}
.icon-ion-ios-arrow-round-up:before {
  content: "\f11a";
}
.icon-ion-ios-arrow-up:before {
  content: "\f3d8";
}
.icon-ion-ios-at:before {
  content: "\f3da";
}
.icon-ion-ios-attach:before {
  content: "\f11b";
}
.icon-ion-ios-backspace:before {
  content: "\f11d";
}
.icon-ion-ios-barcode:before {
  content: "\f3dc";
}
.icon-ion-ios-baseball:before {
  content: "\f3de";
}
.icon-ion-ios-basket:before {
  content: "\f11f";
}
.icon-ion-ios-basketball:before {
  content: "\f3e0";
}
.icon-ion-ios-battery-charging:before {
  content: "\f120";
}
.icon-ion-ios-battery-dead:before {
  content: "\f121";
}
.icon-ion-ios-battery-full:before {
  content: "\f122";
}
.icon-ion-ios-beaker:before {
  content: "\f124";
}
.icon-ion-ios-bed:before {
  content: "\f139";
}
.icon-ion-ios-beer:before {
  content: "\f126";
}
.icon-ion-ios-bicycle:before {
  content: "\f127";
}
.icon-ion-ios-bluetooth:before {
  content: "\f128";
}
.icon-ion-ios-boat:before {
  content: "\f12a";
}
.icon-ion-ios-body:before {
  content: "\f3e4";
}
.icon-ion-ios-bonfire:before {
  content: "\f12c";
}
.icon-ion-ios-book:before {
  content: "\f3e8";
}
.icon-ion-ios-bookmark:before {
  content: "\f12e";
}
.icon-ion-ios-bookmarks:before {
  content: "\f3ea";
}
.icon-ion-ios-bowtie:before {
  content: "\f130";
}
.icon-ion-ios-briefcase:before {
  content: "\f3ee";
}
.icon-ion-ios-browsers:before {
  content: "\f3f0";
}
.icon-ion-ios-brush:before {
  content: "\f132";
}
.icon-ion-ios-bug:before {
  content: "\f134";
}
.icon-ion-ios-build:before {
  content: "\f136";
}
.icon-ion-ios-bulb:before {
  content: "\f138";
}
.icon-ion-ios-bus:before {
  content: "\f13a";
}
.icon-ion-ios-business:before {
  content: "\f1a3";
}
.icon-ion-ios-cafe:before {
  content: "\f13c";
}
.icon-ion-ios-calculator:before {
  content: "\f3f2";
}
.icon-ion-ios-calendar:before {
  content: "\f3f4";
}
.icon-ion-ios-call:before {
  content: "\f13e";
}
.icon-ion-ios-camera:before {
  content: "\f3f6";
}
.icon-ion-ios-car:before {
  content: "\f140";
}
.icon-ion-ios-card:before {
  content: "\f142";
}
.icon-ion-ios-cart:before {
  content: "\f3f8";
}
.icon-ion-ios-cash:before {
  content: "\f144";
}
.icon-ion-ios-cellular:before {
  content: "\f13d";
}
.icon-ion-ios-chatboxes:before {
  content: "\f3fa";
}
.icon-ion-ios-chatbubbles:before {
  content: "\f146";
}
.icon-ion-ios-checkbox-outline:before {
  content: "\f147";
}
.icon-ion-ios-checkbox:before {
  content: "\f148";
}
.icon-ion-ios-checkmark-circle-outline:before {
  content: "\f149";
}
.icon-ion-ios-checkmark-circle:before {
  content: "\f14a";
}
.icon-ion-ios-checkmark:before {
  content: "\f3ff";
}
.icon-ion-ios-clipboard:before {
  content: "\f14c";
}
.icon-ion-ios-clock:before {
  content: "\f403";
}
.icon-ion-ios-close-circle-outline:before {
  content: "\f14d";
}
.icon-ion-ios-close-circle:before {
  content: "\f14e";
}
.icon-ion-ios-close:before {
  content: "\f406";
}
.icon-ion-ios-cloud-circle:before {
  content: "\f152";
}
.icon-ion-ios-cloud-done:before {
  content: "\f154";
}
.icon-ion-ios-cloud-download:before {
  content: "\f408";
}
.icon-ion-ios-cloud-outline:before {
  content: "\f409";
}
.icon-ion-ios-cloud-upload:before {
  content: "\f40b";
}
.icon-ion-ios-cloud:before {
  content: "\f40c";
}
.icon-ion-ios-cloudy-night:before {
  content: "\f40e";
}
.icon-ion-ios-cloudy:before {
  content: "\f410";
}
.icon-ion-ios-code-download:before {
  content: "\f155";
}
.icon-ion-ios-code-working:before {
  content: "\f156";
}
.icon-ion-ios-code:before {
  content: "\f157";
}
.icon-ion-ios-cog:before {
  content: "\f412";
}
.icon-ion-ios-color-fill:before {
  content: "\f159";
}
.icon-ion-ios-color-filter:before {
  content: "\f414";
}
.icon-ion-ios-color-palette:before {
  content: "\f15b";
}
.icon-ion-ios-color-wand:before {
  content: "\f416";
}
.icon-ion-ios-compass:before {
  content: "\f15d";
}
.icon-ion-ios-construct:before {
  content: "\f15f";
}
.icon-ion-ios-contact:before {
  content: "\f41a";
}
.icon-ion-ios-contacts:before {
  content: "\f161";
}
.icon-ion-ios-contract:before {
  content: "\f162";
}
.icon-ion-ios-contrast:before {
  content: "\f163";
}
.icon-ion-ios-copy:before {
  content: "\f41c";
}
.icon-ion-ios-create:before {
  content: "\f165";
}
.icon-ion-ios-crop:before {
  content: "\f41e";
}
.icon-ion-ios-cube:before {
  content: "\f168";
}
.icon-ion-ios-cut:before {
  content: "\f16a";
}
.icon-ion-ios-desktop:before {
  content: "\f16c";
}
.icon-ion-ios-disc:before {
  content: "\f16e";
}
.icon-ion-ios-document:before {
  content: "\f170";
}
.icon-ion-ios-done-all:before {
  content: "\f171";
}
.icon-ion-ios-download:before {
  content: "\f420";
}
.icon-ion-ios-easel:before {
  content: "\f173";
}
.icon-ion-ios-egg:before {
  content: "\f175";
}
.icon-ion-ios-exit:before {
  content: "\f177";
}
.icon-ion-ios-expand:before {
  content: "\f178";
}
.icon-ion-ios-eye-off:before {
  content: "\f17a";
}
.icon-ion-ios-eye:before {
  content: "\f425";
}
.icon-ion-ios-fastforward:before {
  content: "\f427";
}
.icon-ion-ios-female:before {
  content: "\f17b";
}
.icon-ion-ios-filing:before {
  content: "\f429";
}
.icon-ion-ios-film:before {
  content: "\f42b";
}
.icon-ion-ios-finger-print:before {
  content: "\f17c";
}
.icon-ion-ios-fitness:before {
  content: "\f1ab";
}
.icon-ion-ios-flag:before {
  content: "\f42d";
}
.icon-ion-ios-flame:before {
  content: "\f42f";
}
.icon-ion-ios-flash-off:before {
  content: "\f12f";
}
.icon-ion-ios-flash:before {
  content: "\f17e";
}
.icon-ion-ios-flashlight:before {
  content: "\f141";
}
.icon-ion-ios-flask:before {
  content: "\f431";
}
.icon-ion-ios-flower:before {
  content: "\f433";
}
.icon-ion-ios-folder-open:before {
  content: "\f180";
}
.icon-ion-ios-folder:before {
  content: "\f435";
}
.icon-ion-ios-football:before {
  content: "\f437";
}
.icon-ion-ios-funnel:before {
  content: "\f182";
}
.icon-ion-ios-gift:before {
  content: "\f191";
}
.icon-ion-ios-git-branch:before {
  content: "\f183";
}
.icon-ion-ios-git-commit:before {
  content: "\f184";
}
.icon-ion-ios-git-compare:before {
  content: "\f185";
}
.icon-ion-ios-git-merge:before {
  content: "\f186";
}
.icon-ion-ios-git-network:before {
  content: "\f187";
}
.icon-ion-ios-git-pull-request:before {
  content: "\f188";
}
.icon-ion-ios-glasses:before {
  content: "\f43f";
}
.icon-ion-ios-globe:before {
  content: "\f18a";
}
.icon-ion-ios-grid:before {
  content: "\f18c";
}
.icon-ion-ios-hammer:before {
  content: "\f18e";
}
.icon-ion-ios-hand:before {
  content: "\f190";
}
.icon-ion-ios-happy:before {
  content: "\f192";
}
.icon-ion-ios-headset:before {
  content: "\f194";
}
.icon-ion-ios-heart-dislike:before {
  content: "\f13f";
}
.icon-ion-ios-heart-empty:before {
  content: "\f19b";
}
.icon-ion-ios-heart-half:before {
  content: "\f19d";
}
.icon-ion-ios-heart:before {
  content: "\f443";
}
.icon-ion-ios-help-buoy:before {
  content: "\f196";
}
.icon-ion-ios-help-circle-outline:before {
  content: "\f197";
}
.icon-ion-ios-help-circle:before {
  content: "\f198";
}
.icon-ion-ios-help:before {
  content: "\f446";
}
.icon-ion-ios-home:before {
  content: "\f448";
}
.icon-ion-ios-hourglass:before {
  content: "\f103";
}
.icon-ion-ios-ice-cream:before {
  content: "\f19a";
}
.icon-ion-ios-image:before {
  content: "\f19c";
}
.icon-ion-ios-images:before {
  content: "\f19e";
}
.icon-ion-ios-infinite:before {
  content: "\f44a";
}
.icon-ion-ios-information-circle-outline:before {
  content: "\f19f";
}
.icon-ion-ios-information-circle:before {
  content: "\f1a0";
}
.icon-ion-ios-information:before {
  content: "\f44d";
}
.icon-ion-ios-jet:before {
  content: "\f1a5";
}
.icon-ion-ios-journal:before {
  content: "\f189";
}
.icon-ion-ios-key:before {
  content: "\f1a7";
}
.icon-ion-ios-keypad:before {
  content: "\f450";
}
.icon-ion-ios-laptop:before {
  content: "\f1a8";
}
.icon-ion-ios-leaf:before {
  content: "\f1aa";
}
.icon-ion-ios-link:before {
  content: "\f22a";
}
.icon-ion-ios-list-box:before {
  content: "\f143";
}
.icon-ion-ios-list:before {
  content: "\f454";
}
.icon-ion-ios-locate:before {
  content: "\f1ae";
}
.icon-ion-ios-lock:before {
  content: "\f1b0";
}
.icon-ion-ios-log-in:before {
  content: "\f1b1";
}
.icon-ion-ios-log-out:before {
  content: "\f1b2";
}
.icon-ion-ios-magnet:before {
  content: "\f1b4";
}
.icon-ion-ios-mail-open:before {
  content: "\f1b6";
}
.icon-ion-ios-mail-unread:before {
  content: "\f145";
}
.icon-ion-ios-mail:before {
  content: "\f1b8";
}
.icon-ion-ios-male:before {
  content: "\f1b9";
}
.icon-ion-ios-man:before {
  content: "\f1bb";
}
.icon-ion-ios-map:before {
  content: "\f1bd";
}
.icon-ion-ios-medal:before {
  content: "\f1bf";
}
.icon-ion-ios-medical:before {
  content: "\f45c";
}
.icon-ion-ios-medkit:before {
  content: "\f45e";
}
.icon-ion-ios-megaphone:before {
  content: "\f1c1";
}
.icon-ion-ios-menu:before {
  content: "\f1c3";
}
.icon-ion-ios-mic-off:before {
  content: "\f45f";
}
.icon-ion-ios-mic:before {
  content: "\f461";
}
.icon-ion-ios-microphone:before {
  content: "\f1c6";
}
.icon-ion-ios-moon:before {
  content: "\f468";
}
.icon-ion-ios-more:before {
  content: "\f1c8";
}
.icon-ion-ios-move:before {
  content: "\f1cb";
}
.icon-ion-ios-musical-note:before {
  content: "\f46b";
}
.icon-ion-ios-musical-notes:before {
  content: "\f46c";
}
.icon-ion-ios-navigate:before {
  content: "\f46e";
}
.icon-ion-ios-notifications-off:before {
  content: "\f1d1";
}
.icon-ion-ios-notifications-outline:before {
  content: "\f133";
}
.icon-ion-ios-notifications:before {
  content: "\f1d3";
}
.icon-ion-ios-nuclear:before {
  content: "\f1d5";
}
.icon-ion-ios-nutrition:before {
  content: "\f470";
}
.icon-ion-ios-open:before {
  content: "\f1d7";
}
.icon-ion-ios-options:before {
  content: "\f1d9";
}
.icon-ion-ios-outlet:before {
  content: "\f1db";
}
.icon-ion-ios-paper-plane:before {
  content: "\f1dd";
}
.icon-ion-ios-paper:before {
  content: "\f472";
}
.icon-ion-ios-partly-sunny:before {
  content: "\f1df";
}
.icon-ion-ios-pause:before {
  content: "\f478";
}
.icon-ion-ios-paw:before {
  content: "\f47a";
}
.icon-ion-ios-people:before {
  content: "\f47c";
}
.icon-ion-ios-person-add:before {
  content: "\f1e1";
}
.icon-ion-ios-person:before {
  content: "\f47e";
}
.icon-ion-ios-phone-landscape:before {
  content: "\f1e2";
}
.icon-ion-ios-phone-portrait:before {
  content: "\f1e3";
}
.icon-ion-ios-photos:before {
  content: "\f482";
}
.icon-ion-ios-pie:before {
  content: "\f484";
}
.icon-ion-ios-pin:before {
  content: "\f1e5";
}
.icon-ion-ios-pint:before {
  content: "\f486";
}
.icon-ion-ios-pizza:before {
  content: "\f1e7";
}
.icon-ion-ios-planet:before {
  content: "\f1eb";
}
.icon-ion-ios-play-circle:before {
  content: "\f113";
}
.icon-ion-ios-play:before {
  content: "\f488";
}
.icon-ion-ios-podium:before {
  content: "\f1ed";
}
.icon-ion-ios-power:before {
  content: "\f1ef";
}
.icon-ion-ios-pricetag:before {
  content: "\f48d";
}
.icon-ion-ios-pricetags:before {
  content: "\f48f";
}
.icon-ion-ios-print:before {
  content: "\f1f1";
}
.icon-ion-ios-pulse:before {
  content: "\f493";
}
.icon-ion-ios-qr-scanner:before {
  content: "\f1f3";
}
.icon-ion-ios-quote:before {
  content: "\f1f5";
}
.icon-ion-ios-radio-button-off:before {
  content: "\f1f6";
}
.icon-ion-ios-radio-button-on:before {
  content: "\f1f7";
}
.icon-ion-ios-radio:before {
  content: "\f1f9";
}
.icon-ion-ios-rainy:before {
  content: "\f495";
}
.icon-ion-ios-recording:before {
  content: "\f497";
}
.icon-ion-ios-redo:before {
  content: "\f499";
}
.icon-ion-ios-refresh-circle:before {
  content: "\f135";
}
.icon-ion-ios-refresh:before {
  content: "\f49c";
}
.icon-ion-ios-remove-circle-outline:before {
  content: "\f1fa";
}
.icon-ion-ios-remove-circle:before {
  content: "\f1fb";
}
.icon-ion-ios-remove:before {
  content: "\f1fc";
}
.icon-ion-ios-reorder:before {
  content: "\f1fd";
}
.icon-ion-ios-repeat:before {
  content: "\f1fe";
}
.icon-ion-ios-resize:before {
  content: "\f1ff";
}
.icon-ion-ios-restaurant:before {
  content: "\f201";
}
.icon-ion-ios-return-left:before {
  content: "\f202";
}
.icon-ion-ios-return-right:before {
  content: "\f203";
}
.icon-ion-ios-reverse-camera:before {
  content: "\f49f";
}
.icon-ion-ios-rewind:before {
  content: "\f4a1";
}
.icon-ion-ios-ribbon:before {
  content: "\f205";
}
.icon-ion-ios-rocket:before {
  content: "\f14b";
}
.icon-ion-ios-rose:before {
  content: "\f4a3";
}
.icon-ion-ios-sad:before {
  content: "\f207";
}
.icon-ion-ios-save:before {
  content: "\f1a6";
}
.icon-ion-ios-school:before {
  content: "\f209";
}
.icon-ion-ios-search:before {
  content: "\f4a5";
}
.icon-ion-ios-send:before {
  content: "\f20c";
}
.icon-ion-ios-settings:before {
  content: "\f4a7";
}
.icon-ion-ios-share-alt:before {
  content: "\f20f";
}
.icon-ion-ios-share:before {
  content: "\f211";
}
.icon-ion-ios-shirt:before {
  content: "\f213";
}
.icon-ion-ios-shuffle:before {
  content: "\f4a9";
}
.icon-ion-ios-skip-backward:before {
  content: "\f215";
}
.icon-ion-ios-skip-forward:before {
  content: "\f217";
}
.icon-ion-ios-snow:before {
  content: "\f218";
}
.icon-ion-ios-speedometer:before {
  content: "\f4b0";
}
.icon-ion-ios-square-outline:before {
  content: "\f15c";
}
.icon-ion-ios-square:before {
  content: "\f21a";
}
.icon-ion-ios-star-half:before {
  content: "\f4b1";
}
.icon-ion-ios-star-outline:before {
  content: "\f4b2";
}
.icon-ion-ios-star:before {
  content: "\f4b3";
}
.icon-ion-ios-stats:before {
  content: "\f21c";
}
.icon-ion-ios-stopwatch:before {
  content: "\f4b5";
}
.icon-ion-ios-subway:before {
  content: "\f21e";
}
.icon-ion-ios-sunny:before {
  content: "\f4b7";
}
.icon-ion-ios-swap:before {
  content: "\f21f";
}
.icon-ion-ios-switch:before {
  content: "\f221";
}
.icon-ion-ios-sync:before {
  content: "\f222";
}
.icon-ion-ios-tablet-landscape:before {
  content: "\f223";
}
.icon-ion-ios-tablet-portrait:before {
  content: "\f24e";
}
.icon-ion-ios-tennisball:before {
  content: "\f4bb";
}
.icon-ion-ios-text:before {
  content: "\f250";
}
.icon-ion-ios-thermometer:before {
  content: "\f252";
}
.icon-ion-ios-thumbs-down:before {
  content: "\f254";
}
.icon-ion-ios-thumbs-up:before {
  content: "\f256";
}
.icon-ion-ios-thunderstorm:before {
  content: "\f4bd";
}
.icon-ion-ios-time:before {
  content: "\f4bf";
}
.icon-ion-ios-timer:before {
  content: "\f4c1";
}
.icon-ion-ios-today:before {
  content: "\f14f";
}
.icon-ion-ios-train:before {
  content: "\f258";
}
.icon-ion-ios-transgender:before {
  content: "\f259";
}
.icon-ion-ios-trash:before {
  content: "\f4c5";
}
.icon-ion-ios-trending-down:before {
  content: "\f25a";
}
.icon-ion-ios-trending-up:before {
  content: "\f25b";
}
.icon-ion-ios-trophy:before {
  content: "\f25d";
}
.icon-ion-ios-tv:before {
  content: "\f115";
}
.icon-ion-ios-umbrella:before {
  content: "\f25f";
}
.icon-ion-ios-undo:before {
  content: "\f4c7";
}
.icon-ion-ios-unlock:before {
  content: "\f261";
}
.icon-ion-ios-videocam:before {
  content: "\f4cd";
}
.icon-ion-ios-volume-high:before {
  content: "\f11c";
}
.icon-ion-ios-volume-low:before {
  content: "\f11e";
}
.icon-ion-ios-volume-mute:before {
  content: "\f263";
}
.icon-ion-ios-volume-off:before {
  content: "\f264";
}
.icon-ion-ios-walk:before {
  content: "\f266";
}
.icon-ion-ios-wallet:before {
  content: "\f18b";
}
.icon-ion-ios-warning:before {
  content: "\f268";
}
.icon-ion-ios-watch:before {
  content: "\f269";
}
.icon-ion-ios-water:before {
  content: "\f26b";
}
.icon-ion-ios-wifi:before {
  content: "\f26d";
}
.icon-ion-ios-wine:before {
  content: "\f26f";
}
.icon-ion-ios-woman:before {
  content: "\f271";
}
.icon-ion-logo-android:before {
  content: "\f225";
}
.icon-ion-logo-angular:before {
  content: "\f227";
}
.icon-ion-logo-apple:before {
  content: "\f229";
}
.icon-ion-logo-bitbucket:before {
  content: "\f193";
}
.icon-ion-logo-bitcoin:before {
  content: "\f22b";
}
.icon-ion-logo-buffer:before {
  content: "\f22d";
}
.icon-ion-logo-chrome:before {
  content: "\f22f";
}
.icon-ion-logo-closed-captioning:before {
  content: "\f105";
}
.icon-ion-logo-codepen:before {
  content: "\f230";
}
.icon-ion-logo-css3:before {
  content: "\f231";
}
.icon-ion-logo-designernews:before {
  content: "\f232";
}
.icon-ion-logo-dribbble:before {
  content: "\f233";
}
.icon-ion-logo-dropbox:before {
  content: "\f234";
}
.icon-ion-logo-euro:before {
  content: "\f235";
}
.icon-ion-logo-facebook:before {
  content: "\f236";
}
.icon-ion-logo-flickr:before {
  content: "\f107";
}
.icon-ion-logo-foursquare:before {
  content: "\f237";
}
.icon-ion-logo-freebsd-devil:before {
  content: "\f238";
}
.icon-ion-logo-game-controller-a:before {
  content: "\f13b";
}
.icon-ion-logo-game-controller-b:before {
  content: "\f181";
}
.icon-ion-logo-github:before {
  content: "\f239";
}
.icon-ion-logo-google:before {
  content: "\f23a";
}
.icon-ion-logo-googleplus:before {
  content: "\f23b";
}
.icon-ion-logo-hackernews:before {
  content: "\f23c";
}
.icon-ion-logo-html5:before {
  content: "\f23d";
}
.icon-ion-logo-instagram:before {
  content: "\f23e";
}
.icon-ion-logo-ionic:before {
  content: "\f150";
}
.icon-ion-logo-ionitron:before {
  content: "\f151";
}
.icon-ion-logo-javascript:before {
  content: "\f23f";
}
.icon-ion-logo-linkedin:before {
  content: "\f240";
}
.icon-ion-logo-markdown:before {
  content: "\f241";
}
.icon-ion-logo-model-s:before {
  content: "\f153";
}
.icon-ion-logo-no-smoking:before {
  content: "\f109";
}
.icon-ion-logo-nodejs:before {
  content: "\f242";
}
.icon-ion-logo-npm:before {
  content: "\f195";
}
.icon-ion-logo-octocat:before {
  content: "\f243";
}
.icon-ion-logo-pinterest:before {
  content: "\f244";
}
.icon-ion-logo-playstation:before {
  content: "\f245";
}
.icon-ion-logo-polymer:before {
  content: "\f15e";
}
.icon-ion-logo-python:before {
  content: "\f246";
}
.icon-ion-logo-reddit:before {
  content: "\f247";
}
.icon-ion-logo-rss:before {
  content: "\f248";
}
.icon-ion-logo-sass:before {
  content: "\f249";
}
.icon-ion-logo-skype:before {
  content: "\f24a";
}
.icon-ion-logo-slack:before {
  content: "\f10b";
}
.icon-ion-logo-snapchat:before {
  content: "\f24b";
}
.icon-ion-logo-steam:before {
  content: "\f24c";
}
.icon-ion-logo-tumblr:before {
  content: "\f24d";
}
.icon-ion-logo-tux:before {
  content: "\f2ae";
}
.icon-ion-logo-twitch:before {
  content: "\f2af";
}
.icon-ion-logo-twitter:before {
  content: "\f2b0";
}
.icon-ion-logo-usd:before {
  content: "\f2b1";
}
.icon-ion-logo-vimeo:before {
  content: "\f2c4";
}
.icon-ion-logo-vk:before {
  content: "\f10d";
}
.icon-ion-logo-whatsapp:before {
  content: "\f2c5";
}
.icon-ion-logo-windows:before {
  content: "\f32f";
}
.icon-ion-logo-wordpress:before {
  content: "\f330";
}
.icon-ion-logo-xbox:before {
  content: "\f34c";
}
.icon-ion-logo-xing:before {
  content: "\f10f";
}
.icon-ion-logo-yahoo:before {
  content: "\f34d";
}
.icon-ion-logo-yen:before {
  content: "\f34e";
}
.icon-ion-logo-youtube:before {
  content: "\f34f";
}
.icon-liquid_map_pin:before {
  content: "\e913";
}
.icon-liquid_round_dollar:before {
  content: "\e928";
}
.icon-liquid_parent:before {
  content: "\e90b";
}
.icon-liquid_calendar:before {
  content: "\e912";
}
.icon-liquid_user:before {
  content: "\e90a";
}
.icon-liquid_arrow_right:before {
  content: "\e909";
}
.icon-weather_aquarius:before {
  content: "\e985";
}
.icon-weather_cloud_drop:before {
  content: "\e989";
}
.icon-weather_cloud_lightning:before {
  content: "\e98a";
}
.icon-weather_cloud_snowflake:before {
  content: "\e98b";
}
.icon-weather_cloud:before {
  content: "\e98c";
}
.icon-weather_downpour_fullmoon:before {
  content: "\e98d";
}
.icon-weather_downpour_halfmoon:before {
  content: "\e98e";
}
.icon-weather_downpour_sun:before {
  content: "\e98f";
}
.icon-weather_drop:before {
  content: "\e990";
}
.icon-weather_first_quarter:before {
  content: "\e991";
}
.icon-weather_fog_fullmoon:before {
  content: "\e992";
}
.icon-weather_fog_halfmoon:before {
  content: "\e993";
}
.icon-weather_fog_sun:before {
  content: "\e994";
}
.icon-weather_fog:before {
  content: "\e995";
}
.icon-weather_fullmoon:before {
  content: "\e996";
}
.icon-weather_hail_fullmoon:before {
  content: "\e998";
}
.icon-weather_hail_halfmoon:before {
  content: "\e999";
}
.icon-weather_hail_sun:before {
  content: "\e99a";
}
.icon-weather_hail:before {
  content: "\e99b";
}
.icon-weather_last_quarter:before {
  content: "\e99c";
}
.icon-weather_lightning:before {
  content: "\e99f";
}
.icon-weather_mistyrain_fullmoon:before {
  content: "\e9a0";
}
.icon-weather_mistyrain_halfmoon:before {
  content: "\e9a1";
}
.icon-weather_mistyrain_sun:before {
  content: "\e9a2";
}
.icon-weather_mistyrain:before {
  content: "\e9a3";
}
.icon-weather_moon:before {
  content: "\e9a4";
}
.icon-weather_moondown_full:before {
  content: "\e9a5";
}
.icon-weather_moondown_half:before {
  content: "\e9a6";
}
.icon-weather_moonset_full:before {
  content: "\e9a7";
}
.icon-weather_moonset_half:before {
  content: "\e9a8";
}
.icon-weather_move2:before {
  content: "\e9a9";
}
.icon-weather_newmoon:before {
  content: "\e9aa";
}
.icon-weather_rain_fullmoon:before {
  content: "\e9ac";
}
.icon-weather_rain_halfmoon:before {
  content: "\e9ad";
}
.icon-weather_rain_sun:before {
  content: "\e9ae";
}
.icon-weather_rain:before {
  content: "\e9af";
}
.icon-weather_snow_fullmoon:before {
  content: "\e9b2";
}
.icon-weather_snow_halfmoon:before {
  content: "\e9b3";
}
.icon-weather_snow_sun:before {
  content: "\e9b4";
}
.icon-weather_snow:before {
  content: "\e9b5";
}
.icon-weather_snowflake:before {
  content: "\e9b6";
}
.icon-weather_star:before {
  content: "\e9b7";
}
.icon-weather_storm_fullmoon:before {
  content: "\e9b8";
}
.icon-weather_storm_halfmoon:before {
  content: "\e9b9";
}
.icon-weather_storm_sun:before {
  content: "\e9ba";
}
.icon-weather_storm-11:before {
  content: "\e9bb";
}
.icon-weather_storm-32:before {
  content: "\e9bc";
}
.icon-weather_sun:before {
  content: "\e9bd";
}
.icon-weather_sundown:before {
  content: "\e9be";
}
.icon-weather_sunset:before {
  content: "\e9bf";
}
.icon-weather_taurus:before {
  content: "\e9c0";
}
.icon-weather_tempest_fullmoon:before {
  content: "\e9c1";
}
.icon-weather_tempest_halfmoon:before {
  content: "\e9c2";
}
.icon-weather_tempest_sun:before {
  content: "\e9c3";
}
.icon-weather_tempest:before {
  content: "\e9c4";
}
.icon-weather_variable_fullmoon:before {
  content: "\e9c5";
}
.icon-weather_variable_halfmoon:before {
  content: "\e9c6";
}
.icon-weather_variable_sun:before {
  content: "\e9c7";
}
.icon-weather_waning_cresent:before {
  content: "\e9c9";
}
.icon-weather_waning_gibbous:before {
  content: "\e9ca";
}
.icon-weather_waxing_cresent:before {
  content: "\e9cb";
}
.icon-weather_waxing_gibbous:before {
  content: "\e9cc";
}
.icon-weather_wind_fullmoon:before {
  content: "\e9ce";
}
.icon-weather_wind_halfmoon:before {
  content: "\e9cf";
}
.icon-weather_wind_sun:before {
  content: "\e9d5";
}
.icon-weather_wind:before {
  content: "\e9d8";
}
.icon-weather_windgust:before {
  content: "\e9d9";
}
.icon-software_add_vectorpoint:before {
  content: "\e9da";
}
.icon-software_character:before {
  content: "\e9df";
}
.icon-software_crop:before {
  content: "\e9e0";
}
.icon-software_eyedropper:before {
  content: "\e9e1";
}
.icon-software_font_allcaps:before {
  content: "\e9e2";
}
.icon-software_font_baseline_shift:before {
  content: "\e9e3";
}
.icon-software_font_horizontal_scale:before {
  content: "\e9e4";
}
.icon-software_font_kerning:before {
  content: "\e9e5";
}
.icon-software_font_leading:before {
  content: "\e9e6";
}
.icon-software_font_size:before {
  content: "\e9e7";
}
.icon-software_font_smallcapital:before {
  content: "\e9e8";
}
.icon-software_font_smallcaps:before {
  content: "\e9e9";
}
.icon-software_font_strikethrough:before {
  content: "\e9ea";
}
.icon-software_font_tracking:before {
  content: "\e9eb";
}
.icon-software_font_underline:before {
  content: "\e9ec";
}
.icon-software_font_vertical_scale:before {
  content: "\e9ed";
}
.icon-software_horizontal_align_center:before {
  content: "\e9ee";
}
.icon-software_horizontal_align_right:before {
  content: "\e9ef";
}
.icon-software_horizontal_distribute_center:before {
  content: "\e9f0";
}
.icon-software_horizontal_distribute_left:before {
  content: "\e9f1";
}
.icon-software_horizontal_distribute_right:before {
  content: "\e9f2";
}
.icon-software_indent_firstline:before {
  content: "\e9f3";
}
.icon-software_indent_left:before {
  content: "\e9f4";
}
.icon-software_indent_right:before {
  content: "\e9f5";
}
.icon-software_lasso:before {
  content: "\e9f6";
}
.icon-software_layers1:before {
  content: "\e9f7";
}
.icon-software_layers2:before {
  content: "\e9f8";
}
.icon-software_layout_2columns:before {
  content: "\e9f9";
}
.icon-software_layout_3columns:before {
  content: "\e9fa";
}
.icon-software_layout_4boxes:before {
  content: "\e9fb";
}
.icon-software_layout_4columns:before {
  content: "\e9fc";
}
.icon-software_layout_4lines:before {
  content: "\e9fd";
}
.icon-software_layout_header_2columns:before {
  content: "\e9fe";
}
.icon-software_layout_header_3columns:before {
  content: "\e9ff";
}
.icon-software_layout_header_4boxes:before {
  content: "\ea00";
}
.icon-software_layout_header_4columns:before {
  content: "\ea01";
}
.icon-software_layout_header_complex:before {
  content: "\ea02";
}
.icon-software_layout_header_complex2:before {
  content: "\ea03";
}
.icon-software_layout_header_complex3:before {
  content: "\ea04";
}
.icon-software_layout_header_complex4:before {
  content: "\ea05";
}
.icon-software_layout_header_sideleft:before {
  content: "\ea06";
}
.icon-software_layout_header_sideright:before {
  content: "\ea07";
}
.icon-software_layout_header:before {
  content: "\ea08";
}
.icon-software_layout_sidebar_left:before {
  content: "\ea09";
}
.icon-software_layout_sidebar_right:before {
  content: "\ea0a";
}
.icon-software_layout-8boxes:before {
  content: "\ea0b";
}
.icon-software_layout:before {
  content: "\ea0c";
}
.icon-software_magnete:before {
  content: "\ea0d";
}
.icon-software_pages:before {
  content: "\ea0e";
}
.icon-software_paintbrush:before {
  content: "\ea0f";
}
.icon-software_paintbucket:before {
  content: "\ea10";
}
.icon-software_paintroller:before {
  content: "\ea11";
}
.icon-software_paragraph_align_left:before {
  content: "\ea12";
}
.icon-software_paragraph_align_right:before {
  content: "\ea13";
}
.icon-software_paragraph_center:before {
  content: "\ea14";
}
.icon-software_paragraph_justify_all:before {
  content: "\ea15";
}
.icon-software_paragraph_justify_center:before {
  content: "\ea16";
}
.icon-software_paragraph_justify_left:before {
  content: "\ea17";
}
.icon-software_paragraph_justify_right:before {
  content: "\ea18";
}
.icon-software_paragraph_space_after:before {
  content: "\ea19";
}
.icon-software_paragraph_space_before:before {
  content: "\ea1a";
}
.icon-software_paragraph:before {
  content: "\ea1b";
}
.icon-software_pathfinder_exclude:before {
  content: "\ea1c";
}
.icon-software_pathfinder_intersect:before {
  content: "\ea1d";
}
.icon-software_pathfinder_subtract:before {
  content: "\ea1e";
}
.icon-software_pathfinder_unite:before {
  content: "\ea1f";
}
.icon-software_pen_add:before {
  content: "\ea20";
}
.icon-software_pen_remove:before {
  content: "\ea21";
}
.icon-software_pen:before {
  content: "\ea22";
}
.icon-software_pencil:before {
  content: "\ea23";
}
.icon-software_polygonallasso:before {
  content: "\ea24";
}
.icon-software_remove_vectorpoint:before {
  content: "\ea27";
}
.icon-software_scale_expand:before {
  content: "\ea28";
}
.icon-software_scale_reduce:before {
  content: "\ea29";
}
.icon-software_slice:before {
  content: "\ea32";
}
.icon-software_transform_bezier:before {
  content: "\ea33";
}
.icon-software_vector_box:before {
  content: "\ea34";
}
.icon-software_vector_composite:before {
  content: "\ea35";
}
.icon-software_vector_line:before {
  content: "\ea36";
}
.icon-software_vertical_align_bottom:before {
  content: "\ea37";
}
.icon-software_vertical_align_center:before {
  content: "\ea38";
}
.icon-software_vertical_align_top:before {
  content: "\ea39";
}
.icon-software_vertical_distribute_bottom:before {
  content: "\ea3a";
}
.icon-software_vertical_distribute_center:before {
  content: "\ea3b";
}
.icon-software_vertical_distribute_top:before {
  content: "\ea3c";
}
.icon-software-horizontal_align_left:before {
  content: "\ea3d";
}
.icon-music_beginning_button:before {
  content: "\ea3e";
}
.icon-music_bell:before {
  content: "\ea3f";
}
.icon-music_cd:before {
  content: "\ea40";
}
.icon-music_diapason:before {
  content: "\ea41";
}
.icon-music_eject_button:before {
  content: "\ea42";
}
.icon-music_end_button:before {
  content: "\ea43";
}
.icon-music_fastforward_button:before {
  content: "\ea44";
}
.icon-music_headphones:before {
  content: "\ea45";
}
.icon-music_ipod:before {
  content: "\ea46";
}
.icon-music_loudspeaker:before {
  content: "\ea47";
}
.icon-music_microphone_old:before {
  content: "\ea48";
}
.icon-music_microphone:before {
  content: "\ea49";
}
.icon-music_mixer:before {
  content: "\ea4a";
}
.icon-music_mute:before {
  content: "\ea4b";
}
.icon-music_note_multiple:before {
  content: "\ea4c";
}
.icon-music_note_single:before {
  content: "\ea4d";
}
.icon-music_pause_button:before {
  content: "\ea4e";
}
.icon-music_play_button:before {
  content: "\ea4f";
}
.icon-music_playlist:before {
  content: "\ea50";
}
.icon-music_radio_ghettoblaster:before {
  content: "\ea51";
}
.icon-music_radio_portable:before {
  content: "\ea52";
}
.icon-music_record:before {
  content: "\ea53";
}
.icon-music_recordplayer:before {
  content: "\ea54";
}
.icon-music_repeat_button:before {
  content: "\ea55";
}
.icon-music_rewind_button:before {
  content: "\ea56";
}
.icon-music_shuffle_button:before {
  content: "\ea57";
}
.icon-music_stop_button:before {
  content: "\ea58";
}
.icon-music_tape:before {
  content: "\ea59";
}
.icon-music_volume_down:before {
  content: "\ea5a";
}
.icon-music_volume_up:before {
  content: "\ea5b";
}
.icon-ecommerce_bag_check:before {
  content: "\ea5c";
}
.icon-ecommerce_bag_cloud:before {
  content: "\ea5d";
}
.icon-ecommerce_bag_download:before {
  content: "\ea5e";
}
.icon-ecommerce_bag_minus:before {
  content: "\ea5f";
}
.icon-ecommerce_bag_plus:before {
  content: "\ea60";
}
.icon-ecommerce_bag_refresh:before {
  content: "\ea61";
}
.icon-ecommerce_bag_remove:before {
  content: "\ea62";
}
.icon-ecommerce_bag_search:before {
  content: "\ea63";
}
.icon-ecommerce_bag_upload:before {
  content: "\ea64";
}
.icon-ecommerce_bag:before {
  content: "\ea65";
}
.icon-ecommerce_banknote:before {
  content: "\ea66";
}
.icon-ecommerce_banknotes:before {
  content: "\ea67";
}
.icon-ecommerce_basket_check:before {
  content: "\ea68";
}
.icon-ecommerce_basket_cloud:before {
  content: "\ea69";
}
.icon-ecommerce_basket_download:before {
  content: "\ea6a";
}
.icon-ecommerce_basket_minus:before {
  content: "\ea6b";
}
.icon-ecommerce_basket_plus:before {
  content: "\ea6c";
}
.icon-ecommerce_basket_refresh:before {
  content: "\ea6d";
}
.icon-ecommerce_basket_remove:before {
  content: "\ea6e";
}
.icon-ecommerce_basket_search:before {
  content: "\ea6f";
}
.icon-ecommerce_basket_upload:before {
  content: "\ea70";
}
.icon-ecommerce_basket:before {
  content: "\ea71";
}
.icon-ecommerce_bath:before {
  content: "\ea72";
}
.icon-ecommerce_cart_check:before {
  content: "\ea73";
}
.icon-ecommerce_cart_cloud:before {
  content: "\ea74";
}
.icon-ecommerce_cart_content:before {
  content: "\ea75";
}
.icon-ecommerce_cart_download:before {
  content: "\ea76";
}
.icon-ecommerce_cart_minus:before {
  content: "\ea77";
}
.icon-ecommerce_cart_plus:before {
  content: "\ea78";
}
.icon-ecommerce_cart_refresh:before {
  content: "\ea79";
}
.icon-ecommerce_cart_remove:before {
  content: "\ea7a";
}
.icon-ecommerce_cart_search:before {
  content: "\ea7b";
}
.icon-ecommerce_cart_upload:before {
  content: "\ea7c";
}
.icon-ecommerce_cart:before {
  content: "\ea7d";
}
.icon-ecommerce_creditcard:before {
  content: "\ea80";
}
.icon-ecommerce_diamond:before {
  content: "\ea81";
}
.icon-ecommerce_gift:before {
  content: "\ea85";
}
.icon-ecommerce_graph_decrease:before {
  content: "\ea86";
}
.icon-ecommerce_graph_increase:before {
  content: "\ea87";
}
.icon-ecommerce_graph1:before {
  content: "\ea88";
}
.icon-ecommerce_graph2:before {
  content: "\ea89";
}
.icon-ecommerce_graph3:before {
  content: "\ea8a";
}
.icon-ecommerce_megaphone:before {
  content: "\ea8e";
}
.icon-ecommerce_money:before {
  content: "\ea8f";
}
.icon-ecommerce_safe:before {
  content: "\eaa8";
}
.icon-ecommerce_sale:before {
  content: "\eaa9";
}
.icon-ecommerce_sales:before {
  content: "\eaaa";
}
.icon-ecommerce_ticket:before {
  content: "\eaab";
}
.icon-ecommerce_wallet:before {
  content: "\eaad";
}
.icon-basic_elaboration_bookmark_checck:before {
  content: "\eab1";
}
.icon-basic_elaboration_bookmark_minus:before {
  content: "\eab2";
}
.icon-basic_elaboration_bookmark_plus:before {
  content: "\eab3";
}
.icon-basic_elaboration_bookmark_remove:before {
  content: "\eab4";
}
.icon-basic_elaboration_briefcase_check:before {
  content: "\eab5";
}
.icon-basic_elaboration_briefcase_download:before {
  content: "\eab6";
}
.icon-basic_elaboration_briefcase_flagged:before {
  content: "\eab7";
}
.icon-basic_elaboration_briefcase_minus:before {
  content: "\eab8";
}
.icon-basic_elaboration_briefcase_plus:before {
  content: "\eab9";
}
.icon-basic_elaboration_briefcase_refresh:before {
  content: "\eaba";
}
.icon-basic_elaboration_briefcase_remove:before {
  content: "\eabb";
}
.icon-basic_elaboration_briefcase_search:before {
  content: "\eabc";
}
.icon-basic_elaboration_briefcase_star:before {
  content: "\eabd";
}
.icon-basic_elaboration_briefcase_upload:before {
  content: "\eabe";
}
.icon-basic_elaboration_browser_check:before {
  content: "\eabf";
}
.icon-basic_elaboration_browser_download:before {
  content: "\eac0";
}
.icon-basic_elaboration_browser_minus:before {
  content: "\eac1";
}
.icon-basic_elaboration_browser_plus:before {
  content: "\eac2";
}
.icon-basic_elaboration_browser_refresh:before {
  content: "\eac3";
}
.icon-basic_elaboration_browser_remove:before {
  content: "\eac4";
}
.icon-basic_elaboration_browser_search:before {
  content: "\eac5";
}
.icon-basic_elaboration_browser_star:before {
  content: "\eac6";
}
.icon-basic_elaboration_browser_upload:before {
  content: "\eac7";
}
.icon-basic_elaboration_calendar_check:before {
  content: "\eac8";
}
.icon-basic_elaboration_calendar_cloud:before {
  content: "\eac9";
}
.icon-basic_elaboration_calendar_download:before {
  content: "\eaca";
}
.icon-basic_elaboration_calendar_empty:before {
  content: "\eacb";
}
.icon-basic_elaboration_calendar_flagged:before {
  content: "\eacc";
}
.icon-basic_elaboration_calendar_heart:before {
  content: "\eacd";
}
.icon-basic_elaboration_calendar_minus:before {
  content: "\eace";
}
.icon-basic_elaboration_calendar_next:before {
  content: "\eacf";
}
.icon-basic_elaboration_calendar_noaccess:before {
  content: "\ead0";
}
.icon-basic_elaboration_calendar_pencil:before {
  content: "\ead1";
}
.icon-basic_elaboration_calendar_plus:before {
  content: "\ead2";
}
.icon-basic_elaboration_calendar_previous:before {
  content: "\ead3";
}
.icon-basic_elaboration_calendar_refresh:before {
  content: "\ead4";
}
.icon-basic_elaboration_calendar_remove:before {
  content: "\ead5";
}
.icon-basic_elaboration_calendar_search:before {
  content: "\ead6";
}
.icon-basic_elaboration_calendar_star:before {
  content: "\ead7";
}
.icon-basic_elaboration_calendar_upload:before {
  content: "\ead8";
}
.icon-basic_elaboration_cloud_check:before {
  content: "\ead9";
}
.icon-basic_elaboration_cloud_download:before {
  content: "\eada";
}
.icon-basic_elaboration_cloud_minus:before {
  content: "\eadb";
}
.icon-basic_elaboration_cloud_noaccess:before {
  content: "\eadc";
}
.icon-basic_elaboration_cloud_plus:before {
  content: "\eadd";
}
.icon-basic_elaboration_cloud_refresh:before {
  content: "\eade";
}
.icon-basic_elaboration_cloud_remove:before {
  content: "\eadf";
}
.icon-basic_elaboration_cloud_search:before {
  content: "\eae0";
}
.icon-basic_elaboration_cloud_upload:before {
  content: "\eae1";
}
.icon-basic_elaboration_document_check:before {
  content: "\eae2";
}
.icon-basic_elaboration_document_cloud:before {
  content: "\eae3";
}
.icon-basic_elaboration_document_download:before {
  content: "\eae4";
}
.icon-basic_elaboration_document_flagged:before {
  content: "\eae5";
}
.icon-basic_elaboration_document_graph:before {
  content: "\eae6";
}
.icon-basic_elaboration_document_heart:before {
  content: "\eae7";
}
.icon-basic_elaboration_document_minus:before {
  content: "\eae8";
}
.icon-basic_elaboration_document_next:before {
  content: "\eae9";
}
.icon-basic_elaboration_document_noaccess:before {
  content: "\eaea";
}
.icon-basic_elaboration_document_note:before {
  content: "\eaeb";
}
.icon-basic_elaboration_document_pencil:before {
  content: "\eaec";
}
.icon-basic_elaboration_document_picture:before {
  content: "\eaed";
}
.icon-basic_elaboration_document_plus:before {
  content: "\eaee";
}
.icon-basic_elaboration_document_previous:before {
  content: "\eaef";
}
.icon-basic_elaboration_document_refresh:before {
  content: "\eaf0";
}
.icon-basic_elaboration_document_remove:before {
  content: "\eaf1";
}
.icon-basic_elaboration_document_search:before {
  content: "\eaf2";
}
.icon-basic_elaboration_document_star:before {
  content: "\eaf3";
}
.icon-basic_elaboration_document_upload:before {
  content: "\eaf4";
}
.icon-basic_elaboration_folder_check:before {
  content: "\eaf5";
}
.icon-basic_elaboration_folder_cloud:before {
  content: "\eaf6";
}
.icon-basic_elaboration_folder_document:before {
  content: "\eaf7";
}
.icon-basic_elaboration_folder_download:before {
  content: "\eaf8";
}
.icon-basic_elaboration_folder_flagged:before {
  content: "\eaf9";
}
.icon-basic_elaboration_folder_graph:before {
  content: "\eafa";
}
.icon-basic_elaboration_folder_heart:before {
  content: "\eafb";
}
.icon-basic_elaboration_folder_minus:before {
  content: "\eafc";
}
.icon-basic_elaboration_folder_next:before {
  content: "\eafd";
}
.icon-basic_elaboration_folder_noaccess:before {
  content: "\eafe";
}
.icon-basic_elaboration_folder_note:before {
  content: "\eaff";
}
.icon-basic_elaboration_folder_pencil:before {
  content: "\eb00";
}
.icon-basic_elaboration_folder_picture:before {
  content: "\eb01";
}
.icon-basic_elaboration_folder_plus:before {
  content: "\eb02";
}
.icon-basic_elaboration_folder_previous:before {
  content: "\eb03";
}
.icon-basic_elaboration_folder_refresh:before {
  content: "\eb04";
}
.icon-basic_elaboration_folder_remove:before {
  content: "\eb05";
}
.icon-basic_elaboration_folder_search:before {
  content: "\eb06";
}
.icon-basic_elaboration_folder_star:before {
  content: "\eb07";
}
.icon-basic_elaboration_folder_upload:before {
  content: "\eb08";
}
.icon-basic_elaboration_mail_check:before {
  content: "\eb09";
}
.icon-basic_elaboration_mail_cloud:before {
  content: "\eb0a";
}
.icon-basic_elaboration_mail_document:before {
  content: "\eb0b";
}
.icon-basic_elaboration_mail_download:before {
  content: "\eb0c";
}
.icon-basic_elaboration_mail_flagged:before {
  content: "\eb0d";
}
.icon-basic_elaboration_mail_heart:before {
  content: "\eb0e";
}
.icon-basic_elaboration_mail_next:before {
  content: "\eb0f";
}
.icon-basic_elaboration_mail_noaccess:before {
  content: "\eb10";
}
.icon-basic_elaboration_mail_note:before {
  content: "\eb11";
}
.icon-basic_elaboration_mail_pencil:before {
  content: "\eb12";
}
.icon-basic_elaboration_mail_picture:before {
  content: "\eb13";
}
.icon-basic_elaboration_mail_previous:before {
  content: "\eb14";
}
.icon-basic_elaboration_mail_refresh:before {
  content: "\eb15";
}
.icon-basic_elaboration_mail_remove:before {
  content: "\eb16";
}
.icon-basic_elaboration_mail_search:before {
  content: "\eb17";
}
.icon-basic_elaboration_mail_star:before {
  content: "\eb18";
}
.icon-basic_elaboration_mail_upload:before {
  content: "\eb19";
}
.icon-basic_elaboration_message_check:before {
  content: "\eb1a";
}
.icon-basic_elaboration_message_dots:before {
  content: "\eb1b";
}
.icon-basic_elaboration_message_happy:before {
  content: "\eb1c";
}
.icon-basic_elaboration_message_heart:before {
  content: "\eb1d";
}
.icon-basic_elaboration_message_minus:before {
  content: "\eb1e";
}
.icon-basic_elaboration_message_note:before {
  content: "\eb1f";
}
.icon-basic_elaboration_message_plus:before {
  content: "\eb20";
}
.icon-basic_elaboration_message_refresh:before {
  content: "\eb21";
}
.icon-basic_elaboration_message_remove:before {
  content: "\eb22";
}
.icon-basic_elaboration_message_sad:before {
  content: "\eb23";
}
.icon-basic_elaboration_smartphone_cloud:before {
  content: "\eb24";
}
.icon-basic_elaboration_smartphone_heart:before {
  content: "\eb25";
}
.icon-basic_elaboration_smartphone_noaccess:before {
  content: "\eb26";
}
.icon-basic_elaboration_smartphone_note:before {
  content: "\eb27";
}
.icon-basic_elaboration_smartphone_pencil:before {
  content: "\eb28";
}
.icon-basic_elaboration_smartphone_picture:before {
  content: "\eb29";
}
.icon-basic_elaboration_smartphone_refresh:before {
  content: "\eb2a";
}
.icon-basic_elaboration_smartphone_search:before {
  content: "\eb2b";
}
.icon-basic_elaboration_tablet_cloud:before {
  content: "\eb2c";
}
.icon-basic_elaboration_tablet_heart:before {
  content: "\eb2d";
}
.icon-basic_elaboration_tablet_noaccess:before {
  content: "\eb2e";
}
.icon-basic_elaboration_tablet_note:before {
  content: "\eb2f";
}
.icon-basic_elaboration_tablet_pencil:before {
  content: "\eb30";
}
.icon-basic_elaboration_tablet_picture:before {
  content: "\eb31";
}
.icon-basic_elaboration_tablet_refresh:before {
  content: "\eb32";
}
.icon-basic_elaboration_tablet_search:before {
  content: "\eb33";
}
.icon-basic_elaboration_todolist_2:before {
  content: "\eb34";
}
.icon-basic_elaboration_todolist_check:before {
  content: "\eb35";
}
.icon-basic_elaboration_todolist_cloud:before {
  content: "\eb36";
}
.icon-basic_elaboration_todolist_download:before {
  content: "\eb37";
}
.icon-basic_elaboration_todolist_flagged:before {
  content: "\eb38";
}
.icon-basic_elaboration_todolist_minus:before {
  content: "\eb39";
}
.icon-basic_elaboration_todolist_noaccess:before {
  content: "\eb3a";
}
.icon-basic_elaboration_todolist_pencil:before {
  content: "\eb3b";
}
.icon-basic_elaboration_todolist_plus:before {
  content: "\eb3c";
}
.icon-basic_elaboration_todolist_refresh:before {
  content: "\eb3d";
}
.icon-basic_elaboration_todolist_remove:before {
  content: "\eb3e";
}
.icon-basic_elaboration_todolist_search:before {
  content: "\eb3f";
}
.icon-basic_elaboration_todolist_star:before {
  content: "\eb40";
}
.icon-basic_elaboration_todolist_upload:before {
  content: "\eb41";
}
.icon-basic_accelerator:before {
  content: "\eb42";
}
.icon-basic_alarm:before {
  content: "\eb43";
}
.icon-basic_anchor:before {
  content: "\eb44";
}
.icon-basic_anticlockwise:before {
  content: "\eb45";
}
.icon-basic_archive_full:before {
  content: "\eb46";
}
.icon-basic_archive:before {
  content: "\eb47";
}
.icon-basic_ban:before {
  content: "\eb48";
}
.icon-basic_battery_charge:before {
  content: "\eb49";
}
.icon-basic_battery_empty:before {
  content: "\eb4a";
}
.icon-basic_battery_full:before {
  content: "\eb4b";
}
.icon-basic_battery_half:before {
  content: "\eb4c";
}
.icon-basic_bolt:before {
  content: "\eb4d";
}
.icon-basic_book_pen:before {
  content: "\eb4e";
}
.icon-basic_book_pencil:before {
  content: "\eb4f";
}
.icon-basic_book:before {
  content: "\eb50";
}
.icon-basic_bookmark:before {
  content: "\eb51";
}
.icon-basic_calculator:before {
  content: "\eb52";
}
.icon-basic_calendar:before {
  content: "\eb53";
}
.icon-basic_cards_diamonds:before {
  content: "\eb54";
}
.icon-basic_cards_hearts:before {
  content: "\eb55";
}
.icon-basic_case:before {
  content: "\eb56";
}
.icon-basic_chronometer:before {
  content: "\eb57";
}
.icon-basic_clessidre:before {
  content: "\eb58";
}
.icon-basic_clock:before {
  content: "\eb59";
}
.icon-basic_clockwise:before {
  content: "\eb5a";
}
.icon-basic_cloud:before {
  content: "\eb5b";
}
.icon-basic_clubs:before {
  content: "\eb5c";
}
.icon-basic_compass:before {
  content: "\eb5d";
}
.icon-basic_cup:before {
  content: "\eb5e";
}
.icon-basic_diamonds:before {
  content: "\eb5f";
}
.icon-basic_display:before {
  content: "\eb60";
}
.icon-basic_download:before {
  content: "\eb61";
}
.icon-basic_exclamation:before {
  content: "\eb62";
}
.icon-basic_eye_closed:before {
  content: "\eb63";
}
.icon-basic_eye:before {
  content: "\eb64";
}
.icon-basic_female:before {
  content: "\eb65";
}
.icon-basic_flag1:before {
  content: "\eb66";
}
.icon-basic_flag2:before {
  content: "\eb67";
}
.icon-basic_floppydisk:before {
  content: "\eb68";
}
.icon-basic_folder_multiple:before {
  content: "\eb69";
}
.icon-basic_folder:before {
  content: "\eb6a";
}
.icon-basic_gear:before {
  content: "\eb6b";
}
.icon-basic_geolocalize-01:before {
  content: "\eb6c";
}
.icon-basic_geolocalize-05:before {
  content: "\eb6d";
}
.icon-basic_globe:before {
  content: "\eb6e";
}
.icon-basic_gunsight:before {
  content: "\eb6f";
}
.icon-basic_hammer:before {
  content: "\eb70";
}
.icon-basic_headset:before {
  content: "\eb71";
}
.icon-basic_heart_broken:before {
  content: "\eb72";
}
.icon-basic_heart:before {
  content: "\eb73";
}
.icon-basic_helm:before {
  content: "\eb74";
}
.icon-basic_home:before {
  content: "\eb75";
}
.icon-basic_info:before {
  content: "\eb76";
}
.icon-basic_ipod:before {
  content: "\eb77";
}
.icon-basic_joypad:before {
  content: "\eb78";
}
.icon-basic_key:before {
  content: "\eb79";
}
.icon-basic_keyboard:before {
  content: "\eb7a";
}
.icon-basic_laptop:before {
  content: "\eb7b";
}
.icon-basic_life_buoy:before {
  content: "\eb7c";
}
.icon-basic_lightbulb:before {
  content: "\eb7d";
}
.icon-basic_link:before {
  content: "\eb7e";
}
.icon-basic_lock_open:before {
  content: "\eb7f";
}
.icon-basic_lock:before {
  content: "\eb80";
}
.icon-basic_magic_mouse:before {
  content: "\eb81";
}
.icon-basic_magnifier_minus:before {
  content: "\eb82";
}
.icon-basic_magnifier_plus:before {
  content: "\eb83";
}
.icon-basic_magnifier:before {
  content: "\eb84";
}
.icon-basic_mail_multiple:before {
  content: "\eb85";
}
.icon-basic_mail_open_text:before {
  content: "\eb86";
}
.icon-basic_mail_open:before {
  content: "\eb87";
}
.icon-basic_mail:before {
  content: "\eb88";
}
.icon-basic_male:before {
  content: "\eb89";
}
.icon-basic_map:before {
  content: "\eb8a";
}
.icon-basic_message_multiple:before {
  content: "\eb8b";
}
.icon-basic_message_txt:before {
  content: "\eb8c";
}
.icon-basic_message:before {
  content: "\eb8d";
}
.icon-basic_mixer2:before {
  content: "\eb8e";
}
.icon-basic_mouse:before {
  content: "\eb8f";
}
.icon-basic_notebook_pen:before {
  content: "\eb90";
}
.icon-basic_notebook_pencil:before {
  content: "\eb91";
}
.icon-basic_notebook:before {
  content: "\eb92";
}
.icon-basic_paperplane:before {
  content: "\eb93";
}
.icon-basic_pencil_ruler_pen:before {
  content: "\eb94";
}
.icon-basic_pencil_ruler:before {
  content: "\eb95";
}
.icon-basic_photo:before {
  content: "\eb96";
}
.icon-basic_picture_multiple:before {
  content: "\eb97";
}
.icon-basic_picture:before {
  content: "\eb98";
}
.icon-basic_pin1:before {
  content: "\eb99";
}
.icon-basic_pin2:before {
  content: "\eb9a";
}
.icon-basic_postcard_multiple:before {
  content: "\eb9b";
}
.icon-basic_postcard:before {
  content: "\eb9c";
}
.icon-basic_printer:before {
  content: "\eb9d";
}
.icon-basic_question:before {
  content: "\eb9e";
}
.icon-basic_rss:before {
  content: "\eb9f";
}
.icon-basic_server_cloud:before {
  content: "\eba0";
}
.icon-basic_server_download:before {
  content: "\eba1";
}
.icon-basic_server_upload:before {
  content: "\eba2";
}
.icon-basic_server:before {
  content: "\eba3";
}
.icon-basic_server2:before {
  content: "\eba4";
}
.icon-basic_settings:before {
  content: "\eba5";
}
.icon-basic_share:before {
  content: "\eba6";
}
.icon-basic_sheet_multiple:before {
  content: "\eba7";
}
.icon-basic_sheet_pen:before {
  content: "\eba8";
}
.icon-basic_sheet_pencil:before {
  content: "\eba9";
}
.icon-basic_sheet_txt:before {
  content: "\ebaa";
}
.icon-basic_sheet:before {
  content: "\ebab";
}
.icon-basic_signs:before {
  content: "\ebac";
}
.icon-basic_smartphone:before {
  content: "\ebad";
}
.icon-basic_spades:before {
  content: "\ebae";
}
.icon-basic_spread_bookmark:before {
  content: "\ebaf";
}
.icon-basic_spread_text_bookmark:before {
  content: "\ebb0";
}
.icon-basic_spread_text:before {
  content: "\ebb1";
}
.icon-basic_spread:before {
  content: "\ebb2";
}
.icon-basic_star:before {
  content: "\ebb3";
}
.icon-basic_tablet:before {
  content: "\ebb4";
}
.icon-basic_target:before {
  content: "\ebb5";
}
.icon-basic_todo_pen:before {
  content: "\ebb6";
}
.icon-basic_todo_pencil:before {
  content: "\ebb7";
}
.icon-basic_todo_txt:before {
  content: "\ebb8";
}
.icon-basic_todo:before {
  content: "\ebb9";
}
.icon-basic_todolist_pen:before {
  content: "\ebba";
}
.icon-basic_todolist_pencil:before {
  content: "\ebbb";
}
.icon-basic_trashcan_full:before {
  content: "\ebbc";
}
.icon-basic_trashcan_refresh:before {
  content: "\ebbd";
}
.icon-basic_trashcan_remove:before {
  content: "\ebbe";
}
.icon-basic_trashcan:before {
  content: "\ebbf";
}
.icon-basic_upload:before {
  content: "\ebc0";
}
.icon-basic_usb:before {
  content: "\ebc1";
}
.icon-basic_video:before {
  content: "\ebc2";
}
.icon-basic_watch:before {
  content: "\ebc3";
}
.icon-basic_webpage_img_txt:before {
  content: "\ebc4";
}
.icon-basic_webpage_multiple:before {
  content: "\ebc5";
}
.icon-basic_webpage_txt:before {
  content: "\ebc6";
}
.icon-basic_webpage:before {
  content: "\ebc7";
}
.icon-basic_world:before {
  content: "\ebc8";
}
.icon-arrows_anticlockwise_dashed:before {
  content: "\e901";
}
.icon-arrows_anticlockwise:before {
  content: "\e902";
}
.icon-arrows_button_down:before {
  content: "\e903";
}
.icon-arrows_button_off:before {
  content: "\e904";
}
.icon-arrows_button_on:before {
  content: "\e905";
}
.icon-arrows_button_up:before {
  content: "\e906";
}
.icon-arrows_check:before {
  content: "\e907";
}
.icon-arrows_circle_check:before {
  content: "\e908";
}
.icon-arrows_circle_left:before {
  content: "\e90c";
}
.icon-arrows_circle_minus:before {
  content: "\e90d";
}
.icon-arrows_circle_plus:before {
  content: "\e90e";
}
.icon-arrows_circle_remove:before {
  content: "\e90f";
}
.icon-arrows_circle_right:before {
  content: "\e910";
}
.icon-arrows_circle_up:before {
  content: "\e911";
}
.icon-arrows_clockwise_dashed:before {
  content: "\e914";
}
.icon-arrows_clockwise:before {
  content: "\e915";
}
.icon-arrows_compress:before {
  content: "\e916";
}
.icon-arrows_deny:before {
  content: "\e917";
}
.icon-arrows_diagonal:before {
  content: "\e918";
}
.icon-arrows_diagonal2:before {
  content: "\e900";
}
.icon-arrows_down_double-34:before {
  content: "\e919";
}
.icon-arrows_down:before {
  content: "\e91a";
}
.icon-arrows_downleft:before {
  content: "\e91b";
}
.icon-arrows_downright:before {
  content: "\e91c";
}
.icon-arrows_drag_down_dashed:before {
  content: "\e91d";
}
.icon-arrows_drag_down:before {
  content: "\e91e";
}
.icon-arrows_drag_horiz:before {
  content: "\e91f";
}
.icon-arrows_drag_left_dashed:before {
  content: "\e920";
}
.icon-arrows_drag_left:before {
  content: "\e921";
}
.icon-arrows_drag_right_dashed:before {
  content: "\e922";
}
.icon-arrows_drag_right:before {
  content: "\e923";
}
.icon-arrows_drag_up_dashed:before {
  content: "\e924";
}
.icon-arrows_drag_up:before {
  content: "\e925";
}
.icon-arrows_drag_vert:before {
  content: "\e926";
}
.icon-arrows_exclamation:before {
  content: "\e927";
}
.icon-arrows_expand:before {
  content: "\e92b";
}
.icon-arrows_glide_horizontal:before {
  content: "\e92e";
}
.icon-arrows_glide_vertical:before {
  content: "\e92f";
}
.icon-arrows_glide:before {
  content: "\e930";
}
.icon-arrows_hamburger-2:before {
  content: "\e931";
}
.icon-arrows_hamburger1:before {
  content: "\e932";
}
.icon-arrows_horizontal:before {
  content: "\e933";
}
.icon-arrows_info:before {
  content: "\e934";
}
.icon-arrows_left_double-32:before {
  content: "\e93f";
}
.icon-arrows_left:before {
  content: "\e940";
}
.icon-arrows_minus:before {
  content: "\e941";
}
.icon-arrows_move_bottom:before {
  content: "\e942";
}
.icon-arrows_move_left:before {
  content: "\e943";
}
.icon-arrows_move_right:before {
  content: "\e944";
}
.icon-arrows_move_top:before {
  content: "\e945";
}
.icon-arrows_move:before {
  content: "\e946";
}
.icon-arrows_move2:before {
  content: "\e947";
}
.icon-arrows_plus:before {
  content: "\e948";
}
.icon-arrows_question:before {
  content: "\e949";
}
.icon-arrows_remove:before {
  content: "\e94a";
}
.icon-arrows_right_double-31:before {
  content: "\e94b";
}
.icon-arrows_right:before {
  content: "\e94c";
}
.icon-arrows_rotate_anti_dashed:before {
  content: "\e94d";
}
.icon-arrows_rotate_anti:before {
  content: "\e94e";
}
.icon-arrows_rotate_dashed:before {
  content: "\e94f";
}
.icon-arrows_rotate:before {
  content: "\e950";
}
.icon-arrows_shrink_diagonal2:before {
  content: "\e952";
}
.icon-arrows_shrink_horizonal2:before {
  content: "\e953";
}
.icon-arrows_shrink_vertical2:before {
  content: "\e956";
}
.icon-arrows_shrink:before {
  content: "\e957";
}
.icon-arrows_slim_down_dashed:before {
  content: "\e964";
}
.icon-arrows_slim_down:before {
  content: "\e965";
}
.icon-arrows_slim_left_dashed:before {
  content: "\e966";
}
.icon-arrows_slim_left:before {
  content: "\e967";
}
.icon-arrows_slim_right_dashed:before {
  content: "\e968";
}
.icon-arrows_slim_right:before {
  content: "\e969";
}
.icon-arrows_slim_up_dashed:before {
  content: "\e96a";
}
.icon-arrows_slim_up:before {
  content: "\e96b";
}
.icon-arrows_square_check:before {
  content: "\e96c";
}
.icon-arrows_square_down:before {
  content: "\e96d";
}
.icon-arrows_square_left:before {
  content: "\e970";
}
.icon-arrows_square_minus:before {
  content: "\e971";
}
.icon-arrows_square_plus:before {
  content: "\e972";
}
.icon-arrows_square_remove:before {
  content: "\e973";
}
.icon-arrows_square_right:before {
  content: "\e974";
}
.icon-arrows_square_up:before {
  content: "\e975";
}
.icon-arrows_squares:before {
  content: "\e978";
}
.icon-arrows_stretch_diagonal1:before {
  content: "\e979";
}
.icon-arrows_stretch_diagonal2:before {
  content: "\e97a";
}
.icon-arrows_stretch_horizontal1:before {
  content: "\e97d";
}
.icon-arrows_stretch_vertical1:before {
  content: "\e97f";
}
.icon-arrows_switch_horizontal:before {
  content: "\e981";
}
.icon-arrows_switch_vertical:before {
  content: "\e982";
}
.icon-arrows_up_double-33:before {
  content: "\e983";
}
.icon-arrows_up:before {
  content: "\e984";
}
.icon-arrows_vertical:before {
  content: "\ebcb";
}
