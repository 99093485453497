/*
 * Name:        Ave - Main styles
 * Written by: 	LiquidThemes
 * Version:			1.6
*/
/*----------------------------------------
 [ TABLE OF CONTENTS ]

 1. BASE
		|- 1.1. Base
		|- 1.2. Header
		|- 1.3. Footer
		|- 1.4. Titlebar

	2. PAGE LAYOUTS
		|- 2.1. Header Side
		|- 2.2. Sidebar
		|- 2.3. Stack

	3. LIQUID SHORTCODES
		|- 3.1. Buttons
		|- 3.2. Social Icons
		|- 3.3. Accordions
		|- 3.4. Icon Boxes
		|- 3.5. Tabs
		|- 3.6. Subscribe Forms
		|- 3.7. Contact Forms
		|- 3.8. Fancy Titles
		|- 3.9. Pricing Tables
		|- 3.10. Testimonials
		|- 3.11. Carousel
		|- 3.12. Carousel Vertical 3d
		|- 3.13. Latest Posts
		|- 3.14. Filter Lists
		|- 3.15. Fancy Boxes
		|- 3.16. Counter
		|- 3.17. Liquid Forms
		|- 3.18. jQuery UI
		|- 3.19. Reservation Form
		|- 3.20. Tour Form
		|- 3.21. Progressbar
		|- 3.22. Milestone
		|- 3.24. Instagram Feed
		|- 3.25. Twitter Feed
		|- 3.26. Banner
		|- 3.27. Image Groups
		|- 3.28. Pagination
		|- 3.29. Blog Grid
		|- 3.31. Masonry
		|- 3.32. Parallax
		|- 3.33. Team Members
		|- 3.34. Portfolios
		|- 3.35. Blog
		|- 3.36. Particles
		|- 3.37. Carousel Laptop
		|- 3.38. Liquid Fancy Heading
		|- 3.39. Row Overlay
		|- 3.40. Maps
		|- 3.41. Custom Animations
		|- 3.42. Masked Image
		|- 3.43. Slideshow BG
		|- 3.44. Custom Menu
		|- 3.45. Shop Banner
		|- 3.46. Carousel Phone
		|- 3.47. Media
		|- 3.48. Modal
		|- 3.49. Before After
		|- 3.50. Process Box
		|- 3.52. Message
		|- 3.53. Flipbox
		|- 3.54. Iconbox Circle
		|- 3.55. Row Separator
		|- 3.56. Roadmap
		|- 3.57. Countdown
		|- 3.58. breadcrumbs
		|- 3.59. Bullet List
		|- 3.60. Video BG
		|- 3.61. Frickin Image
		|- 3.62. Promo
		|- 3.63. Back to Top
		|- 3.64. Separator
		|- 3.65. Restaurant Menu

	4. VC SHORTCODES
		|- 4.1. Single image
		|- 4.2. Columns
		|- 4.3. Rows
		|- 4.4. Text Block

	6. PARTIALS
		|- 6.1. Search Results 
		|- 6.2. 404 Not Found
		|- 6.3. Sidebar
		|- 6.4. Page Frame

	7. RESPONSIVE
		|- 7.1. Header
		|- 7.2. Elements

 ----------------------------------------

 [ TYPOGRAPHY ]

 $font-primary:		'Glacial Indifferenc', 'Open Sans', sans-serif;
 $font-size: 			16px;
 $line-height: 		30px;
 $font-color: 		#808291;

 [ COLORS ]
 $color-primary: #3ed2a7;
 $color-secondary: #ffb09f;

-----------------------------------------*/
/*
  1. BASE
*/
/* 1.1. Base */

html, body {
  max-width: 100vw;
}

html {
  font-size: 100%;
  overflow-x: hidden;
}
.lqd-sticky-sentinel{
  left: 0 !important;
  right: 0 !important;
}
.d-none{
  display: none !important;
}
.z-9{
  position: relative;
  z-index: 9;
}
.strong6{
  font-weight: 600 !important;
}
.ligher-bg{
  background-color: var(--color-lighter);
}
.bg-dark-light{
  background-color: #E8E8E8;
}
.ligher-bg-09{
  background-color: rgba(249, 248, 245, 0.9);
}
.bg-secondary{
  background-color: var(--color-secondary);
}
.bg-alt{
  background-color: var(--color-alt);
}
.p-15{
  padding: 15px !important;
}
.mb-05{
  margin-bottom: 5px;
}
/*
	Base Styles
*/


#wrap,
#content {
  position: relative;
}
.wrap-text{
  white-space: inherit !important;
}

#content {
  z-index: 2;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  background-color: #fff;
}
#content:before, #content:after {
  content: '';
  display: table;
}
#content:after {
  clear: both;
}

iframe {
  max-width: 100%;
  border: none;
}

audio {
  width: 100%;
}

body.archive #content {
  padding-top: 70px;
  padding-bottom: 70px;
}
body.blog #content {
  padding-top: 70px;
  padding-bottom: 70px;
}
body.blog .main-sidebar {
  margin-top: 0 !important;
}

a {
  text-decoration: none;
  color: var(--color-link);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
a:hover {
  color: var(--color-link-hover);
}
a:hover, a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

textarea {
  resize: vertical;
}

dd {
  margin-left: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

table {
  border: 1px solid #dedede;
}
table tr {
  border-bottom: 1px solid #dedede;
}
table th,
table td {
  padding: 0.45em 1em;
}
table tbody th,
table tbody td,
table thead th {
  border-right: 1px solid #dedede;
}
table.wp-block-table {
  border-color: #dedede;
}
table.wp-block-table tr,
table.wp-block-table td,
table.wp-block-table th {
  border-color: inherit;
}
table.wp-block-table th,
table.wp-block-table td {
  padding: 0.45em 1em;
}

pre {
  margin-bottom: 1.5em;
}

blockquote {
  display: block;
  padding: 0;
  margin-bottom: 2.5em;
  border: 0;
  font-style: italic;
}
blockquote.wp-block-quote.is-large {
  padding-left: 0;
  padding-right: 0;
}

.wp-block-pullquote__citation, .wp-block-quote__citation, cite {
  display: block;
  position: relative;
  padding-top: 2.0833333333em;
  margin-top: 1em;
  font-size: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-style: normal;
}
.wp-block-pullquote__citation:before,
.wp-block-quote__citation:before,
cite:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #a7a9b8;
}
.wp-block-quote.is-large .wp-block-pullquote__citation,
.wp-block-quote.is-large .wp-block-quote__citation,
.wp-block-quote.is-large cite {
  text-align: inherit;
  font-size: 12px;
}

.wp-block-quote.aligncenter cite:before,
.wp-block-quote.aligncenter .wp-block-quote__citation:before {
  left: 50%;
  margin-left: -15px;
}

.wp-block-quote.alignright cite:before,
.wp-block-quote.alignright .wp-block-quote__citation:before {
  left: auto;
  right: 0;
}

.wp-block-pullquote,
.wp-block-quote {
  margin-bottom: 32px;
}
.wp-block-pullquote blockquote,
.wp-block-quote blockquote {
  margin-bottom: 0;
}

.wp-block-pullquote cite:before,
.wp-block-pullquote .wp-block-pullquote__citation:before {
  left: 50%;
  margin-left: -15px;
}

.wp-block-pullquote.is-style-solid-color cite:before,
.wp-block-pullquote.is-style-solid-color .wp-block-pullquote__citation:before {
  left: 0;
  margin-left: 0;
}

figcaption {
  margin: 1.3em auto;
  color: #808291;
  font-style: italic;
}

.fp-sr-only,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 1px;
  word-wrap: normal !important;
}

.split-inner {
  display: inline-block;
}

/*
	Typography
*/
p {
  margin-bottom: 1.3em;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: 'Roboto', sans-serif;
  margin: 1.25em 0 0.75em;
  font-weight: 500;
  line-height: 1.2;
  color: #181b31;
}

h1, .h1 {
  margin-top: 0;
  font-size: 52px;
}

h2, .h2 {
  font-size: 40px;
}

h3, .h3 {
  font-size: 32px;
}

h4, .h4 {
  font-size: 25px;
}

h5, .h5 {
  font-size: 21px;
}

h6, .h6 {
  font-size: 18px;
}

small,
.font_small {
  font-size: 0.667em;
}

.ld-lazyload {
  opacity: 0.0001;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.ld-lazyload.loaded {
  opacity: 1;
}

.lqd-img-holder {
  display: block;
  position: relative;
  background-color: var(--color-img-holder);
}
.lqd-img-holder > img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  image-rendering: auto;
}

.lqd-sticky-bg-spacer,
.lqd-sticky-bg-wrap,
.lqd-sticky-bg {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-size: inherit;
  background-position: inherit;
  background-attachment: inherit;
  background-repeat: inherit;
}

.lqd-sticky-bg-wrap {
  overflow: hidden;
}
[data-shrink-borders=true] .lqd-sticky-bg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.lqd-css-sticky {
  top: 0;
}
.lqd-css-sticky:not(.vc_row) {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.lqd-css-sticky-wrap .lqd-css-sticky.vc_row {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.lqd-css-sticky-wrap .lqd-css-sticky.vc_row[data-parallax] {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.lqd-css-sticky-wrap,
.lqd-css-sticky-wrap-inner {
  width: 100%;
}

.lqd-css-sticky-wrap-inner {
  height: 200%;
  top: 0;
  left: 0;
}

.lqd-section-borders-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
}
.lqd-section-borders-wrap.sticky-applied {
  opacity: 1;
  visibility: visible;
}

.lqd-section-border-sentinel {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 1px;
  z-index: -1;
}

.lqd-section-border {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
}
.lqd-section-border[data-axis=x] {
  width: 4.69vw;
  height: 100%;
}
.lqd-section-border[data-axis=y] {
  width: 100%;
  height: 4.69vw;
}

.lqd-section-border-top {
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

.lqd-section-border-right {
  left: auto;
  right: 0;
  -webkit-transform-origin: right center;
          transform-origin: right center;
}

.lqd-section-border-bottom {
  top: auto;
  bottom: 0;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}

.lqd-section-border-left {
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

.scrollmagic-pin-spacer > .wpb_column {
  width: 100%;
}

/* 1.2. Header */
.main-header .row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.main-header .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.main-header .no-gutters > .col,
.main-header .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.main-header .col, .main-header .col-auto, .main-header .col-lg-1, .main-header .col-lg-2, .main-header .col-lg-3, .main-header .col-lg-4, .main-header .col-lg-5, .main-header .col-lg-6, .main-header .col-lg-7, .main-header .col-lg-8, .main-header .col-lg-9, .main-header .col-lg-10, .main-header .col-lg-11, .main-header .col-lg-12, .main-header .col-lg, .main-header .col-lg-auto, .main-header .col-xl-1, .main-header .col-xl-2, .main-header .col-xl-3, .main-header .col-xl-4, .main-header .col-xl-5, .main-header .col-xl-6, .main-header .col-xl-7, .main-header .col-xl-8, .main-header .col-xl-9, .main-header .col-xl-10, .main-header .col-xl-11, .main-header .col-xl-12, .main-header .col-xl, .main-header .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.main-header .col {
  flex-basis: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  max-width: 100%;
}
.main-header .col-auto {
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (min-width: 1200px) {
  .main-header .container {
    max-width: 1460px;
    width: 100%;
  }
  .for-mob{
    display: none !important;
  }

}
@media (min-width: 992px) {
  .main-header .d-lg-none {
    display: none !important;
  }
  .main-header .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .main-header .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .main-header .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .main-header .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .main-header .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .main-header .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .main-header .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .main-header .col-lg {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .main-header .col-lg-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .main-header .col-lg-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .main-header .col-lg-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .main-header .col-lg-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .main-header .col-lg-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .main-header .col-lg-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .main-header .col-lg-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .main-header .col-lg-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .main-header .col-lg-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .main-header .col-lg-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .main-header .col-lg-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .main-header .col-lg-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .main-header .col-lg-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .main-header .order-lg-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .main-header .order-lg-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .main-header .order-lg-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .main-header .order-lg-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .main-header .order-lg-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .main-header .order-lg-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .main-header .order-lg-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .main-header .order-lg-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .main-header .order-lg-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .main-header .order-lg-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .main-header .order-lg-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .main-header .order-lg-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .main-header .order-lg-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .main-header .offset-lg-0 {
    margin-left: 0;
  }
  .main-header .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .main-header .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .main-header .offset-lg-3 {
    margin-left: 25%;
  }
  .main-header .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .main-header .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .main-header .offset-lg-6 {
    margin-left: 50%;
  }
  .main-header .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .main-header .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .main-header .offset-lg-9 {
    margin-left: 75%;
  }
  .main-header .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .main-header .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .main-header .d-xl-none {
    display: none !important;
  }
  .main-header .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .main-header .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .main-header .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .main-header .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .main-header .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .main-header .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .main-header .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .main-header .col-xl {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .main-header .col-xl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .main-header .col-xl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .main-header .col-xl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .main-header .col-xl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .main-header .col-xl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .main-header .col-xl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .main-header .col-xl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .main-header .col-xl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .main-header .col-xl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .main-header .col-xl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .main-header .col-xl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .main-header .col-xl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .main-header .col-xl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .main-header .order-xl-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .main-header .order-xl-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .main-header .order-xl-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .main-header .order-xl-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .main-header .order-xl-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .main-header .order-xl-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .main-header .order-xl-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .main-header .order-xl-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .main-header .order-xl-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .main-header .order-xl-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .main-header .order-xl-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .main-header .order-xl-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .main-header .order-xl-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .main-header .offset-xl-0 {
    margin-left: 0;
  }
  .main-header .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .main-header .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .main-header .offset-xl-3 {
    margin-left: 25%;
  }
  .main-header .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .main-header .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .main-header .offset-xl-6 {
    margin-left: 50%;
  }
  .main-header .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .main-header .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .main-header .offset-xl-9 {
    margin-left: 75%;
  }
  .main-header .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .main-header .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .main-header .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .main-header .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .main-header .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .main-header .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .main-header .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
@media (min-width: 1200px) {
  .main-header .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .main-header .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .main-header .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .main-header .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .main-header .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
}
.main-header {
  position: relative;
  z-index: 10;
}
.main-header .wpb_single_image {
  margin-bottom: 0;
}
.main-header P:empty {
  display: none;
}

.main-header-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lqd-main-header-default .mainbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.lqd-main-header-default .main-nav > li > a {
  color: #5a5b63;
}
.lqd-main-header-default + .content {
  padding-top: 70px;
}

.header-module {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: relative;
}
.header-module ~ .header-module {
  margin-left: 25px;
}
.header-module p {
  margin-bottom: 0;
}
.header-module .btn + p {
  width: 100%;
  margin-top: 0.25em;
  text-align: center;
}
.text-right .header-module {
  -webkit-box-align: end;
          align-items: flex-end;
}
.text-left .header-module {
  -webkit-box-align: start;
          align-items: flex-start;
}
.text-center .header-module {
  -webkit-box-align: center;
          align-items: center;
}

.module-title {
  font-size: 16px;
  margin: 1.5em 0;
}

.nav-trigger {
  display: -webkit-box;
  display: flex;
  padding: 0;
  border: none;
  background: none;
  background-color: transparent;
  box-shadow: none;
  color: #000;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.nav-trigger .txt,
.nav-trigger .bars,
.nav-trigger .bar {
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: inherit;
  -webkit-box-align: inherit;
          align-items: inherit;
  -webkit-box-pack: inherit;
          justify-content: inherit;
}
.nav-trigger .bars {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column nowrap;
}
.nav-trigger .bar {
  background-color: #000;
}
.nav-trigger .bar:before, .nav-trigger .bar:after {
  background-color: inherit;
}
.nav-trigger .txt {
  display: inline-block;
  margin-left: 13px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
}
.nav-trigger.style-1 .bars {
  width: 25px;
  height: 13px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: all 0.3s 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: all 0.3s 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.nav-trigger.style-1 .bar {
  width: 25px;
  height: 1px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  -webkit-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.nav-trigger.style-1 .bar:first-child, .nav-trigger.style-1 .bar:last-child {
  width: 16px;
  opacity: 0;
  visibility: hidden;
}
.nav-trigger.style-1 .bar:first-child {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}
.nav-trigger.style-1 .bar:last-child {
  margin-bottom: 0;
  -webkit-transform: translateY(7px);
          transform: translateY(7px);
}
.nav-trigger.style-1 .bar:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
          transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
}
.nav-trigger.style-1 .bar:nth-child(2):before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: inherit;
  transition: inherit;
  background-color: inherit;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.nav-trigger.style-mobile .bars {
  width: 20px;
  height: 15px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-transition: all 0.45s 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.45s 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.nav-trigger.style-mobile .bar {
  width: 20px;
  height: 2px;
  border-radius: 50em;
  margin-bottom: 4.25px;
  position: relative;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.nav-trigger.style-mobile .bar:first-child, .nav-trigger.style-mobile .bar:last-child {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.nav-trigger.style-mobile .bar:first-child {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.nav-trigger.style-mobile .bar:last-child {
  margin-bottom: 0;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}
.nav-trigger.style-mobile .bar:nth-child(2) {
  width: 22px;
  margin-right: auto;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.nav-trigger.style-mobile .bar:nth-child(2):before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  -webkit-transition: inherit;
  transition: inherit;
  background-color: inherit;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.nav-trigger.txt-left {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
}
.nav-trigger.txt-left .txt {
  margin-left: 0;
  margin-right: 13px;
}
.nav-trigger.fill-solid .bars {
  width: 42px;
  height: 40px;
  -webkit-transform: none;
          transform: none;
  background-color: rgba(255, 255, 255, 0.95);
}
.nav-trigger.rotate-90 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.nav-trigger.scheme-light {
  color: #fff;
}
.nav-trigger.scheme-light .bar {
  background-color: #fff;
}
.nav-trigger.scheme-light.fill-solid .bars {
  background-color: rgba(0, 0, 0, 0.3);
}
.nav-trigger.collapsed .bars {
  -webkit-transform: none;
          transform: none;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.nav-trigger.collapsed .bar:first-child, .nav-trigger.collapsed .bar:last-child, .nav-trigger.collapsed .bar:nth-child(2) {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nav-trigger.collapsed .bar:first-child:before, .nav-trigger.collapsed .bar:first-child:after, .nav-trigger.collapsed .bar:last-child:before, .nav-trigger.collapsed .bar:last-child:after, .nav-trigger.collapsed .bar:nth-child(2):before, .nav-trigger.collapsed .bar:nth-child(2):after {
  -webkit-transform: rotate(0) translate(0, 0);
          transform: rotate(0) translate(0, 0);
}
.nav-trigger.collapsed.style-mobile .bar:first-child, .nav-trigger.collapsed.style-mobile .bar:last-child {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.nav-trigger.collapsed.style-mobile .bar:nth-child(2) {
  width: 16px;
}
.nav-trigger:focus {
  outline: none;
  box-shadow: none;
}

.ld-module-trigger {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  color: #000;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ld-module-trigger-icon {
  font-size: 24px;
}

.ld-module-trigger-count {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  background-color: var(--color-primary);
  border-radius: 50em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}

.ld-module-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 20;
  background-color: #fff;
  text-align: left;
}
.ld-module-dropdown.left {
  right: auto;
  left: 0;
}

.ld-module-cart .ld-module-dropdown {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 85vh;
}

.ld-cart-contents {
  width: 400px;
  height: 100%;
  padding: 25px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
}
.ld-cart-contents .empty h3 {
  font-size: 22px;
}

.ld-cart-contents .empty,
.ld-cart-foot,
.ld-cart-head,
.ld-cart-product {
  padding-left: 35px;
  padding-right: 35px;
}

.ld-cart-foot,
.ld-cart-head {
  font-size: 12px;
}

.ld-cart-head {
  margin-bottom: 20px;
}

a.remove.ld-cart-product-remove {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: 1px solid #dedede;
  border-radius: 50em;
  position: absolute;
  top: -10px;
  left: 25px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 18px;
  color: #000 !important;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
a.remove.ld-cart-product-remove:before {
  content: '';
  display: inline-block;
  width: 150%;
  height: 150%;
  position: absolute;
  top: -25%;
  left: -25%;
}
a.remove.ld-cart-product-remove:hover {
  border-color: red;
  background-color: red;
  color: #fff !important;
}

.ld-cart-product {
  display: -webkit-box;
  display: flex;
  position: relative;
  padding-bottom: 17px;
  margin-bottom: 17px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.571em;
}
.ld-cart-product img {
  width: 65px;
  border-radius: 3px;
}
.ld-cart-product:hover .ld-cart-product-remove {
  opacity: 1;
  visibility: visible;
}

.ld-cart-product-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.ld-cart-product-info a {
  display: inherit;
  -webkit-box-align: inherit;
          align-items: inherit;
  -webkit-box-flex: 1;
          flex: 1 auto;
  color: inherit;
}

.ld-cart-product-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex-grow: 1;
}
figure + .ld-cart-product-details {
  margin-left: 15px;
}
.ld-cart-product-details dl.variation {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  margin-top: 3px;
}
.ld-cart-product-details dl.variation dd {
  margin: 0 0 0 5px;
}
.ld-cart-product-details dl.variation dd + dt {
  margin-left: 8px;
}

.ld-cart-product-price {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
}

.ld-cart-foot {
  margin-top: 5px;
  margin-bottom: 20px;
}
.ld-cart-foot .btn {
  font-weight: 700;
}
.ld-cart-foot .btn,
.ld-cart-foot .btn > span {
  width: 100%;
}
.ld-cart-foot .btn > span {
  -webkit-box-pack: center;
          justify-content: center;
}
.ld-cart-foot .btn-solid {
  background-color: #2A2A2A;
  border: none;
}
.ld-cart-foot .btn-solid:hover {
  background-color: var(--color-primary);
}
.ld-cart-foot .btn-solid + .btn {
  margin-left: 0;
  margin-top: 2em;
}
.ld-cart-foot .btn-naked {
  color: #2A2A2A;
}

.ld-cart-total {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
}

.ld-cart-total-price {
  font-size: 20px;
  font-weight: 600;
}

.ld-cart-message {
  padding: 1em;
  background-color: var(--color-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0.024em;
  color: #fff;
  text-align: center;
}

.ld-module-trigger-txt {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-box-align: center;
          align-items: center;
}
.ld-module-trigger-txt i {
  margin-left: 0.35em;
}

.ld-dropdown-menu-content {
  width: 210px;
  padding: 20px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.ld-dropdown-menu-content a {
  display: inline-block;
  position: relative;
}
.ld-dropdown-menu-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ld-dropdown-menu-content ul ul {
  margin-left: 0.3em;
}
.ld-dropdown-menu-content li {
  position: relative;
}
.ld-dropdown-menu-content li:not(:last-child) a {
  margin-bottom: 0.625em;
}

.ld-dropdown-menu-underlined li a:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: currentColor;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.ld-dropdown-menu-underlined li a:hover:before {
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.ld-module-v-sep {
  display: -webkit-box;
  display: flex;
  width: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-flex: 1;
          flex: 1 auto;
  position: relative;
}
.ld-module-v-sep .ld-v-sep-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ededed;
}

.ld-module-search .ld-module-dropdown {
  right: -15px;
}
.ld-module-search .ld-module-dropdown.left {
  right: auto;
  left: -15px;
}

.ld-search-form-container {
  background-color: #fff;
  width: 360px;
  padding: 30px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ld-search-form {
  position: relative;
  line-height: 1;
}
.ld-search-form input {
  width: 100%;
  padding: 10px 20px 10px 30px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  letter-spacing: 0.025em;
}
.ld-search-form input::-webkit-input-placeholder {
  color: #a7a9b8;
}
.ld-search-form input::-moz-placeholder {
  color: #a7a9b8;
}
.ld-search-form input:-moz-placeholder {
  color: #a7a9b8;
}
.ld-search-form input:-ms-input-placeholder {
  color: #a7a9b8;
}
.ld-search-form input:focus {
  outline: none;
  border-color: rgba(0, 0, 0, 0.2);
}
.ld-search-form .input-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #b8beca;
}

.ld-module-search-visible-form .ld-search-form-container {
  padding: 0;
  background: none;
  border: none;
}
.ld-module-search-visible-form .ld-search-form input {
  background: none;
}

.header-module .iconbox {
  margin-bottom: 0;
  -webkit-box-align: center !important;
          align-items: center !important;
}
.header-module .iconbox .iconbox-icon-wrap {
  margin-right: 0;
}
.header-module .iconbox .iconbox-icon-container {
  margin-right: 15px;
}
.header-module .iconbox h3 {
  margin-bottom: 0.25em;
}
.header-module .iconbox h3:last-child {
  margin-bottom: 0;
}
.header-module .iconbox-inline h3 {
  -webkit-box-flex: 1;
          flex: auto;
}
.header-module .iconbox-side .contents {
  -webkit-box-flex: 1;
          flex: auto;
}

.lqd-lang-module img {
  width: 18px;
}
.lqd-lang-module img + span {
  margin-left: 0.6em;
}

.navbar-brand {
  display: -webkit-inline-box;
  display: inline-flex;
  height: auto;
  line-height: normal;
  float: none;
  -webkit-box-ordinal-group: 1;
  order: 0;
  flex-shrink: 0;
  -webkit-transition: none;
  transition: none;
}
.navbar-brand img {
  -webkit-transition: opacity 0.3s, visibiliy 0.3s;
  transition: opacity 0.3s, visibiliy 0.3s;
}
.navbar-brand .logo-sticky {
  display: none;
}
.navbar-brand .logo-light,
.navbar-brand .logo-dark {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-brand:hover .navbar-brand-hover {
  width: 100%;
}
.is-stuck .navbar-brand .logo-sticky {
  display: block;
}
.is-stuck .navbar-brand .logo-sticky ~ img {
  display: none;
}

.navbar-brand-inner {
  display: inline-block;
  position: relative;
}

.navbar-brand-hover {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}
.navbar-brand-hover img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

.navbar-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.navbar-header:before, .navbar-header:after {
  content: none;
}
.navbar-header .navbar-toggle {
  -webkit-box-ordinal-group: 4;
          order: 3;
  margin-left: 20px;
}
.navbar-header:only-child {
  -webkit-box-flex: 1;
          flex: 1 0;
}

.navbar-collapse {
  -webkit-box-flex: 1;
          flex: 1 auto;
  padding: 0;
}
.navbar-collapse .header-module {
  margin-bottom: 20px;
}
.navbar-collapse .header-module:last-child {
  margin-bottom: 45px;
}

.navbar-nav > li > a {
  line-height: normal;
}

.main-nav {
  -webkit-box-flex: 1;
          flex: 1 auto;
  margin-left: auto;
  margin-right: auto;
}
.main-nav > li {
  position: relative;
}
.main-nav > li,
.main-nav > li > a {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column nowrap;
}
.main-nav > li > a {
  -webkit-box-flex: 1;
          flex: 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
}
.main-nav > li > a:hover, .main-nav > li > a:focus {
  background-color: transparent;
}

.main-nav .link-txt {
  position: relative;
}
.main-nav .link-txt .txt {
  position: relative;
  z-index: 2;
}
.main-nav .link-ext {
  z-index: 0;
}
.main-nav.justify-content-lg-end {
  margin-right: 0;
}
.main-nav.justify-content-lg-start {
  margin-left: 0;
}

.submenu-expander {
  display: none;
  position: absolute;
  top: 50%;
  left: auto;
  z-index: 3;
  font-size: 18px;
  width: 36px;
  height: 36px;
  margin-left: 18px;
  border-radius: 3px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.submenu-expander:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.page_item_has_children > a .submenu-expander, .menu-item-has-children > a .submenu-expander {
  display: -webkit-inline-box;
  display: inline-flex;
}

/*
	Submenu
*/
.main-nav .children,
.nav-item-children {
  min-width: 232px;
  padding: 1.0625em 0;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 16px 50px rgba(0, 0, 0, 0.07);
  visibility: hidden;
  font-size: 16px;
  line-height: 1.5em;
  list-style: none;
  text-align: left;
}
.main-nav .children > li,
.nav-item-children > li {
  position: relative;
  font-size: 1em;
  line-height: normal;
}
.main-nav .children > li > a,
.nav-item-children > li > a {
  display: block;
  padding: 0.7em 2.5em;
  width: 100%;
  color: #797b86;
}
.main-nav .children > li:hover > a,
.nav-item-children > li:hover > a {
  background-color: #e7eaee;
}
.main-nav .children > li.active > a, .main-nav .children > li.current-menu-item > a, .main-nav .children > li:hover > a,
.nav-item-children > li.active > a,
.nav-item-children > li.current-menu-item > a,
.nav-item-children > li:hover > a {
  color: #181b31;
}
.main-nav .children .children,
.main-nav .children .nav-item-children,
.nav-item-children .children,
.nav-item-children .nav-item-children {
  bottom: auto;
  top: 0;
  left: 100%;
}
.main-nav .children .children.to-left,
.main-nav .children .nav-item-children.to-left,
.nav-item-children .children.to-left,
.nav-item-children .nav-item-children.to-left {
  right: 100%;
}
.main-nav .children.to-left,
.nav-item-children.to-left {
  left: auto;
  right: 0;
}
.main-nav .children.to-left .children,
.main-nav .children.to-left .nav-item-children,
.nav-item-children.to-left .children,
.nav-item-children.to-left .nav-item-children {
  left: auto;
  right: 100%;
}

.main-nav .position-applied > .children,
.position-applied > .nav-item-children {
  display: none;
  visibility: visible;
}

.megamenu-heading {
  margin: 0 0 1.25em;
  font-size: 16px;
  letter-spacing: 0.01em;
}

.megamenu-inner-row {
  padding: 35px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.megamenu .lqd-custom-menu li {
  margin-bottom: 13px;
  font-size: 15px;
}

[data-megamenu-bg-scheme=dark] .megamenu-inner-row {
  border-top-color: rgba(255, 255, 255, 0.07);
}
[data-megamenu-bg-scheme=dark] .lqd-custom-menu a {
  color: #d0d1d6;
}

/*
	Nav Styles
*/
.mainbar-wrap .row {
  flex-wrap: nowrap;
}
.mainbar-wrap .row > div:empty {
  display: none;
}
.mainbar-wrap .megamenu-hover-bg {
  display: none;
}

.mainbar-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: stretch;
          align-items: stretch;
}
.mainbar-container:before, .mainbar-container:after {
  content: none;
}
.mainbar-container .container {
  -webkit-box-flex: 1;
          flex: 1 1;
  padding: 0;
}

.mainbar-row {
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.mainbar-row:before, .mainbar-row:after {
  content: none;
}
.mainbar-row > [class^=col] {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
          align-items: stretch;
  position: static;
}

.mainbar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column wrap;
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.mainbar .navbar-header,
.mainbar .main-nav-trigger {
  position: relative;
  z-index: 10;
}

.secondarybar-wrap {
  position: relative;
  z-index: 11;
  .col{
    padding: 0;
    .navbar-brand{
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.secondarybar-wrap ~ .secondarybar-wrap {
  z-index: 9;
}

.secondarybar-row {
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.secondarybar-row:before, .secondarybar-row:after {
  content: none;
}
.secondarybar-row > [class^=col] {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-box-align: stretch;
          align-items: stretch;
}
.secondarybar-row > [class^=col]:empty {
  display: none;
}

.secondarybar .header-module {
  padding-top: 10px;
  padding-bottom: 10px;
}

@-webkit-keyframes stickySlideDown {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes stickySlideDown {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.is-stuck {
  -webkit-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
          animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
}
.is-stuck .navbar-brand {
  padding-top: 18px;
  padding-bottom: 18px;
}
.is-stuck.mainbar-wrap {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.is-stuck > .megamenu-hover-bg {
  display: none !important;
}

.lqd-sticky-sentinel {
  width: 100%;
  min-height: 1px;
  top: 100%;
  left: 0;
  z-index: -1;
}
.vc_row > .lqd-sticky-sentinel, .titlebar > .lqd-sticky-sentinel {
  top: calc(100% - 2px);
}

.is-stuck {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
}

/* 1.3. Footer */
.main-footer .widget-title {
  font-size: 18px;
  margin-bottom: 1.35em;
}
.main-footer > .vc_row {
  margin-left: 0;
  margin-right: 0;
}
.main-footer .wpb_single_image {
  margin-bottom: 0;
}

.lqd-main-footer-default {
  padding-bottom: 30px;
  padding-top: 30px;
  margin-top: 70px;
  background-color: #f2f2f2;
  color: #3b3b3b;
}

/* 1.4. Titlebar */
.titlebar-scroll-link {
  font-size: 30px;
  position: absolute;
  bottom: 1.25em;
  left: auto;
}
.text-center .titlebar-scroll-link {
  margin-left: -15px;
}

.titlebar-col {
  position: static;
}

.titlebar-inner {
  padding-top: 200px;
  padding-bottom: 200px;
  position: relative;
  z-index: 2;
  color: #000;
}
.titlebar-inner h1,
.titlebar-inner p,
.titlebar-inner .breadcrumb {
  position: relative;
  z-index: 1;
}
.titlebar-inner h1,
.titlebar-inner p,
.titlebar-inner a {
  color: inherit;
}
.titlebar-inner h1 {
  margin: 0 0 0.5em;
  font-size: 60px;
  font-weight: 600;
}
.titlebar-inner p {
  font-size: 20px;
  font-weight: 500;
}
.titlebar-inner p:last-child {
  margin-bottom: 0;
}
.titlebar-inner p,
.titlebar-inner .titlebar-scroll-link {
  opacity: 0.6;
}

.titlebar {
  position: relative;
  background-color: inherit;
  background-size: cover;
}
.titlebar .breadcrumb {
  margin-top: 1.5em;
}
.titlebar .breadcrumb ol {
  background: none;
  border-radius: 0;
}
.titlebar .breadcrumb.bordered {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
}
.titlebar > .breadcrumb {
  margin-top: 0;
}
.titlebar .titlebar-scroll-link:hover {
  opacity: 1;
}
.titlebar .main-header-overlay {
  position: relative;
  top: auto;
  left: auto;
}
.titlebar[data-parallax=true]:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #999;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.titlebar[data-parallax=true] .liquid-parallax-container {
  opacity: 0;
}
.titlebar[data-parallax=true].liquid-parallax-bg:before {
  opacity: 0;
  visibility: hidden;
}
.titlebar[data-parallax=true].liquid-parallax-bg .liquid-parallax-container {
  -webkit-animation: fadeIn 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both alternate;
          animation: fadeIn 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both alternate;
}
.titlebar[data-parallax=true].bg-none {
  background-image: none !important;
}

.titlebar-default .titlebar-inner {
  padding-top: 50px;
  padding-bottom: 50px;
}
.titlebar-default .titlebar-inner h1 {
  font-size: 48px;
}

.titlebar-sm .titlebar-inner {
  padding-top: 75px;
  padding-bottom: 75px;
}

.titlebar-md .titlebar-inner {
  padding-top: 100px;
  padding-bottom: 100px;
}

.titlebar.scheme-light {
  background-color: #10131D;
  color: #fff;
}
.titlebar.scheme-light .titlebar-inner,
.titlebar.scheme-light .titlebar-inner h1,
.titlebar.scheme-light .titlebar-inner p {
  color: inherit;
}

/*
  2. PAGE LAYOUTS
*/
/* 2.1. Header Side */
/* 2.2. Sidebar */
.contents-container .ld-container {
  width: auto;
}

/* 2.3. Stack */
@-webkit-keyframes lqdStackArrowNext {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  61% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@keyframes lqdStackArrowNext {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  61% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@-webkit-keyframes lqdStackArrowPrev {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  61% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@keyframes lqdStackArrowPrev {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  61% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
  }
}
@-webkit-keyframes lqdStackNumberActive {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes lqdStackNumberActive {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes lqdStackNumberPassed {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes lqdStackNumberPassed {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes lqdStackNumberActiveUp {
  from {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes lqdStackNumberActiveUp {
  from {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes lqdStackNumberPassedUp {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
@keyframes lqdStackNumberPassedUp {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes lqdStackArrowDash {
  from {
    stroke-dasharray: 5;
  }
  to {
    stroke-dasharray: 40;
  }
}
@keyframes lqdStackArrowDash {
  from {
    stroke-dasharray: 5;
  }
  to {
    stroke-dasharray: 40;
  }
}
@-webkit-keyframes lqdStackRowFadeScaleEnterFromBottom {
  from {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes lqdStackRowFadeScaleEnterFromBottom {
  from {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes lqdStackRowFadeScaleEnter {
  from {
    -webkit-transform: scale3d(0.9, 0.9, 1);
            transform: scale3d(0.9, 0.9, 1);
    opacity: 0;
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@keyframes lqdStackRowFadeScaleEnter {
  from {
    -webkit-transform: scale3d(0.9, 0.9, 1);
            transform: scale3d(0.9, 0.9, 1);
    opacity: 0;
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes lqdStackRowFadeScaleLeave {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0.9, 0.9, 1);
            transform: scale3d(0.9, 0.9, 1);
    opacity: 0;
  }
}
@keyframes lqdStackRowFadeScaleLeave {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0.9, 0.9, 1);
            transform: scale3d(0.9, 0.9, 1);
    opacity: 0;
  }
}
@-webkit-keyframes lqdStackRowFadeScaleLeaveToBottom {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
  }
}
@keyframes lqdStackRowFadeScaleLeaveToBottom {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
  }
}
@-webkit-keyframes lqdStackRowSlideOverEnterFromTop {
  from {
    -webkit-transform: translate3d(0, -100vh, 0);
            transform: translate3d(0, -100vh, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes lqdStackRowSlideOverEnterFromTop {
  from {
    -webkit-transform: translate3d(0, -100vh, 0);
            transform: translate3d(0, -100vh, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes lqdStackRowSlideOverEnterFromBottom {
  from {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes lqdStackRowSlideOverEnterFromBottom {
  from {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes lqdStackRowSlideOverLeaveToTop {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -50vh, 0);
            transform: translate3d(0, -50vh, 0);
  }
}
@keyframes lqdStackRowSlideOverLeaveToTop {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -50vh, 0);
            transform: translate3d(0, -50vh, 0);
  }
}
@-webkit-keyframes lqdStackRowSlideOverLeaveToBottom {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 50vh, 0);
            transform: translate3d(0, 50vh, 0);
  }
}
@keyframes lqdStackRowSlideOverLeaveToBottom {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 50vh, 0);
            transform: translate3d(0, 50vh, 0);
  }
}
.lqd-stack-page-number,
.lqd-stack-prevnext-wrap,
.lqd-stack-extra {
  display: none;
}

.pp-section {
  width: 100vw;
  height: 100vh;
  position: absolute !important;
  top: 0;
  left: 0;
  background-color: #fff;
  -webkit-transition: -webkit-transform 1.15s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: -webkit-transform 1.15s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: transform 1.15s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: transform 1.15s cubic-bezier(0.22, 0.61, 0.36, 1), -webkit-transform 1.15s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.pp-section:not(.active):not(.lqd-stack-row-entering):not(.lqd-stack-row-leaving) {
  left: -999999px;
  pointer-events: none;
}
.pp-section.vc_row-o-content-bottom {
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.pp-section.vc_row-o-content-top {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.pp-table {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  align-content: flex-start;
}

.pp-tableCell {
  height: auto !important;
  width: 100%;
}

.pp-scrollable {
  overflow-y: auto;
}

.pp-auto-height {
  height: auto !important;
}

.lqd-stack-section-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: inherit;
          justify-content: inherit;
  -webkit-box-flex: 1;
          flex-grow: 1;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.pp-enabled .titlebar {
  display: none !important;
}
.pp-enabled [data-liquid-stack=true] {
  height: 100vh;
}
.pp-enabled [data-liquid-stack=true] > section {
  width: 100%;
  height: 100vh;
  align-content: center;
  -webkit-transform: none !important;
          transform: none !important;
}
.pp-enabled [data-liquid-stack=true] .main-footer {
  top: auto;
  bottom: 0;
  -webkit-animation: none !important;
          animation: none !important;
}

#pp-nav {
  position: fixed;
  top: 50%;
  right: 50px;
  z-index: 9;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#pp-nav .pp-tooltip {
  display: inline-block;
  margin-right: 15px;
  position: absolute;
  top: 50%;
  right: 100%;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  -webkit-transition-property: visibility, opacity, color, -webkit-transform;
  transition-property: visibility, opacity, color, -webkit-transform;
  transition-property: visibility, opacity, transform, color;
  transition-property: visibility, opacity, transform, color, -webkit-transform;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#pp-nav a {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 36px;
  height: 1px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transform-origin: right center;
          transform-origin: right center;
}
#pp-nav a:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 21px;
  position: absolute;
  top: -10px;
  left: 0;
}
#pp-nav a.active {
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  background-color: #000;
}
#pp-nav a.active + .pp-tooltip {
  -webkit-transform: translate(-18px, -50%);
          transform: translate(-18px, -50%);
}
#pp-nav li {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
  margin: 30px 0;
}
#pp-nav li:first-child {
  margin-top: 0;
}
#pp-nav li:last-child {
  margin-bottom: 0;
}
.lqd-stack-has-footer #pp-nav li:nth-last-child(2) {
  margin-bottom: 0;
}
#pp-nav li:hover a {
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  background-color: #000;
}
#pp-nav li:hover .pp-tooltip {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-18px, -50%);
          transform: translate(-18px, -50%);
}
#pp-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.lqd-stack-prevnext-button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-box-align: center;
          align-items: center;
  padding: 0;
  border: none;
  position: fixed;
  top: 50%;
  z-index: 10;
  white-space: nowrap;
  background: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.lqd-stack-prevnext-button:focus {
  outline: none;
}
.lqd-stack-prevnext-button svg {
  stroke: currentColor;
}

.lqd-stack-prev-button {
  left: 50px;
  margin-right: 5px;
}

.lqd-stack-next-button {
  right: 50px;
  margin-left: 5px;
}

.lqd-stack-buttons-style-1 .lqd-stack-prevnext-button {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.lqd-stack-buttons-style-1 .lqd-stack-prevnext-button svg {
  display: none;
}
.lqd-stack-buttons-style-1 .lqd-stack-button-labbel {
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.lqd-stack-buttons-style-1 .lqd-stack-button-ext {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.lqd-stack-buttons-style-1 .lqd-stack-button-ext:before {
  display: inline-block;
  font: normal normal 2em 'liquid-icon';
}
.lqd-stack-buttons-style-1 .lqd-stack-prev-button {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
}
.lqd-stack-buttons-style-1 .lqd-stack-prev-button .lqd-stack-button-ext {
  margin-right: 10px;
}
.lqd-stack-buttons-style-1 .lqd-stack-prev-button .lqd-stack-button-ext:before {
  content: '\f117';
}
.lqd-stack-buttons-style-1 .lqd-stack-prev-button:hover .lqd-stack-button-labbel {
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
}
.lqd-stack-buttons-style-1 .lqd-stack-prev-button:hover .lqd-stack-button-ext {
  -webkit-animation: lqdStackArrowPrev 0.45s cubic-bezier(0.77, 0, 0.175, 1) both alternate;
          animation: lqdStackArrowPrev 0.45s cubic-bezier(0.77, 0, 0.175, 1) both alternate;
}
.lqd-stack-buttons-style-1 .lqd-stack-next-button .lqd-stack-button-ext {
  margin-left: 10px;
}
.lqd-stack-buttons-style-1 .lqd-stack-next-button .lqd-stack-button-ext:before {
  content: '\f119';
}
.lqd-stack-buttons-style-1 .lqd-stack-next-button:hover .lqd-stack-button-labbel {
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}
.lqd-stack-buttons-style-1 .lqd-stack-next-button:hover .lqd-stack-button-ext {
  -webkit-animation: lqdStackArrowNext 0.45s cubic-bezier(0.77, 0, 0.175, 1) both alternate;
          animation: lqdStackArrowNext 0.45s cubic-bezier(0.77, 0, 0.175, 1) both alternate;
}

.lqd-stack-buttons-style-2 .lqd-stack-button-labbel {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 1px;
  word-wrap: normal !important;
}
.lqd-stack-buttons-style-2 .lqd-stack-button-ext {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
}
.lqd-stack-buttons-style-2 .lqd-stack-button-circ {
  opacity: 0.3;
}
.lqd-stack-buttons-style-2 .lqd-stack-button-circ-clone {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  stroke-dasharray: 110;
  stroke-dashoffset: 110;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: stroke-dashoffset 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.lqd-stack-buttons-style-2 .lqd-stack-button-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.lqd-stack-buttons-style-2 .lqd-stack-prevnext-button:hover .lqd-stack-button-arrow {
  -webkit-animation: lqdStackArrowDash 1s ease both alternate;
          animation: lqdStackArrowDash 1s ease both alternate;
}
.lqd-stack-buttons-style-2 .lqd-stack-prev-button .lqd-stack-button-arrow {
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
          transform: translate(-50%, -50%) rotate(180deg);
  margin-left: -1px;
}
.lqd-stack-buttons-style-2 .lqd-stack-prev-button:hover .lqd-stack-button-circ-clone {
  stroke-dashoffset: 166;
}
.lqd-stack-buttons-style-2 .lqd-stack-next-button .lqd-stack-button-arrow {
  margin-left: 1px;
}
.lqd-stack-buttons-style-2 .lqd-stack-next-button:hover .lqd-stack-button-circ-clone {
  stroke-dashoffset: 53.5;
}

.lqd-stack-page-number {
  position: fixed;
  bottom: 40px;
  left: 50%;
  z-index: 10;
  font-size: 20px;
  font-weight: 700;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition-property: opacity, visibility, color;
  transition-property: opacity, visibility, color;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.lqd-stack-page-number span {
  display: inline-block;
}

.lqd-stack-page-number-counter {
  position: relative;
}

.lqd-stack-page-number-current {
  overflow: hidden;
}

.lqd-stack-page-number-passed {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.lqd-stack-page-number-total {
  font-size: 0.55em;
}
.lqd-stack-page-number-total:before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 1.75em;
  margin-right: 4px;
  margin-left: 2px;
  background-color: currentColor;
  -webkit-transform: skewX(-18deg);
          transform: skewX(-18deg);
}

.lqd-stack-row-entering {
  z-index: 10 !important;
}
.lqd-stack-effect-fadeScale.lqd-stack-moving-up .lqd-stack-row-entering {
  -webkit-animation: lqdStackRowFadeScaleEnter 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowFadeScaleEnter 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
  z-index: 0 !important;
}
.lqd-stack-effect-fadeScale.lqd-stack-moving-down .lqd-stack-row-entering {
  -webkit-animation: lqdStackRowFadeScaleEnterFromBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowFadeScaleEnterFromBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}
.lqd-stack-effect-slideOver.lqd-stack-moving-up .lqd-stack-row-entering {
  -webkit-animation: lqdStackRowSlideOverEnterFromTop 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowSlideOverEnterFromTop 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}
.lqd-stack-effect-slideOver.lqd-stack-moving-down .lqd-stack-row-entering {
  -webkit-animation: lqdStackRowSlideOverEnterFromBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowSlideOverEnterFromBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}

.lqd-stack-effect-fadeScale.lqd-stack-moving-up .lqd-stack-row-leaving {
  -webkit-animation: lqdStackRowFadeScaleLeaveToBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowFadeScaleLeaveToBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}
.lqd-stack-effect-fadeScale.lqd-stack-moving-down .lqd-stack-row-leaving {
  -webkit-animation: lqdStackRowFadeScaleLeave 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowFadeScaleLeave 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}
.lqd-stack-effect-slideOver.lqd-stack-moving-up .lqd-stack-row-leaving {
  -webkit-animation: lqdStackRowSlideOverLeaveToBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowSlideOverLeaveToBottom 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}
.lqd-stack-effect-slideOver.lqd-stack-moving-down .lqd-stack-row-leaving {
  -webkit-animation: lqdStackRowSlideOverLeaveToTop 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdStackRowSlideOverLeaveToTop 1.2s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}

.lqd-stack-extra {
  display: block;
}

.lqd-stack-extra-content {
  position: fixed;
  bottom: 40px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-property: opacity, visibility, color;
  transition-property: opacity, visibility, color;
  -webkit-transition-duration: 0.65s;
          transition-duration: 0.65s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.lqd-stack-extra-content > * {
  margin: 0;
  color: inherit;
}
.lqd-stack-extra-content a {
  color: inherit;
}
.pp-enabled .lqd-stack-extra-content {
  opacity: 1;
  visibility: visible;
}
.lqd-stack-extra-content .social-icon a {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.lqd-stack-extra-content .social-icon a:hover {
  opacity: 0.75;
}

.lqd-stack-extra-content-bottom-left {
  left: 50px;
}

.lqd-stack-extra-content-bottom-right {
  right: 50px;
}

.lqd-stack-initiated .main-header:not(.header-side) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.lqd-stack-initiated.admin-bar .main-header {
  top: 32px;
}
.lqd-stack-initiated .lqd-stack-page-number,
.lqd-stack-initiated .lqd-stack-prevnext-wrap {
  display: block;
}

.lqd-stack-has-nav .lqd-stack-prevnext-button {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  -webkit-transform: none;
          transform: none;
}
.lqd-stack-has-nav .lqd-stack-prevnext-wrap {
  position: absolute;
  bottom: 40px;
  right: 50px;
  z-index: 10;
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-prevnext-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  bottom: auto;
  top: 50%;
  right: auto;
  left: 50px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-prevnext-button {
  margin: 0;
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-prev-button {
  margin-bottom: 5px;
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right .lqd-stack-next-button {
  margin-top: 5px;
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-prevnext-button {
  margin: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-prev-button {
  -webkit-transform: rotate(90deg) translateX(-50%);
          transform: rotate(90deg) translateX(-50%);
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-prev-button .lqd-stack-button-labbel {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-next-button {
  -webkit-transform: rotate(90deg) translateX(50%);
          transform: rotate(90deg) translateX(50%);
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-1 .lqd-stack-next-button .lqd-stack-button-labbel {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-2 .lqd-stack-prev-button {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.lqd-stack-has-nav.lqd-stack-has-content-bottom-right.lqd-stack-buttons-style-2 .lqd-stack-next-button {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.lqd-stack-moving .lqd-stack-prevnext-button,
.lqd-stack-moving #pp-nav a {
  pointer-events: none;
}
.lqd-stack-moving .lqd-stack-page-number-current {
  -webkit-animation: lqdStackNumberActive 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) both alternate;
          animation: lqdStackNumberActive 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) both alternate;
}
.lqd-stack-moving .lqd-stack-page-number-passed {
  -webkit-animation: lqdStackNumberPassed 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) both alternate;
          animation: lqdStackNumberPassed 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) both alternate;
}
.lqd-stack-moving.lqd-stack-moving-up .lqd-stack-page-number-current {
  -webkit-animation-name: lqdStackNumberActiveUp;
          animation-name: lqdStackNumberActiveUp;
}
.lqd-stack-moving.lqd-stack-moving-up .lqd-stack-page-number-passed {
  -webkit-animation-name: lqdStackNumberPassedUp;
          animation-name: lqdStackNumberPassedUp;
}

.lqd-stack-active-row-dark #pp-nav a {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.3);
}
.lqd-stack-active-row-dark #pp-nav li:hover a,
.lqd-stack-active-row-dark #pp-nav a.active {
  background-color: #fff;
}
.lqd-stack-active-row-dark #pp-nav li {
  color: #fff;
}
.lqd-stack-active-row-dark .lqd-stack-prevnext-button,
.lqd-stack-active-row-dark .lqd-stack-extra-content,
.lqd-stack-active-row-dark .lqd-stack-page-number {
  color: #fff;
}

.lqd-stack-moving-up #pp-nav li,
.lqd-stack-moving-up #pp-nav a {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.lqd-stack-moving-up .lqd-stack-prevnext-button,
.lqd-stack-moving-up .lqd-stack-extra-content,
.lqd-stack-moving-up .lqd-stack-page-number {
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}
.lqd-stack-moving-up.lqd-stack-has-content-bottom-right .lqd-stack-prevnext-button {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}

.lqd-stack-footer-active .lqd-stack-page-number,
.lqd-stack-footer-active .lqd-stack-extra-content {
  opacity: 0;
  visibility: hidden;
}
.lqd-stack-footer-active section.vc_row:last-of-type {
  left: auto !important;
  visibility: visible !important;
  pointer-events: auto !important;
}

/* 2.4. Boxed */
.site-boxed-layout {
  background-color: #eee;
}
.site-boxed-layout.site-boxed-layout-shadow-1 #wrap {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
}
.site-boxed-layout.site-boxed-layout-shadow-2 #wrap {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
}
.site-boxed-layout.site-boxed-layout-shadow-3 #wrap {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

/*
  3. LIQUID SHORTCODES
*/
/* 3.1. Buttons */
/* Animations */
@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
            transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}
@keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
            transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes btnDotAnimations {
  from {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes btnDotAnimations {
  from {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes btnSplit {
  0%, 100% {
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: translateX(100%) scaleX(1);
            transform: translateX(100%) scaleX(1);
  }
  50% {
    -webkit-transform: translateX(0%) scaleX(2);
            transform: translateX(0%) scaleX(2);
  }
  100% {
    -webkit-transform: translateX(0%) scaleX(1);
            transform: translateX(0%) scaleX(1);
  }
}
@keyframes btnSplit {
  0%, 100% {
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: translateX(100%) scaleX(1);
            transform: translateX(100%) scaleX(1);
  }
  50% {
    -webkit-transform: translateX(0%) scaleX(2);
            transform: translateX(0%) scaleX(2);
  }
  100% {
    -webkit-transform: translateX(0%) scaleX(1);
            transform: translateX(0%) scaleX(1);
  }
}
@-webkit-keyframes btnSplitHover {
  0%, 100% {
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: translateX(0%) scaleX(1);
            transform: translateX(0%) scaleX(1);
  }
  50% {
    -webkit-transform: translateX(0%) scaleX(2);
            transform: translateX(0%) scaleX(2);
  }
  100% {
    -webkit-transform: translateX(100%) scaleX(1);
            transform: translateX(100%) scaleX(1);
  }
}
@keyframes btnSplitHover {
  0%, 100% {
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: translateX(0%) scaleX(1);
            transform: translateX(0%) scaleX(1);
  }
  50% {
    -webkit-transform: translateX(0%) scaleX(2);
            transform: translateX(0%) scaleX(2);
  }
  100% {
    -webkit-transform: translateX(100%) scaleX(1);
            transform: translateX(100%) scaleX(1);
  }
}
/* Button */
.btn {
  display: -webkit-inline-box;
  display: inline-flex;
  padding: 0;
  position: relative;
  border: 1px solid var(--color-primary);
  border-radius: 0;
  font-size: 1em;
  white-space: nowrap;
  color: var(--color-primary);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05);
}
.btn > span,
.btn strong,
.btn i {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
  transition: -webkit-transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
  transition: transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
  transition: transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875), -webkit-transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
}
.btn > span {
  display: -webkit-inline-box;
  display: inline-flex;
  padding: 1.15em 2.1em;
  border-radius: inherit;
  border-color: inherit;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}
.btn.text-uppercase {
  letter-spacing: .1em;
}
.btn.semi-round {
  border-radius: 2px;
}
.btn.round {
  border-radius: 4px;
}
.btn.circle {
  border-radius: 50em;
}
.btn.disabled {
  pointer-events: none;
  cursor: none;
}
.btn.disabled > span,
.btn.disabled .btn-icon {
  opacity: 0.5;
}
.btn.border-none {
  border-width: 0;
}
.btn.border-none > span {
  margin: 0;
}
.btn.border-none .btn-gradient-border rect {
  stroke-width: 0;
}
.btn.border-thin {
  border-width: 1px;
}
.btn.border-thin > span {
  margin: -0.5px;
}
.btn.border-thin .btn-gradient-border rect {
  stroke-width: 1px;
}
.btn.border-thick {
  border-width: 2px;
}
.btn.border-thick > span {
  margin: -1px;
}
.btn.border-thick .btn-gradient-border rect {
  stroke-width: 2px;
}
.btn.border-thicker {
  border-width: 3px;
}
.btn.border-thicker > span {
  margin: -2px;
}
.btn.border-thicker .btn-gradient-border rect {
  stroke-width: 3px;
}
.btn + .btn {
  margin-left: .75em;
}
.btn.wide > span {
  padding-left: 2.4em;
  padding-right: 2.4em;
}
.btn:hover {
  border-color: transparent;
  background-color: var(--color-primary);
  color: #fff;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}
.btn:hover .btn-gradient-bg-hover {
  opacity: 1;
}

/* Sizes*/
.btn-xsm > span {
  padding: 0.6em 1em;
}
.btn-xsm.wide > span {
  padding-left: 1.7em;
  padding-right: 1.7em;
}

.btn-sm > span {
  padding: 0.85em 1.5em;
}
.btn-sm.wide > span {
  padding-left: 3em;
  padding-right: 3em;
}

.btn-lg > span {
  padding: 1.3em 2.7em;
}
.btn-lg.wide > span {
  padding-left: 3.6em;
  padding-right: 3.6em;
}

.btn-xlg > span {
  padding: 1.5em 3.5em;
}
.btn-xlg.wide > span {
  padding-left: 4.5em;
  padding-right: 4.5em;
}

.btn-custom-sized > span {
  width: 100%;
  text-align: center;
  -webkit-box-pack: center;
          justify-content: center;
  padding: 0;
}

.btn-icon {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 1em;
  letter-spacing: 0;
  -webkit-transition: inherit;
  transition: inherit;
  background-size: 99.99% !important;
}
.btn-icon i {
  position: relative;
  z-index: 3;
}
.btn-txt + .btn-icon {
  margin-right: 0;
  margin-left: 8px;
}
.btn-icon:only-child {
  margin-bottom: 0 !important;
}
.btn-gradient-bg + .btn-icon, .btn-icon:first-child {
  margin-left: auto;
  margin-right: auto;
}

.btn-icon,
.btn-txt {
  position: relative;
  z-index: 3;
}

.btn-txt small {
  display: block;
  opacity: 0.7;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
}

/* Icon Shapes */
.btn-icon-solid .btn-icon,
.btn-icon-bordered .btn-icon {
  width: 2.5em;
  height: 2.5em;
  margin-left: 20px;
  border-width: 1px;
  border-style: solid;
  margin-top: -5%;
  margin-bottom: -5%;
}
.btn-icon-solid.btn-icon-left .btn-txt + .btn-icon,
.btn-icon-bordered.btn-icon-left .btn-txt + .btn-icon {
  margin-left: 0;
  margin-right: 20px;
}

.btn-icon-semi-round .btn-icon {
  border-radius: 2px;
}

.btn-icon-round .btn-icon {
  border-radius: 4px;
}

.btn-icon-circle .btn-icon {
  border-radius: 50em;
}
.btn-icon-circle.btn-icon-ripple .btn-icon:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid;
  -webkit-animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
          animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}
.btn-icon-circle.btn-icon-ripple .btn-icon[class*=text-] {
  border-color: currentColor;
}

.btn-icon-solid .btn-icon {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
}

/* Icon Position */
.btn-icon-block .btn-icon,
.btn-icon-block .btn-txt {
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
}
.btn-icon-block .btn-icon {
  margin-top: 1.5em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.btn-icon-block .btn-txt {
  width: 100%;
}

.btn-icon-top .btn-icon,
.btn-icon-left .btn-icon {
  -webkit-box-ordinal-group: 0;
          order: -1;
}

.btn-icon-left .btn-icon {
  margin-left: 0;
  margin-right: 8px;
}

.btn-icon-top.btn-icon-block .btn-icon {
  margin-bottom: 1.5em;
  margin-top: 0;
}
.btn-icon-top.btn-icon-block.btn-icon-xlg .btn-icon {
  margin-bottom: 0.5em;
}

/* Icon Sizes */
.btn-icon-xsm .btn-icon {
  width: 1.5em;
  height: 1.5em;
}

.btn-icon-sm .btn-icon {
  width: 2em;
  height: 2em;
}

.btn-icon-lg .btn-icon {
  width: 3.2em;
  height: 3.2em;
}

.btn-icon-xlg .btn-icon {
  width: 3.5em;
  height: 3.5em;
}

.btn-icon-xxlg .btn-icon {
  width: 6.25em;
  height: 6.25em;
}

.btn-icon-border-thick .btn-icon {
  border-width: 2px;
}

.btn-icon-border-thicker .btn-icon {
  border-width: 3px;
}

.btn-icon-border-thickest .btn-icon {
  border-width: 4px;
}

/* Button Styles */
.btn-solid {
  background: var(--color-primary);
  color: #fff;
}
.btn-solid.color-secondary {
  background-color: #4fda90;
  border-color: #4fda90;
}
.btn-solid.color-secondary .btn-gradient-bg {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary)));
  background: linear-gradient(to right, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%);
}
.btn-solid:hover {
  color: #fff;
}

.btn-bordered.color-secondary {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.btn-bordered.color-secondary .btn-gradient-bg-hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-stop-secondary)), to(var(--color-gradient-start-secondary)));
  background: linear-gradient(to right, var(--color-gradient-stop-secondary) 0%, var(--color-gradient-start-secondary) 100%);
}
.btn-bordered.color-secondary:hover {
  background-color: var(--color-secondary);
  color: #fff;
}

.btn-naked {
  border: none;
}
.btn-naked > span {
  padding: 0;
}
.btn-naked:hover {
  color: var(--color-primary);
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.backgroundcliptext .btn-naked:not(.btn-icon-solid).btn-gradient .btn-txt, .backgroundcliptext .btn-naked:not(.btn-icon-solid).btn-gradient .btn-icon i,
.backgroundcliptext .btn-underlined:not(.btn-icon-solid).btn-gradient .btn-txt,
.backgroundcliptext .btn-underlined:not(.btn-icon-solid).btn-gradient .btn-icon i {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}
.btn-naked > span > .btn-gradient-bg, .btn-underlined > span > .btn-gradient-bg {
  display: none;
}

.btn-underlined {
  border: none;
  background-color: transparent !important;
  position: relative;
}
.btn-underlined:before, .btn-underlined:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.btn-underlined:after {
  background: var(--color-primary);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-underlined > span {
  padding: 0.4em 0;
}
.btn-underlined:hover {
  background-color: transparent;
  color: var(--color-primary);
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
}
.btn-underlined:hover:after {
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.btn-underlined.border-none:before, .btn-underlined.border-none:after {
  height: 0;
}
.btn-underlined.border-thin:before, .btn-underlined.border-thin:after {
  height: 1px;
}
.btn-underlined.border-thick:before, .btn-underlined.border-thick:after {
  height: 2px;
}
.btn-underlined.border-thicker:before, .btn-underlined.border-thicker:after {
  height: 3px;
}
.btn-underlined.color-secondary {
  color: var(--color-secondary);
}
.btn-underlined.color-secondary:before {
  background-color: var(--color-secondary);
}
.btn-underlined.color-secondary:after {
  background-color: #fff;
}

.btn-split, .btn-split:hover {
  border: none;
  box-shadow: none;
  background: none;
  color: #ffa487;
}
.btn-split:hover .btn-split-bg {
  -webkit-animation: btnSplitHover 0.65s cubic-bezier(0.25, 1, 0.35, 1) both;
          animation: btnSplitHover 0.65s cubic-bezier(0.25, 1, 0.35, 1) both;
}

.btn-block {
  width: 100%;
  white-space: normal;
}
.btn-block > span {
  width: 100%;
  -webkit-box-pack: center;
          justify-content: center;
}
.btn-block:hover {
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
}

.btn-gradient {
  border: none;
}
.btn-gradient > span {
  margin: 0 !important;
}
.btn-gradient:hover {
  background-color: transparent;
}

.btn-bordered-gradient {
  border: none;
}
.btn-bordered-gradient:hover defs stop:first-child {
  stop-color: var(--color-gradient-start);
}
.btn-bordered-gradient:hover defs stop:last-child {
  stop-color: var(--color-gradient-stop);
}

.btn-bordered-gradient-primary:before {
  background: -webkit-gradient(linear, left top, right top, from(rgba(120, 11, 238, 0.6)), to(rgba(29, 225, 209, 0.6)));
  background: linear-gradient(to right, rgba(120, 11, 238, 0.6) 0%, rgba(29, 225, 209, 0.6) 100%);
}
.btn-bordered-gradient-primary:after {
  background: #fff;
}

.btn-split-bg,
.btn-gradient-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-size: 99.99%;
}

.btn-gradient-bg {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}

.btn-split-bg {
  width: 50%;
  background: #ffe1d8;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.07);
  -webkit-animation: btnSplit 0.65s cubic-bezier(0.25, 1, 0.35, 1) both;
          animation: btnSplit 0.65s cubic-bezier(0.25, 1, 0.35, 1) both;
}

.btn-gradient-bg-hover {
  z-index: 1;
  opacity: 0;
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-stop)), to(var(--color-gradient-start)));
  background: linear-gradient(to right, var(--color-gradient-stop) 0%, var(--color-gradient-start) 100%);
  background-size: 99.99%;
}

.btn-gradient-border {
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  position: absolute;
  fill: transparent;
  stroke: transparent;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: visible !important;
}
.btn-gradient-border defs stop {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  stop-opacity: 1;
}
.btn-gradient-border defs stop:first-child {
  stop-color: var(--color-gradient-start);
}
.btn-gradient-border defs stop:last-child {
  stop-color: var(--color-gradient-stop);
}
.btn-gradient-border rect {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 1;
}

.ld-ajax-loadmore {
  display: inline-block;
  border: 1px solid;
  position: relative;
  text-align: center;
  white-space: nowrap;
}
.ld-ajax-loadmore span {
  display: inline-block;
}
.ld-ajax-loadmore > span {
  display: block;
  padding: 0.75em 2em;
}
.ld-ajax-loadmore .static,
.ld-ajax-loadmore .loading,
.ld-ajax-loadmore .all-loaded {
  display: block;
  -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.ld-ajax-loadmore .dots {
  display: block;
}
.ld-ajax-loadmore .dots > span {
  width: 6px;
  height: 6px;
  margin: 0 1px;
  border-radius: 50em;
  background-color: currentColor;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.ld-ajax-loadmore .all-loaded,
.ld-ajax-loadmore .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.ld-ajax-loadmore .loading {
  font-size: 10px;
}
.ld-ajax-loadmore .all-loaded i {
  margin-left: 8px;
}
.ld-ajax-loadmore.items-loading, .ld-ajax-loadmore.all-items-loaded {
  pointer-events: none;
  border-color: transparent;
}
.ld-ajax-loadmore.items-loading .static, .ld-ajax-loadmore.all-items-loaded .static {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ld-ajax-loadmore.items-loading .loading {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ld-ajax-loadmore.items-loading .loading .dots > span {
  -webkit-animation: dotAnimations 0.6s cubic-bezier(0.23, 1, 0.32, 1) infinite alternate backwards;
          animation: dotAnimations 0.6s cubic-bezier(0.23, 1, 0.32, 1) infinite alternate backwards;
}
.ld-ajax-loadmore.items-loading .loading .dots > span:nth-child(0) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.ld-ajax-loadmore.items-loading .loading .dots > span:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.ld-ajax-loadmore.items-loading .loading .dots > span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.ld-ajax-loadmore.items-loading .loading .dots > span:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.ld-ajax-loadmore.all-items-loaded {
  color: #000;
}
.ld-ajax-loadmore.all-items-loaded .all-loaded {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.btn-secondary.btn-bordered {
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.btn-secondary.btn-bordered:hover {
  background-color: var(--color-secondary);
  color: #fff;
}
.btn-secondary.btn-solid {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
}

.btn-light.btn-bordered {
  color: #000;
  border-color: #e4e4e4;
}
.btn-light.btn-bordered:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.btn-dark.btn-underlined {
  color: #3f4147;
}
.btn-dark.btn-underlined:before {
  background: rgba(63, 65, 71, 0.5);
}
.btn-dark.btn-underlined:after {
  background: #000;
}
.btn-dark.btn-solid {
  background-color: #222246;
  border-color: #222246;
}

.btn-black.btn-underlined {
  color: #000;
}
.btn-black.btn-underlined:before {
  background: rgba(0, 0, 0, 0.5);
}
.btn-black.btn-underlined:after {
  background: #000;
}
.btn-black.btn-solid {
  background-color: #000;
  border-color: #000;
}

.btn-white.btn-bordered {
  color: #fff;
  border-color: #fff;
}
.btn-white.btn-bordered:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}
.btn-white.btn-solid {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}
.btn-white.btn-underlined:before {
  background-color: rgba(255, 255, 255, 0.75);
}
.btn-white.btn-underlined:after {
  background-color: #fff;
}

.btn-white-fade-border.btn-bordered {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.btn-white-fade-border.btn-bordered:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}

.btn-hover-white.btn-solid:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}
.btn-hover-white.btn-underlined:hover {
  color: #fff;
}
.btn-hover-white.btn-underlined:hover:before, .btn-hover-white.btn-underlined:hover:after {
  background: #fff;
}

/* 3.2. Social Icons */
.social-icon {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 15px;
  line-height: 1em;
  /* Sizes */
  /* Directions */
  /* Shapes */
  /* Color Schemes */
}
.social-icon li {
  display: inline-block;
  margin-right: 1.2em;
  font-size: 1em;
  line-height: 1em;
}
.social-icon li:last-child {
  margin-right: 0;
}
.social-icon i {
  display: inherit;
  -webkit-box-pack: inherit;
          justify-content: inherit;
  -webkit-box-align: inherit;
          align-items: inherit;
}
.social-icon.social-icon-sm {
  font-size: 14px;
}
.social-icon.social-icon-md {
  font-size: 18px;
}
.social-icon.social-icon-lg {
  font-size: 20px;
}
.social-icon.social-icon-lg li {
  margin-right: 1em;
}
.social-icon.vertical li {
  display: block;
  margin-right: 0 !important;
  margin-bottom: 1.2em;
}
.social-icon.vertical li:last-child {
  margin-bottom: 0;
}
.social-icon.round li, .social-icon.semi-round li, .social-icon.square li, .social-icon.circle li {
  margin-right: 0.5em;
}
.social-icon.round a, .social-icon.semi-round a, .social-icon.square a, .social-icon.circle a {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 40px;
  height: 40px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  background-color: var(--color-primary);
  color: #fff;
}
.social-icon.round a:hover, .social-icon.semi-round a:hover, .social-icon.square a:hover, .social-icon.circle a:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
}
.social-icon.round.social-icon-sm li, .social-icon.semi-round.social-icon-sm li, .social-icon.square.social-icon-sm li, .social-icon.circle.social-icon-sm li {
  margin-right: 0.3em;
}
.social-icon.round.social-icon-sm a, .social-icon.semi-round.social-icon-sm a, .social-icon.square.social-icon-sm a, .social-icon.circle.social-icon-sm a {
  width: 30px;
  height: 30px;
}
.social-icon.round.social-icon-md li, .social-icon.semi-round.social-icon-md li, .social-icon.square.social-icon-md li, .social-icon.circle.social-icon-md li {
  margin-right: 0.6em;
}
.social-icon.round.social-icon-md a, .social-icon.semi-round.social-icon-md a, .social-icon.square.social-icon-md a, .social-icon.circle.social-icon-md a {
  width: 48px;
  height: 48px;
}
.social-icon.round.social-icon-lg li, .social-icon.semi-round.social-icon-lg li, .social-icon.square.social-icon-lg li, .social-icon.circle.social-icon-lg li {
  margin-right: 0.7em;
}
.social-icon.round.social-icon-lg a, .social-icon.semi-round.social-icon-lg a, .social-icon.square.social-icon-lg a, .social-icon.circle.social-icon-lg a {
  width: 55px;
  height: 55px;
}
.social-icon.round i,
.social-icon.round a {
  border-radius: 8%;
}
.social-icon.semi-round i,
.social-icon.semi-round a {
  border-radius: 2%;
}
.social-icon.circle i,
.social-icon.circle a {
  border-radius: 50em;
}
.social-icon.scheme-dark a {
  color: #000;
}
.social-icon.scheme-dark.semi-round a, .social-icon.scheme-dark.round a, .social-icon.scheme-dark.square a, .social-icon.scheme-dark.circle a {
  background-color: #000;
  color: #fff;
}
.social-icon.scheme-gray a {
  color: #999;
}
.social-icon.scheme-gray.semi-round a, .social-icon.scheme-gray.round a, .social-icon.scheme-gray.square a, .social-icon.scheme-gray.circle a {
  background-color: #999;
}
.social-icon.scheme-white a {
  color: #fff;
}
.social-icon.scheme-white.semi-round a, .social-icon.scheme-white.round a, .social-icon.scheme-white.square a, .social-icon.scheme-white.circle a {
  background-color: #fff;
  color: #000;
}
.social-icon.branded-text a {
  background-color: transparent;
}
.social-icon.branded i {
  width: 100%;
  height: 100%;
  position: relative;
}
.social-icon.branded a {
  background-color: transparent;
  line-height: normal;
}

/* 3.3. Accordions */
.accordion {
  margin-bottom: 2em;
}

.accordion-title {
  margin: 0;
  position: relative;
  line-height: 1.5em;
}
.accordion-title a {
  display: block;
  width: 100%;
  padding: 0.93em 30px 0.93em 0;
  color: inherit;
}

.accordion-expander {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  font-size: 16px;
  color: inherit;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.accordion-expander > i {
  display: inline-block;
}
.accordion-expander > i:last-child {
  display: none;
}
.active .accordion-expander > i:last-child {
  display: inline-block;
}
.active .accordion-expander > i:first-child {
  display: none;
}

.accordion-content {
  padding: 1em 0;
}
.accordion-content > :last-child,
.accordion-content > :only-child {
  margin-bottom: 0;
}

/* Accordion Styles */
.accordion-active-has-fill .accordion-title a {
  padding-left: 30px;
  padding-right: 60px;
  background-color: transparent;
  color: inherit;
}
.accordion-active-has-fill .accordion-expander {
  right: 30px;
}
.accordion-active-has-fill .accordion-content {
  padding-left: 30px;
  padding-right: 30px;
}
.accordion-active-has-fill.accordion-title-bordered .active .accordion-title a, .accordion-active-has-fill.accordion-title-underlined .active .accordion-title a {
  border-color: transparent;
}
.accordion-active-has-fill .active .accordion-title a {
  background-color: var(--color-primary);
  color: #fff;
}

.accordion-body-underlined .accordion-item {
  border-bottom: 1px solid #e0e1eb;
}
.accordion-body-underlined .accordion-item:last-child {
  border-width: 0;
}

.accordion-body-bordered .accordion-content,
.accordion-body-bordered .accordion-title a {
  padding-left: 30px;
  padding-right: 30px;
}
.accordion-body-bordered .accordion-item {
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion-body-bordered .accordion-content {
  padding-top: 10px;
  padding-bottom: 30px;
}

.accordion-title-underlined .accordion-title a {
  padding-right: 0;
  border-bottom: 1px solid #dadde1;
}
.accordion-title-underlined .accordion-expander {
  right: 0;
}

.accordion-title-bordered .accordion-item {
  margin-bottom: 1.25em;
}
.accordion-title-bordered .accordion-title a {
  padding-left: 30px;
  padding-right: 60px;
  border: 1px solid #d8dbe2;
}

.accordion-title-round .accordion-title a {
  border-radius: 4px;
}

.accordion-title-circle .accordion-title a {
  border-radius: 50em;
}

.accordion-body-round .accordion-item {
  border-radius: 4px;
}

.accordion-active-has-shadow .accordion-title a {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}
.accordion-active-has-shadow .active .accordion-title a {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.accordion-expander-left .accordion-expander {
  right: auto;
  left: 0;
}
.accordion-expander-left .accordion-title a {
  padding-left: 30px;
}
.accordion-expander-left.accordion-body-bordered .accordion-title a, .accordion-expander-left.accordion-title-bordered .accordion-title a, .accordion-expander-left.accordion-active-has-fill .accordion-title a {
  padding-right: 30px;
  padding-left: 60px;
}
.accordion-expander-left.accordion-body-bordered .accordion-expander, .accordion-expander-left.accordion-title-bordered .accordion-expander, .accordion-expander-left.accordion-active-has-fill .accordion-expander {
  left: 30px;
}
.accordion-expander-left.accordion-body-underlined .accordion-content {
  padding-left: 30px;
}

.accordion-xs .accordion-item {
  margin-bottom: 0.5em;
}
.accordion-xs .accordion-title a {
  padding-top: 0;
  padding-bottom: 0;
}

.accordion-sm .accordion-title a {
  padding-top: 0.65em;
  padding-bottom: 0.65em;
}

.accordion-lg .accordion-title a {
  padding-top: 1.05em;
  padding-bottom: 1.05em;
}

.accordion-expander-lg .accordion-expander {
  font-size: 22px;
}

.accordion-expander-xl .accordion-expander {
  font-size: 26px;
}

.accordion-active-bg-white .active .accordion-title a {
  background-color: #fff;
  color: var(--color-primary);
}

.accordion-active-color-primary .active .accordion-title a {
  color: var(--color-primary);
}

.accordion-light .accordion-title a {
  color: #fff;
}
.accordion-light.accordion-body-underlined .accordion-item, .accordion-light.accordion-body-bordered .accordion-item {
  border-color: rgba(255, 255, 255, 0.35);
}
.accordion-light.accordion-title-underlined .accordion-title a, .accordion-light.accordion-title-bordered .accordion-title a {
  border-color: rgba(255, 255, 255, 0.35);
}

.accordion-titles-gray .accordion-title a {
  background-color: #F7F8F9;
}

/* 3.4. Iconboxes */
/* Size Variables */
/* Animations */
@-webkit-keyframes iconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.36);
            transform: scale(1.36);
  }
  100% {
    opacity: 0;
  }
}
@keyframes iconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.36);
            transform: scale(1.36);
  }
  100% {
    opacity: 0;
  }
}
/* Icon Box */
.iconbox {
  display: -webkit-box;
  display: flex;
  margin-bottom: 2em;
  position: relative;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  text-align: center;
  -webkit-transition: box-shadow 0.3s ease, background 0.3s ease;
  transition: box-shadow 0.3s ease, background 0.3s ease;
}
.iconbox .iconbox-icon-container,
.iconbox .contents,
.iconbox > h3 {
  position: relative;
  z-index: 2;
}
.iconbox .iconbox-icon-container,
.iconbox p,
.iconbox h3 {
  -webkit-transition-property: color, background;
  transition-property: color, background;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}
.iconbox h3 {
  display: inline-block;
  margin: 0 0 0.7em;
  font-size: 24px;
  line-height: 1.5em;
}
.iconbox .iconbox-icon-container {
  display: -webkit-box;
  display: flex;
  margin-bottom: 0.5em;
  border: 0 solid #F2F4F7;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 40px;
  line-height: 1em;
  color: var(--color-primary);
  -webkit-transition: border 0.3s, box-shadow 0.3s;
  transition: border 0.3s, box-shadow 0.3s;
}
.iconbox .iconbox-icon-container i,
.iconbox .iconbox-icon-container img,
.iconbox .iconbox-icon-container object,
.iconbox .iconbox-icon-container > svg {
  position: relative;
  z-index: 2;
}
.iconbox .iconbox-icon-container img[src$=".svg"],
.iconbox .iconbox-icon-container object,
.iconbox .iconbox-icon-container > svg {
  display: inline-block;
  width: 40px;
  line-height: 0;
}
.iconbox .iconbox-icon-container img[src$=".svg"] stop,
.iconbox .iconbox-icon-container object stop,
.iconbox .iconbox-icon-container > svg stop {
  -webkit-transition: stop-color 0.3s ease;
  transition: stop-color 0.3s ease;
}
.iconbox .iconbox-icon-container > svg,
.iconbox .iconbox-icon-container > svg path,
.iconbox .iconbox-icon-container > svg rect,
.iconbox .iconbox-icon-container > svg ellipse,
.iconbox .iconbox-icon-container > svg circle,
.iconbox .iconbox-icon-container > svg polygon,
.iconbox .iconbox-icon-container > svg polyline {
  -webkit-transition: fill 0.3s, stroke 0.3s;
  transition: fill 0.3s, stroke 0.3s;
}
.iconbox .iconbox-icon-container i {
  display: inline-block;
  font-size: 1em;
}
.iconbox .iconbox-icon-container[data-plugin-animated-icon] {
  font-size: 1em;
  line-height: 1em !important;
}
.iconbox .iconbox-icon-container[data-plugin-animated-icon] > svg,
.iconbox .iconbox-icon-container[data-plugin-animated-icon] object {
  opacity: 0;
}
.iconbox .contents {
  max-width: 100%;
}
.iconbox .iconbox-fill-el,
.iconbox .iconbox-icon-hover-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0;
  background-size: cover;
}
.iconbox p {
  margin-bottom: 0;
}
.iconbox .btn {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
.iconbox.text-left {
  -webkit-box-align: start;
          align-items: flex-start;
}
.iconbox.text-right {
  -webkit-box-align: end;
          align-items: flex-end;
}
.iconbox:hover .iconbox-fill-el,
.iconbox:hover .iconbox-icon-hover-bg {
  opacity: 1;
}

[data-animate-icon=true] .iconbox-icon-container > svg,
[data-animate-icon=true] .iconbox-icon-container > object {
  opacity: 0;
}
[data-animate-icon=true].iconbox-icon-animating .iconbox-icon-container > svg,
[data-animate-icon=true].iconbox-icon-animating .iconbox-icon-container > object {
  opacity: 1;
}

.iconbox-square .iconbox-icon-container i,
.iconbox-square .iconbox-icon-container img[src$=".svg"],
.iconbox-square .iconbox-icon-container object,
.iconbox-square .iconbox-icon-container > svg,
.iconbox-circle .iconbox-icon-container i,
.iconbox-circle .iconbox-icon-container img[src$=".svg"],
.iconbox-circle .iconbox-icon-container object,
.iconbox-circle .iconbox-icon-container > svg,
.iconbox-lozenge .iconbox-icon-container i,
.iconbox-lozenge .iconbox-icon-container img[src$=".svg"],
.iconbox-lozenge .iconbox-icon-container object,
.iconbox-lozenge .iconbox-icon-container > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.iconbox-square[data-shape-border] img, .iconbox-square.iconbox-icon-solid img,
.iconbox-circle[data-shape-border] img,
.iconbox-circle.iconbox-icon-solid img,
.iconbox-lozenge[data-shape-border] img,
.iconbox-lozenge.iconbox-icon-solid img {
  max-width: 68%;
}

.iconbox-label {
  display: inline-block;
  padding: 8px 12px;
  position: absolute;
  top: 13px;
  right: 13px;
  border-radius: 2px;
  background: #EFEFEF;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #000;
}

/* Icon Shape*/
.iconbox-circle .iconbox-icon-container,
.iconbox-lozenge .iconbox-icon-container,
.iconbox-hexagon .iconbox-icon-container,
.iconbox-square .iconbox-icon-container {
  border-radius: 6px;
  background: var(--color-primary);
  font-size: 40px;
  color: #fff;
}
.iconbox-circle .iconbox-icon-container img[src$=".svg"],
.iconbox-circle .iconbox-icon-container object,
.iconbox-circle .iconbox-icon-container > svg,
.iconbox-lozenge .iconbox-icon-container img[src$=".svg"],
.iconbox-lozenge .iconbox-icon-container object,
.iconbox-lozenge .iconbox-icon-container > svg,
.iconbox-hexagon .iconbox-icon-container img[src$=".svg"],
.iconbox-hexagon .iconbox-icon-container object,
.iconbox-hexagon .iconbox-icon-container > svg,
.iconbox-square .iconbox-icon-container img[src$=".svg"],
.iconbox-square .iconbox-icon-container object,
.iconbox-square .iconbox-icon-container > svg {
  width: 50px;
}

.iconbox-circle .iconbox-icon-container,
.iconbox-lozenge .iconbox-icon-container,
.iconbox-square .iconbox-icon-container {
  width: 80px;
  height: 80px;
}

.iconbox-circle .iconbox-icon-container {
  border-radius: 50em;
}
.iconbox-circle .iconbox-icon-container img[src$=".svg"],
.iconbox-circle .iconbox-icon-container object,
.iconbox-circle .iconbox-icon-container > svg {
  width: 44px;
}

.iconbox-lozenge .iconbox-icon-container {
  border-radius: 15px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.iconbox-lozenge .iconbox-icon-container i,
.iconbox-lozenge .iconbox-icon-container img[src$=".svg"],
.iconbox-lozenge .iconbox-icon-container object,
.iconbox-lozenge .iconbox-icon-container > svg {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
          transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.iconbox-hexagon .iconbox-icon-container {
  width: 80px;
  height: 50px;
  margin-top: 26.6666666667px;
  margin-bottom: 26.6666666667px;
}
.iconbox-hexagon .iconbox-icon-container:before, .iconbox-hexagon .iconbox-icon-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: inherit;
  height: inherit;
  background: inherit;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  border-radius: inherit;
  z-index: -1;
}
.iconbox-hexagon .iconbox-icon-container:before {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.iconbox-hexagon .iconbox-icon-container:after {
  -webkit-transform: rotate(-60deg);
          transform: rotate(-60deg);
}

.iconbox-wavebg .iconbox-icon-container {
  margin-top: 0.6em;
  margin-bottom: 1em;
}
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg {
  opacity: 0.22;
}
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg > img,
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg > svg {
  width: 3.1em;
  height: auto;
}
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg > img {
  max-width: none;
}
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg path {
  fill: var(--color-primary);
}
.iconbox-wavebg .iconbox-icon-container .icon-wave-bg.default-opacity {
  opacity: 1;
}

/* Icon Style*/
.iconbox-inline {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: start;
          justify-content: flex-start;
  text-align: left;
}
.iconbox-inline .iconbox-icon-container,
.iconbox-inline h3 {
  margin-bottom: 0;
}
.iconbox-inline .iconbox-icon-wrap {
  flex-basis: auto;
}
.iconbox-inline .iconbox-icon-container {
  margin-right: 15px;
}
.iconbox-inline h3 {
  -webkit-box-flex: 1;
          flex-grow: 1;
  flex-basis: 0;
}
.iconbox-inline .contents {
  flex-basis: 100%;
  margin-top: 15px;
}
.iconbox-inline.text-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.iconbox-inline.text-right .iconbox-icon-container {
  margin-right: 0;
  margin-left: 15px;
}
.iconbox-inline.iconbox-wavebg .iconbox-icon-container {
  margin-right: 1.5em;
}
.iconbox-inline.iconbox-wavebg.text-right {
  margin-right: 0;
  margin-left: 1.5em;
}

.iconbox-side {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: stretch !important;
          align-items: stretch !important;
  -webkit-box-pack: start;
          justify-content: flex-start;
  text-align: left;
}
.iconbox-side .iconbox-icon-wrap {
  margin-right: 30px;
}
.iconbox-side .iconbox-icon-container {
  margin-bottom: 0;
}
.iconbox-side .contents {
  overflow: hidden;
  -webkit-box-flex: 1;
          flex: 1;
}
.iconbox-side.text-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
}
.iconbox-side.text-right .iconbox-icon-wrap {
  margin-right: 0;
  margin-left: 30px;
}
.iconbox-side.iconbox-wavebg .iconbox-icon-container {
  margin-right: 1.5em;
}
.iconbox-side.iconbox-wavebg.text-right {
  margin-right: 0;
  margin-left: 1.5em;
}

/* Icon Borders */
[data-shape-border="1"] .iconbox-icon-container {
  border-width: 1px;
}

[data-shape-border="2"] .iconbox-icon-container {
  border-width: 2px;
}

[data-shape-border="3"] .iconbox-icon-container {
  border-width: 3px;
}

/* Filling Styles */
.iconbox-shadow,
.iconbox-shadow-hover,
.iconbox-bordered,
.iconbox-filled {
  padding: 25px 10%;
  background-color: #fff;
}
.iconbox-shadow.iconbox-inline, .iconbox-shadow.iconbox-side,
.iconbox-shadow-hover.iconbox-inline,
.iconbox-shadow-hover.iconbox-side,
.iconbox-bordered.iconbox-inline,
.iconbox-bordered.iconbox-side,
.iconbox-filled.iconbox-inline,
.iconbox-filled.iconbox-side {
  padding: 45px 5%;
}

.iconbox-semiround {
  border-radius: 5px;
}

.iconbox-round {
  border-radius: 10px;
}

.iconbox-shadow {
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.07);
}

.iconbox-shadow-hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.iconbox-shadow-hover:hover {
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.07);
}

.iconbox-bordered {
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid #E1E1E1;
}

.iconbox-filled-hover:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: inherit;
  background: var(--color-primary);
  background-size: cover;
  background-position: center;
}
.iconbox-filled-hover:hover {
  color: #fff;
}
.iconbox-filled-hover:hover .iconbox-icon-container,
.iconbox-filled-hover:hover h3,
.iconbox-filled-hover:hover p,
.iconbox-filled-hover:hover p span,
.iconbox-filled-hover:hover a,
.iconbox-filled-hover:hover .btn[class*=text-] {
  color: inherit !important;
}
.iconbox-filled-hover:hover .btn-underlined:before, .iconbox-filled-hover:hover .btn-underlined:after {
  background: currentColor;
}
.iconbox-filled-hover:hover:before {
  opacity: 1;
}
.iconbox-filled-hover:hover.iconbox-icon-image .iconbox-icon-container svg path {
  fill: currentColor;
  stroke: currentColor;
  -webkit-filter: none;
          filter: none;
}
.iconbox-filled-hover.iconbox-has-fill-element:before {
  content: none;
}
.backgroundcliptext .iconbox-filled-hover.iconbox-heading-gradient:hover .iconbox-icon-container i,
.backgroundcliptext .iconbox-filled-hover.iconbox-heading-gradient:hover h3 {
  background: #fff;
}
.iconbox-filled-hover.iconbox-scale-bg:before {
  -webkit-transition: opacity 0.35s 0.1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.35s 0.1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s 0.1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s 0.1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.iconbox-filled-hover.iconbox-scale-bg:hover:before {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: opacity 0.1s 0s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.1s 0s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.1s 0s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.1s 0s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
}
.iconbox-filled-hover.iconbox-light-onhover:before {
  background-color: #fff;
}
.iconbox-filled-hover.iconbox-light-onhover:hover {
  color: rgba(30, 22, 102, 0.55);
}
.iconbox-filled-hover.iconbox-light-onhover:hover .iconbox-icon-container {
  color: var(--color-primary) !important;
}
.iconbox-filled-hover.iconbox-light-onhover:hover h3 {
  color: #1e1666 !important;
}

.iconbox-icon-shadow .iconbox-icon-container {
  box-shadow: 0 17px 30px rgba(0, 0, 0, 0.07);
}

.iconbox-icon-hover-shadow .iconbox-icon-container {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.iconbox-icon-hover-shadow:hover .iconbox-icon-container {
  box-shadow: 0 17px 30px rgba(0, 0, 0, 0.07);
}

.iconbox-icon-linked .iconbox-icon-wrap {
  position: relative;
}
.iconbox-icon-linked .iconbox-icon-wrap:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #E9E9E9;
}
.iconbox-icon-linked:last-child .iconbox-icon-wrap:after {
  display: none;
}

.iconbox-contents-show-onhover.hide-target .contents > * {
  opacity: 0;
}

/* Heading sizes */
.iconbox-heading-xs h3 {
  font-size: 18px;
}

.iconbox-heading-sm h3 {
  font-size: 20px;
}

.iconbox-heading-md h3 {
  font-size: 24px;
}

.iconbox-heading-lg h3 {
  font-size: 28px;
}

/* Heading Gradient */
.backgroundcliptext .iconbox-heading-gradient h3 {
  background: -webkit-gradient(linear, left top, right top, from(#1DE1D1), to(#780BEE));
  background: linear-gradient(to right, #1DE1D1 0%, #780BEE 100%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}

/* Icon Gradient */
.backgroundcliptext .iconbox-icon-gradient .iconbox-icon-container i {
  background: -webkit-gradient(linear, left top, right top, from(#1DE1D1), to(#780BEE));
  background: linear-gradient(to right, #1DE1D1 0%, #780BEE 100%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}

/* Sizes*/
.iconbox-xxs .iconbox-icon-container img {
  width: 20px;
}
.iconbox-xxs.iconbox-circle .iconbox-icon-container, .iconbox-xxs.iconbox-square .iconbox-icon-container, .iconbox-xxs.iconbox-lozenge .iconbox-icon-container {
  width: 20px;
  height: 20px;
}
.iconbox-xxs.iconbox-hexagon .iconbox-icon-container {
  width: 20px;
  height: 12.5px;
}
.iconbox-xxs.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
  margin-top: 6.6666666667px;
  margin-bottom: 6.6666666667px;
}

.iconbox-xs .iconbox-icon-container img {
  width: 45px;
}
.iconbox-xs.iconbox-circle .iconbox-icon-container, .iconbox-xs.iconbox-square .iconbox-icon-container, .iconbox-xs.iconbox-lozenge .iconbox-icon-container {
  width: 45px;
  height: 45px;
}
.iconbox-xs.iconbox-hexagon .iconbox-icon-container {
  width: 45px;
  height: 28.125px;
}
.iconbox-xs.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.iconbox-sm .iconbox-icon-container img {
  width: 60px;
}
.iconbox-sm.iconbox-circle .iconbox-icon-container, .iconbox-sm.iconbox-square .iconbox-icon-container, .iconbox-sm.iconbox-lozenge .iconbox-icon-container {
  width: 60px;
  height: 60px;
}
.iconbox-sm.iconbox-hexagon .iconbox-icon-container {
  width: 60px;
  height: 37.5px;
}
.iconbox-sm.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.iconbox-md .iconbox-icon-container img {
  width: 90px;
}
.iconbox-md.iconbox-circle .iconbox-icon-container, .iconbox-md.iconbox-square .iconbox-icon-container, .iconbox-md.iconbox-lozenge .iconbox-icon-container {
  width: 90px;
  height: 90px;
}
.iconbox-md.iconbox-hexagon .iconbox-icon-container {
  width: 90px;
  height: 56.25px;
}
.iconbox-md.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.iconbox-lg .iconbox-icon-container img {
  width: 100px;
}
.iconbox-lg.iconbox-circle .iconbox-icon-container, .iconbox-lg.iconbox-square .iconbox-icon-container, .iconbox-lg.iconbox-lozenge .iconbox-icon-container {
  width: 100px;
  height: 100px;
}
.iconbox-lg.iconbox-hexagon .iconbox-icon-container {
  width: 100px;
  height: 62.5px;
}
.iconbox-lg.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
  margin-top: 33.3333333333px;
  margin-bottom: 33.3333333333px;
}

.iconbox-xl .iconbox-icon-container img {
  width: 125px;
}
.iconbox-xl.iconbox-circle .iconbox-icon-container, .iconbox-xl.iconbox-square .iconbox-icon-container, .iconbox-xl.iconbox-lozenge .iconbox-icon-container {
  width: 125px;
  height: 125px;
}
.iconbox-xl.iconbox-hexagon .iconbox-icon-container {
  width: 125px;
  height: 78.125px;
}
.iconbox-xl.iconbox-hexagon:not(.iconbox-inline) .iconbox-icon-container {
  margin-top: 41.6666666667px;
  margin-bottom: 41.6666666667px;
}

.iconbox-xxs .iconbox-icon-container {
  font-size: 20px;
}
.iconbox-xxs .iconbox-icon-container img[src$=".svg"],
.iconbox-xxs .iconbox-icon-container object,
.iconbox-xxs .iconbox-icon-container > svg {
  width: 20px;
}

.iconbox-xs .iconbox-icon-container {
  font-size: 20px;
}
.iconbox-xs .iconbox-icon-container img[src$=".svg"],
.iconbox-xs .iconbox-icon-container object,
.iconbox-xs .iconbox-icon-container > svg {
  width: 20px;
}

.iconbox-sm .iconbox-icon-container {
  font-size: 25px;
}
.iconbox-sm .iconbox-icon-container img[src$=".svg"],
.iconbox-sm .iconbox-icon-container object,
.iconbox-sm .iconbox-icon-container > svg {
  width: 25px;
}

.iconbox-md .iconbox-icon-container {
  font-size: 40px;
}
.iconbox-md .iconbox-icon-container img[src$=".svg"],
.iconbox-md .iconbox-icon-container object,
.iconbox-md .iconbox-icon-container > svg {
  width: 40px;
}

.iconbox-lg .iconbox-icon-container {
  font-size: 45px;
}
.iconbox-lg .iconbox-icon-container img[src$=".svg"],
.iconbox-lg .iconbox-icon-container object,
.iconbox-lg .iconbox-icon-container > svg {
  width: 45px;
}

.iconbox-xl .iconbox-icon-container {
  font-size: 50px;
}
.iconbox-xl .iconbox-icon-container img[src$=".svg"],
.iconbox-xl .iconbox-icon-container object,
.iconbox-xl .iconbox-icon-container > svg {
  width: 50px;
}

.iconbox-icon-ripple .iconbox-icon-container:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  border: 1px solid var(--color-primary);
  -webkit-animation: iconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
          animation: iconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}

.iconbox-blue {
  background-color: #dde1f8;
}
.iconbox-blue .contents {
  color: #3d59e8;
}

.iconbox-pink {
  background-color: #fcd7e0;
}
.iconbox-pink .contents {
  color: #fe688b;
}

.iconbox-green {
  background-color: #c7f7ea;
}
.iconbox-green .contents {
  color: #28d5a7;
}

.lqd-parallax-iconboxes {
  padding: 35px 35px 15px;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.082);
}

.iconbox-color-pippin .iconbox-icon-container {
  color: #fd623c;
  background-color: #ffe4df;
}

.iconbox-color-apple .iconbox-icon-container {
  color: #46d664;
  background-color: #cdf2d6;
}

.compose-mode [data-animate-icon=true] .iconbox-icon-container > svg,
.compose-mode [data-animate-icon=true] .iconbox-icon-container > object {
  opacity: 1;
}

@media screen and (min-width: 992px) {
  .lqd-parallax-iconboxes {
    margin-top: -110%;
  }
}
@media screen and (min-width: 768px) {
  .lqd-iconbox-stack {
    position: relative;
    text-align: center;
  }
  .lqd-iconbox-stack .iconbox {
    padding-left: 10px;
    padding-right: 10px;
    width: 150px;
  }
  .lqd-iconbox-stack .iconbox-icon-container {
    margin-bottom: 0;
  }
  .lqd-iconbox-stack .iconbox-blue,
  .lqd-iconbox-stack .iconbox-green {
    position: absolute;
    top: 26%;
    z-index: 2;
  }
  .lqd-iconbox-stack .iconbox-blue {
    top: 30%;
    left: 7%;
  }
  .lqd-iconbox-stack .iconbox-pink {
    margin-top: 12vw;
  }
  .lqd-iconbox-stack .iconbox-green {
    right: 7%;
  }
}
@media screen and (max-width: 767px) {
  .lqd-iconbox-stack .iconbox {
    width: 100%;
  }
  .text-xs-center{
    text-align: center;
  }
  .text-xs-center-item{
    margin-left: auto;
    margin-right: auto;
  }
  .mb-xs-30, .mb-0.mb-xs-30{
    margin-bottom: 30px !important;
  }
  .mb-xs-15, .mb-0.mb-xs-15{
    margin-bottom: 15px !important;
  }
  .d-block-xs, .d-flex.d-block-xs{
    display: block !important;
  }
}
/* 3.5. Tabs */
.tabs-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: stretch;
          align-items: stretch;
  margin-bottom: 2em;
  position: relative;
}
.tabs-nav > li,
.tabs-nav > li > a {
  display: -webkit-box;
  display: flex;
}
.tabs-nav a {
  position: relative;
  z-index: 2;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.6em 1em;
  font-size: 1em;
  align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.tabs-nav li {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 1.5em;
  letter-spacing: inherit;
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
          justify-content: center;
}
.tabs-nav li.active a,
.tabs-nav li a:hover,
.tabs-nav li a:focus {
  color: var(--color-link-hover);
  background-color: transparent;
}

.tabs-pane {
  display: none;
}
.tabs-pane.active {
  display: block;
}

/* Nav positions */
.tabs-nav-centered .tabs-nav {
  -webkit-box-pack: center;
          justify-content: center;
}

.tabs-nav-justified .tabs-nav li,
.tabs-nav-justified .tabs-nav li a {
  -webkit-box-flex: 1;
          flex: 1 auto;
}

.tabs-nav-spaced .tabs-nav li + li {
  margin-left: 8px;
}

/* Nav Borderes */
.tabs-nav-bordered .tabs-nav {
  border: 1px solid #e0e1eb;
}

.tabs-nav-underlined .tabs-nav {
  border-bottom: 1px solid #e0e1eb;
}

.tabs-nav-items-bordered .tabs-nav li {
  font-size: 12px;
  border: 1px solid #E1E1E1;
}
.tabs-nav-items-bordered .tabs-nav a {
  padding: 1.25em 3.6666666667em;
  margin: -1px;
}

/* Nav Stats */
.tabs-nav-filled .tabs-nav a {
  padding: 1.15em 2.8em;
  background-color: rgba(0, 0, 0, 0.07);
  color: #000;
}
.tabs-nav-filled.tabs-content-filled .tabs-nav a {
  background: none;
}

.tabs-nav-active-filled .tabs-nav li.active a,
.tabs-nav-active-filled .tabs-nav li a:hover {
  background-color: #e0e1eb;
}

.tabs-nav-active-underlined .tabs-nav li {
  position: relative;
  overflow: hidden;
}
.tabs-nav-active-underlined .tabs-nav li:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 0;
  pointer-events: none;
  background-color: var(--color-primary);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}
.tabs-nav-active-underlined .tabs-nav li.active:after, .tabs-nav-active-underlined .tabs-nav li:hover:after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.tabs-nav-shadowed .tabs-nav {
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
}

/* Contens Stats */
.tabs-contents-shadowed .tabs-nav {
  margin: 0;
}
.tabs-contents-shadowed .tabs-content {
  padding: 40px 30px 1.25em;
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.07);
}

.tabs-content-filled .tabs-nav {
  margin-bottom: 0;
}
.tabs-content-filled .tabs-content {
  padding: 30px 40px;
  background-color: #ECECEC;
}

/* Nav Icons */
.tabs-nav-icon {
  margin-right: 0.65em;
  font-size: 26px;
}

/* Nav Size */
.tabs-nav-sm .tabs-nav li {
  margin-left: 35px;
  margin-right: 35px;
}
.tabs-nav-sm .tabs-nav a {
  padding: 0.05em 0;
}

.tabs-nav-lg .tabs-nav a {
  padding: 1.2em 2em;
}

.tabs-nav-side {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: stretch;
          justify-content: stretch;
}
.tabs-nav-side .tabs-nav,
.tabs-nav-side .tabs-content {
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.tabs-nav-side .tabs-nav {
  width: 26.5%;
  padding: 35px 0 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  flex-wrap: inherit;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.tabs-nav-side .tabs-nav a {
  width: 100%;
  -webkit-box-flex: 1;
  flex: 1 auto;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.tabs-nav-side .tabs-nav a:after {
  content: '';
  display: inline-block;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  background-color: currentColor;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.tabs-nav-side .tabs-nav a:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.tabs-nav-side .tabs-nav li {
  width: 100%;
  -webkit-box-pack: start;
          justify-content: flex-start;
  font-size: 16px;
  text-align: left;
  -webkit-transition: font-weight 0.3s;
  transition: font-weight 0.3s;
}
.tabs-nav-side .tabs-nav li.active {
  font-weight: 700;
}
.tabs-nav-side .tabs-nav li.active a:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.tabs-nav-side .tabs-nav .tabs-nav-title {
  padding: 0 40px;
  margin-bottom: 20px;
}
.tabs-nav-side .tabs-nav .tabs-nav-title h6 {
  margin: 0;
  color: #5A5A5A;
}
.tabs-nav-side .tabs-content {
  width: 73.5%;
  padding: 30px;
}

.tabs-nav-side-alt .tabs-nav {
  width: 31.6%;
}
.tabs-nav-side-alt .tabs-nav li {
  border-bottom: 1px solid #F0F0F0;
  font-size: 20px;
  font-weight: 600;
}
.tabs-nav-side-alt .tabs-nav li a {
  padding: 1.15em 2.25em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
  color: #100F26;
}
.tabs-nav-side-alt .tabs-nav li a:after {
  content: '\f105';
  width: auto;
  height: auto;
  position: absolute;
  top: 1.15em;
  left: 0;
  background: none;
  opacity: 0;
  font-family: fontAwesome;
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.tabs-nav-side-alt .tabs-nav li a span {
  margin-top: 0.65em;
  font-size: 0.75em;
  line-height: 1.7333333333em;
  font-weight: 400;
}
.tabs-nav-side-alt .tabs-nav li.active a span,
.tabs-nav-side-alt .tabs-nav li a span {
  color: #84898F;
}
.tabs-nav-side-alt .tabs-nav li.active a:after, .tabs-nav-side-alt .tabs-nav li:hover a:after {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.tabs-nav-side-alt .tabs-nav li.active {
  font-weight: 600;
}
.tabs-nav-side-alt .tabs-nav li.active a {
  color: var(--color-primary);
}
.tabs-nav-side-alt .tabs-content {
  width: 68.4%;
}

.tabs-nav-side-block .tabs-nav li {
  border: none;
  border-radius: 6px;
  background-color: #fff;
  font-size: 21px;
  font-weight: 500;
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.05);
}
.tabs-nav-side-block .tabs-nav li:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  background-color: var(--color-primary);
  opacity: 0;
  -webkit-transform: scale(0.97, 0.95);
          transform: scale(0.97, 0.95);
  -webkit-transition: opacity 0.45s cubic-bezier(0.2, 0.95, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.2, 0.95, 0.2, 1);
  transition: opacity 0.45s cubic-bezier(0.2, 0.95, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.2, 0.95, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.2, 0.95, 0.2, 1), opacity 0.45s cubic-bezier(0.2, 0.95, 0.2, 1);
  transition: transform 0.45s cubic-bezier(0.2, 0.95, 0.2, 1), opacity 0.45s cubic-bezier(0.2, 0.95, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.2, 0.95, 0.2, 1);
}
.tabs-nav-side-block .tabs-nav li:not(:last-child) {
  margin-bottom: 1.7em;
}
.tabs-nav-side-block .tabs-nav li a {
  padding-left: 30px;
  padding-right: 30px;
}
.tabs-nav-side-block .tabs-nav li a:after {
  content: none;
}
.tabs-nav-side-block .tabs-nav li a span {
  margin-top: 0.5em;
  opacity: 0.65;
  font-size: 0.7142857143em;
  color: inherit;
}
.tabs-nav-side-block .tabs-nav li.active:before, .tabs-nav-side-block .tabs-nav li:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.tabs-nav-side-block .tabs-nav li.active a, .tabs-nav-side-block .tabs-nav li:hover a {
  color: #fff;
}
.tabs-nav-side-block .tabs-nav li.active a span, .tabs-nav-side-block .tabs-nav li:hover a span {
  color: inherit;
}
.tabs-nav-side-block .tabs-nav li.active {
  font-weight: 500;
}

/* 3.6. Subscribe Forms */
@-webkit-keyframes formRotate360 {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes formRotate360 {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.ld-sf {
  position: relative;
  margin-bottom: 2em;
  /*
  * Form sizes
  */
  /*
  * Input style
  */
  /*
  * Button positions
  */
  /*
  * Button style
  */
  /*
  * Shadows
  */
  /*
  * Shapes
  */
  /*
  * Border thickness
  */
  /*
  * Button Stats
  */
}
.ld-sf input:focus {
  outline: none;
}
.ld-sf p {
  line-height: 1.5em;
  margin-bottom: 20px;
  position: relative;
}
.ld-sf p [type=submit] {
  padding-top: 0;
  padding-bottom: 0;
}
.ld-sf [type=text],
.ld-sf [type=email],
.ld-sf [type=submit] {
  height: 60px;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}
.ld-sf [type=text],
.ld-sf [type=email] {
  display: block;
  width: 100%;
  padding: 0 2.5em;
  border-width: 1px;
  border-style: solid;
  border-color: #ededed;
  border-radius: 2px;
  background-color: transparent;
  line-height: 1.5em;
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}
.ld-sf [type=text]:focus,
.ld-sf [type=email]:focus {
  border-color: #aaa;
}
.ld-sf [type=text]::-webkit-input-placeholder,
.ld-sf [type=email]::-webkit-input-placeholder {
  color: inherit;
}
.ld-sf [type=text]::-moz-placeholder,
.ld-sf [type=email]::-moz-placeholder {
  color: inherit;
}
.ld-sf [type=text]:-moz-placeholder,
.ld-sf [type=email]:-moz-placeholder {
  color: inherit;
}
.ld-sf [type=text]:-ms-input-placeholder,
.ld-sf [type=email]:-ms-input-placeholder {
  color: inherit;
}
.ld-sf select {
  display: inline-block;
  width: auto;
}
.ld-sf [type=submit] {
  display: -webkit-inline-box;
  display: inline-flex;
  padding: 0 40px;
  margin: 0;
  border: 0;
  position: relative;
  border-radius: 2px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 13px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.13em;
  outline: none;
  background-color: var(--color-primary);
  color: #fff;
  -webkit-transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;
}
.ld-sf [type=submit]:hover .submit-icon {
  -webkit-transform: translate3d(2px, 0, 0);
          transform: translate3d(2px, 0, 0);
}
.ld-sf [type=submit]:hover .submit-text + .submit-icon {
  -webkit-transform: translate3d(2px, 0, 0);
          transform: translate3d(2px, 0, 0);
}
.ld-sf .submit-icon,
.ld-sf .submit-text {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  line-height: 1em;
}
.ld-sf .submit-icon {
  font-size: 1.1em;
}
.ld-sf .submit-icon i {
  margin: 0;
}
.ld-sf .submit-icon + .submit-text {
  margin-left: 0.65em;
}
.ld-sf .submit-text + .submit-icon {
  margin-left: 0.65em;
}
.ld-sf .submit-text:empty {
  display: none;
}
.ld-sf[data-plugin-ld-sf] {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease, visiblity 0.3s ease;
  transition: opacity 0.3s ease, visiblity 0.3s ease;
}
.ld-sf--is-initialized {
  opacity: 1 !important;
  visibility: visible !important;
}
.ld-sf--size-xs [type=text],
.ld-sf--size-xs [type=email],
.ld-sf--size-xs [type=submit] {
  height: 45px;
}
.ld-sf--size-sm [type=text],
.ld-sf--size-sm [type=email],
.ld-sf--size-sm [type=submit] {
  height: 50px;
}
.ld-sf--size-md [type=text],
.ld-sf--size-md [type=email],
.ld-sf--size-md [type=submit] {
  height: 60px;
}
.ld-sf--size-lg [type=text],
.ld-sf--size-lg [type=email] {
  height: 65px;
}
.ld-sf--size-lg [type=submit] {
  height: 60px;
}
.ld-sf--size-xl [type=text],
.ld-sf--size-xl [type=email] {
  height: 70px;
}
.ld-sf--size-xl [type=submit] {
  height: 65px;
}
.ld-sf--input-solid [type=text],
.ld-sf--input-solid [type=email] {
  border: none;
  background-color: #fff;
  color: #000;
}
.ld-sf--input-underlined [type=text], .ld-sf--input-underlined [type=email] {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-bottom-width: 1px;
  border-left: none;
  border-radius: 0 !important;
}
.ld-sf--input-underlined [type=text]:focus,
.ld-sf--input-underlined [type=email]:focus {
  background-color: transparent;
}
html:not([dir=rtl]) .ld-sf--input-underlined [type=text],
html:not([dir=rtl]) .ld-sf--input-underlined [type=email] {
  padding-left: 0 !important;
}
.ld-sf--button-inside [type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 100%;
  max-height: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ld-sf--button-inside label ~ [type=submit] {
  min-height: 0;
}
.ld-sf--button-inside.ld-sf--button-bordered [type=submit] {
  bottom: 1px;
  height: calc(100% - 2px);
  min-height: calc(100% - 2px);
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.ld-sf--button-inside.ld-sf--button-bordered .submit-text,
.ld-sf--button-inside.ld-sf--button-bordered .submit-icon {
  margin-top: 0;
}
.ld-sf--button-inside.ld-sf--button-bordered.ld-sf--border-thick [type=submit] {
  bottom: 2px;
  height: calc(100% - 4px);
  min-height: calc(100% - 4px);
}
.ld-sf--button-inside.ld-sf--button-bordered.ld-sf--border-thicker [type=submit] {
  bottom: 3px;
  height: calc(100% - 6px);
  min-height: calc(100% - 6px);
}
.ld-sf--button-inside.ld-sf--button-naked [type=submit] {
  padding-right: 1em;
}
.ld-sf--button-inside.button-shrinked [type=submit] {
  bottom: 14%;
  right: 12px;
  max-height: 72%;
  min-height: 72%;
  height: 72%;
}
.ld-sf--button-inline form {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: stretch;
          align-items: stretch;
}
.ld-sf--button-inline p,
.ld-sf--button-inline [type=submit] {
  vertical-align: bottom;
}
.ld-sf--button-inline p {
  -webkit-box-flex: 1;
          flex: 1 0;
  padding-right: 20px;
}
.ld-sf--button-inline [type=text],
.ld-sf--button-inline [type=email] {
  display: inline-block;
}
.ld-sf--button-block [type=submit] {
  width: 100%;
}
.ld-sf--button-bordered [type=submit] {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.ld-sf--button-naked [type=submit] {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: none;
  color: var(--color-primary);
}
.ld-sf--button-underlined [type=submit] {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.ld-sf--input-shadow [type=text],
.ld-sf--input-shadow [type=email] {
  box-shadow: 0 16px 50px rgba(0, 0, 0, 0.07);
}
.ld-sf--input-shadow [type=text]:focus,
.ld-sf--input-shadow [type=email]:focus {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.ld-sf--input-inner-shadow [type=text],
.ld-sf--input-inner-shadow [type=email] {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) inset;
}
.ld-sf--input-inner-shadow [type=text]:focus,
.ld-sf--input-inner-shadow [type=email]:focus {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset;
}
.ld-sf--sharp [type=text],
.ld-sf--sharp [type=email],
.ld-sf--sharp [type=submit] {
  border-radius: 0;
}
.ld-sf--round [type=text],
.ld-sf--round [type=email],
.ld-sf--round [type=submit] {
  border-radius: 4px;
}
.ld-sf--circle [type=text],
.ld-sf--circle [type=email],
.ld-sf--circle [type=submit] {
  border-radius: 50em;
}
.ld-sf--border-thin [type=text],
.ld-sf--border-thin [type=email],
.ld-sf--border-thin [type=submit] {
  border-width: 1px;
}
.ld-sf--border-thin .submit-text,
.ld-sf--border-thin .submit-icon {
  margin-top: -1px;
}
.ld-sf--border-thick [type=text],
.ld-sf--border-thick [type=email],
.ld-sf--border-thick [type=submit] {
  border-width: 2px;
}
.ld-sf--border-thick .submit-text,
.ld-sf--border-thick .submit-icon {
  margin-top: -2px;
}
.ld-sf--border-thicker [type=text],
.ld-sf--border-thicker [type=email],
.ld-sf--border-thicker [type=submit] {
  border-width: 3px;
}
.ld-sf--border-thicker .submit-text,
.ld-sf--border-thicker .submit-icon {
  margin-top: -3px;
}
.ld-sf--button-hide-label [type=submit] .submit-text {
  display: none !important;
}
.ld-sf--button-hide-label [type=submit] .submit-icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ld-sf--button-hidden [type=submit] {
  display: none !important;
}

.ld_sf_form {
  position: relative;
}

.ld-sf-spinner {
  display: inline-block;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50em;
  -webkit-transform: translate(-50%, -50%) scale(1.5);
          transform: translate(-50%, -50%) scale(1.5);
  color: inherit;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, visibility, transform;
  transition-property: opacity, visibility, transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}
.ld-sf-spinner:before,
.ld-sf-spinner span {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  border: 2px solid;
}
.ld-sf-spinner:before {
  content: '';
  opacity: 0.1;
}
.ld-sf-spinner span {
  text-indent: -99999px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-clip-path: polygon(0 0%, 100% 50%, 100% 0%, 0% 0%);
          clip-path: polygon(0 0%, 100% 50%, 100% 0%, 0% 0%);
  -webkit-animation: formRotate360 1s linear both infinite;
          animation: formRotate360 1s linear both infinite;
}

.form-submitting .submit-text,
.form-submitting .submit-icon {
  opacity: 0;
  visibility: hidden;
}
.form-submitting .ld-sf-spinner {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.contact-form-result {
  margin-top: 1.5em;
}

#ld_sf_response p {
  padding: 0.75em 1.25em;
  border-radius: 3px;
  font-weight: 700;
  color: #000;
}

.ld_sf_response-success {
  background-color: rgba(107, 228, 117, 0.4);
  color: #1ca13d;
}

.ld_sf_response-error {
  background-color: rgba(228, 107, 107, 0.4);
  color: #a11c1c;
}

/* 3.7. Contact Forms */
.contact-form {
  margin-bottom: 2em;
}
.contact-form p {
  margin-bottom: 0;
  position: relative;
}
.contact-form .ld-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.contact-form select,
.contact-form input,
.contact-form textarea,
.contact-form .ui-selectmenu-button {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 50px;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #d5d6de;
  border-radius: 0;
  background: none;
  -webkit-box-align: center;
          align-items: center;
  color: #b7b9c4;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.contact-form select::-webkit-input-placeholder,
.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder,
.contact-form .ui-selectmenu-button::-webkit-input-placeholder {
  color: inherit;
}
.contact-form select::-moz-placeholder,
.contact-form input::-moz-placeholder,
.contact-form textarea::-moz-placeholder,
.contact-form .ui-selectmenu-button::-moz-placeholder {
  color: inherit;
}
.contact-form select:-moz-placeholder,
.contact-form input:-moz-placeholder,
.contact-form textarea:-moz-placeholder,
.contact-form .ui-selectmenu-button:-moz-placeholder {
  color: inherit;
}
.contact-form select:-ms-input-placeholder,
.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder,
.contact-form .ui-selectmenu-button:-ms-input-placeholder {
  color: inherit;
}
.contact-form select:focus,
.contact-form input:focus,
.contact-form textarea:focus,
.contact-form .ui-selectmenu-button:focus {
  outline: none;
  border-color: #9c9eb1;
  color: #9c9eb1;
}
.contact-form input[type=date] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact-form input[type=submit],
.contact-form button[type=submit] {
  display: -webkit-inline-box;
  display: inline-flex;
  width: auto;
  height: 50px;
  margin-bottom: 0;
  padding: 0 2.8em;
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  -webkit-box-align: center;
          align-items: center;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact-form input[type=submit]:hover,
.contact-form button[type=submit]:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}
.contact-form input[type=radio],
.contact-form input[type=checkbox] {
  display: initial;
  width: auto;
  height: auto;
}
.contact-form select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact-form select[multiple] {
  padding: 0;
  height: auto;
}
.contact-form select[multiple] option {
  padding: 0.5em 2em;
}
.contact-form label {
  font-weight: inherit;
  margin-right: 5px;
}
.contact-form textarea {
  height: auto !important;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 0 !important;
}
.contact-form i {
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 2;
  left: calc(1em + 15px);
  margin-top: -10px;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.contact-form i ~ label,
.contact-form i ~ input,
.contact-form i ~ textarea {
  text-indent: 1em;
}
.contact-form .ui-selectmenu-button .ui-selectmenu-text {
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.contact-form .ui-selectmenu-button .ui-selectmenu-icon {
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.contact-form .ui-spinner {
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: none;
}
.contact-form input.ui-spinner-input {
  margin: 0;
  box-shadow: none;
}
.contact-form a.ui-spinner-button {
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.contact-form a.ui-spinner-button:active {
  background-color: #181b31;
}

/* Inputs sizes */
.contact-form-inputs-sm select,
.contact-form-inputs-sm input,
.contact-form-inputs-sm textarea,
.contact-form-inputs-sm .ui-selectmenu-button {
  height: 40px;
}
.contact-form-inputs-sm input[type=submit],
.contact-form-inputs-sm button[type=submit] {
  height: 40px;
}

.contact-form-inputs-md select,
.contact-form-inputs-md input,
.contact-form-inputs-md textarea,
.contact-form-inputs-md .ui-selectmenu-button {
  height: 50px;
}
.contact-form-inputs-md input[type=submit],
.contact-form-inputs-md button[type=submit] {
  height: 50px;
}

.contact-form-inputs-lg select,
.contact-form-inputs-lg input,
.contact-form-inputs-lg textarea,
.contact-form-inputs-lg .ui-selectmenu-button {
  height: 60px;
  margin-bottom: 30px;
}
.contact-form-inputs-lg input[type=submit],
.contact-form-inputs-lg button[type=submit] {
  height: 60px;
}

/* Input Shapes */
.contact-form-inputs-underlined select,
.contact-form-inputs-underlined input,
.contact-form-inputs-underlined textarea,
.contact-form-inputs-underlined .ui-selectmenu-button {
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
  border-bottom-width: 1px;
}
.contact-form-inputs-underlined i {
  left: 15px;
}
.contact-form-inputs-underlined i ~ label,
.contact-form-inputs-underlined i ~ input,
.contact-form-inputs-underlined i ~ textarea {
  text-indent: 2em;
}

.contact-form-inputs-filled select,
.contact-form-inputs-filled input,
.contact-form-inputs-filled textarea,
.contact-form-inputs-filled .ui-selectmenu-button {
  border: none;
  background-color: #fff;
}

/* Inputs Border Thickness */
.contact-form-inputs-border-thin select,
.contact-form-inputs-border-thin input,
.contact-form-inputs-border-thin textarea,
.contact-form-inputs-border-thin .ui-selectmenu-button {
  border-width: 1px;
}
.contact-form-inputs-border-thin input[type=submit],
.contact-form-inputs-border-thin button[type=submit] {
  border-width: 1px;
}

.contact-form-inputs-border-thick select,
.contact-form-inputs-border-thick input,
.contact-form-inputs-border-thick textarea,
.contact-form-inputs-border-thick .ui-selectmenu-button {
  border-width: 2px;
}
.contact-form-inputs-border-thick input[type=submit],
.contact-form-inputs-border-thick button[type=submit] {
  border-width: 2px;
}

.contact-form-inputs-border-thicker select,
.contact-form-inputs-border-thicker input,
.contact-form-inputs-border-thicker textarea,
.contact-form-inputs-border-thicker .ui-selectmenu-button {
  border-width: 3px;
}
.contact-form-inputs-border-thicker input[type=submit],
.contact-form-inputs-border-thicker button[type=submit] {
  border-width: 3px;
}

/* Inputs Border Roundness */
.contact-form-inputs-sharp select,
.contact-form-inputs-sharp input,
.contact-form-inputs-sharp textarea,
.contact-form-inputs-sharp .ui-selectmenu-button {
  border-radius: 0;
}
.contact-form-inputs-sharp input[type=submit],
.contact-form-inputs-sharp button[type=submit] {
  border-radius: 0;
}
.contact-form-inputs-sharp input[type=submit],
.contact-form-inputs-sharp button[type=submit] {
  border-radius: 0;
}

.contact-form-inputs-round select,
.contact-form-inputs-round input,
.contact-form-inputs-round textarea,
.contact-form-inputs-round .ui-selectmenu-button {
  border-radius: 4px;
}
.contact-form-inputs-round input[type=submit],
.contact-form-inputs-round button[type=submit] {
  border-radius: 4px;
}

.contact-form-inputs-circle select,
.contact-form-inputs-circle input,
.contact-form-inputs-circle textarea,
.contact-form-inputs-circle .ui-selectmenu-button {
  border-radius: 50em;
}
.contact-form-inputs-circle textarea {
  border-radius: 15px !important;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
}
.contact-form-inputs-circle textarea[rows="1"] {
  border-radius: 50em !important;
}
.contact-form-inputs-circle input[type=submit],
.contact-form-inputs-circle button[type=submit] {
  border-radius: 50em;
}
.contact-form-inputs-circle .ui-spinner-button {
  right: 1em;
}
.contact-form-inputs-circle:not(.contact-form-inputs-filled) textarea {
  padding-left: 0;
  padding-right: 0;
}

.contact-form-inputs-underlined select,
.contact-form-inputs-underlined input,
.contact-form-inputs-underlined textarea,
.contact-form-inputs-underlined .ui-selectmenu-button {
  border-radius: 0;
}

/*
	Styling Button
*/
/* Button sizes */
.contact-form-button-sm input[type=submit],
.contact-form-button-sm button[type=submit] {
  height: 40px;
}

.contact-form-button-md input[type=submit],
.contact-form-button-md button[type=submit] {
  height: 50px;
}

.contact-form-button-lg input[type=submit],
.contact-form-button-lg button[type=submit] {
  height: 60px;
  margin-bottom: 30px;
}
.contact-form-button-lg i {
  margin-top: -15px;
}

/* Button Width */
.contact-form-button-block input[type=submit],
.contact-form-button-block button[type=submit] {
  width: 100%;
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
}

/* Button Shapes */
.contact-form-button-underlined input[type=submit],
.contact-form-button-underlined button[type=submit] {
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
  background-color: transparent;
  color: var(--color-primary);
  border-bottom-width: 1px;
}
.contact-form-button-underlined input[type=submit]:hover,
.contact-form-button-underlined button[type=submit]:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}

.contact-form-button-bordered input[type=submit],
.contact-form-button-bordered button[type=submit] {
  color: var(--color-primary);
  background-color: transparent;
}
.contact-form-button-bordered input[type=submit]:hover,
.contact-form-button-bordered button[type=submit]:hover {
  background-color: var(--color-primary);
  color: #fff;
}

/* Button Border Thickness */
.contact-form-button-border-thin input[type=submit],
.contact-form-button-border-thin button[type=submit] {
  border-width: 1px;
}

.contact-form-button-border-thick input[type=submit],
.contact-form-button-border-thick button[type=submit] {
  border-width: 2px;
}

.contact-form-button-border-thicker input[type=submit],
.contact-form-button-border-thicker button[type=submit] {
  border-width: 3px;
}

/* Button Border Roundness */
.contact-form-button-sharp input[type=submit],
.contact-form-button-sharp button[type=submit] {
  border-radius: 0;
}

.contact-form-button-round input[type=submit],
.contact-form-button-round button[type=submit] {
  border-radius: 4px;
}

.contact-form-button-circle input[type=submit],
.contact-form-button-circle button[type=submit] {
  border-radius: 50em;
}

/* Custom Labels */
.liquid-cl {
  display: -webkit-box;
  display: flex;
  height: calc(100% - 20px);
  margin: 0;
  position: absolute;
  top: 0;
  left: calc(15px + 2em);
  -webkit-box-align: center;
          align-items: center;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.liquid-cl:after {
  content: attr(data-label);
  position: absolute;
  bottom: 100%;
  left: 0;
  opacity: 0;
  font-size: 75%;
  -webkit-transition: inherit;
  transition: inherit;
}
.liquid-cl span {
  -webkit-transition: inherit;
  transition: inherit;
}
textarea ~ .liquid-cl {
  padding-top: 1em;
  -webkit-box-align: start;
          align-items: flex-start;
}
.contact-form-inputs-lg .liquid-cl {
  height: calc(100% - 30px);
}
.contact-form-inputs-underlined .liquid-cl {
  left: 15px;
}
input:focus ~ .liquid-cl span, textarea:focus ~ .liquid-cl span, .input-focused .liquid-cl span, .input-filled .liquid-cl span {
  opacity: 0;
}

.liquid-cl-1:after {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
input:focus ~ .liquid-cl-1 span, textarea:focus ~ .liquid-cl-1 span, .input-focused .liquid-cl-1 span, .input-filled .liquid-cl-1 span {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
input:focus ~ .liquid-cl-1:after, textarea:focus ~ .liquid-cl-1:after, .input-focused .liquid-cl-1:after, .input-filled .liquid-cl-1:after {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
}

.liquid-cl-2 {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.liquid-cl-2:after {
  -webkit-transform: translateY(10px) rotateX(30deg);
          transform: translateY(10px) rotateX(30deg);
}
input:focus ~ .liquid-cl-2 span, textarea:focus ~ .liquid-cl-2 span, .input-focused .liquid-cl-2 span, .input-filled .liquid-cl-2 span {
  -webkit-transform: translateY(10px) rotateX(-30deg);
          transform: translateY(10px) rotateX(-30deg);
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
input:focus ~ .liquid-cl-2:after, textarea:focus ~ .liquid-cl-2:after, .input-focused .liquid-cl-2:after, .input-filled .liquid-cl-2:after {
  opacity: 1;
  -webkit-transform: translateY(0) rotateX(0);
          transform: translateY(0) rotateX(0);
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
}

.liquid-cl-3:after {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
input:focus ~ .liquid-cl-3 span, textarea:focus ~ .liquid-cl-3 span, .input-focused .liquid-cl-3 span, .input-filled .liquid-cl-3 span {
  -webkit-transform: translateY(-75%);
          transform: translateY(-75%);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
input:focus ~ .liquid-cl-3:after, textarea:focus ~ .liquid-cl-3:after, .input-focused .liquid-cl-3:after, .input-filled .liquid-cl-3:after {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}

.contact-form-result {
  margin-top: 1.5em;
}

.form-message {
  padding: 0.5em 1.5em;
  border-radius: 3px;
  font-weight: 700;
}

.form-success {
  background-color: rgba(107, 228, 117, 0.4);
  color: #1ca13d;
}

.form-error {
  background-color: rgba(228, 107, 107, 0.4);
  color: #a11c1c;
}

/* 3.8. Fancy Titles */
.fancy-title {
  margin-bottom: 2.5em;
  position: relative;
}
.fancy-title > :empty:not(br):not(hr):not(i.line) {
  display: none;
}
.fancy-title h2,
.fancy-title h3,
.fancy-title h4 {
  margin: 0.25em 0;
}
.fancy-title i {
  margin-right: 0.27em;
}
.fancy-title > :first-child {
  margin-top: 0;
}
.fancy-title > :last-child {
  margin-bottom: 0;
}
.fancy-title .text-uppercase {
  letter-spacing: 0.1em;
}
.fancy-title i.line {
  display: inline-block;
  width: 1.138em;
  height: 3px;
  vertical-align: middle;
  background-color: var(--color-primary);
}
.fancy-title .underlined {
  display: inline-block;
  padding-bottom: 1.25em;
  position: relative;
  clear: both;
}
.fancy-title .underlined:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
}
.fancy-title.text-center .underlined:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.fancy-title.text-right .underlined:after {
  left: auto;
  right: 0;
}

.fancy-title-underlined {
  padding-bottom: 2.5em;
}
.fancy-title-underlined:after {
  content: '';
  display: inline-block;
  width: 50px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
}
.fancy-title-underlined.text-center:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.fancy-title-underlined.text-right:after {
  left: auto;
  right: 0;
}

/* Lines */
.fancy-title .lined-alt {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}
.fancy-title .line-alt {
  -webkit-box-flex: 1;
          flex: 1 auto;
  height: 1px;
  position: relative;
  background-color: #E5E5E5;
  border-radius: 50em;
  margin-right: 2em;
}
.fancy-title .line-alt:last-child {
  margin-right: 0;
  margin-left: 2em;
}
.fancy-title .line-alt-doubled {
  top: 0.3em;
}
.fancy-title .line-alt-doubled:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -0.6em;
  left: 0;
  border-radius: inherit;
  background-color: inherit;
}

@media screen and (min-width: 1200px) {
  .fancy-title .pull-to-left {
    margin-left: -2.3076923077em;
  }
}
/* 3.9. Pricing Tables */
.pricing-table {
  margin-bottom: 2em;
  position: relative;
  text-align: center;
}
.pricing-table ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.pricing-table ul li {
  display: block;
}
.pricing-table .btn {
  font-size: 14px;
  font-weight: 600;
}
.pricing-table .pricing {
  margin: 0;
}
.pricing-table.featured {
  z-index: 2;
}

.pricing-table-footer:empty {
  display: none;
}

.pricing-table-default {
  border: 1px solid #e4e4eb;
}
.pricing-table-default .pricing-table-header {
  padding: 16px 16px;
  background-color: #f0f2f4;
  border-bottom: 1px solid #fff;
  border-color: inherit;
}
.pricing-table-default h5 {
  padding: 0;
  margin: 0;
  font-size: 24px;
  line-height: 2em;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #181a23;
}
.pricing-table-default h5 small {
  display: block;
  font-size: 50%;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: var(--color-primary);
  margin-bottom: 10px;
}
.pricing-table-default .pricing-table-body {
  padding: 24px 60px;
  border-color: inherit;
}
.pricing-table-default ul {
  color: #828593;
  border-color: inherit;
}
.pricing-table-default ul li {
  padding: 1.333em 0;
  font-size: 15px;
  line-height: 1.6em;
  border-bottom: 1px solid #fff;
  border-color: inherit;
}
.pricing-table-default ul strong {
  display: block;
  font-size: 1.071428571428571em;
  color: #181a23;
}
.pricing-table-default .btn {
  margin-bottom: 50px;
}
.pricing-table-default .pricing {
  margin-bottom: 0.666em;
  font-size: 30px;
  font-weight: 700;
  color: var(--color-primary);
}
.pricing-table-default .pricing sup {
  font-size: 60%;
  font-weight: 400;
}

.pricing-table-colorful {
  margin-left: -20px;
  margin-right: -20px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 14px 14px 70px rgba(0, 0, 0, 0.07);
}
.pricing-table-colorful .pricing-table-header {
  padding: 18px 50px;
  position: relative;
}
.pricing-table-colorful .pricing-table-header:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 3px 3px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  z-index: 0;
  background-color: var(--color-primary);
}
.pricing-table-colorful h5 {
  display: -webkit-box;
  display: flex;
  width: 100%;
  margin: 7px 0;
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: 600;
  color: #181b31;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}
.pricing-table-colorful h5 small {
  -webkit-box-ordinal-group: 2;
          order: 1;
  font-size: 1.875em;
  color: var(--color-primary);
}
.pricing-table-colorful .pricing-table-body {
  padding: 30px 0;
}
.pricing-table-colorful ul {
  color: #808291;
}
.pricing-table-colorful ul li {
  border-bottom: 1px solid #e7eaef;
  padding: 1em;
}
.pricing-table-colorful ul li:last-child {
  border: none;
}
.pricing-table-colorful ul strong {
  color: #181b31;
}
.pricing-table-colorful .pricing-table-footer {
  margin-bottom: 42px;
}
.pricing-table-colorful .pricing-table-footer:before, .pricing-table-colorful .pricing-table-footer:after {
  content: '';
  display: table;
}
.pricing-table-colorful .pricing-table-footer:after {
  clear: both;
}
.pricing-table-colorful .featured-tag {
  color: var(--color-primary);
}
.pricing-table-colorful .featured-tag i,
.pricing-table-colorful .featured-tag span {
  display: block;
}
.pricing-table-colorful .featured-tag i {
  font-size: 30px;
  margin-bottom: 0.25em;
}
.pricing-table-colorful.color-green .pricing-table-header:before {
  background-color: #46d664;
}
.pricing-table-colorful.color-green .featured-tag,
.pricing-table-colorful.color-green h5 small {
  color: #46d664;
}
.pricing-table-colorful.color-red .pricing-table-header:before {
  background-color: #fd623c;
}
.pricing-table-colorful.color-red .featured-tag,
.pricing-table-colorful.color-red h5 small {
  color: #fd623c;
}
.pricing-table-colorful.color-blue .pricing-table-header:before {
  background-color: #02a0e5;
}
.pricing-table-colorful.color-blue .featured-tag,
.pricing-table-colorful.color-blue h5 small {
  color: #02a0e5;
}
.pricing-table-colorful.first {
  margin-left: 0;
}
.pricing-table-colorful.last {
  margin-right: 0;
}
.pricing-table-colorful.featured {
  margin-top: -50px;
}

.pricing-table-modern {
  padding: 50px 45px 55px;
  color: #5E6575;
}
.pricing-table-modern:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: auto;
}
.pricing-table-modern .pricing-table-header,
.pricing-table-modern .pricing-table-body,
.pricing-table-modern .pricing-table-footer {
  position: relative;
  z-index: 2;
}
.pricing-table-modern h5 {
  margin-top: 0;
  margin-bottom: 1.35em;
  color: #32325C;
  font-size: 24px;
  font-weight: 700;
}
.pricing-table-modern .pricing {
  font-size: 72px;
  color: var(--color-primary);
  line-height: 1;
}
.pricing-table-modern .pricing small {
  display: block;
  margin-top: 1em;
  font-size: 0.2222222222em;
  color: #5B6C94;
}
.pricing-table-modern .pricing-table-body {
  padding: 50px 15px 0;
  font-size: 16px;
}
.pricing-table-modern ul {
  font-size: 16px;
  line-height: 1.5em;
}
.pricing-table-modern ul li:not(:last-child) {
  margin-bottom: 1.45em;
}
.pricing-table-modern .pricing-table-footer {
  margin-top: 60px;
}
.pricing-table-modern.featured {
  color: #fff;
}
.pricing-table-modern.featured:before {
  background: -webkit-gradient(linear, left top, left bottom, from(#0856F7), to(#1DFFDD));
  background: linear-gradient(to bottom, #0856F7 0%, #1DFFDD 100%);
}
.pricing-table-modern.featured h5,
.pricing-table-modern.featured .pricing,
.pricing-table-modern.featured .pricing small {
  color: inherit;
}
.pricing-table-modern.featured[class*=bg-]:before {
  background: inherit;
}

.pricing-table-minimal {
  padding: 45px 50px 50px;
  border-radius: 10px;
  background-color: #fff;
  color: #2D3136;
}
.pricing-table-minimal .pricing-table-header {
  text-align: center;
}
.pricing-table-minimal .pricing-table-header h5 {
  display: inline-block;
  padding: 0.6em 1.2em;
  margin: 0;
  background-color: #F1F3F7;
  border-radius: 50em;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 400;
  color: #2D3136;
}
.pricing-table-minimal .pricing {
  margin: 0;
  font-size: 60px;
  line-height: 1em;
}
.pricing-table-minimal .pricing > span {
  font-size: 0.35em;
}
.pricing-table-minimal .pricing small {
  font-size: 0.25em;
}
.pricing-table-minimal .pricing-table-body {
  margin-top: 35px;
  font-size: 16px;
}
.pricing-table-minimal .pricing-table-body li {
  margin-bottom: 0.625em;
}
.pricing-table-minimal .pricing-table-footer {
  margin-top: 30px;
}

.pricing-table-agency {
  padding-top: 6rem;
  padding-bottom: 4.5rem;
  background-color: #fff;
  box-shadow: 0 40px 125px rgba(0, 0, 0, 0.07);
}
.pricing-table-agency .pricing-table-featured-label {
  padding: 5px 30px;
  border-radius: 0 0 50em 50em;
  position: absolute;
  top: 0;
  left: 50%;
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 16px;
  line-height: 1.5em;
  color: #fff;
}
.pricing-table-agency .pricing-table-header {
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
}
.pricing-table-agency .pricing-table-header h5 {
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
}
.pricing-table-agency .pricing {
  font-size: 56px;
  line-height: 1.5em;
  font-weight: 700;
  color: #000;
}
.pricing-table-agency .pricing sup,
.pricing-table-agency .pricing small {
  font-size: 0.3571428571em;
  font-weight: 400;
}
.pricing-table-agency .pricing sup {
  top: -1.25em;
  margin-right: 0.2em;
}
.pricing-table-agency .pricing small {
  opacity: 0.6;
}
.pricing-table-agency .pricing small:before {
  content: '/';
}
.pricing-table-agency .pricing-table-body {
  padding: 0 5rem;
  margin-bottom: 1.5rem;
}
.pricing-table-agency .pricing-table-body li {
  padding: 1.5em 1em;
  border-top: 1px solid #ebeff1;
  font-size: 14px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #666;
}
.pricing-table-agency .pricing-table-footer {
  padding: 0 1.5rem;
}

/* 3.10. Testimonials */
.testimonial {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1 auto;
  position: relative;
  margin-bottom: 2em;
  font-size: 1em;
}
.testimonial .testimonial-quote-mark,
.testimonial .testimonial-info {
  position: relative;
  z-index: 2;
}
.testimonial blockquote {
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  z-index: 2;
  font-size: 1em;
  font-style: inherit;
}
.testimonial blockquote strong {
  font-weight: 600;
}
.testimonial blockquote small {
  font-size: 0.833333333333333em;
}
.testimonial blockquote small:before {
  content: none;
}
.testimonial .testimonial-quote {
  margin-bottom: 1.25em;
  position: relative;
  font-size: 20px;
  line-height: 1.875em;
}
.testimonial .testimonial-quote h5 {
  margin-bottom: 1em;
}
.testimonial time,
.testimonial .star-rating {
  font-size: 14px;
  color: #a7a9b8;
}
.testimonial > time {
  margin-top: 2.25em;
}
.testimonial time.size-sm {
  font-size: 11px;
}
.testimonial .testimonial-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.testimonial .testimonial-details h5,
.testimonial .testimonial-details h6 {
  margin: 0;
  line-height: 1.5em;
}
.testimonial .testimonial-details h5 {
  font-size: 18px;
  color: #16171e;
}
.testimonial .testimonial-details h6 {
  font-size: 16px;
  color: #a7a9b8;
}
.testimonial .testimonial-details h6.sm {
  font-size: 10px;
}
.testimonial .testimonial-details h6.md {
  font-size: 12px;
}
.testimonial .testimonial-details time,
.testimonial .testimonial-details .star-rating {
  margin-left: auto;
}
.testimonial .testimonial-details time:first-child,
.testimonial .testimonial-details .star-rating:first-child {
  margin-left: 0;
}
.testimonial .avatar {
  width: 70px;
  margin-right: 20px;
  border-radius: 50em;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.testimonial .avatar img {
  width: 100%;
}
.testimonial .testimonial-quote-mark {
  margin-right: 15px;
}
.testimonial .testimonial-quote-mark svg {
  fill: var(--color-primary);
}

.testimonials-quotes .testimonial-quote > blockquote > :first-child {
  margin-top: 0;
}
.testimonials-quotes .testimonial-quote > blockquote > :last-child {
  margin-bottom: 0;
}

/* Testimonial Filling */
.testimonial-fill-onhover,
.testimonial-whole-filled,
.testimonial-whole-shadowed {
  padding: 7.5%;
}

.testimonial-quote-filled .testimonial-quote,
.testimonial-quote-shadowed .testimonial-quote {
  padding: 7.5%;
}
.testimonial-quote-filled .testimonial-details,
.testimonial-quote-shadowed .testimonial-details {
  margin-left: 60px;
  margin-right: 60px;
}

.testimonial-whole-filled {
  background-color: #fff;
  border-radius: 5px;
}

.testimonial-quote-filled .testimonial-quote {
  background-color: #fff;
}
.testimonial-quote-filled .testimonial-quote:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -10px;
  left: calc(60px + 35px - 14px);
  z-index: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: inherit;
}
.testimonial-quote-filled.testimonial-avatar-lg .testimonial-quote:after {
  left: calc(60px + 40px - 14px);
}
.testimonial-quote-filled.testimonial-avatar-sm .testimonial-quote:after {
  left: calc(60px + 30px - 14px);
}
.testimonial-quote-filled.testimonial-details-top .testimonial-quote:after {
  bottom: auto;
  top: -10px;
}

.testimonial-whole-shadowed {
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.07);
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.flickity-viewport .testimonial-whole-shadowed {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}
.flickity-viewport .is-selected .testimonial-whole-shadowed {
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.07);
}

.testimonial-whole-shadowed-alt {
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.14);
}
.flickity-viewport .testimonial-whole-shadowed-alt {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 70px;
}
.flickity-viewport .is-selected .testimonial-whole-shadowed-alt {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.14);
}

.testimonial-quote-shadowed .testimonial-quote {
  border-radius: 5px;
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.07);
}
.testimonial-quote-shadowed.testimonial-details-top .testimonial-quote {
  box-shadow: 0 -9px 50px rgba(0, 0, 0, 0.07);
}

.testimonial-avatar-shadowed .avatar {
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.1);
}

.testimonial-fill-onhover:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: var(--color-primary);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.testimonial-fill-onhover > * {
  z-index: 2;
}
.testimonial-fill-onhover .testimonial-quote-mark svg {
  -webkit-transition: fill 0.3s;
  transition: fill 0.3s;
}
.testimonial-fill-onhover .testimonial-quote,
.testimonial-fill-onhover .testimonial-info h5,
.testimonial-fill-onhover .testimonial-info h6 {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.is-selected .testimonial-fill-onhover {
  color: #fff;
}
.is-selected .testimonial-fill-onhover:after {
  opacity: 1;
}
.is-selected .testimonial-fill-onhover .testimonial-quote-mark svg {
  fill: #fff;
}
.is-selected .testimonial-fill-onhover .star-rating,
.is-selected .testimonial-fill-onhover .star-rating li,
.is-selected .testimonial-fill-onhover .testimonial-quote,
.is-selected .testimonial-fill-onhover .testimonial-info h5,
.is-selected .testimonial-fill-onhover .testimonial-info h6 {
  color: inherit;
}
.is-selected .testimonial-fill-onhover .testimonial-quote p span {
  color: inherit;
}

/* Testimonial Quote indented */
.testimonial-quote-indented > time,
.testimonial-quote-indented .testimonial-quote {
  margin-left: 90px;
}
.testimonial-quote-indented.text-center > time,
.testimonial-quote-indented.text-center .testimonial-quote {
  margin-left: 0;
  margin-right: 0;
}
.testimonial-quote-indented.text-right > time,
.testimonial-quote-indented.text-right .testimonial-quote {
  margin-left: 0 !important;
  margin-right: 90px;
}

/* Testimonial Sizes */
.testimonial-xl .testimonial-quote {
  font-size: 36px;
  line-height: 1.25em;
}

.testimonial-lg .testimonial-quote {
  font-size: 30px;
  line-height: 1.6em;
}

.testimonial-sm .testimonial-quote {
  font-size: 18px;
  line-height: 1.666em;
}

.testimonial-xs .testimonial-quote {
  font-size: 16px;
  line-height: 1.875em;
}

.testimonial-details-xl .testimonial-details h5 {
  font-size: 24px;
}
.testimonial-details-xl .testimonial-details h6 {
  font-size: 12px;
}

.testimonial-details-lg .testimonial-details h5 {
  font-size: 20px;
}
.testimonial-details-lg .testimonial-details h6 {
  font-size: 14px;
}

.testimonial-details-sm .testimonial-details h5 {
  font-size: 16px;
}
.testimonial-details-sm .testimonial-details h6 {
  font-size: 14px;
}

.testimonial-avatar-xl .avatar {
  width: 86px;
}
.testimonial-avatar-xl.testimonial-quote-indented > time,
.testimonial-avatar-xl.testimonial-quote-indented .testimonial-quote {
  margin-left: 106px;
}
.testimonial-avatar-xl.testimonial-quote-indented.text-right > time,
.testimonial-avatar-xl.testimonial-quote-indented.text-right .testimonial-quote {
  margin-right: 106px;
}

.testimonial-avatar-lg .avatar {
  width: 80px;
}
.testimonial-avatar-lg.testimonial-quote-indented > time,
.testimonial-avatar-lg.testimonial-quote-indented .testimonial-quote {
  margin-left: 100px;
}
.testimonial-avatar-lg.testimonial-quote-indented.text-right > time,
.testimonial-avatar-lg.testimonial-quote-indented.text-right .testimonial-quote {
  margin-right: 100px;
}

.testimonial-avatar-sm .avatar {
  width: 60px;
}
.testimonial-avatar-sm.testimonial-quote-indented > time,
.testimonial-avatar-sm.testimonial-quote-indented .testimonial-quote {
  margin-left: 80px;
}
.testimonial-avatar-sm.testimonial-quote-indented.text-right > time,
.testimonial-avatar-sm.testimonial-quote-indented.text-right .testimonial-quote {
  margin-right: 80px;
}

.testimonial-avatar-topleft,
.testimonial-avatar-topright {
  margin-top: 35px;
}
.testimonial-avatar-topleft .avatar,
.testimonial-avatar-topright .avatar {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
}
.testimonial-avatar-topleft.testimonial-avatar-lg,
.testimonial-avatar-topright.testimonial-avatar-lg {
  margin-top: 40px;
}
.testimonial-avatar-topleft.testimonial-avatar-sm,
.testimonial-avatar-topright.testimonial-avatar-sm {
  margin-top: 30px;
}

.testimonial-avatar-topleft .avatar {
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.testimonial-avatar-topright .avatar {
  left: auto;
  right: 0;
  -webkit-transform: translateX(50%) translateY(-50%);
          transform: translateX(50%) translateY(-50%);
}

.testimonial-avatar-topcenter .testimonial-details {
  margin-top: 1em;
}
.testimonial-avatar-topcenter .avatar {
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

/* Testimonials Details Positions */
.testimonial-details-top .testimonial-quote {
  margin-bottom: 0;
  margin-top: 1.25em;
}
.testimonial-details-top .testimonial-details {
  -webkit-box-ordinal-group: 0;
          order: -1;
}

.testimonial-info-inline .testimonial-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}
.testimonial-info-inline .testimonial-info h5:after {
  content: '/';
  margin-left: 0.25em;
  margin-right: 0.25em;
}

/* Testimonials Alignments */
.testimonial.text-center time,
.testimonial.text-center .star-rating {
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}
.testimonial.text-center .testimonial-details {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
}
.testimonial.text-center .avatar {
  margin: 0 0 10px;
}
.testimonial.text-center .testimonial-quote-mark {
  margin-right: 0;
}
.testimonial.text-right .testimonial-quote-mark {
  margin-left: 15px;
  margin-right: 0;
}
.testimonial.text-right .testimonial-details {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.testimonial.text-right .testimonial-details time,
.testimonial.text-right .testimonial-details .star-rating {
  margin-left: 0;
  margin-right: auto;
}
.testimonial.text-right .avatar {
  margin-right: 0;
  margin-left: 20px;
}

.testimonial-quote-filled > time,
.testimonial-quote-filled .testimonial-quote,
.testimonial-quote-shadowed > time,
.testimonial-quote-shadowed .testimonial-quote {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.testimonial-quote-filled.text-right .testimonial-details,
.testimonial-quote-shadowed.text-right .testimonial-details {
  margin-left: 0;
}
.testimonial-quote-filled.text-center .testimonial-details,
.testimonial-quote-shadowed.text-center .testimonial-details {
  margin-left: 0;
  margin-right: 0;
}

.testimonial-quote-filled.text-center .testimonial-quote {
  text-align: left;
}
.testimonial-quote-filled.text-center .testimonial-quote:after {
  left: 50%;
  margin-left: -14px;
}
.testimonial-quote-filled.text-right .testimonial-quote:after {
  left: auto;
  right: calc(60px + 35px - 14px);
}
.testimonial-quote-filled.text-right.testimonial-avatar-lg .testimonial-quote:after {
  right: calc(60px + 40px - 14px);
}
.testimonial-quote-filled.text-right.testimonial-avatar-sm .testimonial-quote:after {
  right: calc(60px + 30px - 14px);
}

/* Testimonial Carousels */
.testimonials-details {
  width: 75%;
  margin: 0 auto;
}
.testimonials-details .flickity-prev-next-button {
  position: absolute;
}
.testimonials-details .flickity-prev-next-button.previous {
  left: -45px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.testimonials-details .flickity-prev-next-button.next {
  right: -45px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.testimonials-details .flickity-slider > div {
  padding-left: 6px;
  padding-right: 6px;
}
.testimonials-details .testimonial {
  margin: 0;
}
.carousel-nav-bottom .testimonials-details {
  width: 100%;
}
.testimonials-details .flickity-viewport {
  margin: 0;
}
.testimonials-details.w-100 {
  width: 100%;
}

.testimonials-quote-only .testimonial-quote {
  margin-bottom: 0.75em;
}
.testimonials-quote-only .flickity-viewport {
  margin: 0;
}
.testimonials-quote-only .flickity-prev-next-button {
  top: 150%;
}

.testimonials-details-only {
  opacity: 0.5;
  cursor: pointer;
}
.testimonials-details-only .avatar {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.testimonials-details-only .testimonial-details,
.testimonials-details-only .testimonial-info {
  width: 100%;
}
.testimonials-details-only:not(.text-center) .testimonial-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: end;
          align-items: flex-end;
}
.testimonials-details-only:not(.text-center) .testimonial-details h5,
.testimonials-details-only:not(.text-center) .testimonial-details h6 {
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-transition: all 0.38s;
  transition: all 0.38s;
}
.testimonials-details-only:not(.text-center) .testimonial-details h5 {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.testimonials-details-only:not(.text-center) .testimonial-details h6 {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.is-nav-selected .testimonials-details-only {
  opacity: 1;
}
.is-nav-selected .testimonials-details-only .testimonial-details h5,
.is-nav-selected .testimonials-details-only .testimonial-details h6 {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.is-nav-selected .testimonials-details-only .testimonial-details h5 {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.is-nav-selected .testimonials-details-only .testimonial-details h6 {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

/* 3.11. Carousel */
.carousel-items p:empty {
  display: none;
}
.carousel-items:not(.flickity-enabled) {
  display: -webkit-box;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  flex-wrap: nowrap;
}
.carousel-items:not(.flickity-enabled) > .carousel-item {
  -webkit-box-flex: 0;
          flex-grow: 0;
  flex-shrink: 0;
}

.flickity-equal-cells .flickity-slider > * {
  min-height: 100%;
}

.carousel-item {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
          justify-content: center;
}
.carousel-item .carousel-item {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.carousel-item-inner {
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  -webkit-box-flex: 1;
          flex: 1 auto;
}

.carousel-item-inner .wpb_single_image:only-child .vc_single_image-wrapper,
.carousel-item-inner .wpb_single_image:only-child .vc_figure,
.carousel-item-inner .wpb_single_image:only-child img {
  width: 100%;
}

.flickity-prev-next-button {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 40px;
  height: 40px;
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  border-radius: 0;
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 22px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.flickity-prev-next-button svg {
  width: auto;
  height: auto;
  position: relative;
  top: auto;
  left: auto;
  stroke: #181b30;
  stroke-width: 1px;
  -webkit-transition: stroke 0.3s;
  transition: stroke 0.3s;
}
.flickity-prev-next-button i {
  position: relative;
  z-index: 1;
}
.flickity-prev-next-button.previous {
  margin-right: 5px;
  left: 0;
}
.flickity-prev-next-button.next {
  margin-left: 5px;
  right: 0;
}
.flickity-prev-next-button:hover, .flickity-prev-next-button:focus {
  background: none;
  box-shadow: none;
  outline: none;
}
.flickity-prev-next-button:active {
  opacity: 1;
}

/* Nav position */
.carousel-nav {
  position: relative;
}

.carousel-nav-floated.carousel-nav,
.carousel-nav-floated .carousel-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.carousel-nav-floated .flickity-prev-next-button {
  position: absolute;
}

.carousel-nav-center .carousel-nav {
  text-align: center;
}

.carousel-nav-left .carousel-nav {
  text-align: left;
}

.carousel-nav-right .carousel-nav {
  text-align: right;
}
.carousel-nav-right.carousel-nav-floated .carousel-nav {
  left: auto;
  right: 0;
}

.carousel-nav-top .flickity-prev-next-button.previous,
.carousel-nav-bottom .flickity-prev-next-button.previous {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.carousel-nav-top .flickity-prev-next-button.next,
.carousel-nav-bottom .flickity-prev-next-button.next {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.carousel-nav-top .carousel-nav {
  top: 0;
}

.carousel-nav-bottom .carousel-nav {
  top: auto;
  bottom: 0;
  -webkit-transform: translate(0);
          transform: translate(0);
}

.carousel-nav-middle .flickity-prev-next-button {
  top: 50%;
}
.carousel-nav-middle .flickity-prev-next-button.previous {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.carousel-nav-middle .flickity-prev-next-button.next {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Nav direction */
.carousel-nav-vertical .carousel-nav {
  width: auto;
}
.carousel-nav-vertical .flickity-prev-next-button {
  display: -webkit-box;
  display: flex;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  -webkit-transform: none !important;
          transform: none !important;
}
.carousel-nav-vertical .flickity-prev-next-button.previous {
  margin-bottom: 10px;
}
.carousel-nav-vertical .flickity-prev-next-button.next {
  margin-top: 10px;
}
.carousel-nav-vertical.carousel-nav-center .flickity-prev-next-button {
  margin-left: auto !important;
  margin-right: auto !important;
}
.carousel-nav-vertical.carousel-nav-center .carousel-nav {
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.carousel-nav-vertical.carousel-nav-left .flickity-prev-next-button {
  margin-right: auto;
}
.carousel-nav-vertical.carousel-nav-right .flickity-prev-next-button {
  margin-left: auto;
}

/* Nav sizes */
.carousel-nav-xl .flickity-prev-next-button {
  font-size: 28px;
  width: 60px;
  height: 60px;
}
.carousel-nav-xl.carousel-nav-rectangle .flickity-prev-next-button {
  width: 70px;
  height: 40px;
}
.carousel-nav-xl.carousel-nav-rectangle .flickity-prev-next-button svg {
  max-width: 35%;
}

.carousel-nav-lg .flickity-prev-next-button {
  font-size: 26px;
  width: 50px;
  height: 50px;
}
.carousel-nav-lg.carousel-nav-rectangle .flickity-prev-next-button {
  height: 30px;
}



.carousel-nav-sm .flickity-prev-next-button {
  font-size: 20px;
  width: 35px;
  height: 35px;
}
.carousel-nav-sm.carousel-nav-rectangle .flickity-prev-next-button {
  height: 24px;
}

/* Nav shapes */
.carousel-nav-solid .flickity-prev-next-button:before,
.carousel-nav-bordered .flickity-prev-next-button:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: inherit;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background: -webkit-gradient(linear, right top, left top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to left, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}
.carousel-nav-solid .flickity-prev-next-button:hover,
.carousel-nav-bordered .flickity-prev-next-button:hover {
  color: #fff;
  stroke: #fff;
}
.carousel-nav-solid .flickity-prev-next-button:hover svg,
.carousel-nav-bordered .flickity-prev-next-button:hover svg {
  stroke: inherit;
}
.carousel-nav-solid .flickity-prev-next-button:hover:before,
.carousel-nav-bordered .flickity-prev-next-button:hover:before {
  opacity: 1;
}

.carousel-nav-bordered .flickity-prev-next-button {
  border-color: #181b30;
}
.carousel-nav-bordered .flickity-prev-next-button:before {
  background: #181b30;
}

.carousel-nav-solid .flickity-prev-next-button {
  background: #fff;
  border: none;
}

.carousel-nav-rectangle .flickity-prev-next-button svg,
.carousel-nav-circle .flickity-prev-next-button svg,
.carousel-nav-square .flickity-prev-next-button svg {
  max-width: 50%;
}

.carousel-nav-circle .flickity-prev-next-button {
  border-radius: 50em;
}

/* Nav shadows */
.carousel-nav-shadowed .flickity-prev-next-button {
  box-shadow: 0 7px 28px rgba(0, 0, 0, 0.12);
}

.carousel-nav-shadowed-onhover .flickity-prev-next-button:hover {
  box-shadow: 0 7px 28px rgba(0, 0, 0, 0.12);
}

.flickity-page-dots .dot {
  position: relative;
  background-color: rgba(51, 51, 51, 0.25);
  color: rgba(51, 51, 51, 0.25);
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.flickity-page-dots .dot.is-selected {
  background-color: #333;
  color: #333;
}

.carousel-dots-left .flickity-page-dots {
  text-align: left;
}

.carousel-dots-right .flickity-page-dots {
  text-align: right;
}

.carousel-dots-sm .flickity-page-dots .dot {
  width: 9px;
  height: 9px;
}

.carousel-dots-lg .flickity-page-dots .dot {
  width: 12px;
  height: 12px;
}

.carousel-dots-style2 .dot:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -1.5px;
  left: -1.5px;
  bottom: -1.5px;
  right: -1.5px;
  border: 3px solid;
  border-radius: inherit;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  opacity: 0;
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.15s;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.carousel-dots-style2 .dot:hover, .carousel-dots-style2 .dot.is-selected {
  background-color: transparent !important;
}
.carousel-dots-style2 .dot:hover:before, .carousel-dots-style2 .dot.is-selected:before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.carousel-dots-style3 .dot:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  opacity: 0;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  background: -webkit-gradient(linear, right top, left top, from(#5792FF), to(#32D7FF));
  background: linear-gradient(to left, #5792FF 0%, #32D7FF 100%);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.15s;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.carousel-dots-style3 .dot:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #A4A4A4;
  border-radius: inherit;
  -webkit-transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.carousel-dots-style3 .dot:hover, .carousel-dots-style3 .dot.is-selected {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  background-color: transparent !important;
}
.carousel-dots-style3 .dot:hover:before, .carousel-dots-style3 .dot.is-selected:before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.carousel-dots-style3 .dot:hover:after, .carousel-dots-style3 .dot.is-selected:after {
  background-color: #fff;
}

.carousel-dots-style4 .dot {
  background-color: #A4A4A4;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.carousel-dots-style4 .dot:hover, .carousel-dots-style4 .dot.is-selected {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-color: #000;
}

.carousel-dots-inside .flickity-page-dots {
  bottom: 25px;
}

.carousel-shadow-active .carousel-item-inner {
  margin-bottom: 100px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.carousel-shadow-active .carousel-item-inner .wpb_single_image:only-child {
  margin-bottom: 0;
}
.carousel-shadow-active .carousel-item.is-selected .carousel-item-inner {
  box-shadow: 0 50px 75px rgba(0, 0, 0, 0.1);
}

.carousel-shadow-all .carousel-item-inner {
  margin-bottom: 100px;
  box-shadow: 0 50px 75px rgba(0, 0, 0, 0.2);
}
.carousel-shadow-all .carousel-item-inner .wpb_single_image:only-child {
  margin-bottom: 0;
}

.carousel-nav-light .flickity-button {
  border-color: rgba(255, 255, 255, 0.35);
  color: #fff;
}
.carousel-nav-light .flickity-button svg {
  stroke: currentColor;
}
.carousel-nav-light .flickity-button.previous:after {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.carousel-nav-light .flickity-button:hover {
  border-color: #fff;
  color: #000;
}
.carousel-nav-light .flickity-button:hover svg {
  stroke: currentColor;
}
.carousel-nav-light .flickity-button:hover:before {
  background-color: #fff;
}

.carousel-nav-border-gray .flickity-button {
  border-color: #e6e6e6;
}

.carousel-nav-border-transparent .flickity-button {
  border-color: transparent;
}

.carousel-nav-border-none .flickity-button {
  border: none;
}

.carousel-nav-hover-gray .flickity-button {
  color: #000;
}
.carousel-nav-hover-gray .flickity-button svg {
  stroke: currentColor;
}
.carousel-nav-hover-gray .flickity-button:before {
  background-color: #f6f7fc;
}
.carousel-nav-hover-gray .flickity-button:hover {
  border-color: #f6f7fc;
  color: #000;
}
.carousel-nav-hover-gray .flickity-button:hover svg {
  stroke: currentColor;
}

.carousel-nav-hover-light .flickity-button:hover {
  border-color: #fff;
  color: #fff;
}
.carousel-nav-hover-light .flickity-button:hover svg {
  stroke: currentColor;
}

.carousel-dots-light .flickity-page-dots .dot {
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
}
.carousel-dots-light .flickity-page-dots .dot.is-selected {
  background-color: #fff;
  color: #fff;
}

.OneLine{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.TwoLines{
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-height: 64px;

}

@media (min-width: 768px) {
  .carousel-items-4-5 .carousel-item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .carousel-items-4-5 .carousel-item {
    width: 26.666666666667%;
  }
}
@media (min-width: 1200px) {
  .carousel-items-4-5 .carousel-item {
    width: 22.222222222222%;
  }
}
/* 3.12. Carousel Vertical 3D */
.carousel-vertical-3d .carousel-items {
  display: block;
  overflow: visible;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1200px;
          perspective: 1200px;
}
.carousel-vertical-3d .carousel-item {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
}
.carousel-vertical-3d .carousel-item * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.carousel-vertical-3d.carousel-initialized .carousel-item {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0.45s step-end;
  transition: visibility 0.45s step-end;
}
.carousel-vertical-3d.carousel-initialized .carousel-item > :only-child {
  margin-bottom: 0;
}
.carousel-vertical-3d.carousel-initialized .carousel-item.is-active, .carousel-vertical-3d.carousel-initialized .carousel-item.is-top, .carousel-vertical-3d.carousel-initialized .carousel-item.is-bottom {
  opacity: 1;
  visibility: visible;
}
.carousel-vertical-3d.carousel-initialized .carousel-item.is-top, .carousel-vertical-3d.carousel-initialized .carousel-item.is-bottom {
  cursor: pointer;
  -webkit-transition-timing-function: step-start;
          transition-timing-function: step-start;
}
.carousel-vertical-3d.carousel-initialized .carousel-item.is-top > *, .carousel-vertical-3d.carousel-initialized .carousel-item.is-bottom > * {
  pointer-events: none;
}
.carousel-vertical-3d.carousel-initialized .carousel-item.is-active {
  position: relative;
  top: auto;
  left: auto;
}
.carousel-vertical-3d.carousel-initialized .carousel-item.is-moving.was-top, .carousel-vertical-3d.carousel-initialized .carousel-item.is-moving.was-bottom {
  opacity: 1;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

/* 3.13. Latest Posts */
.liquid-lp-media {
  margin-bottom: 1.5em;
  position: relative;
  background-size: cover;
  background-position: center;
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s, -webkit-transform 0.3s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
}
.liquid-lp-media img {
  width: 100%;
}
.liquid-lp-media > a {
  display: block;
  position: relative;
  z-index: 2;
}
.liquid-lp-media.round {
  border-radius: 4px;
}
.liquid-lp-media.round a,
.liquid-lp-media.round figure,
.liquid-lp-media.round img {
  border-radius: inherit;
}
.liquid-lp-media[data-parallax] > a > img {
  visibility: hidden;
}
.liquid-lp-media.w-auto {
  display: inline-block;
}
.liquid-lp-media.w-auto img {
  width: auto;
}

.liquid-lp-media-frame span {
  position: absolute;
  z-index: 3;
  background-color: var(--color-primary);
  -webkit-transition: -webkit-transform 0.65s cubic-bezier(0.21, 0.98, 0.35, 1);
  transition: -webkit-transform 0.65s cubic-bezier(0.21, 0.98, 0.35, 1);
  transition: transform 0.65s cubic-bezier(0.21, 0.98, 0.35, 1);
  transition: transform 0.65s cubic-bezier(0.21, 0.98, 0.35, 1), -webkit-transform 0.65s cubic-bezier(0.21, 0.98, 0.35, 1);
}
.liquid-lp-media-frame .top,
.liquid-lp-media-frame .bottom {
  left: 0;
  width: 100%;
  height: 10px;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}
.liquid-lp-media-frame .right,
.liquid-lp-media-frame .left {
  top: 0;
  width: 10px;
  height: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.liquid-lp-media-frame .top {
  top: 0;
  -webkit-transform-origin: center top;
          transform-origin: center top;
}
.liquid-lp-media-frame .right {
  right: 0;
  -webkit-transform-origin: right center;
          transform-origin: right center;
}
.liquid-lp-media-frame .bottom {
  bottom: 0;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
}
.liquid-lp-media-frame .left {
  left: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

.liquid-lp-title {
  margin: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.liquid-lp-title a {
  display: block;
  color: inherit;
}
.liquid-lp-title a:hover {
  color: var(--color-primary);
}
.liquid-lp-title .split-inner {
  display: inline-block;
  position: relative;
}
.liquid-lp-title .split-inner:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 0.1em;
  position: absolute;
  bottom: -0.05em;
  left: 0;
  background-color: #181b31;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: -webkit-transform 0.35s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: transform 0.35s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: transform 0.35s cubic-bezier(0.2, 0.95, 0.25, 1), -webkit-transform 0.35s cubic-bezier(0.2, 0.95, 0.25, 1);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}
.liquid-lp-title.size-xl {
  font-size: 60px;
}
.liquid-lp-title.size-lg {
  font-size: 48px;
}
.liquid-lp-title.size-md {
  font-size: 36px;
}
.liquid-lp-title.size-sm {
  font-size: 30px;
}
.liquid-lp-title.size-xsm {
  font-size: 18px;
}
.liquid-lp-title:last-child {
  margin-bottom: 0;
}

.liquid-lp-excerpt {
  margin-top: 1em;
  margin-bottom: 1em;
}
.liquid-lp-excerpt p {
  margin: 0;
}

.liquid-lp-footer {
  margin-top: 1.5em;
  position: relative;
}
.liquid-lp-footer .category,
.liquid-lp-footer .post_tag {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  font-size: 13px;
  font-weight: 500;
}
.liquid-lp-footer .category ul,
.liquid-lp-footer .post_tag ul {
  margin-left: 0.5em;
  margin-bottom: 0;
  font-size: 1em;
}
.liquid-lp-footer .category {
  margin-right: 0.75em;
}
.liquid-lp-footer .post_tag {
  margin-left: 0.75em;
}

.liquid-lp-extra-icon {
  font-size: 24px;
}

.liquid-lp-category {
  padding: 0;
  margin: 0 0 0.25em;
  list-style: none;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.liquid-lp-category li {
  display: inline-block;
}
.liquid-lp-category li:after {
  content: '\002C';
  margin-right: 0.25em;
}
.liquid-lp-category li:last-child:after {
  content: none;
}
.liquid-lp-category a {
  position: relative;
}
.liquid-lp-category .liquid-lp-gradient-border {
  fill: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.liquid-lp-category .liquid-lp-gradient-border rect {
  fill: none;
  stroke: url("#liquid-lp-gradient");
}
.liquid-lp-category.bordered li {
  margin-right: 0.5em;
  position: relative;
  border-radius: 50em;
}
.liquid-lp-category.bordered li:before, .liquid-lp-category.bordered li:after {
  content: '';
  display: inline-block;
  position: absolute;
  border-radius: inherit;
}
.liquid-lp-category.bordered li:before {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.liquid-lp-category.bordered li:after {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  z-index: 0;
  margin: 0;
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}
.liquid-lp-category.bordered li:hover:before {
  opacity: 0;
}
.liquid-lp-category.bordered a {
  display: inline-block;
  position: relative;
  z-index: 3;
  padding: 3px 9px;
  font-size: 12px;
  font-weight: 600;
}
.liquid-lp-category.bordered a:hover {
  background-color: transparent;
  color: #fff !important;
}
.liquid-lp-category.circle a {
  border-radius: 50em;
  padding: 7px 15px;
  line-height: 1.5em;
  font-weight: 400;
}
.liquid-lp-category.square li {
  border-radius: 0;
}
.liquid-lp-category.square li:before, .liquid-lp-category.square li:after {
  content: none;
}
.liquid-lp-category.size-sm {
  color: inherit;
  font-size: inherit;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}
.liquid-lp-category.underlined-onhover a {
  position: relative;
}
.liquid-lp-category.underlined-onhover a:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #181b31;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
}
.liquid-lp-category.underlined-onhover a:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.liquid-lp-category:not(.size-sm) a {
  color: var(--color-primary);
}
svg + .liquid-lp-category {
  display: inline-block;
}

.liquid-lp-category-filled li {
  margin-bottom: 5px;
  margin-right: 5px;
}
.liquid-lp-category-filled a {
  display: inline-block;
  padding: 0.2em 0.6666666667em;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  color: #fff !important;
}
.liquid-lp-category-filled a:hover {
  background-color: #000;
}

.liquid-lp-featured-label {
  display: inline-block;
  margin-bottom: 1.25em;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.liquid-lp-date {
  display: block;
  margin-top: 1em;
  font-size: 0.75em;
}
.liquid-lp-date.size-lg {
  font-size: 0.875em;
}
.liquid-lp-date.size-sm {
  font-size: 0.625em;
}
.liquid-lp-date.text-uppercase {
  letter-spacing: 0.2em;
}

.liquid-lp-meta {
  font-size: 0.875em;
}
.liquid-lp-meta > a {
  margin-right: 20px;
}
.liquid-lp-meta i,
.liquid-lp-meta svg {
  margin-right: 5px;
  vertical-align: middle;
}

.liquid-lp-details {
  display: inline-block;
  position: relative;
  z-index: 3;
  margin-top: 0.75em;
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.35);
}
.liquid-lp-details:first-child {
  margin-top: 0;
  margin-bottom: 0.5em;
}
.liquid-lp-details time,
.liquid-lp-details .liquid-lp-category {
  display: inline-block;
  margin: 0;
  font-size: 1em;
  font-weight: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
}
.liquid-lp-details .liquid-lp-category a {
  color: inherit;
  font-weight: inherit;
}
.liquid-lp-details a:hover {
  color: #000;
}
.liquid-lp-details.size-lg {
  font-size: 18px;
}
.liquid-lp-details.size-sm {
  font-size: 10px;
}

.liquid-lp-details-lined {
  white-space: nowrap;
  margin-left: 4em;
}
.liquid-lp-details-lined:before, .liquid-lp-details-lined:after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 0.1em;
  min-height: 1px;
  left: -4em;
  background-color: #181b31;
}
.liquid-lp-details-lined:before {
  width: 3em;
  margin-top: -0.05em;
  top: 50%;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
.liquid-lp-details-lined:after {
  bottom: 0;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.liquid-lp-details-lined > * {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
.liquid-lp-details-lined.liquid-lp-details-lined-alt:after {
  content: none;
}
.liquid-lp-details-lined + .liquid-lp-read-more-overlay {
  left: 4em !important;
}

.ld-post-author {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.ld-post-author figure {
  width: 50px;
  margin-right: 15px;
}
.ld-post-author figure img {
  width: 100%;
}
.ld-post-author .ld-author-info {
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.ld-post-author time {
  color: #9B9B9B;
  font-size: 12px;
  line-height: 1.5em;
}
.ld-post-author h5 {
  font-size: 11px;
  line-height: 1.5em;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
}

.liquid-lp-read-more .btn-line {
  display: inline-block;
  width: 2.727em;
  height: 2px;
  margin-right: 1em;
  position: relative;
  background-color: var(--color-primary);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}
.liquid-lp-read-more .btn-line ~ .btn-txt {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.2, 0.95, 0.25, 1);
}
.liquid-lp-read-more .btn-line-after {
  -webkit-transform: scaleX(0) translateX(0);
          transform: scaleX(0) translateX(0);
}
.liquid-lp-read-more .btn-line-after:after {
  content: '\f054';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: -3px;
  margin-top: 1px;
  -webkit-transform: translateY(-50%) scaleX(0);
          transform: translateY(-50%) scaleX(0);
  font: normal normal 1em/1 fontAwesome;
}
.liquid-lp-read-more .btn-txt ~ .btn-line {
  margin-right: 0;
  margin-left: 1em;
}
.liquid-lp-read-more.size-md {
  font-size: 12px;
}
.liquid-lp-read-more.size-sm {
  font-size: 11px;
}
.liquid-lp-read-more.size-xs {
  font-size: 10px;
}
.liquid-lp-read-more.liquid-lp-read-more-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.liquid-blog-item-inner[data-hover3d=true] {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.liquid-lp {
  position: relative;
  margin-bottom: 30px;
}
.liquid-lp hr {
  margin-top: 2em;
  margin-bottom: 2em;
}
.carousel-items .liquid-lp {
  margin-top: 3px;
}
.liquid-lp .liquid-overlay-link ~ header .liquid-lp-category,
.liquid-lp .liquid-overlay-link ~ .liquid-lp-category {
  position: relative;
  z-index: 21;
}
.liquid-lp.sticky .liquid-lp-title > a {
  display: -webkit-box;
  display: flex;
}
.liquid-lp.sticky .liquid-lp-title > a:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9Ii0xNTggLTE5IDYxNiA2MTYuMDE4MDIiIHdpZHRoPSIyNHB4Ij48cGF0aCBkPSJtMTI4LjEzMjgxMiAzMDAuMDg5ODQ0djI2NS4wMzkwNjJjMCA2Ljg4NjcxOSA1LjU4MjAzMiAxMi40NzI2NTYgMTIuNDcyNjU3IDEyLjQ3MjY1NnMxMi40NzI2NTYtNS41ODU5MzcgMTIuNDcyNjU2LTEyLjQ3MjY1NnYtMjY0Ljk1NzAzMWMzNS4xODM1OTQtMi41ODk4NDQgNjguMjczNDM3LTE3LjcyMjY1NiA5My4yNS00Mi42NDQ1MzEgNTAuMzQzNzUtNDkuNDk2MDk0IDU5LjQ0NTMxMy0xMjcuMzcxMDk0IDIxLjg2NzE4Ny0xODcuMTQwNjI1LTM3LjU4MjAzMS01OS43NzM0MzgtMTExLjcwNzAzMS04NS4zMTY0MDctMTc4LjEyODkwNi02MS4zOTg0MzgtNjYuNDI5Njg3IDIzLjkyMTg3NS0xMDcuMjQ2MDk0IDkwLjg1OTM3NS05OC4wOTM3NSAxNjAuODYzMjgxIDkuMTU2MjUgNzAuMDExNzE5IDY1LjgxMjUgMTI0LjIwMzEyNiAxMzYuMTYwMTU2IDEzMC4yMzgyODJ6bTEyLjQ3MjY1Ny0yNzQuMzI0MjE5YzUwLjY0ODQzNy4xNTYyNSA5Ni4yMjI2NTYgMzAuNzgxMjUgMTE1LjUwNzgxMiA3Ny42MTcxODcgMTkuMjg1MTU3IDQ2LjgzNTkzOCA4LjQ4ODI4MSAxMDAuNjc1NzgyLTI3LjM2NzE4NyAxMzYuNDQ1MzEzLTIzLjE0ODQzOCAyMy4wNDI5NjktNTQuNTI3MzQ0IDM1LjkxMDE1Ni04Ny4xOTE0MDYgMzUuNzUtLjI4NTE1NyAwLS41NzQyMTktLjA4NTkzNy0uODU5Mzc2LS4wODU5MzdoLS4wODk4NDNjLTY4Ljk1NzAzMSAwLTEyNC44NjMyODEtNTUuOTA2MjUtMTI0Ljg2MzI4MS0xMjQuODY3MTg4IDAtNjguOTU3MDMxIDU1LjkwNjI1LTEyNC44NTkzNzUgMTI0Ljg2MzI4MS0xMjQuODU5Mzc1em0wIDAiIGZpbGw9IiMwMDAwMDAiLz48cGF0aCBkPSJtMTUyLjUyNzM0NCA5Mi41MzkwNjJjMTguODYzMjgxIDMuOTM3NSAzNC42Njc5NjggMTYuNzI2NTYzIDQyLjQ0OTIxOCAzNC4zNTU0NjkgMi43ODEyNSA2LjI5Njg3NSAxMC4xNDg0MzggOS4xNDg0MzggMTYuNDQ5MjE5IDYuMzYzMjgxIDYuMzAwNzgxLTIuNzg1MTU2IDkuMTQ4NDM4LTEwLjE1MjM0MyA2LjM2NzE4OC0xNi40NDkyMTgtMTEuMDUwNzgxLTI1LTMzLjQ3NjU2My00My4xMzY3MTktNjAuMjMwNDY5LTQ4LjcwNzAzMi00LjM3NS0uOTE0MDYyLTguOTAyMzQ0LjU3ODEyNi0xMS44NzUgMy45MTAxNTctMi45NzY1NjIgMy4zMzU5MzctMy45Mzc1IDguMDAzOTA2LTIuNTIzNDM4IDEyLjI0MjE4NyAxLjQxNDA2MyA0LjIzODI4MiA0Ljk4NDM3NiA3LjM5ODQzOCA5LjM2MzI4MiA4LjI4NTE1NnptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PC9zdmc+Cg==);
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: center;
}
.liquid-lp:hover .liquid-lp-media {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.1);
}
.liquid-lp:hover .liquid-lp-media-frame .top,
.liquid-lp:hover .liquid-lp-media-frame .bottom {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
.liquid-lp:hover .liquid-lp-media-frame .right,
.liquid-lp:hover .liquid-lp-media-frame .left {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(0) .split-inner:after {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(1) .split-inner:after {
  -webkit-transition-delay: 0.0666666667s;
          transition-delay: 0.0666666667s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(2) .split-inner:after {
  -webkit-transition-delay: 0.1333333333s;
          transition-delay: 0.1333333333s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(3) .split-inner:after {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(4) .split-inner:after {
  -webkit-transition-delay: 0.2666666667s;
          transition-delay: 0.2666666667s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(5) .split-inner:after {
  -webkit-transition-delay: 0.3333333333s;
          transition-delay: 0.3333333333s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(6) .split-inner:after {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(7) .split-inner:after {
  -webkit-transition-delay: 0.4666666667s;
          transition-delay: 0.4666666667s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(8) .split-inner:after {
  -webkit-transition-delay: 0.5333333333s;
          transition-delay: 0.5333333333s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(9) .split-inner:after {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}
.liquid-lp:hover .liquid-lp-title .split-unit:nth-child(10) .split-inner:after {
  -webkit-transition-delay: 0.6666666667s;
          transition-delay: 0.6666666667s;
}
.liquid-lp:hover .liquid-lp-title .split-inner:after {
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
.liquid-lp:hover .liquid-lp-details-lined:before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.liquid-lp:hover .liquid-lp-details-lined:after {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.liquid-lp:hover .liquid-lp-details-lined > * {
  -webkit-transform: translateX(-4em);
          transform: translateX(-4em);
}
.liquid-lp:hover .liquid-lp-details-lined > *:nth-child(0) {
  -webkit-transition-delay: 0.02s;
          transition-delay: 0.02s;
}
.liquid-lp:hover .liquid-lp-details-lined > *:nth-child(1) {
  -webkit-transition-delay: 0.0866666667s;
          transition-delay: 0.0866666667s;
}
.liquid-lp:hover .liquid-lp-details-lined > *:nth-child(2) {
  -webkit-transition-delay: 0.1533333333s;
          transition-delay: 0.1533333333s;
}
.liquid-lp:hover .liquid-lp-details-lined > *:nth-child(3) {
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
}
.liquid-lp:hover .liquid-lp-details-lined > *:nth-child(4) {
  -webkit-transition-delay: 0.2866666667s;
          transition-delay: 0.2866666667s;
}
.liquid-lp:hover .liquid-lp-details-lined > *:nth-child(5) {
  -webkit-transition-delay: 0.3533333333s;
          transition-delay: 0.3533333333s;
}
.liquid-lp:hover .liquid-lp-details-lined.liquid-lp-details-lined-alt > *, .liquid-lp:hover .liquid-lp-details-lined.liquid-lp-details-lined-alt:before {
  -webkit-transform: none;
          transform: none;
}
.liquid-lp:hover .liquid-lp-read-more .btn-line ~ .btn-txt {
  -webkit-transform: translateX(-3.727em);
          transform: translateX(-3.727em);
}
.liquid-lp:hover .liquid-lp-read-more .btn-line-before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}
.liquid-lp:hover .liquid-lp-read-more .btn-line-after {
  -webkit-transform: scaleX(1) translateX(-3.727em);
          transform: scaleX(1) translateX(-3.727em);
}
.liquid-lp:hover .liquid-lp-read-more .btn-line-after:after {
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translateY(-50%) scaleX(1);
          transform: translateY(-50%) scaleX(1);
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
}

/* Latest post special styles */
.liquid-lp-time-aside {
  margin-bottom: 4em;
}
.liquid-lp-time-aside > time {
  display: -webkit-box;
  display: flex;
  width: 80px;
  height: 82px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  border: 1px solid #EEEEEE;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
}
.liquid-lp-time-aside > time span {
  display: block;
  font-size: 2.142em;
  font-weight: 700;
  color: #181b31;
}
.liquid-lp-time-aside header,
.liquid-lp-time-aside .liquid-lp-excerpt,
.liquid-lp-time-aside .liquid-lp-footer {
  margin-left: 100px;
  overflow: hidden;
}
.liquid-lp-time-aside .liquid-lp-footer {
  margin-top: 0;
}
.liquid-lp-time-aside .liquid-lp-meta {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.liquid-lp-time-aside .liquid-lp-meta > span {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}
.liquid-lp-time-aside .liquid-lp-meta > span + span {
  margin-left: 1em;
}
.liquid-lp-time-aside .liquid-lp-meta .liquid-lp-category {
  margin-bottom: 0;
}

.liquid-lp-gradient defs stop:first-child {
  stop-color: var(--color-gradient-start);
}
.liquid-lp-gradient defs stop:last-child {
  stop-color: var(--color-gradient-stop);
}

.liquid-lp-sp-block {
  padding-bottom: 61.5%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1200px;
          perspective: 1200px;
  overflow: hidden;
}
.liquid-lp-sp-block .liquid-lp-media,
.liquid-lp-sp-block .liquid-lp-inner {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
}
.liquid-lp-sp-block .liquid-lp-media {
  margin: 0;
  right: 0;
  background-size: cover;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.3, 1, 0.4, 1), -webkit-filter 0.3s cubic-bezier(0.3, 1, 0.4, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.3, 1, 0.4, 1), -webkit-filter 0.3s cubic-bezier(0.3, 1, 0.4, 1);
  transition: transform 0.3s cubic-bezier(0.3, 1, 0.4, 1), filter 0.3s cubic-bezier(0.3, 1, 0.4, 1);
  transition: transform 0.3s cubic-bezier(0.3, 1, 0.4, 1), filter 0.3s cubic-bezier(0.3, 1, 0.4, 1), -webkit-transform 0.3s cubic-bezier(0.3, 1, 0.4, 1), -webkit-filter 0.3s cubic-bezier(0.3, 1, 0.4, 1);
}
.liquid-lp-sp-block .liquid-lp-media a {
  display: block;
}
.liquid-lp-sp-block .liquid-lp-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding: 20px 30px;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.liquid-lp-sp-block .liquid-lp-category {
  margin-bottom: 2em;
}
.liquid-lp-sp-block .liquid-lp-category a {
  border: none;
}
.liquid-lp-sp-block .liquid-lp-category a span {
  position: relative;
  z-index: 2;
}
.liquid-lp-sp-block .liquid-lp-category a:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background-image: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.liquid-lp-sp-block .liquid-lp-category a:hover {
  background: none;
  color: #fff;
}
.liquid-lp-sp-block .liquid-lp-category a:hover:before {
  opacity: 1;
}
.liquid-lp-sp-block .liquid-lp-title {
  font-size: 18px;
  line-height: 1.333em;
  text-transform: uppercase;
  letter-spacing: 0;
}
.liquid-lp-sp-block .liquid-lp-media img {
  visibility: hidden;
}
.liquid-lp-sp-block .liquid-lp-footer {
  margin-top: auto;
  position: relative;
  padding-top: 1.5em;
}
.liquid-lp-sp-block .liquid-lp-footer:before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background-image: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}
.liquid-lp-sp-block.liquid-lp-sp-instagram, .liquid-lp-sp-block.liquid-lp-featured {
  color: #fff;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram:before, .liquid-lp-sp-block.liquid-lp-featured:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
  background-color: #181a23;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-media,
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-inner, .liquid-lp-sp-block.liquid-lp-featured .liquid-lp-media,
.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-inner {
  width: 100%;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-media, .liquid-lp-sp-block.liquid-lp-featured .liquid-lp-media {
  width: 100%;
  z-index: 1;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category, .liquid-lp-sp-block.liquid-lp-featured .liquid-lp-category {
  margin-bottom: 1em;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-inner, .liquid-lp-sp-block.liquid-lp-featured .liquid-lp-inner {
  padding-left: 50px;
  padding-right: 50px;
  z-index: 3;
  background-color: transparent;
  color: inherit;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-title, .liquid-lp-sp-block.liquid-lp-featured .liquid-lp-title {
  font-size: 28px;
  line-height: 1.5em;
  color: inherit;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-title a, .liquid-lp-sp-block.liquid-lp-featured .liquid-lp-title a {
  color: inherit;
}
.liquid-lp-sp-block.liquid-lp-reverse .liquid-lp-media {
  left: 0;
  right: auto;
}
.liquid-lp-sp-block.liquid-lp-reverse .liquid-lp-inner {
  left: auto;
  right: 0;
}
.liquid-lp-sp-block.liquid-lp-featured .liquid-lp-category a {
  color: #fff;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram:before {
  opacity: 0.9;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background-image: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram h3 {
  margin: auto 15%;
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5em;
  color: #fff;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram a {
  color: #fff;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-inner {
  padding: 20px 30px;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category {
  margin-bottom: 0;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category a {
  border: 1px solid #fff;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category a:before {
  content: none;
}
.liquid-lp-sp-block.liquid-lp-sp-instagram .liquid-lp-category a:hover {
  background-color: #fff;
  color: #000 !important;
}
.liquid-lp-sp-block:hover .liquid-lp-media {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}
.liquid-lp-sp-block:hover.liquid-lp-sp-instagram .liquid-lp-media, .liquid-lp-sp-block:hover.liquid-lp-featured .liquid-lp-media {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.liquid-lp-sp-block-alt {
  border: 1px solid #e8e8e8;
}
.liquid-lp-sp-block-alt .liquid-lp-inner {
  padding-top: 45px;
  padding-bottom: 40px;
}
.liquid-lp-sp-block-alt .liquid-lp-title {
  font-size: 21px;
  line-height: 1.428em;
}
.liquid-lp-sp-block-alt .liquid-lp-category li a {
  padding: 6px 10px;
  border: 1px solid var(--color-primary);
  line-height: 1.5em;
  color: #000;
}
.liquid-lp-sp-block-alt .liquid-lp-category li a:before {
  background-image: none;
  background-color: var(--color-primary);
}
.liquid-lp-sp-block-alt .liquid-lp-excerpt {
  font-size: 17px;
  line-height: 1.47em;
}
.liquid-lp-sp-block-alt .liquid-lp-footer time {
  margin: 0;
  font-size: 13px;
}
.liquid-lp-sp-block-alt .liquid-lp-footer:before {
  content: none;
}
.liquid-lp-sp-block-alt.liquid-lp-featured:before {
  opacity: 0.18;
  background-color: #000;
}
.liquid-lp-sp-block-alt.liquid-lp-featured .liquid-lp-title {
  margin-top: 1em;
  font-size: 25px;
  line-height: 1.32em;
}

.ld-lp-carousel-filterable .fancy-title h6 {
  font-size: 13px;
}
.ld-lp-carousel-filterable .fancy-title h2 {
  font-size: 48px;
  line-height: 1em;
  letter-spacing: -0.025em;
}
.ld-lp-carousel-filterable .liquid-filter-items {
  margin-bottom: 1em;
}
.ld-lp-carousel-filterable .filter-list {
  width: 100%;
  font-size: 14px;
}
.ld-lp-carousel-filterable .ld-lp-carousel-filterable-btn {
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.ld-lp-carousel-filterable .ld-lp-carousel-filterable-btn .btn-gradient-bg-hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}
.ld-lp-carousel-filterable .liquid-lp:hover .liquid-lp-media {
  -webkit-transform: none;
          transform: none;
  box-shadow: 0 15px 50px -8px rgba(0, 0, 0, 0.15);
}

.liquid-blog-item .liquid-lp-media {
  margin-bottom: 1.25em;
}
.liquid-blog-item .liquid-lp-excerpt {
  margin-top: 0.35em;
  margin-bottom: 0.75em;
}
.liquid-blog-item .liquid-lp-title {
  line-height: 1em;
  letter-spacing: -0.05em;
}
.liquid-blog-item .liquid-lp-footer {
  margin-top: 0;
}
.liquid-blog-item:hover .liquid-lp-media {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}
.carousel-items .liquid-blog-item {
  margin-bottom: 0 !important;
}

.liquid-blog-contents-inside .liquid-blog-item-inner,
.liquid-blog-contents-inside .liquid-lp-media {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.liquid-blog-contents-inside .liquid-blog-item-inner {
  display: -webkit-box;
  display: flex;
  padding: 25px 5% 40px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column nowrap;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.liquid-blog-contents-inside .liquid-lp-footer {
  position: absolute;
  bottom: 40px;
  left: 5%;
  right: 5%;
}
.liquid-blog-contents-inside .liquid-lp-media {
  margin: 0;
  overflow: hidden;
}
.liquid-blog-contents-inside .liquid-lp-media img {
  visibility: hidden;
}
.liquid-blog-contents-inside .liquid-lp-media:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #141622), to(transparent));
  background: linear-gradient(to top, #141622 50%, transparent 100%);
  -webkit-transition: opacity 0.45s cubic-bezier(0.3, 0.95, 0.5, 1), -webkit-transform 0.45s cubic-bezier(0.3, 0.95, 0.5, 1);
  transition: opacity 0.45s cubic-bezier(0.3, 0.95, 0.5, 1), -webkit-transform 0.45s cubic-bezier(0.3, 0.95, 0.5, 1);
  transition: transform 0.45s cubic-bezier(0.3, 0.95, 0.5, 1), opacity 0.45s cubic-bezier(0.3, 0.95, 0.5, 1);
  transition: transform 0.45s cubic-bezier(0.3, 0.95, 0.5, 1), opacity 0.45s cubic-bezier(0.3, 0.95, 0.5, 1), -webkit-transform 0.45s cubic-bezier(0.3, 0.95, 0.5, 1);
}
.liquid-blog-contents-inside.contents-bottom .liquid-blog-item-inner {
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.liquid-blog-contents-inside.contents-middle .liquid-blog-item-inner {
  -webkit-box-pack: center;
          justify-content: center;
}
.liquid-blog-contents-inside.contents-top .liquid-blog-item-inner {
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.liquid-blog-contents-inside.h-100 {
  padding-bottom: 52.65%;
}
.liquid-blog-contents-inside.h-300 {
  padding-bottom: 66.67%;
}
.liquid-blog-contents-inside.h-400 {
  padding-bottom: 88.89%;
}
.liquid-blog-contents-inside.h-450 {
  padding-bottom: 100%;
}
.liquid-blog-contents-inside.h-500 {
  padding-bottom: 111.112%;
}
.liquid-blog-contents-inside.h-600 {
  padding-bottom: 133.335%;
}
.liquid-blog-contents-inside:hover .liquid-lp-media:after {
  opacity: 0.7;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.liquid-blog-cloned-title .liquid-lp-title .split-unit {
  overflow: hidden;
  line-height: 1.15em;
}
.liquid-blog-cloned-title .liquid-lp-title .split-inner {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.liquid-blog-cloned-title .liquid-lp-title .split-inner:after {
  content: none;
}
.liquid-blog-cloned-title .liquid-lp-title-clone [data-split-text=true] {
  visibility: hidden;
}
.liquid-blog-cloned-title .liquid-lp-title-clone [data-split-text=true].split-text-applied {
  visibility: visible;
}
.liquid-blog-cloned-title .liquid-lp-title-clone .split-inner {
  display: inline-block;
  -webkit-transform: translateY(150%);
          transform: translateY(150%);
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(0) .split-inner {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(1) .split-inner {
  -webkit-transition-delay: 0.0769230769s;
          transition-delay: 0.0769230769s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(2) .split-inner {
  -webkit-transition-delay: 0.1538461538s;
          transition-delay: 0.1538461538s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(3) .split-inner {
  -webkit-transition-delay: 0.2307692308s;
          transition-delay: 0.2307692308s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(4) .split-inner {
  -webkit-transition-delay: 0.3076923077s;
          transition-delay: 0.3076923077s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(5) .split-inner {
  -webkit-transition-delay: 0.3846153846s;
          transition-delay: 0.3846153846s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(6) .split-inner {
  -webkit-transition-delay: 0.4615384615s;
          transition-delay: 0.4615384615s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(7) .split-inner {
  -webkit-transition-delay: 0.5384615385s;
          transition-delay: 0.5384615385s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(8) .split-inner {
  -webkit-transition-delay: 0.6153846154s;
          transition-delay: 0.6153846154s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(9) .split-inner {
  -webkit-transition-delay: 0.6923076923s;
          transition-delay: 0.6923076923s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title .split-unit:nth-child(10) .split-inner {
  -webkit-transition-delay: 0.7692307692s;
          transition-delay: 0.7692307692s;
}
.liquid-blog-cloned-title:hover .liquid-lp-title-original .split-inner {
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.35, 0.95, 0.4, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.35, 0.95, 0.4, 1);
  transition: transform 0.45s cubic-bezier(0.35, 0.95, 0.4, 1);
  transition: transform 0.45s cubic-bezier(0.35, 0.95, 0.4, 1), -webkit-transform 0.45s cubic-bezier(0.35, 0.95, 0.4, 1);
  -webkit-transform: translateY(-150%);
          transform: translateY(-150%);
}
.liquid-blog-cloned-title:hover .liquid-lp-title-clone .split-inner {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.1, 0.9, 0.4, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.1, 0.9, 0.4, 1);
  transition: transform 0.6s cubic-bezier(0.1, 0.9, 0.4, 1);
  transition: transform 0.6s cubic-bezier(0.1, 0.9, 0.4, 1), -webkit-transform 0.6s cubic-bezier(0.1, 0.9, 0.4, 1);
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.liquid-blog-item-masonry {
  padding: 0 !important;
}
.liquid-blog-item-masonry .liquid-lp-media {
  min-height: inherit;
  position: relative;
  top: auto;
  left: auto;
}
.liquid-blog-item-masonry .liquid-lp-title {
  margin-bottom: 0.5em;
}
.liquid-blog-item-masonry .liquid-lp-footer {
  margin: 0;
}
.container-fluid .liquid-blog-item-masonry {
  min-height: 300px;
}

.liquid-blog-item-carousel .liquid-lp-title {
  margin-bottom: 0.5em;
}
.liquid-blog-item-carousel span + .liquid-lp-date:before,
.liquid-blog-item-carousel .liquid-lp-category + .liquid-lp-date:before {
  content: '-';
  margin-right: 0.25em;
}

.liquid-blog-item-split {
  display: -webkit-box;
  display: flex;
}
.liquid-blog-item-split .liquid-lp-details-lined:after {
  content: none;
}
.liquid-blog-item-split .liquid-blog-item-inner,
.liquid-blog-item-split .liquid-lp-media {
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.liquid-blog-item-split .liquid-blog-item-inner {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.liquid-blog-item-split .liquid-blog-item-inner:only-child {
  padding-left: 0;
}
.liquid-blog-item-split .liquid-lp-media {
  margin-bottom: 0;
}
.liquid-blog-item-split .liquid-lp-title {
  margin-bottom: 0.3em;
}
.liquid-blog-item-split .liquid-lp-excerpt {
  margin-top: 1em;
  margin-bottom: 1em;
}

.liquid-blog-item-fullwidth .liquid-lp-read-more {
  border-color: #fff;
}
.liquid-blog-item-fullwidth .liquid-lp-read-more > span {
  padding: 1.666em 2.916em;
}
.liquid-blog-item-fullwidth .liquid-lp-footer {
  margin-top: 2.5em;
}
.liquid-blog-item-fullwidth .liquid-lp-media {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.liquid-blog-item-fullwidth .liquid-lp-media:after {
  background: #141622;
  height: 100% !important;
  opacity: 0.3 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.liquid-blog-item-fullwidth .liquid-blog-item-inner {
  padding: 15% 0;
}
.liquid-blog-item-fullwidth.liquid-blog-scheme-dark .liquid-lp-read-more {
  border-color: #000;
}
.liquid-blog-item-fullwidth.liquid-blog-contents-inside .liquid-lp-footer {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
}
.liquid-blog-item-fullwidth:hover .liquid-lp-read-more {
  background-color: #fff;
  color: #000;
}
.liquid-blog-item-fullwidth:hover.liquid-blog-scheme-dark .liquid-lp-read-more {
  background-color: #000;
  color: #fff;
}

.liquid-blog-item-timeline {
  padding: 20px 40px 45px;
  border: 1px solid #ebeef3;
  border-radius: 4px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  -webkit-transition: box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.liquid-blog-item-timeline .liquid-lp-details {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin-bottom: 1.75em;
}
.liquid-blog-item-timeline .liquid-lp-category.bordered a {
  padding: 0.15em 10px;
  border-radius: 4px;
  letter-spacing: 0.1em;
}
.liquid-blog-item-timeline .liquid-lp-media {
  margin-bottom: 2em;
}
.liquid-blog-item-timeline .liquid-lp-media img {
  border-radius: 4px;
}
.liquid-blog-item-timeline .liquid-lp-excerpt {
  margin-top: 0.95em;
}
.liquid-blog-item-timeline.liquid-blog-scheme-dark .liquid-lp-details {
  color: rgba(0, 0, 0, 0.35);
}
.liquid-blog-item-timeline:hover {
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.12);
}

.liquid-blog-item-gray {
  min-height: 350px;
  background-color: #F4F5F6;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.liquid-blog-item-gray .liquid-blog-item-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  min-height: inherit;
  padding: 40px;
}
.liquid-blog-item-gray .liquid-lp-title {
  font-size: 22px;
  line-height: 1.5454545455em;
  font-weight: 400;
  letter-spacing: 0;
}
.liquid-blog-item-gray .liquid-lp-excerpt {
  font-size: 16px;
  line-height: 1.5em;
  color: #9B9B9B;
}
.liquid-blog-grid .masonry-item:first-child .liquid-blog-item-gray {
  border-radius: 4px 0 0 4px;
}
.liquid-blog-grid .masonry-item:last-child .liquid-blog-item-gray {
  border-radius: 0 4px 4px 0;
}
.liquid-blog-grid .masonry-item + .masonry-item .liquid-blog-item-gray {
  border-left: 1px solid #EAEAEA;
}
.liquid-blog-item-gray:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.07);
  z-index: 2;
}

.liquid-blog-item-candy .liquid-lp-media {
  margin-bottom: 27px;
  overflow: hidden;
}
.liquid-blog-item-candy .liquid-lp-header {
  margin-bottom: 15px;
}
.liquid-blog-item-candy .liquid-lp-footer time,
.liquid-blog-item-candy .liquid-lp-footer a {
  color: #000;
}
.liquid-blog-item-candy .liquid-lp-details-lined:before {
  background-color: var(--color-primary);
}

.liquid-blog-scheme-dark a,
.liquid-blog-scheme-light a {
  color: inherit;
}
.liquid-blog-scheme-dark a:hover, .liquid-blog-scheme-dark a:focus,
.liquid-blog-scheme-light a:hover,
.liquid-blog-scheme-light a:focus {
  color: inherit;
}
.liquid-blog-scheme-dark .liquid-lp-title,
.liquid-blog-scheme-light .liquid-lp-title {
  color: inherit;
}
.liquid-blog-scheme-dark .liquid-lp-category.bordered a,
.liquid-blog-scheme-light .liquid-lp-category.bordered a {
  color: var(--color-primary);
}

.liquid-blog-scheme-dark {
  color: #181b31;
}
.liquid-blog-scheme-dark .btn-line,
.liquid-blog-scheme-dark .liquid-lp-title .split-inner:after {
  background-color: #181b31;
}

.liquid-blog-scheme-light {
  color: #fff;
}
.liquid-blog-scheme-light .btn-line,
.liquid-blog-scheme-light .liquid-lp-details-lined:before,
.liquid-blog-scheme-light .liquid-lp-details-lined:after,
.liquid-blog-scheme-light .liquid-lp-category.underlined-onhover a:after,
.liquid-blog-scheme-light .liquid-lp-title .split-inner:after {
  background-color: #fff;
}
.liquid-blog-scheme-light .liquid-lp-details {
  color: rgba(255, 255, 255, 0.55);
}
.liquid-blog-scheme-light .liquid-lp-details a:hover {
  color: #fff;
}

.liquid-blog-scheme-dark-alt .liquid-lp-details,
.liquid-blog-scheme-light-alt .liquid-lp-details {
  color: inherit;
}

.liquid-blog-scheme-dark-alt .liquid-lp-excerpt {
  color: rgba(24, 27, 49, 0.55);
}

.liquid-blog-scheme-light-alt .liquid-lp-excerpt {
  color: rgba(255, 255, 255, 0.55);
}

.liquid-blog-item-square .liquid-blog-item-inner {
  padding: 6% 30px;
  padding-bottom: 6%;
}
.liquid-blog-item-square .liquid-lp-header {
  width: 85%;
}
.liquid-blog-item-square .liquid-lp-category {
  font-size: 12px;
}
.liquid-blog-item-square .liquid-lp-title {
  font-size: 22px;
  line-height: 1.333em;
}

.liquid-lp-small-thumb .liquid-lp-media {
  width: 70px;
  flex-shrink: 0;
}
.liquid-lp-small-thumb .liquid-lp-header {
  -webkit-box-flex: 1;
          flex: 1 1;
}

.liquid-blog-style-masonry .masonry-item {
  padding-left: 6px;
  padding-right: 6px;
}
.liquid-blog-style-masonry .liquid-lp {
  margin-bottom: 12px;
}

.liquid-lp.format-link .liquid-lp-title {
  margin-bottom: 1em;
}
.liquid-lp.format-link .liquid-lp-title a {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-box-align: start;
          align-items: flex-start;
  text-decoration: underline;
}
.liquid-lp.format-link .liquid-lp-title svg {
  margin-right: 0.625em;
  fill: currentColor;
  width: 1.25em;
  height: 1.25em;
}
.liquid-lp.format-quote {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 50px;
}
.liquid-lp.format-quote blockquote,
.liquid-lp.format-quote .liquid-lp-title {
  margin: 0;
}
.liquid-lp.format-quote .liquid-lp-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  text-align: center;
}
.liquid-lp.format-quote svg {
  margin-bottom: 0.6666666667em;
}
.liquid-lp.format-quote cite {
  padding-top: 10px;
  font-size: 12px;
  color: #888;
}
.liquid-lp.format-quote cite:before {
  content: none;
}
.liquid-lp.format-gallery .liquid-lp-media .carousel-nav button {
  width: 53px;
  height: 53px;
  font-size: 14px;
  background-color: #fff;
  color: #000;
}
.liquid-lp.format-audio:hover .liquid-lp-media, .liquid-lp.format-video:hover .liquid-lp-media {
  -webkit-transform: none !important;
          transform: none !important;
  box-shadow: none !important;
}
audio{
  outline: none;
  border:none !important;
}

/* 3.14. Filter Lists */
.liquid-filter-items {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 1.5em;
  color: #16171e;
}

.liquid-filter-items-inner {
  display: inherit;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: inherit;
  -webkit-box-align: inherit;
          align-items: inherit;
  -webkit-box-pack: inherit;
          justify-content: inherit;
}
.liquid-filter-items-inner:only-child {
  width: 100%;
}

.liquid-filter-items-label {
  margin-bottom: 0;
  line-height: 1;
}
.liquid-filter-items-label.size-xxl {
  font-size: 72px;
  letter-spacing: -0.025em;
}
.liquid-filter-items-label.size-xl {
  font-size: 55px;
  line-height: 1;
}
.liquid-filter-items-label.size-lg {
  font-size: 24px;
}
.liquid-filter-items-label.size-md {
  font-size: 18px;
}
.liquid-filter-items-label + .filter-list li {
  margin-bottom: 0;
}

.filter-list {
  padding: 0;
  margin: 0 0 30px;
  list-style: none;
  font-size: 0.8125em;
}
.filter-list.size-lg {
  font-size: 24px;
}
.filter-list.size-md {
  font-size: 18px;
}
.filter-list.size-sm {
  font-size: 12px;
}
.filter-list li {
  margin-bottom: 0.5em;
  position: relative;
  color: #9ba1af;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.filter-list li span {
  display: inline-block;
}
.filter-list li.active, .filter-list li:hover {
  color: #16171e;
}
.liquid-filter-items-label + .filter-list {
  margin-left: 3.5em;
  margin-bottom: 0;
}

.filter-list-inline li {
  display: inline-block;
}

.filter-list-scheme-light li {
  color: rgba(255, 255, 255, 0.5);
}
.filter-list-scheme-light li.active, .filter-list-scheme-light li:hover {
  color: #fff;
}

.filters-underline li span,
.filters-line-through li span {
  position: relative;
}
.filters-underline li span:after,
.filters-line-through li span:after {
  content: '';
  height: 0.0909090909em;
  min-height: 1px;
  position: absolute;
  background-color: #16171e;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.filters-underline li.active span:after, .filters-underline li:hover span:after,
.filters-line-through li.active span:after,
.filters-line-through li:hover span:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

.filters-line-through li span:after {
  width: 110%;
  top: 50%;
  left: -5%;
  margin-top: -0.0454545455em;
}

.filters-underline li span:after {
  bottom: -0.5833333333em;
  left: 0;
  width: 100%;
  height: 0.1666666667em;
  min-height: 2px;
}

.filter-list-style-1 {
  margin-top: 10px;
  margin-bottom: 70px;
}
.filter-list-style-1 span {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 1, 0.5, 1);
  transition: -webkit-transform 0.4s cubic-bezier(0.4, 1, 0.5, 1);
  transition: transform 0.4s cubic-bezier(0.4, 1, 0.5, 1);
  transition: transform 0.4s cubic-bezier(0.4, 1, 0.5, 1), -webkit-transform 0.4s cubic-bezier(0.4, 1, 0.5, 1);
}
.filter-list-style-1 li {
  margin-bottom: 2em;
}
.filter-list-style-1 li:before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 1.2em;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.6em;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background-image: linear-gradient(to bottom, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
  opacity: 0;
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
  -webkit-transition: opacity 0.3s cubic-bezier(0.4, 1, 0.5, 1), -webkit-transform 0.3s cubic-bezier(0.4, 1, 0.5, 1);
  transition: opacity 0.3s cubic-bezier(0.4, 1, 0.5, 1), -webkit-transform 0.3s cubic-bezier(0.4, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0.4, 1, 0.5, 1), opacity 0.3s cubic-bezier(0.4, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0.4, 1, 0.5, 1), opacity 0.3s cubic-bezier(0.4, 1, 0.5, 1), -webkit-transform 0.3s cubic-bezier(0.4, 1, 0.5, 1);
}
.filter-list-style-1 li.active:before, .filter-list-style-1 li:hover:before {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
}
.filter-list-style-1 li.active span, .filter-list-style-1 li:hover span {
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
}

/* 3.15. Fancy Boxes */
.fancy-box {
  position: relative;
  margin-bottom: 30px;
}
.fancy-box figure {
  background-size: cover;
}
.fancy-box h3 {
  font-size: 1.5em;
  line-height: 1.5em;
  letter-spacing: 0;
  margin: 0;
}
.fancy-box p {
  margin: 0;
}
.fancy-box img {
  width: 100%;
}
.fancy-box .cb-img-container,
.fancy-box .fancy-box-image {
  position: relative;
}
.fancy-box .cb-img-container {
  overflow: hidden;
}
.fancy-box .blur-main-image,
.fancy-box .blur-image-canvas,
.fancy-box .blur-image-inner,
.fancy-box .blur-image-container {
  position: absolute;
  top: 0;
  left: 0;
}
.fancy-box .blur-image-container,
.fancy-box .blur-image-inner {
  width: 100%;
  height: 100%;
}
.fancy-box .blur-image-container {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.fancy-box .blur-image-canvas {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.fancy-box .cb-img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
  background-size: cover;
  background-position: center;
}
.fancy-box .fancy-box-footer {
  position: relative;
  z-index: 2;
}
.fancy-box:hover .cb-img-overlay {
  opacity: 1;
  visibility: visible;
}
.fancy-box:hover .cb-img-btn {
  opacity: 1;
  visibility: visible;
}
.fancy-box:hover .cb-img-btn-inner {
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
}
.fancy-box:hover .cb-img-btn-inner .btn {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  -webkit-transition-delay: 0.18s;
          transition-delay: 0.18s;
}

.cb-subtitle {
  display: inline-block;
  padding: 3px 10px;
  margin-bottom: 10px;
  background-color: var(--color-primary);
  font-size: 12px;
}

.cb-img-btn-bg,
.cb-img-btn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cb-img-btn-inner {
  display: -webkit-box;
  display: flex;
  padding: 15px;
  border: 2px solid #fff;
  position: absolute;
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 15px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-transform: scale3d(1.1, 1.1, 0);
          transform: scale3d(1.1, 1.1, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1), -webkit-transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
}

.cb-img-btn {
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.cb-img-btn .liquid-overlay-link {
  z-index: 2;
}
.cb-img-btn .btn {
  opacity: 0;
  -webkit-transform: scale3d(1.1, 1.1, 0);
          transform: scale3d(1.1, 1.1, 0);
}
.cb-img-btn .btn-naked {
  color: #fff;
}

/* Content box styles */
.fancy-box-booking .fancy-box-contents {
  padding: 3.125em 1.875em;
}
.fancy-box-booking .fancy-box-info {
  width: 75%;
  margin: 2.8125em 1.875em;
  position: relative;
}
.fancy-box-booking .fancy-box-info:before {
  content: '';
  display: inline-block;
  width: 1.875em;
  height: 1px;
  position: absolute;
  top: 0.5em;
  left: -60px;
  background-color: var(--color-primary);
}
.fancy-box-booking .fancy-box-header {
  display: inline-block;
  padding: 0.625em 1.875em 1.25em;
  position: absolute;
  bottom: 0;
  left: 1.875em;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  background-color: #fff;
  box-shadow: 0 7px 50px rgba(0, 0, 0, 0.1);
}
.fancy-box-booking .fancy-box-details {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--color-primary);
}
.fancy-box-booking .fancy-box-details > span:after {
  content: '\2758';
  margin-left: 1em;
  margin-right: 0.83em;
}
.fancy-box-booking .fancy-box-details > span:last-child:after {
  content: none;
}
.fancy-box-booking .btn {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.075);
}

.fancy-box-classes {
  background-color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: box-shadow 0.45s cubic-bezier(0.32, 0.98, 0.37, 1);
  transition: box-shadow 0.45s cubic-bezier(0.32, 0.98, 0.37, 1);
}
.fancy-box-classes .fancy-box-contents {
  padding: 1em 25px;
}
.fancy-box-classes h3 {
  margin-bottom: 0.4375em;
}
.fancy-box-classes .fancy-box-label {
  display: inline-block;
  padding: 4px 10px;
  position: absolute;
  top: 30px;
  left: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #fff;
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}
.fancy-box-classes .trainer {
  font-size: 0.8125em;
  color: #828593;
}
.fancy-box-classes .trainer i {
  display: inline-block;
  margin-right: 5px;
}
.backgroundcliptext .fancy-box-classes .trainer i {
  padding-top: 1px;
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}
.fancy-box-classes:hover {
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.07);
}
.flickity-slider .fancy-box-classes {
  margin-bottom: 50px;
}

.fancy-box-travel {
  padding-bottom: 64.5%;
  overflow: hidden;
  color: #fff;
}
.fancy-box-travel:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#181b31), to(transparent));
  background-image: linear-gradient(to top, #181b31 0%, transparent 100%);
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.fancy-box-travel h3 {
  margin: 0.4375em 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0;
  color: #fff;
}
.fancy-box-travel .fancy-box-image,
.fancy-box-travel .fancy-box-contents {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.fancy-box-travel .fancy-box-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), -webkit-filter 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), -webkit-filter 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), filter 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), filter 0.5s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), -webkit-filter 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.fancy-box-travel .fancy-box-image img {
  visibility: hidden;
}
.fancy-box-travel .fancy-box-contents {
  margin: 30px;
  z-index: 2;
}
.fancy-box-travel .fancy-box-time {
  display: inline-block;
  padding: 0.25em 0.625em;
  background-color: var(--color-primary);
  font-size: 0.75em;
  letter-spacing: 0.05em;
}
.fancy-box-travel .fancy-box-footer {
  margin-top: 1.875em;
}
.fancy-box-travel .btn {
  font-size: 0.875em;
  font-weight: 700;
}
.fancy-box-travel.fancy-box-big .fancy-box-contents {
  right: 30%;
}
.fancy-box-travel.fancy-box-tall {
  padding-bottom: 134.375%;
}
.fancy-box-travel.fancy-box-small {
  padding-bottom: 69.5%;
}
.fancy-box-travel.fancy-box-wide {
  padding-bottom: 33.36%;
}
.fancy-box-travel.fancy-box-square {
  padding-bottom: 100%;
}
.fancy-box-travel.hide-target .fancy-box-info p,
.fancy-box-travel.hide-target .fancy-box-info .fancy-box-footer {
  opacity: 0;
}
.fancy-box-travel:hover:after {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.fancy-box-travel:hover .fancy-box-image {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.fancy-box-tour {
  background-color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: box-shadow 0.45s cubic-bezier(0.32, 0.98, 0.37, 1);
  transition: box-shadow 0.45s cubic-bezier(0.32, 0.98, 0.37, 1);
}
.fancy-box-tour .fancy-box-contents,
.fancy-box-tour .fancy-box-footer {
  border: 1px solid #e7e9ef;
  border-top: none;
}
.fancy-box-tour .fancy-box-contents {
  padding: 1.25em 20px 1.5625em;
}
.fancy-box-tour .fancy-box-info {
  font-size: 0.8125em;
}
.fancy-box-tour .rating {
  font-size: 12px;
  margin: 0.45em 0;
  color: #a7a9b8;
}
.fancy-box-tour .star-rating {
  display: inline-block;
  font-size: 1em;
  color: var(--color-primary);
}
.fancy-box-tour .fancy-box-footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 1.0625em 20px;
}
.fancy-box-tour .fancy-box-footer h6 {
  margin: 0;
  font-size: 1em;
  color: #181b31;
}
.fancy-box-tour .fancy-box-footer h6 strong {
  font-weight: bold;
  font-size: 1.5em;
  color: var(--color-primary);
}
.fancy-box-tour .fancy-box-icon {
  display: inline-block;
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
  opacity: 0;
  color: var(--color-primary);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
}
.fancy-box-tour .fancy-box-icon i {
  display: inline-block;
}
.fancy-box-tour:hover {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.07);
}
.fancy-box-tour:hover .fancy-box-icon {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.flickity-slider .fancy-box-tour {
  margin-bottom: 50px;
}

.fancy-box-offer {
  display: -webkit-box;
  display: flex;
  padding-bottom: 30px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #e5e7ed;
}
.fancy-box-offer .fancy-box-cell {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  flex-basis: 16.666666666666667%;
  -webkit-box-flex: 1;
          flex: 1 0;
}
.fancy-box-offer .fancy-box-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  flex-basis: 20%;
}
.fancy-box-offer .fancy-box-image {
  margin-right: 18px;
  -webkit-box-flex: 0;
          flex: 0 auto;
}
.fancy-box-offer small {
  display: block;
  font-size: 13px;
  letter-spacing: 0.05em;
  color: #808291;
  font-weight: 400;
}
.fancy-box-offer h3 {
  line-height: 1.5em;
  font-weight: 600;
}
.fancy-box-offer h5 {
  margin: 0;
  font-size: 1.125em;
  line-height: 1.5em;
  font-weight: 600;
}
.fancy-box-offer img {
  max-width: 120px;
}
.fancy-box-offer p span {
  display: inline-block;
}
.fancy-box-offer .btn {
  font-size: 14px;
  -webkit-box-pack: center;
          justify-content: center;
}
.fancy-box-offer .btn-md > span {
  padding-top: 1em;
  padding-bottom: 1em;
}
.fancy-box-offer:last-child {
  border-bottom: none;
}

.fancy-box-offer-header {
  padding-bottom: 15px;
}
.fancy-box-offer-header h3 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.fancy-box-offer-header p,
.fancy-box-cell:before {
  font-size: 0.875em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #a7a9b8;
}

.fancy-box-case-study {
  -webkit-perspective: 1200px;
          perspective: 1200px;
  min-height: 400px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.fancy-box-case-study h3 {
  font-size: 27px;
}
.fancy-box-case-study figure {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transform: scale(1.075);
          transform: scale(1.075);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.fancy-box-case-study figure:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(13, 15, 30, 0.35);
}
.fancy-box-case-study .cb-img-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.fancy-box-case-study .fancy-box-contents {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: inherit;
          perspective: inherit;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: box-shadow 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.fancy-box-case-study .fancy-box-header {
  position: absolute;
  bottom: 40px;
  left: 35px;
  right: 35px;
  -webkit-transform: translateZ(35px) scale(0.942);
          transform: translateZ(35px) scale(0.942);
  color: #fff;
}
.fancy-box-case-study .ld-cb-icon {
  display: block;
  font-size: 44px;
  line-height: 1;
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.fancy-box-case-study .ld-cb-cat {
  font-size: 13px;
  opacity: 0.6;
}
.fancy-box-case-study.scheme-light .fancy-box-header {
  color: #fff;
}
.fancy-box-case-study.scheme-light .fancy-box-header h3 {
  color: inherit;
}
.fancy-box-case-study:hover {
  -webkit-transform: scale(1.075);
          transform: scale(1.075);
}
.fancy-box-case-study:hover figure {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.fancy-box-case-study:hover .fancy-box-contents {
  box-shadow: 0 60px 110px rgba(0, 0, 0, 0.23);
}
.fancy-box-case-study:hover .ld-cb-icon {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.fancy-box-overlay {
  padding-bottom: 53.335%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.fancy-box-overlay .cb-img-container,
.fancy-box-overlay .fancy-box-contents {
  position: absolute;
  top: 0;
  left: 0;
}
.fancy-box-overlay .fancy-box-image,
.fancy-box-overlay .cb-img-container,
.fancy-box-overlay .fancy-box-contents {
  width: 100%;
  height: 100%;
}
.fancy-box-overlay .cb-img-container {
  width: 102%;
  height: 102%;
  left: -1%;
  top: -1%;
}
.fancy-box-overlay .fancy-box-image {
  background-position: center;
}
.fancy-box-overlay .blur-image-container {
  opacity: 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.fancy-box-overlay .cb-overlay {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.fancy-box-overlay .fancy-box-contents {
  width: 85%;
  padding: 35px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.fancy-box-overlay .fancy-box-footer {
  margin-top: auto;
  z-index: 2;
}
.fancy-box-overlay h3 {
  font-size: 24px;
  margin-bottom: 1.5rem;
}
.fancy-box-overlay.shadowed {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.18);
}
.fancy-box-overlay.tall {
  padding-bottom: 133.335%;
}
.fancy-box-overlay.tall .fancy-box-contents {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.fancy-box-overlay.tall h3 {
  margin-bottom: 0.5rem;
}
.fancy-box-overlay.scheme-light {
  color: rgba(255, 255, 255, 0.75);
}
.fancy-box-overlay.scheme-light h3 {
  color: #fff;
}
.fancy-box-overlay.scheme-light .cb-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.fancy-box-overlay.fancy-box-overlay-alt {
  padding-left: 30px;
  padding-right: 30px;
}
.fancy-box-overlay.fancy-box-overlay-alt .fancy-box-contents {
  width: 100%;
}
.fancy-box-overlay.fancy-box-overlay-alt .fancy-box-footer {
  margin-top: 1.5rem;
}
.fancy-box-overlay:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.18);
}
.fancy-box-overlay:hover .cb-overlay {
  opacity: 0.9;
}
.fancy-box-overlay:hover .blur-image-container {
  opacity: 1;
}

.fancy-box-classic h3 {
  margin-bottom: 0.5rem;
}
.fancy-box-classic.text-center h3 {
  margin-bottom: 1.25rem;
}

.fancy-box-custom-height {
  padding-bottom: 0 !important;
}

.fancy-box-heading-lg h3 {
  font-size: 36px;
}

.fancy-box-heading-sm h3 {
  font-size: 18px;
}

/* 3.16. Counnter */
.liquid-counter {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.liquid-counter:hover .liquid-counter-element-hover {
  opacity: 1;
}

.liquid-counter-element {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  font-weight: 700;
  line-height: 1em;
}
.liquid-counter-element > span {
  display: -webkit-box;
  display: flex;
}
.text-left .liquid-counter-element {
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.text-right .liquid-counter-element {
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.text-center .liquid-counter-element {
  -webkit-box-pack: center;
          justify-content: center;
}

.liquid-counter-text {
  line-height: 1.5em;
}

.liquid-counter-element-static, .liquid-counter-element-hover {
  display: none;
}
.liquid-counter-element-static span,
.liquid-counter-element-hover span {
  display: inline-block;
  vertical-align: middle;
}
.counter-animated .liquid-counter-element-static,
.counter-animated .liquid-counter-element-hover {
  display: -webkit-box;
  display: flex;
}

.liquid-counter-element-static > span {
  background: inherit;
}
.counter-animated .liquid-counter-element-static ~ span {
  display: none;
}

.liquid-counter-element-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.backgroundcliptext .liquid-counter-element-hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop)));
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}
.backgroundcliptext .liquid-counter-element-hover span {
  background: inherit;
}

.liquid-counter-regular .liquid-counter-element {
  font-weight: 400;
}

.liquid-counter-medium .liquid-counter-element {
  font-weight: 500;
}

.liquid-counter-semibold .liquid-counter-element {
  font-weight: 600;
}

.liquid-counter-bold .liquid-counter-element {
  font-weight: 700;
}

.liquid-counter-sm .liquid-counter-element {
  font-size: 36px;
}

.liquid-counter-md .liquid-counter-element {
  font-size: 47px;
}

.liquid-counter-lg .liquid-counter-element {
  font-size: 60px;
}

.liquid-counter-lg2 .liquid-counter-element {
  font-size: 200px;
}

.liquid-counter-xl .liquid-counter-element {
  font-size: 300px;
}

.liquid-counter-animator {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background: inherit;
}

.liquid-animator-value {
  display: inline-block;
  visibility: hidden;
}

.liquid-animator-numbers {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
  overflow: hidden;
  visibility: visible;
}
.liquid-animator-numbers ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  background: inherit;
}
.liquid-animator-numbers li {
  margin: 0;
  background: inherit;
}

.liquid-counter-huge .liquid-counter-element {
  line-height: 0.8em;
}
.liquid-counter-huge .liquid-text-top {
  display: block;
  margin-bottom: 2em;
  position: relative;
  top: 2em;
  font-size: 0.875em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.liquid-counter-huge .liquid-text-bottom {
  position: absolute;
  bottom: 0;
  left: 100%;
  font-size: 1.125em;
  line-height: 1.5em;
  font-style: italic;
}

.liquid-counter-bordered {
  width: 100%;
  padding: 30px 5%;
  border: 1px solid #d8dbe2;
  text-align: center;
}
.liquid-counter-bordered .liquid-counter-element {
  -webkit-box-pack: center;
          justify-content: center;
}
.liquid-counter-bordered .liquid-counter-text {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #a7a9b8;
}

.backgroundcliptext .liquid-counter-has-gradient .liquid-counter-element-static,
.backgroundcliptext .liquid-counter-has-gradient .liquid-counter-element > span {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  text-fill-color: transparent !important;
  -webkit-text-fill-color: transparent !important;
}

/* 3.17. Liquid Forms */
.liquid-form .input-container {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
}
.liquid-form .input-container .ui-selectmenu-button {
  margin-bottom: 0 !important;
}
.liquid-form select,
.liquid-form input,
.liquid-form textarea,
.liquid-form .ui-selectmenu-button {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 2em;
  position: relative;
  border: 1px solid #d5d6de;
  border-radius: 0;
  background: none;
  color: #b7b9c4;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.liquid-form select::-webkit-input-placeholder,
.liquid-form input::-webkit-input-placeholder,
.liquid-form textarea::-webkit-input-placeholder,
.liquid-form .ui-selectmenu-button::-webkit-input-placeholder {
  color: inherit;
}
.liquid-form select::-moz-placeholder,
.liquid-form input::-moz-placeholder,
.liquid-form textarea::-moz-placeholder,
.liquid-form .ui-selectmenu-button::-moz-placeholder {
  color: inherit;
}
.liquid-form select:-moz-placeholder,
.liquid-form input:-moz-placeholder,
.liquid-form textarea:-moz-placeholder,
.liquid-form .ui-selectmenu-button:-moz-placeholder {
  color: inherit;
}
.liquid-form select:-ms-input-placeholder,
.liquid-form input:-ms-input-placeholder,
.liquid-form textarea:-ms-input-placeholder,
.liquid-form .ui-selectmenu-button:-ms-input-placeholder {
  color: inherit;
}
.liquid-form select:focus,
.liquid-form input:focus,
.liquid-form textarea:focus,
.liquid-form .ui-selectmenu-button:focus {
  outline: none;
  border-color: #9c9eb1;
  color: #9c9eb1;
}
.liquid-form .ui-selectmenu-icon {
  visibility: hidden;
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.liquid-form .input[type=submit],
.liquid-form button[type=submit] {
  height: 50px;
}
.liquid-form button[type=submit] > span {
  padding-top: 0;
  padding-bottom: 0;
}

.liquid-form-inputs-sm select,
.liquid-form-inputs-sm input,
.liquid-form-inputs-sm textarea,
.liquid-form-inputs-sm .ui-selectmenu-button {
  height: 40px;
}
.liquid-form-inputs-sm .input[type=submit],
.liquid-form-inputs-sm button[type=submit] {
  height: 40px;
}

.liquid-form-inputs-lg select,
.liquid-form-inputs-lg input,
.liquid-form-inputs-lg textarea,
.liquid-form-inputs-lg .ui-selectmenu-button {
  height: 60px;
}
.liquid-form-inputs-lg .input[type=submit],
.liquid-form-inputs-lg button[type=submit] {
  height: 60px;
}

.liquid-form-inputs-round select,
.liquid-form-inputs-round input,
.liquid-form-inputs-round textarea,
.liquid-form-inputs-round .ui-selectmenu-button {
  border-radius: 3px;
}
.liquid-form-inputs-round input[type=submit],
.liquid-form-inputs-round button[type=submit] {
  border-radius: 3px;
}

.date-picker {
  cursor: pointer;
}
.date-picker:focus {
  cursor: auto;
}

.liquid-input-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.liquid-input-icon-right {
  left: auto;
  right: 15px;
}

.liquid-input-icon-left {
  right: auto;
  left: 15px;
}

/* Jquery UI */
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background-color: #ededed;
  border-color: #ededed;
  color: #000;
}

.ui-selectmenu-button:active {
  background-color: transparent;
  color: #9c9eb1;
}

.ui-widget.ui-widget-content {
  max-height: 40vh;
  border-color: #e4e4eb;
}

/* 3.18. jQuery UI */
.ui-widget {
  font-family: inherit;
}

.ui-datepicker {
  border-color: #f7f7f7 !important;
  border-radius: 12px;
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.07);
}
.ui-datepicker table {
  border: none;
}
.ui-datepicker table tr {
  border: none;
}
.ui-datepicker .ui-state-default {
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: none;
  font-size: 14px;
  line-height: 30px;
  border-radius: 50em;
  text-align: center;
}
.ui-datepicker .ui-state-default:hover {
  background-color: var(--color-primary);
  color: #fff;
}
.ui-datepicker .ui-state-active,
.ui-datepicker .ui-state-highlight {
  color: #fff;
}
.ui-datepicker .ui-state-highlight {
  background-color: #666;
}
.ui-datepicker .ui-state-active {
  background-color: var(--color-primary);
}
.ui-datepicker .ui-state-active {
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
}

.ui-datepicker-header {
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  background: none;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  top: 2px !important;
  border: none !important;
  background: none !important;
  cursor: pointer;
  font-weight: 400;
}
.ui-datepicker-prev span,
.ui-datepicker-next span {
  position: relative;
  background: none !important;
  font-size: 12px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ui-datepicker-prev span:before,
.ui-datepicker-next span:before {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: fontAwesome;
  text-align: center;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  text-indent: 0;
}

.ui-datepicker-prev {
  left: 2px !important;
}
.ui-datepicker-prev span:before {
  content: '\f060';
}
.ui-datepicker-prev:hover span {
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
}

.ui-datepicker-next {
  right: 2px !important;
}
.ui-datepicker-next span:before {
  content: '\f061';
}
.ui-datepicker-next:hover span {
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}

/* 3.19. Reservation Form */
.liquid-form-reservation {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.liquid-form-reservation .input-container {
  display: -webkit-box;
  display: flex;
  min-width: 285px;
  padding: 0 15px;
  margin-bottom: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column wrap;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
          align-items: stretch;
  border-left: 1px solid #d8dbe2;
}
.liquid-form-reservation .input-container:last-child, .liquid-form-reservation .input-container:first-child {
  border: none;
}
.liquid-form-reservation .input-container:first-child {
  padding-left: 0;
}
.liquid-form-reservation .liquid-input-icon-right {
  right: 30px;
}
.liquid-form-reservation .liquid-input-icon-left {
  left: 30px;
  color: var(--color-primary);
}
.liquid-form-reservation input,
.liquid-form-reservation select,
.liquid-form-reservation button,
.liquid-form-reservation .ui-selectmenu-button {
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.liquid-form-reservation input:focus,
.liquid-form-reservation select:focus,
.liquid-form-reservation button:focus,
.liquid-form-reservation .ui-selectmenu-button:focus {
  outline: none;
}
.liquid-form-reservation input,
.liquid-form-reservation select,
.liquid-form-reservation .ui-selectmenu-button {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 0 30px 0 60px;
  background: none;
  border: none;
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #3f4147;
}
.liquid-form-reservation input::-webkit-input-placeholder,
.liquid-form-reservation select::-webkit-input-placeholder,
.liquid-form-reservation .ui-selectmenu-button::-webkit-input-placeholder {
  color: #3f4147;
}
.liquid-form-reservation input::-moz-placeholder,
.liquid-form-reservation select::-moz-placeholder,
.liquid-form-reservation .ui-selectmenu-button::-moz-placeholder {
  color: #3f4147;
}
.liquid-form-reservation input:-moz-placeholder,
.liquid-form-reservation select:-moz-placeholder,
.liquid-form-reservation .ui-selectmenu-button:-moz-placeholder {
  color: #3f4147;
}
.liquid-form-reservation input:-ms-input-placeholder,
.liquid-form-reservation select:-ms-input-placeholder,
.liquid-form-reservation .ui-selectmenu-button:-ms-input-placeholder {
  color: #3f4147;
}
.liquid-form-reservation button {
  text-align: center;
}
.liquid-form-reservation button > span {
  -webkit-box-flex: 1;
          flex: 1 auto;
  -webkit-box-pack: center;
          justify-content: center;
}
.liquid-form-reservation .ui-selectmenu-icon {
  -webkit-box-ordinal-group: 2;
          order: 1;
  display: none;
}
.liquid-form-reservation .ui-selectmenu-button {
  width: 100%;
}

/* 3.20. Tour Form */
.liquid-form-tour {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  padding: 40px 30px 20px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.07);
}
.liquid-form-tour select,
.liquid-form-tour input,
.liquid-form-tour textarea,
.liquid-form-tour .ui-selectmenu-button {
  padding-left: 25px;
  padding-right: 25px;
  border-color: transparent;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.07) inset;
  background-color: #f4f5f7;
}
.liquid-form-tour select:focus,
.liquid-form-tour input:focus,
.liquid-form-tour textarea:focus,
.liquid-form-tour .ui-selectmenu-button:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.07);
}
.liquid-form-tour .input-container {
  -webkit-box-flex: 1;
          flex: 1 auto;
  margin: 10px 0.4%;
}
.liquid-form-tour .input-container:first-child {
  margin-left: 0;
}
.liquid-form-tour .input-container:last-child {
  margin-right: 0;
}
.liquid-form-tour .liquid-tour-hot-destinations {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.liquid-tour-hot-destinations {
  font-size: 0.875em;
}
.liquid-tour-hot-destinations > span,
.liquid-tour-hot-destinations > a {
  margin-right: 15px;
}
.liquid-tour-hot-destinations > span {
  color: #a7a9b8;
}
.liquid-tour-hot-destinations > span i {
  margin-left: 15px;
}
.liquid-tour-hot-destinations > a:after {
  content: '\2758';
  margin-left: 15px;
  color: #d8dbe2;
}
.liquid-tour-hot-destinations > a:last-child:after {
  content: none;
}

/* 3.21. Progressbar */
.liquid-progressbar {
  position: relative;
  margin-top: 2.2em;
  margin-bottom: 3.25em;
}
.liquid-progressbar.values-not-encountering .liquid-progressbar-percentage {
  opacity: 1;
}

.liquid-progressbar-inner {
  position: relative;
  display: block;
  width: 100%;
  height: 15px;
  background-color: #eef0f3;
}

.liquid-progressbar-bar {
  display: inline-block;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  color: #181b31;
  background-color: var(--color-primary);
}

.liquid-progressbar-percentage {
  position: absolute;
  top: -2.2em;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}

.liquid-progressbar-title {
  margin: 0;
  position: absolute;
  top: -2.2em;
  left: 0;
  font-size: 1em;
  color: #808291;
}

/* Progressbar Sizes */
.liquid-progressbar-thin .liquid-progressbar-inner {
  height: 1px;
}

.liquid-progressbar-thick .liquid-progressbar-inner {
  height: 2px;
}

.liquid-progressbar-thicker .liquid-progressbar-inner {
  height: 5px;
}

.liquid-progressbar-sm .liquid-progressbar-inner {
  height: 10px;
}

.liquid-progressbar-lg .liquid-progressbar-inner {
  height: 20px;
}

.liquid-progressbar-xl .liquid-progressbar-inner {
  height: 30px;
}

/* Progressbar Roundness */
.liquid-progressbar-round .liquid-progressbar-inner {
  border-radius: 3px;
}

.liquid-progressbar-circle .liquid-progressbar-inner {
  border-radius: 50em;
}

/* Progressbar Values Positions */
.liquid-progressbar-values-bottom {
  margin-top: 0;
  margin-bottom: 3.5em;
}
.liquid-progressbar-values-bottom .liquid-progressbar-percentage {
  top: auto;
  bottom: -2.2em;
}
.liquid-progressbar-values-bottom .liquid-progressbar-title {
  top: auto;
  bottom: -2.2em;
}

.liquid-progressbar-values-inside .liquid-progressbar-bar {
  color: #fff;
}
.liquid-progressbar-values-inside .liquid-progressbar-title,
.liquid-progressbar-values-inside .liquid-progressbar-percentage {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.liquid-progressbar-values-inside .liquid-progressbar-title {
  left: 15px;
  right: auto;
  white-space: nowrap;
  color: #fff;
}

/* Details Size */
.ld-prgbr-details-sm {
  font-size: 14px;
}

/* Percentage Shape */
.liquid-progressbar-percentage.style-tooltip {
  display: -webkit-inline-box;
  display: inline-flex;
  padding: 6px 10px;
  margin-top: -10px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  background-color: #666;
  line-height: 1.5em;
  color: #fff;
}
.liquid-progressbar-percentage.style-tooltip:after {
  content: '';
  display: inline-block;
  width: 0;
  left: 0;
  position: absolute;
  bottom: -4px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-style: solid;
  border-color: #666 transparent transparent;
}
.liquid-progressbar-percentage.style-tooltip.round {
  border-radius: 4px;
}
.liquid-progressbar-percentage.style-tooltip.circle {
  margin-top: -17px;
  padding: 0;
  width: 38px;
  height: 38px;
  font-size: 12px;
  border-radius: 50em;
}
.liquid-progressbar-percentage.style-tooltip.circle:after {
  bottom: -3px;
}

.liquid-progressbar-count-hide .liquid-progressbar-value {
  display: none;
}

/* Shadow */
.ld-prgbr-bar-shadow .liquid-progressbar-bar {
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.1);
}

/* Orientation */
.ld-prgbr-circle {
  margin-top: 0;
}
.ld-prgbr-circle .liquid-progressbar-inner,
.ld-prgbr-circle .liquid-progressbar-percentage,
.ld-prgbr-circle .liquid-progressbar-bar {
  background: none;
}
.ld-prgbr-circle .liquid-progressbar-percentage,
.ld-prgbr-circle .liquid-progressbar-inner,
.ld-prgbr-circle .liquid-progressbar-bar {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
}
.ld-prgbr-circle .liquid-progressbar-bar {
  display: -webkit-box;
  display: flex;
  width: auto;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  height: auto;
}
.ld-prgbr-circle .liquid-progressbar-bar {
  text-align: center;
}
.ld-prgbr-circle .liquid-progressbar-percentage {
  font-size: 48px;
  font-weight: 700;
  color: #000;
}
.ld-prgbr-circle .liquid-progressbar-details {
  margin-top: 20px;
}
.ld-prgbr-circle .liquid-progressbar-title {
  position: relative;
  top: auto;
  left: auto;
  font-size: 17px;
  text-align: center;
  color: #4E4E4E;
}

/* 3.22. Milestone */
.liquid-milestone {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-box-align: start;
          align-items: flex-start;
  margin-bottom: 2em;
}
.liquid-milestone .liquid-milestone-time {
  margin: 0 15px 0 0;
  -webkit-box-flex: 0;
          flex: 0 auto;
  font-size: 36px;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: var(--color-primary);
}
.liquid-milestone .liquid-milestone-time span {
  color: inherit;
}

.liquid-milestone-content {
  -webkit-box-flex: 1;
          flex: 1 auto;
  overflow: hidden;
}
.liquid-milestone-content h5 {
  margin: 0;
  font-weight: 700;
}
.liquid-milestone-content p {
  margin: 0;
}

/* 3.24. Instagram Feed */
.liquid-ig-feed {
  margin-bottom: 2em;
}

.liquid-ig-feed-list {
  display: -webkit-box;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  list-style: none;
}
.liquid-ig-feed-list > li {
  -webkit-box-flex: 0;
          flex: 0 0 20%;
  position: relative;
}
.liquid-ig-feed-list > li img {
  width: 100%;
  height: auto;
}
.liquid-ig-feed-list > li:hover .liquid-ig-feed-overlay {
  opacity: 1;
  visibility: visible;
}
.liquid-ig-feed-list > li:hover .liquid-ig-feed-overlay i,
.liquid-ig-feed-list > li:hover .liquid-ig-feed-overlay span {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.liquid-ig-feed-list > li:hover .liquid-ig-feed-overlay span {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.liquid-stretch-images .liquid-ig-feed-list img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
[data-list-columns="1"] .liquid-ig-feed-list > li {
  flex-basis: 100%;
}
[data-list-columns="2"] .liquid-ig-feed-list > li {
  flex-basis: 50%;
}
[data-list-columns="3"] .liquid-ig-feed-list > li {
  flex-basis: 33.333333333333333%;
}
[data-list-columns="4"] .liquid-ig-feed-list > li {
  flex-basis: 25%;
}
[data-list-columns="6"] .liquid-ig-feed-list > li {
  flex-basis: 16.666666666666667%;
}
[data-list-gap="2"] .liquid-ig-feed-list {
  margin-left: -1px;
  margin-right: -1px;
}
[data-list-gap="2"] .liquid-ig-feed-list > li {
  padding: 1px;
}
[data-list-gap="4"] .liquid-ig-feed-list {
  margin-left: -2px;
  margin-right: -2px;
}
[data-list-gap="4"] .liquid-ig-feed-list > li {
  padding: 2px;
}
[data-list-gap="6"] .liquid-ig-feed-list {
  margin-left: -3px;
  margin-right: -3px;
}
[data-list-gap="6"] .liquid-ig-feed-list > li {
  padding: 3px;
}
[data-list-gap="8"] .liquid-ig-feed-list {
  margin-left: -4px;
  margin-right: -4px;
}
[data-list-gap="8"] .liquid-ig-feed-list > li {
  padding: 4px;
}

.liquid-ig-feed-overlay {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: var(--color-primary);
  color: #fff;
  -webkit-transition: opacity 0.3s, visibility 0,3s;
  transition: opacity 0.3s, visibility 0,3s;
}
.liquid-ig-feed-overlay i,
.liquid-ig-feed-overlay span {
  display: block;
  opacity: 0;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.liquid-ig-feed-overlay i:only-child,
.liquid-ig-feed-overlay span:only-child {
  margin-bottom: 0;
}
.liquid-ig-feed-overlay i {
  margin-bottom: 0.34em;
  font-size: 50px;
}
.liquid-ig-feed-overlay span {
  text-transform: uppercase;
  letter-spacing: 0.01em;
  font-weight: 700;
}
.liquid-ig-feed-overlay:hover {
  color: #fff;
}

/* 3.25. Twitter Feed */
.liquid-twitter-feed {
  margin-bottom: 2em;
}

.liquid-twitter-feed-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.liquid-twitter-feed-list > li {
  margin-bottom: 0.9375em;
  line-height: 1.7142857143em;
}
.liquid-twitter-feed-list > li:last-child {
  margin-bottom: 0;
}
.liquid-twitter-feed-list p {
  margin-bottom: 0;
}
.liquid-twitter-feed-list blockquote {
  font-style: normal;
  margin: 0;
  font-size: 1em;
}

.liquid-twitter-feed-icon {
  display: block;
  margin-bottom: 0.7142857143em;
  font-size: 28px;
  line-height: 1em;
  color: #1da1f2;
}

.liquid-twitter-feed-list .twitter-tweet > a,
.liquid-tweet-time {
  display: block;
  font-size: 11px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

/* 3.26. Banner */
/* 3.27. Image Groups */
.liquid-img-group-container {
  position: relative;
  margin-bottom: 2em;
}
.liquid-img-group-container[data-custom-animations] {
  opacity: 1;
  visibility: visible;
}
.liquid-img-group-container[data-custom-animations]:not(.ca-initvalues-applied) {
  min-height: 45px;
}
.liquid-img-group-container[data-custom-animations]:before {
  content: '';
  display: inline-block;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -22px;
  background-image: url("data:image/svg+xml;base64,PCEtLSBCeSBTYW0gSGVyYmVydCAoQHNoZXJiKSwgZm9yIGV2ZXJ5b25lLiBNb3JlIEAgaHR0cDovL2dvby5nbC83QUp6YkwgLS0+Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMDAwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjIiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjAuOHMiCiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgICAgICAgICAgPC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  background-size: 44px;
  background-repeat: no-repeat;
  background-position: center;
}
.liquid-img-group-container[data-custom-animations] .liquid-img-group-single {
  opacity: 0;
  visibility: hidden;
}
.liquid-img-group-container[data-custom-animations].ca-initvalues-applied:before {
  content: none;
}
.liquid-img-group-container[data-custom-animations].ca-initvalues-applied .liquid-img-group-single {
  opacity: 1;
  visibility: visible;
}
[data-row-bg]:not(.row-bg-appended) .liquid-img-group-container[data-custom-animations] {
  opacity: 0;
  visibility: hidden;
}

.liquid-img-group-img-container {
  display: -webkit-inline-box;
  display: inline-flex;
  position: relative;
  border-radius: inherit;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.liquid-img-container-inner {
  width: 100%;
  position: relative;
}

.liquid-img-group-single {
  display: block;
  position: relative;
}
.liquid-img-group-single .ld-parallax-wrap,
.liquid-img-group-single .liquid-img-container-inner,
.liquid-img-group-single .liquid-img-group-img-container,
.liquid-img-group-single figure,
.liquid-img-group-single img {
  border-radius: inherit;
}
.liquid-img-group-single .ld-parallax-wrap,
.liquid-img-group-single figure {
  width: 100%;
}
.liquid-img-group-single figure {
  display: inline-block;
  margin: 0 !important;
}
.liquid-img-group-single[data-roundness="2"] {
  border-radius: 2px;
}
.liquid-img-group-single[data-roundness="4"] {
  border-radius: 4px;
}
.liquid-img-group-single[data-roundness="6"] {
  border-radius: 6px;
}
.liquid-img-group-single[data-roundness="8"] {
  border-radius: 8px;
}
.liquid-img-group-single[data-animate-shadow][data-shadow-style="1"].is-in-view .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-animate-shadow][data-shadow-style="1"].is-in-view .liquid-img-container-inner, .liquid-img-group-single[data-shadow-style="1"] .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-shadow-style="1"] .liquid-img-container-inner {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.07);
}
.liquid-img-group-single[data-animate-shadow][data-shadow-style="2"].is-in-view .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-animate-shadow][data-shadow-style="2"].is-in-view .liquid-img-container-inner, .liquid-img-group-single[data-shadow-style="2"] .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-shadow-style="2"] .liquid-img-container-inner {
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);
}
.liquid-img-group-single[data-animate-shadow][data-shadow-style="3"].is-in-view .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-animate-shadow][data-shadow-style="3"].is-in-view .liquid-img-container-inner, .liquid-img-group-single[data-shadow-style="3"] .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-shadow-style="3"] .liquid-img-container-inner {
  box-shadow: 0 30px 70px rgba(0, 0, 0, 0.15);
}
.liquid-img-group-single[data-animate-shadow][data-shadow-style="4"].is-in-view .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-animate-shadow][data-shadow-style="4"].is-in-view .liquid-img-container-inner, .liquid-img-group-single[data-shadow-style="4"] .ld-parallax-wrap.overflow-hidden,
.liquid-img-group-single[data-shadow-style="4"] .liquid-img-container-inner {
  box-shadow: 0 30px 100px rgba(0, 0, 0, 0.17);
}
.liquid-img-group-single[data-animate-shadow] .liquid-img-container-inner {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: box-shadow 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  transition: box-shadow 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}
.liquid-img-group-single[data-animate-shadow] img {
  opacity: 0.00000001;
  -webkit-transition: opacity 0.65s cubic-bezier(0.5, 1, 0.5, 1);
  transition: opacity 0.65s cubic-bezier(0.5, 1, 0.5, 1);
}
.liquid-img-group-single[data-animate-shadow].is-in-view img {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}
.liquid-img-group-single[data-animate-shadow].is-in-view img.ld-lazyload {
  opacity: 0.00000001;
}
.liquid-img-group-single[data-animate-shadow].is-in-view img.ld-lazyload.loaded {
  opacity: 1;
}
.liquid-img-group-single.pos-abs {
  top: 0;
  left: 0;
}
.liquid-img-group-single.custom-height-applied > .liquid-img-group-img-container,
.liquid-img-group-single.custom-height-applied > .liquid-img-group-img-container > .ld-parallax-wrap {
  height: 100%;
}
.liquid-img-group-single.reset-color-adjust-enabled figure {
  -webkit-transition: -webkit-filter 0.3s;
  transition: -webkit-filter 0.3s;
  transition: filter 0.3s;
  transition: filter 0.3s, -webkit-filter 0.3s;
}
div:not(.carousel-item-inner) > .liquid-img-group-container .liquid-img-group-single.reset-color-adjust-enabled:hover figure {
  -webkit-filter: none;
          filter: none;
}
.carousel-item.is-selected .liquid-img-group-single.reset-color-adjust-enabled figure {
  -webkit-filter: none;
          filter: none;
}

.liquid-img-group-browser {
  display: block;
  margin-bottom: 2em;
}
.liquid-img-group-browser .liquid-img-group-img-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.liquid-img-group-browser .liquid-img-group-img-container:before {
  content: '';
  display: inline-block;
  width: 100%;
  padding-top: 5.25%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../img/misc/browser-bar.jpg");
  background-position: center top;
}
.liquid-img-group-browser figure {
  background-size: cover;
}
.liquid-img-group-browser figure img {
  opacity: 0;
  visibility: hidden;
}
.liquid-img-group-browser .liquid-img-group-url {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  width: 50%;
  height: 9.5%;
  position: absolute;
  top: 0;
  left: 20.2%;
  z-index: 2;
  font-size: 14px;
  color: #515151;
}
.liquid-img-group-browser .liquid-img-group-url span {
  color: #cecece;
}

.liquid-img-group-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: relative;
  z-index: 10;
}
.liquid-img-group-content.content-floated-mid-left, .liquid-img-group-content.content-floated-mid-right, .liquid-img-group-content.content-floated-mid {
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.liquid-img-group-content.content-floated-mid {
  left: 50%;
}
.liquid-img-group-content.content-floated-mid-left {
  left: 0;
}
.liquid-img-group-content.content-floated-mid-right {
  left: 100%;
}

.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  pointer-events: none;
  opacity: 0;
}

.block-revealer__content {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  border-radius: inherit;
}
.block-revealer__content img:not(.ld-lazyload) {
  opacity: 1;
}

.compose-mode .liquid-img-group-container[data-custom-animations]:not(.ca-initvalues-applied) {
  min-height: 0;
}
.compose-mode .liquid-img-group-container[data-custom-animations]:before {
  content: none;
}
.compose-mode .liquid-img-group-container[data-custom-animations] .liquid-img-group-single {
  opacity: 1;
  visibility: visible;
}
[data-row-bg]:not(.row-bg-appended) .compose-mode .liquid-img-group-container[data-custom-animations] {
  opacity: 1;
  visibility: visible;
}
.compose-mode .liquid-img-group-single {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  margin: 0 !important;
}
.compose-mode .liquid-img-group-single[data-animate-shadow] img {
  opacity: 1;
}
.compose-mode .liquid-img-group-browser figure img {
  opacity: 1;
  visibility: visible;
}

.lqd-parallax-images .liquid-img-group-single:last-child {
  position: absolute;
  bottom: -10%;
  left: 30%;
}

.lqd-parallax-images-2 .liquid-img-group-container:first-child {
  margin-top: 60px;
}
.lqd-parallax-images-2 .liquid-img-group-container:nth-child(2) {
  position: absolute;
  bottom: 110px;
  left: 20%;
}
.lqd-parallax-images-2 .liquid-img-group-container:last-child {
  position: absolute;
  bottom: 230px;
  left: 36%;
}

.lqd-parallax-images-3 .liquid-img-group-single:nth-child(2), .lqd-parallax-images-3 .liquid-img-group-single:last-child {
  position: absolute;
}
.lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
  top: 0px;
  right: 10%;
  left: 10%;
}
.lqd-parallax-images-3 .liquid-img-group-single:last-child {
  top: 12%;
  right: 23%;
  left: 13%;
}

.lqd-parallax-images-4 .liquid-counter {
  font-family: 'Scheherazade', serif !important;
  font-weight: 400;
  color: #bba07a;
}

.lqd-parallax-images-4-alt .liquid-counter {
  font-family: inherit !important;
}
.lqd-parallax-images-4-alt .liquid-counter-element {
  font-size: 80px;
}

.lqd-parallax-images-6 .liquid-img-group-single:first-of-type {
  position: absolute;
  bottom: -10%;
  left: 30%;
}
.lqd-parallax-images-6 .liquid-img-group-single:nth-of-type(2) {
  position: absolute;
}

.lqd-parallax-images-8 .btn-icon {
  color: #fe506c;
  font-size: 20px;
}
.lqd-parallax-images-8 .btn-icon-solid .btn-icon {
  background: white;
}
.lqd-parallax-images-8 .liquid-img-group-single:last-of-type {
  position: absolute;
  right: 0;
  bottom: -10%;
}

.lqd-parallax-images-9 .liquid-img-group-single {
  position: absolute;
}
.lqd-parallax-images-9 .liquid-img-group-single:nth-child(1) {
  bottom: 50vh;
  left: 10%;
}
.lqd-parallax-images-9 .liquid-img-group-single:nth-child(2) {
  bottom: 0px;
  left: -30%;
}
.lqd-parallax-images-9 .liquid-img-group-single:nth-child(3) {
  bottom: -25vh;
  left: 0px;
}
.lqd-parallax-images-9 .liquid-img-group-single:nth-child(4) {
  right: 35%;
  bottom: 0px;
}
.lqd-parallax-images-9 .liquid-img-group-single:nth-child(5) {
  right: -25%;
  bottom: 10vh;
}

.lqd-parallax-images-11 .liquid-img-group-single:nth-child(1), .lqd-parallax-images-11 .liquid-img-group-single:nth-child(3) {
  position: absolute;
}
.lqd-parallax-images-11 .liquid-img-group-single:nth-child(1) {
  top: 35%;
  left: -80px;
}
.lqd-parallax-images-11 .liquid-img-group-single:nth-child(3) {
  top: 70px;
  right: -120px;
  left: 120px;
}

@media (min-width: 768px) {
  .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
    left: 20%;
  }
  .lqd-parallax-images-3 .liquid-img-group-single:last-child {
    right: 0px;
    left: 22.5%;
  }
}
@media (min-width: 992px) {
  .lqd-parallax-images .liquid-img-group-single:last-child {
    bottom: -13%;
    left: -45%;
  }

  .lqd-parallax-images-2 .liquid-img-group-container:first-child {
    margin-top: 0px;
    margin-right: -40%;
    margin-left: 60%;
  }
  .lqd-parallax-images-2 .liquid-img-group-container:nth-child(2) {
    bottom: 80px;
    left: 30%;
  }
  .lqd-parallax-images-2 .liquid-img-group-container:last-child {
    bottom: 240px;
    left: 17%;
  }

  .lqd-parallax-images-3 .liquid-img-group-single:first-child {
    margin-left: -20%;
  }
  .lqd-parallax-images-3 .liquid-img-group-single:nth-child(2) {
    left: 0;
  }
  .lqd-parallax-images-3 .liquid-img-group-single:last-child {
    left: -8%;
  }

  .lqd-parallax-images-4 .liquid-img-group-container:nth-child(2) {
    margin-top: -120px;
    margin-left: -30px;
  }
  .lqd-parallax-images-4 .liquid-img-group-container:last-child .liquid-img-group-single {
    margin-top: -40%;
    margin-left: -68%;
    left: -80%;
  }

  .lqd-parallax-images-4-alt .liquid-img-group-container:last-child .liquid-img-group-single {
    left: 45%;
  }

  .lqd-parallax-images-5 .txt-container {
    margin-top: -200px;
  }

  .lqd-parallax-images-6 .liquid-img-group-single:first-of-type {
    right: -45%;
    bottom: -13%;
  }
  .lqd-parallax-images-6 .liquid-img-group-single:nth-of-type(2) {
    top: -40%;
    right: -5%;
  }
  .lqd-parallax-images-6 .liquid-img-group-single:last-of-type {
    left: -20%;
  }

  .lqd-parallax-images-7 .liquid-img-group-single:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
  }
  .lqd-parallax-images-7 .liquid-img-group-single:last-of-type {
    margin-right: -100%;
    margin-left: 30%;
    margin-bottom: -200px;
  }

  .lqd-parallax-images-8 .liquid-img-group-single:last-of-type {
    right: 0;
    bottom: -8%;
  }

  .lqd-parallax-images-10 .liquid-img-group-single:first-of-type {
    margin-right: 20vw;
    margin-left: -14vw;
    top: -120px;
  }
  .lqd-parallax-images-10 .liquid-img-group-single:last-of-type {
    margin-top: -70%;
    margin-left: 25%;
  }
}
@media screen and (max-width: 991px) {
  .lqd-parallax-images-5 .txt-container {
    -webkit-transform: none !important;
            transform: none !important;
  }
}
/* 3.28. Pagination */
.page-nav {
  margin-top: 1em;
  margin-bottom: 1em;
}
.page-nav.bordered-top {
  margin-top: 2em;
  padding-top: 45px;
  border-top: 3px solid #181b31;
}

.page-links,
ul.pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  clear: both;
}

ul.pagination {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
ul.pagination .page-numbers,
ul.pagination li {
  display: inline-block;
}
ul.pagination li {
  margin-left: 8px;
  margin-right: 8px;
}
ul.pagination .page-numbers.current {
  color: #16171e;
}

.page-links {
  float: left;
  margin-top: 1.5em;
}
.page-links > span,
.page-links a span {
  display: inline-block;
}
.page-links > a,
.page-links > span {
  padding-left: 8px;
  padding-right: 8px;
}
.page-links .page-links-title {
  padding: 0;
}

/* 3.29. Blog Grid */
.liquid-blog-grid {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.liquid-blog-grid:before, .liquid-blog-grid:after {
  content: none;
}
.liquid-blog-grid > div {
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.liquid-blog-grid > .carousel-container {
  -webkit-box-flex: 1;
          flex: 1 auto;
}

/* 3.31. Masonry */
[data-liquid-masonry=true] {
  -webkit-transition: height 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: height 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}

/* 3.32. Parallax */
.ld-parallax-wrap.overflow-hidden:before, .ld-parallax-wrap.overflow-hidden:after {
  content: '';
  display: table;
}
.ld-parallax-wrap.overflow-hidden:after {
  clear: both;
}

.liquid-parallax-bg {
  position: relative;
}
.liquid-parallax-bg:not(.vc_row):not(.titlebar) {
  overflow: hidden;
}

.liquid-parallax-container,
.liquid-parallax-figure {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.liquid-parallax-container,
.liquid-parallax-figure {
  background-size: inherit;
  background-position: inherit;
  background-repeat: no-repeat;
}

.liquid-parallax-container {
  overflow: hidden;
}

[data-responsive-bg][data-parallax]:not(.loaded) .liquid-parallax-figure {
  background-image: none !important;
}

.lqd-parallax-sentinel {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

/* 3.33. Team Members */
.ld-tm-name,
.ld-tm-pos {
  margin: 0;
  line-height: 1.5em;
}

.ld-tm-name {
  font-size: 24px;
  font-weight: 700;
}
.ld-tm-name.size-sm {
  font-size: 20px;
}

.ld-tm-pos {
  font-size: 12px;
}

.ld-tm-img img {
  width: 100%;
}
.ld-tm-img.text-center img {
  width: auto;
}
.ld-tm-img.text-center img.circle {
  width: 160px;
}

.ld-tm-info .ld-tm-social:empty {
  display: none;
}
.ld-tm-info.ld-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.ld-tm-info.ld-overlay .ld-tm-name,
.ld-tm-info.ld-overlay .ld-tm-pos,
.ld-tm-info.ld-overlay .ld-tm-social {
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
  opacity: 0;
  -webkit-transition: opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.45s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
}
.ld-tm-info.ld-overlay .ld-tm-name {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.ld-tm-info.ld-overlay .ld-tm-pos {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.ld-tm-info .iconbox-xs {
  margin-bottom: 10px;
}
.ld-tm-info .iconbox-xs:last-child {
  margin-bottom: 0;
}
.ld-tm-info .iconbox-xs .iconbox-icon-container {
  font-size: 15px;
}
.ld-tm-info .iconbox-xs h3 {
  font-size: 14px;
}
.ld-tm-info.text-center .iconbox-xs {
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
}
.ld-tm-info.text-center .iconbox-xs h3 {
  -webkit-box-flex: 0;
          flex: 0 auto;
}

.ld-tm {
  margin-bottom: 30px;
  color: #181b31;
}
.ld-tm h1, .ld-tm h2, .ld-tm h3, .ld-tm h4, .ld-tm h5, .ld-tm h6, .ld-tm a {
  color: inherit;
}
.ld-tm a:hover {
  color: var(--color-primary);
}
.ld-tm .ld-tm-pos.color-primary {
  color: var(--color-primary);
}
.ld-tm.text-light {
  color: #fff;
}
.ld-tm.text-light .ld-overlay {
  background-color: rgba(24, 27, 49, 0.9);
}
.ld-tm:hover .ld-overlay {
  opacity: 1;
  visibility: visible;
}
.ld-tm:hover .ld-overlay .ld-tm-name {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.ld-tm:hover .ld-overlay .ld-tm-pos {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.ld-tm:hover .ld-overlay .ld-tm-social {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.ld-tm:hover .ld-overlay .ld-tm-name,
.ld-tm:hover .ld-overlay .ld-tm-pos,
.ld-tm:hover .ld-overlay .ld-tm-social {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.ld-tm:hover .ld-overlay .ld-tm-pos {
  opacity: 0.55;
}
.ld-tm.text-light:hover .ld-overlay .ld-tm-pos {
  opacity: 1;
}
.d-block{
  display: block;
}
.ld-tm-circ {
  position: relative;
  padding-bottom: 100%;
}
.ld-tm-circ .ld-tm-avatar {
  position: absolute;
  border-radius: 50em;
}
.ld-tm-circ .ld-tm-avatar figure {
  border-radius: inherit;
  box-shadow: 0 15px 70px rgba(0, 0, 0, 0.13);
}
.ld-tm-circ .ld-tm-avatar img {
  width: 70px;
  height: auto;
  border-radius: inherit;
}
.ld-tm-circ .ld-tm-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  background: -webkit-gradient(linear, right top, left top, from(#ffa5cd), to(#ffba83));
  background: linear-gradient(270deg, #ffa5cd 0%, #ffba83 100%);
}
.ld-tm-circ .ld-tm-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ld-tm-circ .ld-tm-container > div {
  position: absolute;
  border-radius: 50em;
}

.ld-tm-circ-outer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.ld-tm-circ-outer .ld-tm-bg {
  opacity: 0.1;
}

.ld-tm-circ-middle {
  width: 66.67%;
  height: 66.67%;
  top: 16.665%;
  left: 16.665%;
  z-index: 1;
}
.ld-tm-circ-middle .ld-tm-bg {
  opacity: 0.2;
}

.ld-tm-circ-inner {
  width: 31.9%;
  height: 31.9%;
  top: 34.05%;
  left: 34.05%;
  z-index: 2;
}
.ld-tm-circ-inner .ld-tm-bg {
  opacity: 0.3;
}

.lqd-parallax-team-members .liquid-img-group-container:first-child {
  top: -80px;
  left: -90%;
}
.lqd-parallax-team-members .liquid-img-group-container:nth-child(2) {
  margin-top: -400px;
}
.lqd-parallax-team-members .liquid-img-group-container:last-child {
  margin-top: -300px;
  left: -90%;
}

/* 3.34. Portfolios */
.ld-pf-btns {
  position: relative;
  z-index: 3;
}
.pf-btns-mid .ld-pf-btns {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.ld-pf-btn {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 46px;
  height: 46px;
  font-size: 18px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-transition-property: background, color, border;
  transition-property: background, color, border;
  will-change: transform, opacity;
}

/* Button Fills */
.ld-pf-btn-brd {
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #000;
}
.ld-pf-btn-brd:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.ld-pf-btn-solid {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.ld-pf-btn-solid:hover {
  background-color: #000;
}

/* Border Widths */
.ld-pf-btn-brd-thick {
  border-width: 1.6px;
}

.title-size-48 .ld-pf-title {
  font-size: 48px;
  line-height: 0.8333333333em;
  margin-bottom: 0.3541666667em;
}
.title-size-42 .ld-pf-title {
  font-size: 42px;
}
.title-size-36 .ld-pf-title {
  font-size: 36px;
}
.title-size-32 .ld-pf-title {
  font-size: 32px;
}
.title-size-30 .ld-pf-title {
  font-size: 30px;
  letter-spacing: -0.01em;
}
.title-size-26 .ld-pf-title {
  font-size: 26px;
}
.title-size-24 .ld-pf-title {
  font-size: 24px;
}
.title-size-18 .ld-pf-title {
  font-size: 18px;
}

.ld-pf-category {
  position: relative;
  z-index: 3;
  font-size: 16px;
}
.ld-pf-category .read-more {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
}
.ld-pf-category p {
  margin: 0;
}
.ld-pf-category.size-lg {
  font-size: 15px;
}
.ld-pf-category.size-md {
  font-size: 14px;
}
.ld-pf-category.size-sm {
  font-size: 12px;
}

.ld-pf-category-item {
  position: relative;
}

.ld-pf-category-lined {
  padding-left: 2.5em;
}
.ld-pf-category-lined:before, .ld-pf-category-lined:after {
  content: '';
  display: inline-block;
  width: 2em;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.5px;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  background-color: #000;
}
.ld-pf-category-lined:after {
  background-color: var(--color-primary);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ld-pf-category-lined .read-more {
  left: 2.5em;
}
.ld-pf-category-lined .read-more[data-split-text=true] {
  opacity: 0;
}
.ld-pf-category-lined .read-more .split-inner {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 0;
}
.ld-pf-category-lined .read-more.split-text-applied {
  opacity: 1;
}

/*
	All css styles for hover, etc will be applied to the figure inside .liquid-of-image
*/
.ld-pf-image {
  position: relative;
  overflow: hidden;
}
.ld-pf-image figure {
  height: 100%;
  background-position: center;
  background-size: cover;
  -webkit-transition: -webkit-transform 0.3s linear, -webkit-filter 0.3s linear;
  transition: -webkit-transform 0.3s linear, -webkit-filter 0.3s linear;
  transition: transform 0.3s linear, filter 0.3s linear;
  transition: transform 0.3s linear, filter 0.3s linear, -webkit-transform 0.3s linear, -webkit-filter 0.3s linear;
}
.ld-pf-image .liquid-parallax-figure {
  -webkit-transition: -webkit-filter 0.3s linear;
  transition: -webkit-filter 0.3s linear;
  transition: filter 0.3s linear;
  transition: filter 0.3s linear, -webkit-filter 0.3s linear;
}
.ld-pf-image img {
  width: 100%;
}
.ld-pf-image .liquid-parallax-bg > .ld-lazyload {
  opacity: 0;
}
.ld-pf-image .ld-pf-bg {
  z-index: 2;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.pf-details-inside .ld-pf-image img {
  visibility: hidden;
}
.ld-pf-image.shadowed {
  box-shadow: 0 12px 60px rgba(0, 0, 0, 0.12);
}

.ld-pf-details {
  display: -webkit-box;
  display: flex;
  padding: 45px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  position: relative;
  z-index: 2;
}
.ld-pf-details > * {
  margin: 0;
  line-height: 1.25em;
  color: inherit;
}
.ld-pf-details h1, .ld-pf-details h2, .ld-pf-details h3, .ld-pf-details h4, .ld-pf-details h5, .ld-pf-details h6 {
  color: #000;
}
.ld-pf-details h3 {
  margin-bottom: 0.15em;
}
.ld-pf-details .split-inner {
  display: inline-block;
}
.pf-details-h-mid .ld-pf-details {
  -webkit-box-align: center;
          align-items: center;
}
.pf-details-h-str .ld-pf-details {
  -webkit-box-align: start;
          align-items: flex-start;
}
.pf-details-h-end .ld-pf-details {
  -webkit-box-align: end;
          align-items: flex-end;
}
.pf-details-v-str .ld-pf-details {
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.pf-details-v-mid .ld-pf-details {
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
}
.pf-details-v-end .ld-pf-details {
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.pf-details-inside .ld-pf-details {
  position: absolute;
  top: 0;
  left: 0;
}
.pf-details-full .ld-pf-details {
  width: 100%;
  height: 100%;
}
.pf-details-boxed .ld-pf-details {
  width: 75%;
  padding: 30px;
}
.pf-details-w-auto .ld-pf-details {
  width: auto;
}
.pf-details-circle .ld-pf-details {
  width: 260px;
  height: 260px;
  padding: 2em 3em 3.5em 3.5em;
  border-radius: 50em;
}
.pf-details-h-mid.pf-details-boxed .ld-pf-details {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.pf-details-h-end.pf-details-boxed .ld-pf-details {
  left: auto;
  right: 0;
}
.pf-details-v-mid.pf-details-boxed .ld-pf-details {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.pf-details-v-end.pf-details-boxed .ld-pf-details {
  top: auto;
  bottom: 0;
}
.pf-details-v-mid.pf-details-h-mid.pf-details-boxed .ld-pf-details {
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.pf-details-pull-up .ld-pf-details {
  margin-top: -95px;
}
.pf-details-pull-up-half .ld-pf-details {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.pf-details-pull-down .ld-pf-details {
  margin-bottom: -50px;
}
.pf-details-pull-left .ld-pf-details {
  margin-left: -35px;
}
.pf-details-pull-right .ld-pf-details {
  margin-left: 30px;
}
.pf-details-inside [data-hover3d=true] .ld-pf-details {
  -webkit-transform: translate3d(0, 0, 0.01px);
          transform: translate3d(0, 0, 0.01px);
  -webkit-transform-style: inherit;
          transform-style: inherit;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: inherit;
          perspective: inherit;
}

.ld-pf-details-inner {
  display: inherit;
  width: 100%;
  height: 100%;
  -webkit-box-align: inherit;
          align-items: inherit;
  -webkit-box-pack: inherit;
          justify-content: inherit;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: inherit;
  position: relative;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.ld-pf-details-inner > :first-child {
  margin-top: 0;
}
.ld-pf-details-inner > :last-child {
  margin-bottom: 0;
}
.ld-pf-details-inner .ld-pf-details-inner {
  width: auto !important;
  height: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.pf-contents-mid .ld-pf-details-inner {
  align-self: center;
  margin: auto;
}
.pf-details-inner-full .ld-pf-details-inner {
  width: 100%;
  height: 100%;
}
.pf-details-visible .ld-pf-details-inner {
  opacity: 1;
  visibility: visible;
}
.pf-details-inside [data-hover3d=true] .ld-pf-details-inner {
  -webkit-transform: translate3d(0, 0, 50px) scale(0.78);
          transform: translate3d(0, 0, 50px) scale(0.78);
}

.ld-pf-item .ld-pf-bg {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  will-change: transform, opacity;
}
.ld-pf-item.pf-details-visible .ld-pf-bg {
  opacity: 1;
}
.ld-pf-item.pf-bg-shadow .ld-pf-bg {
  box-shadow: 0 13px 50px rgba(0, 0, 0, 0.1);
}
.ld-pf-item.pf-bg-hidden .ld-pf-bg {
  opacity: 0;
}

.ld-pf-wrap[data-hover3d=true] {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1200px;
          perspective: 1200px;
  overflow: visible;
}

[data-hover3d] .ld-pf-inner {
  -webkit-transform-style: inherit;
          transform-style: inherit;
  -webkit-backface-visibility: inherit;
          backface-visibility: inherit;
  -webkit-perspective: inherit;
          perspective: inherit;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.ld-pf-item {
  position: relative;
  margin-bottom: 30px;
  border-radius: 0;
}
.ld-pf-item div,
.ld-pf-item figure,
.ld-pf-item img {
  border-radius: inherit;
}
.ld-pf-item .liquid-overlay-link {
  z-index: 2;
}
.ld-pf-item time {
  font-size: 12px;
}
.ld-pf-item [data-split-text]:not(.split-text-applied) {
  visibility: hidden;
}
.flickity-equal-cells .carousel-item .ld-pf-item {
  height: 100%;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  margin-bottom: 0;
}
.flickity-equal-cells .carousel-item .ld-pf-item.pf-details-inside .ld-pf-image,
.flickity-equal-cells .carousel-item .ld-pf-item.pf-details-inside .ld-pf-inner {
  height: 100%;
}
.flickity-equal-cells .carousel-item .ld-pf-item.pf-hover-shadow {
  height: calc(100% - 50px);
  max-height: 550px;
}
.ld-pf-item.pf-details-inside {
  overflow: hidden;
}
.ld-pf-item:hover .ld-pf-bg {
  opacity: 1;
}
.ld-pf-item:hover .ld-pf-details-inner {
  opacity: 1;
  visibility: visible;
}
.ld-pf-item:hover .ld-pf-category-lined:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.ld-pf-item:hover .pf-extra-arrow {
  height: 32px;
  margin-bottom: 1em;
}
.ld-pf-item:hover .pf-extra-arrow line,
.ld-pf-item:hover .pf-extra-arrow polyline {
  stroke-dashoffset: 0;
  -webkit-transition: stroke-dasharray 0.6s cubic-bezier(0.23, 1, 0.32, 1), stroke-dashoffset 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: stroke-dasharray 0.6s cubic-bezier(0.23, 1, 0.32, 1), stroke-dashoffset 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.pf-carousel-equal-height .carousel-item {
  max-height: 550px;
}

.ld-pf-semiround {
  border-radius: 4px;
}

.ld-pf-round {
  border-radius: 8px;
}

.ld-pf-circle {
  border-radius: 50em;
}

.liquid-portfolio-list-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}
.liquid-portfolio-list-row:before, .liquid-portfolio-list-row:after {
  content: none;
}

.pf-extra-arrow {
  position: relative;
  height: 0;
  -webkit-transition: height 0.3s cubic-bezier(0.86, 0, 0.07, 1), margin 0.3s;
  transition: height 0.3s cubic-bezier(0.86, 0, 0.07, 1), margin 0.3s;
}
.pf-extra-arrow line,
.pf-extra-arrow polyline {
  -webkit-transition: stroke-dasharray 0.25s cubic-bezier(0.23, 1, 0.32, 1), stroke-dashoffset 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  transition: stroke-dasharray 0.25s cubic-bezier(0.23, 1, 0.32, 1), stroke-dashoffset 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
.pf-extra-arrow line,
.pf-extra-arrow polyline {
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
}

.grid-stamp {
  position: absolute;
  top: 0;
  left: 0;
}
.grid-stamp.is-right {
  left: auto;
  right: 0;
}

@media screen and (max-width: 768px) {
  .grid-stamp:not([class*=col-xs-]), .grid-stamp.col-xs-12 {
    display: none;
  }
}
.ld-pf-item.ld-pf-light {
  color: rgba(255, 255, 255, 0.7);
}
.ld-pf-item.ld-pf-light h1, .ld-pf-item.ld-pf-light h2, .ld-pf-item.ld-pf-light h3, .ld-pf-item.ld-pf-light h4, .ld-pf-item.ld-pf-light h5, .ld-pf-item.ld-pf-light h6 {
  color: #fff;
}
.ld-pf-item.ld-pf-light a,
.ld-pf-item.ld-pf-light .ld-pf-details,
.ld-pf-item.ld-pf-light .ld-pf-details-inner {
  color: inherit;
}
.ld-pf-item.ld-pf-light a:hover {
  color: #fff;
}
.ld-pf-item.ld-pf-light .ld-pf-bg {
  background-color: rgba(0, 0, 0, 0.8);
}
.ld-pf-item.ld-pf-light .pf-extra-arrow svg {
  fill: #fff;
}
.ld-pf-item.ld-pf-light .ld-pf-category-lined:before {
  background-color: #fff;
}
.ld-pf-item.ld-pf-light .ld-pf-btn {
  color: #fff;
}
.ld-pf-item.ld-pf-light .ld-pf-btn-brd {
  border-color: rgba(255, 255, 255, 0.5);
}
.ld-pf-item.ld-pf-light .ld-pf-btn-brd:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}
.ld-pf-item.ld-pf-light .ld-pf-btn-solid {
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
}
.ld-pf-item.ld-pf-light .ld-pf-btn-solid:hover {
  background-color: #fff;
}
.ld-pf-item.ld-pf-light-alt {
  color: #fff;
}

.ld-pf-item.ld-pf-dark {
  color: inherit;
}
.ld-pf-item.ld-pf-dark h1, .ld-pf-item.ld-pf-dark h2, .ld-pf-item.ld-pf-dark h3, .ld-pf-item.ld-pf-dark h4, .ld-pf-item.ld-pf-dark h5, .ld-pf-item.ld-pf-dark h6 {
  color: #000;
}
.ld-pf-item.ld-pf-dark a {
  color: rgba(0, 0, 0, 0.5);
}
.ld-pf-item.ld-pf-dark a:hover {
  color: #000;
}
.ld-pf-item.ld-pf-dark .ld-pf-btn {
  color: #fff;
}
.ld-pf-item.ld-pf-dark .ld-pf-btn:hover {
  color: #fff;
}
.ld-pf-item.ld-pf-dark-alt {
  color: #000;
}

.pf-hover-blurimage:hover .ld-pf-image figure {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.pf-hover-masktext .split-unit {
  overflow: hidden;
}
.pf-hover-masktext .split-inner {
  display: block;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.pf-hover-shadow .ld-pf-image {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.pf-hover-shadow:hover .ld-pf-image {
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.15);
}

.pf-hover-shadow-alt:hover .ld-pf-image {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.pf-hover-shadow-alt-2:hover .ld-pf-image {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.pf-hover-img-border .ld-pf-image {
  -webkit-perspective: 1200px;
          perspective: 1200px;
  -webkit-transition: -webkit-clip-path 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-clip-path 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transition: clip-path 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transition: clip-path 0.8s cubic-bezier(0.23, 1, 0.32, 1), -webkit-clip-path 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-clip-path: inset(0px);
          clip-path: inset(0px);
}
.pf-hover-img-border:hover .ld-pf-image {
  -webkit-clip-path: inset(10px);
          clip-path: inset(10px);
}

.ld-pf-item.hover-3d {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1200px;
          perspective: 1200px;
  overflow: visible;
}
.ld-pf-item.hover-3d .liquid-overlay-link {
  -webkit-transform: translateZ(60px) scale(0.85);
          transform: translateZ(60px) scale(0.85);
}
.ld-pf-item.hover-3d .ld-pf-bg {
  -webkit-transform: translateZ(1px);
          transform: translateZ(1px);
}
.ld-pf-item.hover-3d:hover {
  z-index: 2;
}

.pf-hover-rise .ld-pf-image {
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.pf-hover-rise .ld-pf-image.shadowed {
  -webkit-transition: box-shadow 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: box-shadow 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.pf-hover-rise:hover .ld-pf-image {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.pf-hover-rise:hover .ld-pf-image.shadowed {
  box-shadow: 0 25px 70px rgba(0, 0, 0, 0.1);
}

.ld-pf-single-2 .pf-single-contents .carousel-item img {
  width: 100%;
}

.pf-single-title {
  font-size: 48px;
  line-height: 1em;
}
.pf-single-title.size-xl {
  font-size: 100px;
}
.pf-single-title.size-lg {
  font-size: 60px;
}
.pf-single-title:last-child {
  margin-bottom: -0.2em !important;
}

.pf-single-cat {
  font-size: 12px;
  color: inherit;
}

.pf-info {
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  font-size: 16px;
  line-height: 1.2em;
}
.pf-info > span {
  display: inline-block;
  position: relative;
  -webkit-box-flex: 1;
          flex-grow: 1;
  flex-basis: 47%;
}
.pf-info > span:nth-child(3n + 1) {
  margin-left: 0;
}
.pf-info small {
  display: block;
}
.pf-info h5 {
  font-size: 1em;
}
.pf-info hr {
  width: 50px;
  position: absolute;
  top: -2.1875em;
  left: 0;
  margin: 0;
  border-color: #181b31;
}

.pf-single-header {
  margin-bottom: 2em;
}
.pf-single-header h4 {
  font-size: 24px;
}
.pf-single-header.bg-solid {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
}

.post-nav.pf-nav .nav-subtitle {
  font-size: 16px;
}

.pf-related-posts {
  padding-top: 2em;
}

.pf-related {
  margin-bottom: 2em;
  position: relative;
}

.pf-related-title {
  margin-bottom: 0.5em;
  position: relative;
}
.pf-related-title a {
  color: inherit;
}
.pf-related-title .split-inner {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}
.pf-related-title .title-shad {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-primary);
}

.pf-related-cat {
  font-size: 12px;
}
.pf-related-cat li {
  position: relative;
  z-index: 2;
}

.pf-related-alt {
  padding-bottom: 69.5%;
  color: #fff;
}
.pf-related-alt h2,
.pf-related-alt a {
  color: inherit;
}
.pf-related-alt h2 {
  line-height: 1em;
}
.pf-related-alt figure,
.pf-related-alt header {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.pf-related-alt > figure {
  background-size: cover;
  background-position: center;
}
.pf-related-alt > figure:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.pf-related-alt img {
  visibility: hidden;
}
.pf-related-alt header {
  display: -webkit-box;
  display: flex;
  padding: 2em 25%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  align-content: center;
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
}
.pf-related-alt:hover > figure:after {
  opacity: 0;
}

/* 3.35. Blog */
.blog-single-cover {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  color: rgba(24, 27, 49, 0.5);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1200px;
          perspective: 1200px;
}
.blog-single-cover .blog-single-media {
  background-size: cover;
}
.blog-single-cover .blog-single-media:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 101%;
  position: absolute;
  top: 0;
  left: 0;
}
.blog-single-cover .blog-single-media[data-responsive-bg] > img {
  visibility: hidden;
}
.blog-single-cover .entry-title,
.blog-single-cover a {
  color: #181b31;
}
.blog-single-cover .ld-parallax-wrap {
  overflow: visible !important;
}
.blog-single-cover .lightbox-link svg {
  stroke: #181b31;
}
.blog-single-cover .liquid-parallax-bg > img {
  visibility: hidden;
}
.blog-single-cover .carousel-item {
  padding-left: 0;
  padding-right: 0;
}
.blog-single-cover .flickity-button {
  border-color: #fefefe;
  color: #fefefe;
}
.blog-single-cover .flickity-button:before {
  background-color: currentColor;
}
.blog-single-cover .flickity-button svg {
  stroke: currentColor;
}
.blog-single-cover .flickity-button:hover svg {
  stroke: #000;
}
.blog-single-cover.scheme-dark .blog-single-media:after {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.6)), to(transparent));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, transparent 100%);
}
.blog-single-cover.scheme-light {
  background-color: #12131a;
  color: rgba(255, 255, 255, 0.5);
}
.blog-single-cover.scheme-light .blog-single-media:after {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(20, 22, 34, 0.6)), to(transparent));
  background-image: linear-gradient(to right, rgba(20, 22, 34, 0.6) 0%, transparent 100%);
}
.blog-single-cover.scheme-light .entry-title,
.blog-single-cover.scheme-light a {
  color: #fff;
}
.blog-single-cover.scheme-light .post-meta {
  color: inherit;
}
.blog-single-cover.scheme-light .post-meta a {
  color: #fff;
}
.blog-single-cover.scheme-light .lightbox-link svg {
  stroke: #fff;
}
.blog-single-cover.scheme-light .flickity-prev-next-button {
  color: #fff;
}
.blog-single-cover.scheme-light .flickity-prev-next-button svg {
  stroke: #fff;
}
.blog-single-cover[data-inview=true]:before {
  content: '';
  display: inline-block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url(../img/spinners/spinner-4-light.svg);
  background-size: 35px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.blog-single-cover[data-inview=true] .blog-single-media {
  -webkit-transform: translateZ(150px);
          transform: translateZ(150px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, -webkit-transform 1s cubic-bezier(0.06, 0.78, 0.35, 1);
  transition: opacity 0.5s, -webkit-transform 1s cubic-bezier(0.06, 0.78, 0.35, 1);
  transition: transform 1s cubic-bezier(0.06, 0.78, 0.35, 1), opacity 0.5s;
  transition: transform 1s cubic-bezier(0.06, 0.78, 0.35, 1), opacity 0.5s, -webkit-transform 1s cubic-bezier(0.06, 0.78, 0.35, 1);
}
.blog-single-cover[data-inview=true] .blog-single-media:after {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.blog-single-cover[data-inview=true] .post-meta > span,
.blog-single-cover[data-inview=true] .blog-single-title .split-unit {
  -webkit-transform: translateY(80px);
          transform: translateY(80px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.8s cubic-bezier(0.06, 0.78, 0.35, 1), -webkit-transform 0.8s cubic-bezier(0.06, 0.78, 0.35, 1);
  transition: opacity 0.8s cubic-bezier(0.06, 0.78, 0.35, 1), -webkit-transform 0.8s cubic-bezier(0.06, 0.78, 0.35, 1);
  transition: transform 0.8s cubic-bezier(0.06, 0.78, 0.35, 1), opacity 0.8s cubic-bezier(0.06, 0.78, 0.35, 1);
  transition: transform 0.8s cubic-bezier(0.06, 0.78, 0.35, 1), opacity 0.8s cubic-bezier(0.06, 0.78, 0.35, 1), -webkit-transform 0.8s cubic-bezier(0.06, 0.78, 0.35, 1);
}
.blog-single-cover[data-inview=true] .blog-single-title {
  visibility: hidden;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(0) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(1) {
  -webkit-transition-delay: 0.0666666667s;
          transition-delay: 0.0666666667s;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(2) {
  -webkit-transition-delay: 0.1333333333s;
          transition-delay: 0.1333333333s;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(3) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(4) {
  -webkit-transition-delay: 0.2666666667s;
          transition-delay: 0.2666666667s;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(5) {
  -webkit-transition-delay: 0.3333333333s;
          transition-delay: 0.3333333333s;
}
.blog-single-cover[data-inview=true] .blog-single-title .split-unit:nth-child(6) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.blog-single-cover[data-inview=true] .blog-single-title.split-text-applied {
  visibility: visible;
}
.blog-single-cover[data-inview=true] .post-meta > span {
  visibility: hidden;
}
.blog-single-cover[data-inview=true] .post-meta > span:nth-child(1) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.blog-single-cover[data-inview=true] .post-meta > span:nth-child(2) {
  -webkit-transition-delay: 0.2666666667s;
          transition-delay: 0.2666666667s;
}
.blog-single-cover[data-inview=true] .post-meta > span:nth-child(3) {
  -webkit-transition-delay: 0.3333333333s;
          transition-delay: 0.3333333333s;
}
.blog-single-cover[data-inview=true] .post-meta > span:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link svg path {
  stroke-dasharray: 228;
  stroke-dashoffset: 228;
  -webkit-transition: stroke-dashoffset 1s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: stroke-dashoffset 1s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link svg path:last-child {
  -webkit-transition-duration: 5s;
          transition-duration: 5s;
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link span {
  display: inline-block;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  opacity: 0;
  -webkit-transition: opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .lightbox-link + .carousel-nav {
  margin-top: 1em;
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .carousel-nav {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 0;
  -webkit-transition: opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button {
  border-color: #fefefe;
  color: #fefefe;
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button:before {
  background-color: currentColor;
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button svg {
  stroke: currentColor;
}
.blog-single-cover[data-inview=true] .blog-single-details-extra .flickity-button:hover svg {
  stroke: #000;
}
.blog-single-cover.is-in-view:before {
  opacity: 0;
  visibility: hidden;
}
.blog-single-cover.is-in-view .post-meta > span,
.blog-single-cover.is-in-view .blog-single-title .split-unit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.blog-single-cover.is-in-view .blog-single-media {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  opacity: 1;
  visibility: visible;
}
.blog-single-cover.is-in-view .blog-single-media:after {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.blog-single-cover.is-in-view .blog-single-details-extra .lightbox-link svg path {
  stroke-dashoffset: 0;
}
.blog-single-cover.is-in-view .blog-single-details-extra .lightbox-link span {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.blog-single-cover.is-in-view .blog-single-details-extra .carousel-nav {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.blog-single-title {
  margin-top: 0;
  margin-bottom: 0.6em;
  letter-spacing: -0.01em;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.post-meta {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  margin-bottom: 2em;
  font-size: 16px;
  line-height: 1.5em;
}
.post-meta .block {
  display: block;
  font-size: 12px;
}
.post-meta > span {
  display: inline-block;
}
.post-meta > span + span {
  margin-left: 3.25em;
}
.text-center .post-meta {
  -webkit-box-pack: center;
          justify-content: center;
}

.blog-single-details-extra {
  overflow: hidden;
}
.blog-single-details-extra h3 {
  margin-top: 0;
}
.blog-single-details-extra .lightbox-link {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  font-size: 12px;
}
.blog-single-details-extra .lightbox-link svg {
  margin-right: 20px;
}
.blog-single-details-extra .carousel-thumbs {
  width: 50%;
  margin: 0 auto;
  position: relative;
}
.blog-single-details-extra .carousel-thumbs img {
  width: 100%;
  border-radius: 50em;
  border: 2px solid transparent;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.blog-single-details-extra .carousel-thumbs .flickity-prev-next-button {
  border: none;
}
.blog-single-details-extra .carousel-thumbs .flickity-prev-next-button.previous {
  left: -45px;
}
.blog-single-details-extra .carousel-thumbs .flickity-prev-next-button.next {
  right: -45px;
}
.blog-single-details-extra .carousel-thumbs .carousel-item {
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  cursor: pointer;
}
.blog-single-details-extra .carousel-thumbs .is-nav-selected {
  opacity: 1;
}
.blog-single-details-extra .carousel-thumbs .is-nav-selected img {
  border-color: #fff;
}

.blog-single-content {
  padding-top: 4.3181818182em;
}
.blog-single-content:before, .blog-single-content:after {
  content: '';
  display: table;
}
.blog-single-content:after {
  clear: both;
}
.blog-single-content .ld-container {
  width: 100%;
}
.blog-single-content .liquid-img-group-container,
.blog-single-content figure {
  margin-top: 3.75em;
  margin-bottom: 1.5em;
}
.blog-single-content blockquote {
  text-align: center;
}
.blog-single-content blockquote cite:before {
  left: 50%;
  margin-left: -15px;
}

.blog-single-footer,
.post-author,
.post-nav {
  margin: 0.2em 0 1.25em;
}

.blog-single-footer {
  display: -webkit-box;
  display: flex;
}
.blog-single-footer > ul,
.blog-single-footer > span {
  margin: 2.5em 0;
}

.post-categories,
.tags-links {
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  list-style: none;
}
.post-categories > li,
.post-categories > a,
.tags-links > li,
.tags-links > a {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 0.2em;
}
.post-categories li a,
.post-categories > a,
.tags-links li a,
.tags-links > a {
  padding: 0.5em 1.5em;
  margin-bottom: 0.5em;
  -webkit-box-align: center;
          align-items: center;
  border: 1px solid #16171e;
  color: #16171e;
  opacity: 0.6;
}
.post-categories li a:hover,
.post-categories > a:hover,
.tags-links li a:hover,
.tags-links > a:hover {
  opacity: 1;
}

.share-links {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.share-links > span {
  margin-right: 1em;
}
.share-links .social-icon {
  display: inline-block;
}

.post-author {
  padding: 2.25em 0;
  border-top: 1px solid #d8dbe2;
  border-bottom: 1px solid #d8dbe2;
}
.post-author figure {
  float: left;
  margin-right: 30px;
}
.post-author figure img {
  border-radius: 50em;
}
.post-author p {
  margin: 0;
  font-size: 1em;
  line-height: 1.6em;
}
.post-author h3,
.post-author h6 {
  margin: 0;
  line-height: 1.5em;
}
.post-author h3 {
  font-size: 22px;
  font-weight: 700;
  color: #181b31;
}
.post-author h3 a {
  color: inherit;
}
.post-author h6 {
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #a7a9b8;
}
.post-author .post-author-info {
  overflow: hidden;
}

.post-nav {
  display: -webkit-box;
  display: flex;
  padding: 2.25em 0;
  font-size: 1.0909090909em;
  line-height: 1.25em;
}
.post-nav a {
  display: inline-block;
  position: relative;
}
.post-nav .nav-subtitle {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.post-nav .nav-subtitle:last-child {
  margin-bottom: 0;
}
.post-nav .nav-title {
  color: #16171e;
}
.post-nav .nav-next {
  text-align: right;
}
.post-nav svg {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0.95, 0.3, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 0.95, 0.3, 1);
  transition: transform 0.3s cubic-bezier(0, 0.95, 0.3, 1);
  transition: transform 0.3s cubic-bezier(0, 0.95, 0.3, 1), -webkit-transform 0.3s cubic-bezier(0, 0.95, 0.3, 1);
}
.post-nav .nav-previous {
  padding-left: 30px;
}
.post-nav .nav-previous svg {
  left: -40px;
}
.post-nav .nav-previous:hover svg {
  -webkit-transform: translateX(-7px);
          transform: translateX(-7px);
}
.post-nav .nav-next {
  padding-right: 30px;
}
.post-nav .nav-next svg {
  right: -40px;
}
.post-nav .nav-next:hover svg {
  -webkit-transform: translateX(7px);
          transform: translateX(7px);
}

.related-posts > .container > .row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.related-posts > .container > .row > div {
  -webkit-box-flex: 1;
          flex: 1 0;
  flex-basis: 25%;
}
.related-posts > .container > .row > .col-md-12 {
  flex-basis: 100%;
}

.related-post .related-post-title {
  font-size: 23px;
  line-height: 1.5em;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.single-post.has-sidebar .sidebar-container {
  margin-top: 100px;
}
.single-post.has-sidebar .contents-container .related-posts > .container {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}

.blog-single > :last-child {
  margin-bottom: 0;
}

.post-password-form input {
  display: -webkit-inline-box;
  display: inline-flex;
  height: 50px;
  padding: 5px 2em;
  border: none;
  border-radius: 3px;
  background: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-weight: 400;
}
.post-password-form input[type=password] {
  border: 1px solid #eaeaea;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.post-password-form input[type=password]:focus {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  outline: none;
}
.post-password-form input[type=submit] {
  background-color: var(--color-primary);
  color: #fff;
}

.comments-area:last-child {
  margin-bottom: 2.25em;
}

.comment-respond {
  margin: 0.2em 0 0;
}
.comment-respond.filled {
  padding: 2.75em 0 3em;
  background-color: #f4f5f8;
}

.comment-form {
  margin-top: 3em;
  font-size: 16px;
}
.comment-form input,
.comment-form textarea {
  position: relative;
  z-index: 1;
  width: 100%;
  border: none;
  position: relative;
  background-color: transparent;
  line-height: 1.5em;
}
.comment-form input:focus,
.comment-form textarea:focus {
  outline: none;
}
.comment-form input {
  height: 45px;
}
.comment-form textarea + .input-placeholder {
  -webkit-box-align: start;
          align-items: flex-start;
}
.comment-form input[type=submit] {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 16px 55px;
  border: none;
  background-color: #181b31;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.comment-form input[type=submit]:hover {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.comment-form .input-placeholder {
  display: -webkit-box;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  -webkit-box-align: center;
          align-items: center;
  pointer-events: none;
}
.comment-form p {
  margin-bottom: 35px;
  position: relative;
  -webkit-perspective: 1200px;
          perspective: 1200px;
}
.comment-form p:before, .comment-form p:after {
  content: '';
  display: table;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.comment-form p:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: left center;
          transform-origin: left center;
  background-color: #d8dbe2;
}
.comment-form p:after {
  clear: both;
  -webkit-transform: scale(0, 2);
          transform: scale(0, 2);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  background-color: #181b31;
}
.comment-form p.form-submit {
  margin-bottom: 90px;
}
.comment-form p.form-submit:before, .comment-form p.form-submit:after, .comment-form p.comment-notes:before, .comment-form p.comment-notes:after {
  content: none;
}
.comment-form p.input-focused:before {
  -webkit-transform: scale(0, 2);
          transform: scale(0, 2);
  -webkit-transform-origin: right center;
          transform-origin: right center;
}
.comment-form p.input-focused:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}
.comment-form p.input-filled .split-unit {
  opacity: 0 !important;
  -webkit-transform: translateY(-27%) rotateX(-45de) !important;
          transform: translateY(-27%) rotateX(-45de) !important;
}
.comment-form .logged-in-as {
  padding-left: 15px;
  padding-right: 14px;
}
.comment-form .logged-in-as:before, .comment-form .logged-in-as:after {
  content: none;
}

.comment-notes,
.comment-reply-title {
  text-align: center;
}

.comment-reply-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.comment-reply-title small {
  display: -webkit-inline-box;
  display: inline-flex;
}

#cancel-comment-reply-link {
  padding: 0.35em 0.75em 0.5em;
  margin-left: 1em;
  background-color: rgba(253, 58, 24, 0.6);
  font-size: 12px;
  line-height: 1.5em;
  text-transform: none;
  color: #fff;
  border-radius: 2px;
}
#cancel-comment-reply-link:hover {
  background-color: #fd3a18;
}

.blog-single-cover-fade .comments-area > .container,
.blog-single-cover-fade .comment-respond .container {
  width: 100%;
}
.blog-single-cover-fade .comments-area > .container > .row > div,
.blog-single-cover-fade .comment-respond .container > .row > div {
  width: 100%;
  margin-left: 0;
}

.blog-single-default .comments-area,
.blog-single-image-left .comments-area {
  padding: 2.75em 0 3em;
  background-color: #f4f5f8;
  margin-bottom: 0 !important;
}
.blog-single-default .comments-area:last-child,
.blog-single-image-left .comments-area:last-child {
  margin-bottom: 0;
}
.blog-single-default .comment-form p.form-submit,
.blog-single-image-left .comment-form p.form-submit {
  margin-bottom: 0;
}

.comment-author {
  color: #16171e;
}
.comment-author a {
  color: #16171e;
}
.comment-author .avatar {
  width: 70px;
  margin-right: 18px;
  border-radius: 50em;
}
.comment-author .says {
  display: none;
}

.comment-meta {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.comment-metadata {
  font-size: 0.7777777778em;
}
.comment-metadata a {
  color: #a7a9b8;
}

.comment .comment-extras,
.comment-content {
  padding-left: 93px;
}

.comment-content {
  margin-top: -18px;
}
.comment-content p {
  margin-bottom: 0.75em;
}

.comment-extras {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.comment-extras p {
  margin: 0;
}

.comment-body {
  margin-bottom: 30px;
}

.comment {
  font-size: 18px;
  line-height: 1.3333333333em;
}
.comment .reply a {
  display: inline-block;
  padding: 7px 13px;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #181b31;
  border: 1px solid #d8dbe2;
}
.comment .reply a:hover {
  border-color: #181b31;
  background-color: #181b31;
  color: #fff;
}
.comment.bypostauthor .comment-author, .comment.bypostauthor .comment-author a {
  color: var(--color-primary);
}

.pingback {
  font-size: 18px;
  line-height: 1.3333333333em;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8dbe2;
}
.pingback cite {
  flex-basis: 50%;
  padding: 0;
  margin: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1em;
}
.pingback cite:before {
  content: none;
}
.pingback time {
  margin: 0 1.5em;
}

.comment-list {
  padding: 1em 0;
  margin: 2.25em 0 1em;
}
.comment-list,
.comment-list ol.children {
  list-style: none;
}
.comment-list ol.children {
  padding: 0;
  margin: 0;
}
.comment-list ol.children {
  margin-left: 86px;
}
.comment-list ol.children .avatar {
  width: 45px;
}
.comment-list ol.children .comment .comment-extras,
.comment-list ol.children .comment-content {
  padding-left: 68px;
}
.comment-list ol.children .comment-content {
  margin-top: -8px;
}
.comment-list .comment-respond {
  background: none;
  padding: 0;
}
.comment-list .comment-respond .container,
.comment-list .comment-respond .container > .row > div {
  width: 100%;
  margin: 0;
}

.has-sidebar .contents-container > .comments-area > .container,
.has-sidebar .contents-container > .comments-area > .container > .row > div,
.has-sidebar .contents-container .comment-respond > .container,
.has-sidebar .contents-container .comment-respond > .container > .row > div {
  width: 100%;
  margin: 0;
}

.blog-single-image-left .blog-single {
  margin-top: 2.2727272727em;
}
.blog-single-image-left .blog-single-cover {
  margin-bottom: 2.727em;
}
.blog-single-image-left .blog-single-title {
  letter-spacing: -0.01em;
}
.blog-single-image-left .blog-single-content {
  padding-top: 1em;
}

.blog-single-cover-fade .blog-single-cover.scheme-light .blog-single-media:after {
  background: -webkit-gradient(linear, left bottom, left top, from(#12131a), to(rgba(18, 19, 26, 0.6)));
  background: linear-gradient(to top, #12131a 0%, rgba(18, 19, 26, 0.6) 100%);
}
.blog-single-cover-fade .blog-single-cover.scheme-dark .blog-single-media:after {
  background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(255, 255, 255, 0.6)));
  background: linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0.6) 100%);
}
.blog-single-cover-fade .blog-single-cover .blog-single-details-extra {
  margin-bottom: 75px;
}
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-media:after {
  -webkit-transform: translate(0);
          transform: translate(0);
}
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details,
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details > .container,
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details > .container > .row,
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details > .container > .row > [class^=col-] {
  height: 100%;
}
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details > .container > .row > [class^=col-] {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.blog-single-cover-fade .blog-single-cover[data-inview=true] .blog-single-details .blog-single-header {
  margin-top: auto;
  margin-bottom: auto;
}
.blog-single-cover-fade .related-posts > .container {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}
.blog-single-cover-fade .related-posts .related-posts-title.text-left {
  text-align: center !important;
}

.blog-single-cover-bordered .related-posts > .container {
  width: auto;
}
.blog-single-cover-bordered .comments-area > .container,
.blog-single-cover-bordered .comment-respond > .container {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}
.blog-single-cover-bordered .comments-area > .container > .row > .col-md-8,
.blog-single-cover-bordered .comment-respond > .container > .row > .col-md-8 {
  width: 100%;
  margin-left: 0;
}

.blog-single-cover-bordered.blog-single-post-has-thumbnail .lqd-main-header-default + .content {
  padding-top: 0;
}

.related-posts {
  margin: 1.5em 0 0.5em;
}

.related-posts-title {
  margin-bottom: 1.75em;
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.related-post-date,
.related-post-categories {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 3;
  list-style: none;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.related-post-image {
  margin-bottom: 0.9090909091em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
}
.related-post-image img {
  width: 100%;
}

.related-post-title {
  margin: 0 0 0.4166666667em;
  font-size: 1.0909090909em;
  line-height: 1.0833333333em;
  letter-spacing: -0.01em;
}
.related-post-title a {
  color: #16171e;
}

.related-post {
  position: relative;
  margin-bottom: 1.875em;
}
.related-post .liquid-overlay-link {
  z-index: 2;
}
.related-post:hover .related-post-image {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}

.related-post-alt {
  color: #fff;
  padding-bottom: 70%;
  overflow: hidden;
}
.related-post-alt a {
  color: #fff;
}
.related-post-alt .related-post-image,
.related-post-alt .related-post-header {
  position: absolute;
  bottom: 0;
  left: 0;
}
.related-post-alt .related-post-image {
  width: 100%;
  height: 100%;
  margin: 0;
}
.related-post-alt .related-post-image:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(#141622), to(transparent));
  background: linear-gradient(to top, #141622 0%, transparent 100%);
}
.related-post-alt .related-post-image img {
  visibility: hidden;
}
.related-post-alt .related-post-header {
  width: 100%;
  padding: 0 30px 22px;
  z-index: 2;
}
.related-post-alt:hover .related-post-image {
  -webkit-transform: none;
          transform: none;
}

.page-scheme-dark .post-meta a {
  color: #16171e;
}

/* 3.36. Particles */
.ld-particles-container {
  width: 100%;
  height: 450px;
}
.lqd-particles-bg-wrap .ld-particles-container {
  height: 100%;
}

.lqd-particles-bg-wrap,
.ld-particles-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lqd-particles-bg-wrap {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* 3.37. Carousel Laptop */
.ld-carousel-laptop {
  text-align: center;
}
.ld-carousel-laptop .ld-carousel-laptop-inner {
  position: relative;
  display: inline-block;
}
.ld-carousel-laptop .mockup-content-container {
  position: absolute;
  top: 7.3%;
  left: 10.8%;
  bottom: 11.3%;
  right: 10.4%;
  z-index: 2;
}
.ld-carousel-laptop .carousel-container,
.ld-carousel-laptop .carousel-items,
.ld-carousel-laptop .mockup-content-inner {
  width: 100%;
  height: 100%;
}
.ld-carousel-laptop .flickity-viewport {
  height: 100% !important;
}
.ld-carousel-laptop .carousel-items {
  margin: 0;
}
.ld-carousel-laptop .carousel-item {
  padding: 0;
}
.ld-carousel-laptop img {
  width: 100%;
}
.ld-carousel-laptop .flickity-prev-next-button.previous {
  left: 6%;
}
.ld-carousel-laptop .flickity-prev-next-button.next {
  right: 6%;
}

/* 3.38. Liquid Fancy Heading */
.ld-fancy-heading p, .ld-fancy-heading h1, .ld-fancy-heading h2, .ld-fancy-heading h3, .ld-fancy-heading h4, .ld-fancy-heading h5, .ld-fancy-heading h6, .ld-fancy-heading > div {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0.5em;
}
.ld-fancy-heading .ld-fh-txt {
  position: relative;
  display: inline-block;
}
.ld-fancy-heading .ld-fh-txt i {
  vertical-align: middle;
}
.ld-fancy-heading.carousel-nav-appended {
  display: -webkit-box;
  display: flex;
  height: auto;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.ld-fancy-heading.has-mask-image > * {
  background-position: center;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  color: transparent;
}
.ld-fancy-heading.has-mask-image .ld-fh-txt {
  position: static;
  display: inline;
}

.mask-text .split-unit {
  overflow: hidden;
}

.no-backgroundcliptext .ld-gradient-heading {
  background: none !important;
}
.backgroundcliptext .ld-gradient-heading .ld-fh-txt {
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
}
.backgroundcliptext .ld-gradient-heading .txt-rotate-keywords,
.backgroundcliptext .ld-gradient-heading .txt-rotate-keywords .keyword,
.backgroundcliptext .ld-gradient-heading .split-unit,
.backgroundcliptext .ld-gradient-heading .split-inner {
  background: inherit;
}
.backgroundcliptext .ld-gradient-heading .split-inner {
  line-height: 1.5em;
  margin-top: -0.75em;
  margin-bottom: -0.75em;
}

.ld-fh-underline {
  display: inline-block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.txt-rotate-keywords {
  display: inline-block;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  white-space: nowrap;
}
.txt-rotate-keywords .keyword {
  display: inline-block;
  position: absolute;
  left: 0;
  top: auto;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.txt-rotate-keywords .keyword:first-child {
  position: relative;
  top: auto;
  left: auto;
  opacity: 1;
}
.text-slide-activated .txt-rotate-keywords .keyword {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.text-slide-activated .txt-rotate-keywords .keyword.active {
  position: relative;
  opacity: 1;
}

@media screen and (min-width: 480px) {
  .ld-fancy-heading .lqd-lines {
    white-space: nowrap;
  }
  .window-resizing .ld-fancy-heading .lqd-lines {
    white-space: normal;
  }
}
/* 3.39. Row Overlay */
.liquid-row-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  -webkit-transition: background 0.3s, opacity 0.3s;
  transition: background 0.3s, opacity 0.3s;
}

.liquid-row-overlay-hover {
  opacity: 0;
}

/* 3.40. Maps */
@-webkit-keyframes markerWave {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8;
  }
  20% {
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(5.5);
    transform: scale(5.5);
    opacity: 0;
  }
}
@keyframes markerWave {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8;
  }
  20% {
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(5.5);
    transform: scale(5.5);
    opacity: 0;
  }
}
.ld-gmap-container {
  width: 100%;
  height: 450px;
}

.ld-gmap {
  height: 100%;
}

.map_marker {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50em;
  background-color: var(--color-primary);
}
.map_marker div {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50em;
  opacity: 1;
  background-color: var(--color-primary);
}
.map_marker > div:first-child {
  -webkit-animation: markerWave 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite forwards;
          animation: markerWave 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite forwards;
}
.map_marker > div:first-child + div {
  -webkit-animation: markerWave 1.5s 0.45s cubic-bezier(0, 0, 0.2, 1) infinite forwards;
          animation: markerWave 1.5s 0.45s cubic-bezier(0, 0, 0.2, 1) infinite forwards;
}

@media screen and (min-width: 992px) {
  .row.align-items-stretch .ld-gmap-container {
    height: auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: stretch;
            justify-content: stretch;
    -webkit-box-align: stretch;
            align-items: stretch;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .row.align-items-stretch .ld-gmap-container .ld-gmap {
    -webkit-box-flex: 1;
            flex-grow: 1;
    height: auto;
    min-height: 350px;
  }
}
/* 3.41. Custom Animations */
[data-custom-animations=true] {
  visibility: hidden;
  opacity: 0;
}

.ca-initvalues-applied {
  visibility: visible;
  opacity: 1;
}

.compose-mode [data-custom-animations=true] {
  visibility: visible !important;
  opacity: 1 !important;
}

/* 3.42. Masked Image */
.ld-masked-image svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.ld-masked-image svg defs,
.ld-masked-image svg clipPath,
.ld-masked-image svg path {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ld-masked-image figure {
  background-position: -90% 10%;
  background-size: 95%;
}
.ld-masked-image img {
  width: 100%;
  height: auto;
  visibility: hidden;
}

@media (min-width: 768px) {
  .ld-masked-image {
    position: absolute;
    right: -90vw;
    left: 42vw;
  }
}
@media (min-width: 992px) {
  .ld-masked-image {
    top: -58vh;
    right: -65vw;
    left: 5vw;
  }
}
/* 3.43. Slideshow BG */
.slideshow-applied {
  position: relative;
}

.ld-slideshow-bg-wrap,
.ld-slideshow-bg-inner,
.ld-slideshow-item,
.ld-slideshow-item-inner,
.ld-slideshow-figure {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ld-slideshow-bg-inner {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.ld-slideshow-bg-wrap {
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.slideshow-applied .ld-slideshow-bg-wrap {
  opacity: 1;
  visibility: visible;
}

.ld-slideshow-item {
  overflow: hidden;
}
.ld-slideshow-item,
.ld-slideshow-item * {
  pointer-events: none;
}

.ld-slideshow-figure {
  background-size: cover;
  background-position: center;
}
.ld-slideshow-figure.active {
  z-index: 1;
}

/* 3.44. Custom Menu */
.lqd-custom-menu li a {
  display: block;
}
.lqd-custom-menu li.is-active a {
  color: var(--color-primary);
}
.lqd-custom-menu.menu-items-have-fill a {
  padding: 0.5em 1em;
}
.lqd-custom-menu[data-move-element] {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visiblity 0.3s;
  transition: opacity 0.3s, visiblity 0.3s;
}
.lqd-custom-menu[data-move-element].element-was-moved {
  opacity: 1;
  visibility: visible;
}
.lqd-custom-menu[class*=text-] a, .lqd-custom-menu[class*=text-hover] a {
  color: inherit;
}
.lqd-custom-menu.active-dark li.is-active a {
  color: #000 !important;
}

.lqd-sticky-menu {
  width: 100%;
  padding: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #f6f6f6;
}
.lqd-sticky-menu.on-top {
  bottom: auto;
  top: 0;
}

.reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.inline-nav > li {
  display: inline-block;
}
.inline-nav > li + li {
  margin-left: 15px;
}
.inline-nav li a {
  display: inline;
}

.comma-sep-li > li {
  display: inline-block;
}
.comma-sep-li > li:not(:last-child):after {
  content: ',';
  margin-right: 0.25em;
}

/* 3.45. Shop Banner */
.ld-shop-banner {
  position: relative;
  margin-bottom: 30px;
}
.ld-shop-banner.round .ld-shop-banner-inner {
  border-radius: 5px;
}
.ld-shop-banner.round .ld-shop-banner-image figure {
  border-radius: 5px;
}
.ld-shop-banner.custom-height-applied {
  padding: 0;
}

.ld-shop-banner-container {
  -webkit-perspective: 1600px;
          perspective: 1600px;
}

.ld-shop-banner-inner {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: inherit;
          perspective: inherit;
}

.ld-shop-banner-image figure {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.ld-shop-banner-image img {
  visibility: hidden;
}

.ld-shop-banner-content {
  padding: 20px 30px;
  font-size: 20px;
  color: #000;
  -webkit-transform: translateZ(50px) scale(0.92);
          transform: translateZ(50px) scale(0.92);
}

.ld-shop-banner-style1 .ld-shop-banner-inner {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.ld-shop-banner-style1 .ld-shop-banner-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
}
.ld-shop-banner-style1 .ld-shop-banner-image {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: inherit;
          perspective: inherit;
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item-inner,
.ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item {
  width: 100%;
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav {
  width: 70%;
  height: 80px;
  position: absolute;
  bottom: 3%;
  right: 1%;
  -webkit-transform: translateZ(70px) scale(0.884);
          transform: translateZ(70px) scale(0.884);
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  padding-top: 2px;
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item figure {
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  background-size: cover;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item figure:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 2px solid #fff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item img {
  visibility: hidden;
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item.is-nav-selected figure {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item.is-nav-selected figure:before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.ld-shop-banner-style1 .liquid-overlay-link {
  -webkit-transform: translateZ(50px);
          transform: translateZ(50px);
}
.ld-shop-banner-style1:hover .ld-shop-banner-inner {
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
}
.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(0) {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(1) {
  -webkit-transition-delay: 0.0666666667s;
          transition-delay: 0.0666666667s;
}
.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(2) {
  -webkit-transition-delay: 0.1333333333s;
          transition-delay: 0.1333333333s;
}
.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(3) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.ld-shop-banner-style1:hover .ld-shop-banner-carousel-nav .carousel-item.is-selected:nth-child(4) {
  -webkit-transition-delay: 0.2666666667s;
          transition-delay: 0.2666666667s;
}

/* 3.46. Carousel Phone */
.ld-carousel-phone {
  position: relative;
}
.ld-carousel-phone .mockup-container {
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ld-carousel-phone .mockup-container img {
  width: 325px;
}
.ld-carousel-phone .mockup-content-container {
  width: 100%;
  position: absolute;
  top: 2.5%;
  bottom: 2.75%;
  left: 0;
  z-index: 0;
  overflow: hidden;
}
.ld-carousel-phone .carousel-item,
.ld-carousel-phone .carousel-item-inner,
.ld-carousel-phone .flickity-viewport,
.ld-carousel-phone .carousel-container,
.ld-carousel-phone .carousel-items,
.ld-carousel-phone .mockup-content-inner {
  height: 100% !important;
}
.ld-carousel-phone .carousel-item {
  max-width: 365px;
  padding: 0;
  margin: 0 1%;
}
.ld-carousel-phone .carousel-item img {
  width: 100%;
  border-radius: 35px;
}
.ld-carousel-phone .carousel-item-inner {
  margin: 0 40px;
  position: relative;
}
.ld-carousel-phone .is-selected .carousel-item-inner:after {
  opacity: 0;
}

/* 3.47. Media */
.ld-media-icon,
.ld-media-item-overlay {
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ld-media-icon {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 32px;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
.icon-sm .ld-media-icon {
  font-size: 28px;
}
.icon-lg .ld-media-icon {
  font-size: 44px;
}
.ld-media-icon.icon-play {
  width: 55px;
  height: 55px;
  font-size: 15px;
  border-radius: 50em;
  -webkit-transition-property: opacity, border, -webkit-transform;
  transition-property: opacity, border, -webkit-transform;
  transition-property: transform, opacity, border;
  transition-property: transform, opacity, border, -webkit-transform;
}
.ld-media-icon.icon-play.bordered {
  border: 2px solid #fff;
}
.ld-media-icon.icon-play.solid {
  background-color: var(--color-primary);
}
.ld-media-icon.icon-play.size-sm {
  width: 45px;
  height: 45px;
}
.ld-media-icon.icon-play.size-lg {
  width: 65px;
  height: 65px;
}

.ld-media-bg {
  background-color: rgba(0, 0, 0, 0.4);
}

.ld-media-bg,
.ld-media-item-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ld-media-item-overlay {
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
}

.ld-media-content {
  position: relative;
  z-index: 2;
}

.ld-media-item {
  overflow: hidden;
  position: relative;
  color: #000;
}
.ld-media-item h3,
.ld-media-item h6 {
  margin: 0;
  color: inherit;
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ld-media-item h3 {
  margin-bottom: 0.25em;
}
.ld-media-item h6 {
  font-size: 12px;
}
.ld-media-item figure {
  background-size: cover;
  position: relative;
}
.ld-media-item img,
.ld-media-item figure {
  -webkit-transition: -webkit-transform 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.85s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ld-media-item img {
  width: 100%;
  visibility: hidden;
}
.ld-media-item.shadow-onhover {
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.ld-media-item.contents-visible h3,
.ld-media-item.contents-visible h6,
.ld-media-item.contents-visible .ld-media-bg,
.ld-media-item.contents-visible .ld-media-icon,
.ld-media-item.contents-visible .ld-media-item-overlay {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
          transform: none;
}
.ld-media-item.content-bottom h3,
.ld-media-item.content-bottom h6 {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
          transform: none;
}
.ld-media-item.content-bottom img {
  visibility: visible;
}
.ld-media-item.content-bottom figure {
  overflow: hidden;
  background: none !important;
}
.ld-media-item.content-bottom.shadow-onhover > .ld-media-content {
  padding-left: 18px;
  padding-right: 18px;
}
.ld-media-item:hover h3,
.ld-media-item:hover h6 {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.ld-media-item:hover h3 {
  opacity: 1;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.ld-media-item:hover h6 {
  opacity: 0.4;
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.ld-media-item:hover figure {
  -webkit-transform: scale(1.075);
          transform: scale(1.075);
}
.ld-media-item:hover .ld-media-item-overlay {
  opacity: 1;
  visibility: visible;
}
.ld-media-item:hover .ld-media-icon {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.ld-media-item:hover .liquid-overlay-link {
  z-index: 3;
}
.ld-media-item:hover.shadow-onhover {
  box-shadow: 0 50px 75px rgba(0, 0, 0, 0.25);
}
.ld-media-item:hover.contents-visible .icon-play {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  border-width: 1px;
}
.ld-media-item:hover.content-bottom figure {
  -webkit-transform: none;
          transform: none;
}
.ld-media-item:hover.content-bottom figure img {
  -webkit-transform: scale(1.075);
          transform: scale(1.075);
}

.liquid-media-element-custom-height .ld-media-item,
.liquid-media-element-custom-height figure {
  height: 100%;
}

.ld-media-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.ld-media-row > div {
  margin-bottom: 30px;
}

/* 3.48. Modal */
.lity {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  background: #0b0b0b;
  background: rgba(0, 0, 0, 0.9);
  outline: none !important;
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.lity.lity-opened {
  opacity: 1;
}

.lity.lity-closed {
  opacity: 0;
}

.lity * {
  box-sizing: border-box;
}

.lity-wrap {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  outline: none !important;
}

.lity-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.lity-loader {
  z-index: 9991;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.8em;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.lity-loading .lity-loader {
  opacity: 1;
}

.lity-container {
  z-index: 9992;
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  white-space: normal;
  max-width: 100%;
  max-height: 100%;
  outline: none !important;
}

.lity-content {
  z-index: 9993;
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.lity-loading .lity-content,
.lity-closed .lity-content {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.lity-content:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.lity-close {
  z-index: 9994;
  width: 35px;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: none;
  outline: none;
  box-shadow: none;
}

.lity-close::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.lity-close:hover,
.lity-close:focus,
.lity-close:active,
.lity-close:visited {
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: none;
  outline: none;
  box-shadow: none;
}

.lity-close:active {
  top: 1px;
}

.lity-image img {
  max-width: 100%;
  display: block;
  line-height: 0;
  border: 0;
}

.lity-iframe .lity-container,
.lity-youtube .lity-container,
.lity-vimeo .lity-container,
.lity-facebookvideo .lity-container,
.lity-googlemaps .lity-container {
  width: 100%;
  max-width: 964px;
}

.lity-iframe-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: auto;
  pointer-events: auto;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

.lity-iframe-container iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

.lity-hide {
  display: none;
}

.featherlight .featherlight-close-icon {
  top: 1.3em;
  right: 2em;
  font-size: 1.25em;
}
.featherlight .featherlight-content {
  padding: 75px 2em 45px;
  border-radius: 4px;
}
.featherlight .featherlight-inner {
  padding-top: 2em;
  position: relative;
}
.featherlight .featherlight-inner:before {
  content: '';
  display: inline-block;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.lity {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.lity-wrap {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.lity-wrap:before {
  content: none;
}

.lity-container {
  max-height: 90vh;
  border-radius: 4px;
}

.lity-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.lity-close {
  right: 30px;
  top: 30px;
}
.lity-close:active {
  top: 30px;
}

.lqd-modal-inner {
  width: 100%;
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: auto;
  padding: 25px 2em;
  background: #fff;
  cursor: auto;
  white-space: normal;
}

.lqd-modal-head h2 {
  margin-top: 0;
  margin-bottom: 1.25em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 28px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .lity-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .lity-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .lity-container {
    width: 1170px;
  }
}
/* 3.49. Before After */
@-webkit-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
.cd-image-container {
  position: relative;
  margin: 0 auto 30px;
}
.cd-image-container:hover .cd-image-label {
  opacity: 0;
  visibility: visible;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.cd-image-container img {
  display: block;
  width: 100%;
}

.cd-image-label {
  padding: 6px 14px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5em;
  border-radius: 2px;
  font-weight: 500;
  color: #000;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: opacity 0.3s 0.7s, -webkit-transform 0.3s 0.7s;
  transition: opacity 0.3s 0.7s, -webkit-transform 0.3s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s, -webkit-transform 0.3s 0.7s;
}

.cd-image-label.is-hidden {
  visibility: hidden;
}

.is-visible .cd-image-label {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  border-right: 3px solid rgba(255, 255, 255, 0.75);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.cd-resize-img img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: auto;
  max-width: none;
}

.cd-resize-img .cd-image-label {
  right: auto;
  left: 15px;
}

.is-visible .cd-resize-img {
  width: 50%;
  /* bounce in animation of the modified image */
  -webkit-animation: cd-bounce-in 0.7s;
          animation: cd-bounce-in 0.7s;
}

.cd-handle {
  display: inline-block;
  position: absolute;
  height: 40px;
  width: 40px;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  background-color: #fff;
  cursor: move;
  opacity: 0;
  color: #000;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cd-handle:before {
  content: '\f07e';
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font: normal normal 17px/1 fontAwesome;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.is-visible .cd-handle {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: opacity 0s 0.7s, -webkit-transform 0.3s 0.7s;
  transition: opacity 0s 0.7s, -webkit-transform 0.3s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0s 0.7s, -webkit-transform 0.3s 0.7s;
}

.cd-handle.draggable:before {
  -webkit-transform: translate(-50%, -50%) scale(1.15);
          transform: translate(-50%, -50%) scale(1.15);
}

/* 3.50. Process Box */
.ld-pb-top {
  display: inline-block;
  position: relative;
}
.ld-pb-top:after {
  content: '';
  display: inline-block;
  width: 150%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px dashed #ddd;
}
.ld-pb-top .ld-pb-icon-wrap,
.ld-pb-top figure {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 208px;
  height: 208px;
  margin: 0;
  border-radius: 50em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.ld-pb-top figure:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.wpb_column:last-child .ld-pb-top:after {
  content: none;
}

.ld-pb-icon-wrap {
  background-color: #e8faf4;
  color: #3ed2a7;
  font-size: 90px;
  line-height: 1;
}
.ld-pb-icon-wrap i {
  position: relative;
  z-index: 2;
}

.ld-pb-num {
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  z-index: 2;
  font-size: 55px;
  line-height: 1;
  font-weight: 600;
  color: #fff;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ld-pb {
  margin-bottom: 30px;
}
.ld-pb h3 {
  margin-top: 1.25em;
  font-size: 20px;
}

/* 3.52. Message */
.ld-msg-close {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 34px;
  height: 34px;
  padding: 0;
  margin-top: -17px;
  position: absolute;
  top: 50%;
  right: 30px;
  border: none;
  background: none;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 38px;
  line-height: 1;
  color: #000;
  opacity: 0.4;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ld-msg-close span {
  display: inline-block;
}
.ld-msg-close:hover {
  opacity: 1;
}

.ld-msg-icon {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 34px;
  height: 34px;
  margin-right: 26px;
  border: 1.2px solid;
  border-radius: 50em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 30px;
}

.ld-msg-txt h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  color: #34495E;
}

.ld-msg-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}

.ld-msg {
  padding: 20px 40px 20px 20px;
  margin-bottom: 30px;
  position: relative;
  background-color: #F8FAFC;
}
.ld-msg:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 2.5px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.3;
}

.ld-msg-success:after {
  background-color: #63DBC1;
  opacity: 0.5;
}
.ld-msg-success .ld-msg-icon {
  color: #63DBC1;
}

.ld-msg-warning:after {
  background-color: #F2C223;
}
.ld-msg-warning .ld-msg-icon {
  color: #F2C223;
}

.ld-msg-error:after {
  background-color: #E33847;
}
.ld-msg-error .ld-msg-icon {
  color: #E33847;
}

/* 3.53. Flipbox */
.ld-flipbox-inner,
.ld-flipbox-face,
.ld-flipbox-wrap {
  width: 100%;
  min-height: inherit;
  border-radius: inherit;
}

.ld-flipbox-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
          flex: 1 auto;
  padding: 40px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  text-align: center;
  color: #fff;
}
.ld-flipbox-inner h1, .ld-flipbox-inner h2, .ld-flipbox-inner h3, .ld-flipbox-inner h4, .ld-flipbox-inner h5, .ld-flipbox-inner h6 {
  color: inherit;
}
.ld-flipbox-inner :last-child {
  margin-bottom: 0;
}

.ld-flipbox-face {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: stretch;
          align-items: stretch;
  background-size: cover;
  background-position: center;
  background-color: var(--color-primary);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.ld-flipbox-wrap,
.ld-flipbox-face {
  -webkit-transform-style: inherit;
          transform-style: inherit;
  -webkit-perspective: inherit;
          perspective: inherit;
}

.ld-flipbox-front .ld-flipbox-inner {
  -webkit-transform: translate3d(0, 0, 60px) scale(0.85);
          transform: translate3d(0, 0, 60px) scale(0.85);
}

.ld-flipbox-back {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate3d(0, 1, 0, 180deg);
          transform: rotate3d(0, 1, 0, 180deg);
}
.ld-flipbox-back .ld-flipbox-inner {
  -webkit-transform: translate3d(0, 0, 60px) scale(0.95);
          transform: translate3d(0, 0, 60px) scale(0.95);
}
.ld-flipbox-bt .ld-flipbox-back, .ld-flipbox-tb .ld-flipbox-back {
  -webkit-transform: rotateY(-180deg) rotateZ(-180deg);
          transform: rotateY(-180deg) rotateZ(-180deg);
}

.ld-flipbox-wrap {
  position: relative;
  -webkit-transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, transform;
  transition-property: box-shadow, transform, -webkit-transform;
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ld-flipbox-shadow .ld-flipbox-wrap {
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.15);
}
.ld-flipbox-bt.ld-flipbox-shadow:hover .ld-flipbox-wrap, .ld-flipbox-tb.ld-flipbox-shadow:hover .ld-flipbox-wrap {
  box-shadow: 0 -50px 60px rgba(0, 0, 0, 0.15);
}

.ld-flipbox {
  display: -webkit-box;
  display: flex;
  min-height: 270px;
  margin-bottom: 30px;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1200px;
          perspective: 1200px;
}
.ld-flipbox:hover .ld-flipbox-wrap {
  -webkit-transform: rotate3d(0, 1, 0, 180deg);
          transform: rotate3d(0, 1, 0, 180deg);
  -webkit-transform-origin: 50% 50% !important;
          transform-origin: 50% 50% !important;
}
.ld-flipbox:hover.ld-flipbox-rl .ld-flipbox-wrap {
  -webkit-transform: rotate3d(0, -1, 0, 180deg);
          transform: rotate3d(0, -1, 0, 180deg);
}
.ld-flipbox:hover.ld-flipbox-bt .ld-flipbox-wrap {
  -webkit-transform: rotate3d(1, 0, 0, 180deg);
          transform: rotate3d(1, 0, 0, 180deg);
}
.ld-flipbox:hover.ld-flipbox-tb .ld-flipbox-wrap {
  -webkit-transform: rotate3d(-1, 0, 0, 180deg);
          transform: rotate3d(-1, 0, 0, 180deg);
}
.ld-flipbox:hover.ld-flipbox-shadow-onhover .ld-flipbox-wrap {
  box-shadow: 0 50px 60px rgba(0, 0, 0, 0.15);
}
.ld-flipbox:hover.ld-flipbox-shadow-onhover.ld-flipbox-bt .ld-flipbox-wrap, .ld-flipbox:hover.ld-flipbox-shadow-onhover.ld-flipbox-tb .ld-flipbox-wrap {
  box-shadow: 0 -50px 60px rgba(0, 0, 0, 0.15);
}

.is-ie .ld-flipbox-face,
.is-ie .ld-flipbox-face .ld-flipbox-inner {
  -webkit-transform: none;
          transform: none;
}
.is-ie .ld-flipbox-back {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}
.is-ie .ld-flipbox:hover .ld-flipbox-wrap {
  -webkit-transform: none !important;
          transform: none !important;
}
.is-ie .ld-flipbox:hover .ld-flipbox-back {
  opacity: 1;
  visibility: visible;
}

/* 3.54. Iconbox Circle */
.one-ib-circ-icn {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 80px;
  height: 80px;
  margin: -50px 0 0 -50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50em;
  font-size: 30px;
  opacity: 0;
}
.one-ib-circ-icn span {
  display: inherit;
  width: inherit;
  height: inherit;
  position: relative;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: inherit;
  background-color: #fff;
  color: #000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.one-ib-circ-icn span:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  opacity: 0;
  background-color: var(--color-primary);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.one-ib-circ-icn span:after {
  content: '';
  display: inline-block;
  width: 126%;
  height: 126%;
  position: absolute;
  top: -13%;
  left: -13%;
}
.one-ib-circ-icn img, .one-ib-circ-icn svg, .one-ib-circ-icn i {
  position: relative;
  z-index: 1;
}
.one-ib-circ-icn img, .one-ib-circ-icn svg {
  max-width: 60%;
}
.one-ib-circ-icn:hover span {
  color: #fff;
}
.one-ib-circ-icn:hover span:before {
  opacity: 1;
}
.one-ib-circ-icn:hover svg {
  stroke: #fff;
}
.one-ib-circ-icn:hover svg line, .one-ib-circ-icn:hover svg polyline, .one-ib-circ-icn:hover svg rect, .one-ib-circ-icn:hover svg circle, .one-ib-circ-icn:hover svg polygon, .one-ib-circ-icn:hover svg path {
  stroke: inherit;
}
.one-ib-circ-icn:hover ~ .one-ib-circ-cnt h3,
.one-ib-circ-icn:hover ~ .one-ib-circ-cnt hr,
.one-ib-circ-icn:hover ~ .one-ib-circ-cnt p {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.one-ib-circ-icn:hover ~ .one-ib-circ-cnt hr {
  -webkit-transition-delay: 0.07s;
          transition-delay: 0.07s;
}
.one-ib-circ-icn:hover ~ .one-ib-circ-cnt p {
  -webkit-transition-delay: 0.14s;
          transition-delay: 0.14s;
}

.one-ib-circ-cnt {
  width: 60%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.625em;
  color: #666;
}
.one-ib-circ-cnt h3,
.one-ib-circ-cnt hr,
.one-ib-circ-cnt p {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, visibility, -webkit-transform;
  transition-property: transform, opacity, visibility;
  transition-property: transform, opacity, visibility, -webkit-transform;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.one-ib-circ-cnt h3 {
  color: #333;
  font-size: 21px;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.one-ib-circ-cnt hr {
  width: 17%;
  border-color: rgba(0, 0, 0, 0.1);
  -webkit-transition-delay: 0.05s;
          transition-delay: 0.05s;
}
.one-ib-circ-cnt p {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.one-ib-circ-item:last-child .one-ib-circ-cnt h3,
.one-ib-circ-item:last-child .one-ib-circ-cnt hr,
.one-ib-circ-item:last-child .one-ib-circ-cnt p {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.one-ib-circ-item:hover ~ .one-ib-circ-item .one-ib-circ-cnt h3,
.one-ib-circ-item:hover ~ .one-ib-circ-item .one-ib-circ-cnt hr,
.one-ib-circ-item:hover ~ .one-ib-circ-item .one-ib-circ-cnt p {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.one-ib-circ-inner {
  position: absolute;
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;
  -webkit-transform: translateZ(80px) scale(0.86666);
          transform: translateZ(80px) scale(0.86666);
}
.one-ib-circ-inner > .vc_ld_icon_box_circle_item,
.one-ib-circ-inner > .one-ib-circ-item {
  display: -webkit-box !important;
  display: flex !important;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  position: absolute !important;
  top: 50%;
  left: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.one-ib-circ-inner > .vc_ld_icon_box_circle_item .one-ib-circ-item {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.one-ib-circ-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #efefef;
  border-radius: 50em;
  -webkit-transform-style: inherit;
          transform-style: inherit;
  -webkit-perspective: inherit;
          perspective: inherit;
}

.one-ib-circ {
  position: relative;
  padding-bottom: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1200px;
          perspective: 1200px;
}

/* 3.55. Row Separator */
.one-row-overlay {
  display: block;
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.one-row_top_divider,
.one-row_bottom_divider {
  width: 100%;
  position: absolute;
  left: 0;
}
.one-row_top_divider svg,
.one-row_bottom_divider svg {
  width: 100%;
  height: 100%;
  position: relative;
}
.one-row_top_divider svg.centered-angle,
.one-row_bottom_divider svg.centered-angle {
  width: 102%;
  left: -1%;
}

.one-row_bottom_divider {
  bottom: -1px;
}

.one-row_top_divider {
  top: -1px;
}
.one-row_top_divider svg {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.one-row_top_divider svg.one-divider-triangle, .one-row_top_divider svg.one-divider-circle {
  width: auto;
  position: relative;
  left: 50%;
  -webkit-transform: rotateZ(180deg) translateX(-50%);
  transform: rotateZ(180deg) translateX(-50%);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

/* 3.56. Roadmap */
.one-roadmap-bar {
  display: inline-block;
  width: 15px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  align-self: flex-start;
  border-radius: 50em;
}
.one-roadmap-bar:before, .one-roadmap-bar:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  background: var(--color-primary);
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}
.one-roadmap-bar:before {
  width: 100%;
  height: 15px;
  border-radius: 50em;
}
.one-roadmap-bar:after {
  width: 2px;
  height: 100%;
  left: 6px;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

.one-roadmap-info h6,
.one-roadmap-info p {
  margin: 0;
  line-height: 1.5em;
  letter-spacing: normal;
  color: inherit;
}
.one-roadmap-info h6 {
  margin-bottom: 0.25em;
  font-size: 15px;
}
.one-roadmap-info p {
  font-size: 16px;
}

.one-roadmap-mark {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 32px;
  height: 32px;
  margin-left: auto;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border: 2px solid;
  border-radius: 50em;
  font-size: 30px;
  opacity: 0.43;
}

.one-roadmap-item {
  display: -webkit-box;
  display: flex;
  position: relative;
  padding-bottom: 1em;
  padding-left: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  color: #000;
}
.one-roadmap-item:last-of-type {
  padding-bottom: 0;
}
.one-roadmap-item:last-of-type .one-roadmap-bar:after {
  content: none;
}

.one-roadmap-item-checked .one-roadmap-bar,
.one-roadmap-item-checked .one-roadmap-info {
  opacity: 0.6;
}
.one-roadmap-item-checked .one-roadmap-mark {
  opacity: 1;
}

.one-roadmap {
  padding: 45px 40px 45px 60px;
}
.one-roadmap:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid;
  opacity: 0.3;
}
.one-roadmap[data-custom-animations=true] .one-roadmap-item {
  opacity: 0;
}
.one-roadmap[data-custom-animations=true] .one-roadmap-bar:before, .one-roadmap[data-custom-animations=true] .one-roadmap-bar:after {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(0) .one-roadmap-bar:before {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(0) .one-roadmap-bar:after {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(1) .one-roadmap-bar:before {
  -webkit-transition-delay: 0.2631578947s;
          transition-delay: 0.2631578947s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(1) .one-roadmap-bar:after {
  -webkit-transition-delay: 0.2816901408s;
          transition-delay: 0.2816901408s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(2) .one-roadmap-bar:before {
  -webkit-transition-delay: 0.5263157895s;
          transition-delay: 0.5263157895s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(2) .one-roadmap-bar:after {
  -webkit-transition-delay: 0.5633802817s;
          transition-delay: 0.5633802817s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(3) .one-roadmap-bar:before {
  -webkit-transition-delay: 0.7894736842s;
          transition-delay: 0.7894736842s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(3) .one-roadmap-bar:after {
  -webkit-transition-delay: 0.8450704225s;
          transition-delay: 0.8450704225s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(4) .one-roadmap-bar:before {
  -webkit-transition-delay: 1.0526315789s;
          transition-delay: 1.0526315789s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(4) .one-roadmap-bar:after {
  -webkit-transition-delay: 1.1267605634s;
          transition-delay: 1.1267605634s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(5) .one-roadmap-bar:before {
  -webkit-transition-delay: 1.3157894737s;
          transition-delay: 1.3157894737s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(5) .one-roadmap-bar:after {
  -webkit-transition-delay: 1.4084507042s;
          transition-delay: 1.4084507042s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(6) .one-roadmap-bar:before {
  -webkit-transition-delay: 1.5789473684s;
          transition-delay: 1.5789473684s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(6) .one-roadmap-bar:after {
  -webkit-transition-delay: 1.6901408451s;
          transition-delay: 1.6901408451s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(7) .one-roadmap-bar:before {
  -webkit-transition-delay: 1.8421052632s;
          transition-delay: 1.8421052632s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(7) .one-roadmap-bar:after {
  -webkit-transition-delay: 1.9718309859s;
          transition-delay: 1.9718309859s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(8) .one-roadmap-bar:before {
  -webkit-transition-delay: 2.1052631579s;
          transition-delay: 2.1052631579s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(8) .one-roadmap-bar:after {
  -webkit-transition-delay: 2.2535211268s;
          transition-delay: 2.2535211268s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(9) .one-roadmap-bar:before {
  -webkit-transition-delay: 2.3684210526s;
          transition-delay: 2.3684210526s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-item:nth-child(9) .one-roadmap-bar:after {
  -webkit-transition-delay: 2.5352112676s;
          transition-delay: 2.5352112676s;
}
.one-roadmap.ca-initvalues-applied .one-roadmap-bar:before, .one-roadmap.ca-initvalues-applied .one-roadmap-bar:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

/* 3.57. Countdown */
.countdown {
  font-size: 5.3125em;
  line-height: 1em;
  font-weight: 600;
}
.countdown .countdown-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.countdown .countdown-section {
  display: -webkit-inline-box;
  display: inline-flex;
  margin: 0 2%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  text-align: center;
  position: relative;
}
.countdown .countdown-sep {
  margin-top: -0.35em;
}
.countdown .countdown-amount {
  letter-spacing: -0.05em;
  line-height: 1em;
}
.countdown .countdown-period {
  margin-top: 0.5em;
  font-size: 0.1764705882em;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.5;
}
.countdown.light {
  color: #fff;
}
.countdown.primary {
  color: var(--color-primary);
}
.countdown.primary .countdown-period {
  color: #fff;
}
.countdown.countdown-sep-off .countdown-sep {
  display: none;
}

/* 3.58. breadcrumbs */
ol.breadcrumb {
  background: none;
}
ol.breadcrumb > li {
  color: inherit;
}
ol.breadcrumb > li:not(:last-child):after {
  content: '\f105';
  margin-left: 0.75em;
  margin-right: 0.5em;
  font-family: 'FontAwesome';
  font-size: 0.85em;
}
ol.breadcrumb > li:hover {
  opacity: 1;
}
ol.breadcrumb > li + li:before {
  content: none;
}
ol.breadcrumb > .active {
  color: inherit;
}
ol.breadcrumb span {
  opacity: 0.6;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
ol.breadcrumb a:hover span {
  opacity: 1;
}
ol.breadcrumb.text-darker {
  color: #545663;
}
ol.breadcrumb.text-darker a {
  color: inherit;
}
ol.breadcrumb.text-darker a:hover {
  color: #000;
}

/* 3.59. Bullet List */
.one-bullet-list {
  margin-bottom: 30px;
}
.one-bullet-list li {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 0.5em;
}
.one-bullet-list li:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 1em;
  border-radius: 50em;
  background-color: var(--color-primary);
}
.one-bullet-list .inline-nav li {
  display: -webkit-inline-box;
  display: inline-flex;
}
.one-bullet-list .inline-nav li + li {
  margin-left: 2.5em;
}

/* 3.60. Video BG */
.lqd-vbg-video:not([data-property]),
.lqd-vbg-video .mejs__overlay,
.lqd-vbg-video .mejs__poster {
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
  width: 100% !important;
  height: 100% !important;
}
.lqd-vbg-video .YTPOverlay,
.lqd-vbg-video .mb_YTPBar,
.lqd-vbg-video .inlinePlayButton,
.lqd-vbg-video .mejs__overlay-play,
.lqd-vbg-video .mejs-controls,
.lqd-vbg-video .mejs__controls {
  display: none !important;
}

.lqd-vbg-loader {
  background-image: url(../img/spinners/spinner-1.gif);
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 55px;
}

.lqd-vbg-loader,
.lqd-vbg-inner,
.lqd-vbg-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lqd-vbg-inner:after {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.lqd-vbg-wrap .inline-YTPlayer {
  height: 100%;
}

/* 3.61. Frickin Image */
.lqd-frickin-img-holder,
.lqd-frickin-img-bg {
  -webkit-transition: opacity 1s 0s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1.5s 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 1s 0s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1.5s 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 1s 0s cubic-bezier(0.23, 1, 0.32, 1), transform 1.5s 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 1s 0s cubic-bezier(0.23, 1, 0.32, 1), transform 1.5s 0.25s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1.5s 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  opacity: 0;
}

.lqd-frickin-img-bg {
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 25px;
  right: 25px;
  bottom: 25px;
  z-index: 0;
  background-color: var(--color-primary);
}

.lqd-frickin-img-holder {
  z-index: 2;
  opacity: 0;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.17);
}
.lqd-frickin-img-holder figure {
  background-size: cover;
}
.lqd-frickin-img-holder img {
  width: 100%;
}
.lqd-frickin-img-holder [data-responsive-bg=true] > img {
  visibility: hidden;
}

.lqd-frickin-img-inner {
  padding: 25px;
}

.lqd-frickin-img-holder,
.lqd-frickin-img {
  position: relative;
}

.lqd-frickin-img {
  margin-bottom: 30px;
}
.lqd-frickin-img.is-in-view .lqd-frickin-img-bg,
.lqd-frickin-img.is-in-view .lqd-frickin-img-holder {
  opacity: 1;
}
.lqd-frickin-img.is-in-view .lqd-frickin-img-bg {
  -webkit-transform: translate(-12px, 12px);
          transform: translate(-12px, 12px);
}
.lqd-frickin-img.is-in-view .lqd-frickin-img-holder {
  -webkit-transform: translate(12px, -12px);
          transform: translate(12px, -12px);
}
.lqd-frickin-img.is-in-view.lqd-freak-to-left .lqd-frickin-img-bg {
  -webkit-transform: translate(12px, 12px);
          transform: translate(12px, 12px);
}
.lqd-frickin-img.is-in-view.lqd-freak-to-left .lqd-frickin-img-holder {
  -webkit-transform: translate(-12px, -12px);
          transform: translate(-12px, -12px);
}

/* 3.62. Promo */
.lqd-promo-cat {
  display: -webkit-box;
  display: flex;
  margin-right: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-style: italic;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  width: 30px;
}
.lqd-promo-cat ul {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.lqd-promo-dynamic-shape {
  display: block;
  width: 124%;
  height: 124%;
  position: absolute;
  top: -15%;
  left: -25%;
  z-index: 0;
}
.lqd-promo-dynamic-shape svg {
  overflow: visible;
}

.lqd-promo-img {
  display: -webkit-box;
  display: flex;
  width: calc(58.75% - 30px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
          flex-grow: 0;
  flex-shrink: 0;
}
.lqd-promo-img img {
  width: 100%;
}

.lqd-promo-img-inner {
  box-shadow: 0 50px 100px rgba(0, 0, 0, 0.3);
  opacity: 0;
  overflow: hidden;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.lqd-promo-img-inner.block-revealer {
  opacity: 1;
}
.lqd-promo-img-inner .block-revealer__element {
  will-change: transform;
}
.lqd-promo-img-inner.revealing-ended .block-revealer__element {
  will-change: auto;
}

.lqd-promo-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
  padding: 60px 0 60px 15px;
  width: 39.25%;
  -webkit-box-flex: 0;
          flex: 0 auto;
}
.lqd-promo-content > * {
  -webkit-box-flex: 0;
          flex-grow: 0;
}
.lqd-promo-content h2 {
  margin: 0 -1em 0 -1em;
  font-size: 120px;
  font-weight: 700;
  line-height: 1em;
}
.lqd-promo-content .lqd-words,
.lqd-promo-content .lqd-lines {
  white-space: nowrap;
}
.lqd-promo-content .split-inner {
  display: inline-block;
}
.lqd-promo-content .btn {
  margin-top: auto;
}

.lqd-promo-inner {
  display: -webkit-box;
  display: flex;
  position: relative;
}

.lqd-promo-wrap {
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;
}

.lqd-promo-alt {
  margin-top: 0;
  margin-bottom: 0;
  color: #181b31;
}
.lqd-promo-alt .lqd-promo-inner {
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-align: center;
          align-items: center;
}
.lqd-promo-alt .lqd-promo-cat {
  display: -webkit-box;
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-align: center;
          align-items: center;
  margin: 0;
  width: auto;
  -webkit-box-flex: 1;
          flex: 1 0;
  -webkit-writing-mode: inherit;
      -ms-writing-mode: inherit;
          writing-mode: inherit;
  font-style: normal;
  font-size: 11px;
}
.lqd-promo-alt .lqd-promo-cat:before {
  content: '';
  display: inline-block;
  width: 2em;
  height: 1px;
  margin-right: 1em;
  background-color: #000;
}
.lqd-promo-alt .lqd-promo-cat ul {
  -webkit-transform: none;
          transform: none;
  margin: 0;
}
.lqd-promo-alt .lqd-promo-cat li:not(:last-child):after {
  content: '|';
  margin-left: 0.75em;
}
.lqd-promo-alt .lqd-promo-content h2 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.lqd-promo-alt .lqd-promo-img-inner {
  box-shadow: none;
}

/* 3.63. Back to Top */
@-webkit-keyframes lqdBackToTopArrow {
  40% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
    opacity: 0;
  }
  41% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes lqdBackToTopArrow {
  40% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
    opacity: 0;
  }
  41% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.lqd-back-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
  -webkit-transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
}
.lqd-back-to-top a {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 45px;
  height: 45px;
  border-radius: 50em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 30px -2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  color: #000;
  -webkit-transition: box-shadow 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: box-shadow 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.lqd-back-to-top a i {
  display: inline-block;
  line-height: 1;
  margin-top: -1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.lqd-back-to-top a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.lqd-back-to-top a:hover i {
  -webkit-animation: lqdBackToTopArrow 1s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
          animation: lqdBackToTopArrow 1s cubic-bezier(0.23, 1, 0.32, 1) both alternate;
}
.lqd-back-to-top.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

/* 3.64. Separator */
.lqd-h-sep {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}

.lqd-h-sep-inner {
  -webkit-box-flex: 1;
          flex: 1 0;
  height: 1px;
  background-color: var(--color-primary);
}

/* 3.65. Restaurant Menu */
.lqd-rst-menu {
  padding-bottom: 1.25em;
  margin-bottom: 1.5em;
}
.lqd-rst-menu:not(:last-of-type) {
  border-bottom: 1px dashed #dbdbdb;
}
.lqd-rst-menu ul {
  font-size: 13px;
  color: #a7a7a7;
}

/*
  4. VC SHORTCODES
*/
/* 4.1. Single image */
.ld-img-hover-opacity {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ld-img-hover-opacity:hover {
  opacity: 1;
}

/* 4.2. Columns */
.lqd-column.pull-down {
  margin-bottom: -340px;
}
.lqd-column.pull-up-05x {
  margin-top: -4.5%;
}
.lqd-column.pull-up {
  margin-top: -10%;
}
.lqd-column.pull-up-2x {
  margin-top: -20%;
}
.lqd-column.pull-up-3x {
  margin-top: -30%;
}
.lqd-column.pull-up-4x {
  margin-top: -40%;
}

.column-shadowed-1 {
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
}

.white-box-shadow {
  box-shadow: -10px 0px 30px 1px #ffffff, 10px 0px 30px 1px white;
}

.liquid-column-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  -webkit-transition: background .3s,opacity .3s;
  transition: background .3s,opacity .3s;
  border-radius: inherit;
}
.liquid-column-overlay ~ .lqd-column-inner {
  position: relative;
}

.liquid-column-overlay-hover {
  opacity: 0;
}

.lqd-column:hover .liquid-column-overlay-hover {
  opacity: 1;
}
.lqd-column:hover .liquid-column-overlay-hover + .liquid-column-overlay {
  opacity: 0;
}

/* 4.3. Rows */
.vc_row {
  position: relative;
}
.vc_row.bg-none, .vc_row[data-row-bg] {
  background-image: none !important;
}
.vc_row[data-parallax]:not(.liquid-parallax-bg) {
  background-size: 140% !important;
}
.vc_row:hover .liquid-row-overlay-hover {
  opacity: 1;
}
.vc_row:hover .liquid-row-overlay-hover + .liquid-row-overlay {
  opacity: 0;
}

.vc_row-flex .ld-row:before, .vc_row-flex .ld-row:after {
  content: none;
}

.ld-container.container-fluid {
  width: 100%;
}

.row-bg-loader {
  display: inline-block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  background-image: url("data:image/svg+xml;base64,PCEtLSBCeSBTYW0gSGVyYmVydCAoQHNoZXJiKSwgZm9yIGV2ZXJ5b25lLiBNb3JlIEAgaHR0cDovL2dvby5nbC83QUp6YkwgLS0+Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMDAwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjIiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjAuOHMiCiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgICAgICAgICAgPC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  background-position: center;
  background-size: 38px;
  background-repeat: no-repeat;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.row-bg-appended .row-bg-loader, .slideshow-applied .row-bg-loader {
  opacity: 0;
  visibility: hidden;
}
.row-bg-loader.style-2 {
  background-image: url(../img/spinners/spinner-1.gif);
  background-size: 80px;
}
.row-bg-loader.style-3 {
  background-image: url(../img/spinners/spinner-2.gif);
}

.row-bg-wrap,
.row-bg-inner,
.row-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: inherit;
  background-position: inherit;
  background-attachment: inherit;
  background-repeat: no-repeat;
}

.row-bg-wrap {
  overflow: hidden;
}

.bg-not-loaded .row-bg-inner {
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .vc_row.pull-down {
    margin-bottom: -340px;
  }
  .vc_row.pull-up {
    margin-top: -230px;
  }
}
@media screen and (max-width: 768px) {
  .vc_row[class*=pb-] {
    padding-bottom: 3rem !important;
  }
  .vc_row[class*=pt-] {
    padding-top: 3rem !important;
  }
}
/* 4.4. Text Block */
.wpb_text_column > .wpb_wrapper > ul {
  padding-left: 0;
}
.wpb_text_column ul {
  list-style-position: inside;
}

/*
  6. PARTIALS
*/
/* 6.1. Search Results */
body.search .content {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.search .content article {
  margin-bottom: 3em;
}
body.search .content .entry-title {
  margin: 1em 0 0.75em;
}
body.search .content .entry-title a {
  color: #000;
}
body.search .content .entry-title a:hover {
  color: var(--color-primary);
}
body.search .titlebar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
body.search .titlebar-inner {
  padding-top: 100px;
  padding-bottom: 100px;
}
body.search .titlebar-inner h1 {
  font-size: 48px;
}
body.search .no-results .page-header {
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 2.5em;
}
body.search .no-results .page-header .page-title {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 42px;
}
body.search .no-results .search-form input {
  display: -webkit-inline-box;
  display: inline-flex;
  height: 50px;
  padding: 5px 2em;
  border: none;
  border-radius: 3px;
  background: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-weight: 400;
}
body.search .no-results .search-form input[type=search] {
  border: 1px solid #eaeaea;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
body.search .no-results .search-form input[type=search]:focus {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  outline: none;
}
body.search .no-results .search-form input[type=submit] {
  background-color: var(--color-primary);
  color: #fff;
}

/* 6.2. 404 Not Found */
.page-404 {
  padding-top: 200px;
  padding-bottom: 130px;
}
.page-404 .btn {
  font-size: 13px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.07);
}
.page-404 .btn .btn-icon {
  font-size: 2.15em;
  margin-right: 0.5em;
  text-shadow: 0 0.25px 0 currentColor, 0 -0.25px 0 currentColor, 0.25px 0 0 currentColor, -0.25px 0 0 currentColor;
}
.page-404 .btn:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.text-404 {
  position: relative;
}
.text-404 .ld-particles-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: auto;
}
.text-404 .re-particles-inner {
  position: relative;
}
.text-404 .re-particles-inner:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 15px 2px #fff inset, 0 0 30px 2px #fff inset, 0 0 45px 2px #fff inset, 0 0 60px 2px #fff inset;
}
.text-404 h1 {
  margin: 0 0 0.45em;
  font-size: 300px;
  line-height: 1;
  font-weight: 700;
  position: relative;
}

/* 6.3. Sidebar */
.has-sidebar .contents-container > .container {
  width: 100%;
}

/* Sidebar Widgets */
.wpb_widgetised_column .widget,
.main-sidebar .widget {
  margin-bottom: 45px;
}
.wpb_widgetised_column .widget-title,
.main-sidebar .widget-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 1em;
}
.wpb_widgetised_column ul,
.main-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.wpb_widgetised_column ul ul,
.wpb_widgetised_column ul ol,
.main-sidebar ul ul,
.main-sidebar ul ol {
  margin-left: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.wpb_widgetised_column a,
.main-sidebar a {
  color: #000;
}
.wpb_widgetised_column a:hover,
.main-sidebar a:hover {
  color: var(--color-primary);
}
.wpb_widgetised_column .ui-selectmenu-button,
.wpb_widgetised_column select,
.main-sidebar .ui-selectmenu-button,
.main-sidebar select {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px 15px;
  background: none;
  border: 1px solid #d8dbe2;
  border-radius: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.wpb_widgetised_column .ui-selectmenu-button .ui-selectmenu-icon,
.main-sidebar .ui-selectmenu-button .ui-selectmenu-icon {
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.widget_nav_menu ul,
.widget_archive ul,
.widget_categories ul,
.widget_pages ul,
.widget_meta ul,
.widget_recent_comments ul,
.widget_recent_entries ul {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.widget_nav_menu ul li,
.widget_archive ul li,
.widget_categories ul li,
.widget_pages ul li,
.widget_meta ul li,
.widget_recent_comments ul li,
.widget_recent_entries ul li {
  margin-bottom: 1em;
}
.widget_nav_menu ul a,
.widget_archive ul a,
.widget_categories ul a,
.widget_pages ul a,
.widget_meta ul a,
.widget_recent_comments ul a,
.widget_recent_entries ul a {
  position: relative;
}
.widget_nav_menu ul a:before,
.widget_archive ul a:before,
.widget_categories ul a:before,
.widget_pages ul a:before,
.widget_meta ul a:before,
.widget_recent_comments ul a:before,
.widget_recent_entries ul a:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -0.35em;
  left: 0;
  background-color: currentColor;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
.widget_nav_menu ul a:hover:before,
.widget_archive ul a:hover:before,
.widget_categories ul a:hover:before,
.widget_pages ul a:hover:before,
.widget_meta ul a:hover:before,
.widget_recent_comments ul a:hover:before,
.widget_recent_entries ul a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

.widget_menu li {
  margin-bottom: 0.75em;
}

.widget_product_search,
.widget_search {
  position: relative;
}
.widget_product_search label,
.widget_product_search input,
.widget_search label,
.widget_search input {
  width: 100%;
  display: block;
}
.widget_product_search input,
.widget_search input {
  padding: 15px;
}
.widget_product_search input[type=search],
.widget_search input[type=search] {
  border: 1px solid #dedede;
  padding-left: 45px;
}
.widget_product_search input[type=search]:focus,
.widget_search input[type=search]:focus {
  outline: none;
  border-color: #b8b8b8;
}

.widget_search label {
  position: relative;
}
.widget_search label:after {
  content: '\f002';
  font: normal normal 16px/1 fontAwesome;
}
.widget_search label:after,
.widget_search input[type=submit] {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 8px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.widget_search input[type=submit] {
  border: none;
  background: none;
  opacity: 0;
}

.widget_product_search button[type=submit] {
  display: inline-block;
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 8px;
  background: none;
  border: none;
  text-indent: -99999px;
}
.widget_product_search button[type=submit]:after {
  content: '\f002';
  display: -webkit-inline-box;
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font: normal normal 16px/1 fontAwesome;
}
.widget_product_search button[type=submit]:focus {
  outline: none;
}

.widget_calendar #wp-calendar {
  width: 100%;
  border: 1px solid #ededed;
}
.widget_calendar caption,
.widget_calendar tfoot td {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  padding: 10px 15px;
  color: #000;
}
.widget_calendar caption {
  border: 1px solid #ededed;
  border-bottom: none;
}
.widget_calendar tfoot {
  border-top: 1px solid #ededed;
}
.widget_calendar thead td,
.widget_calendar thead th,
.widget_calendar tbody td,
.widget_calendar tbody th {
  text-align: center;
  padding: 5px;
}
.widget_calendar thead th {
  color: #000;
  font-weight: 600;
}

.ld_widget_recent_entries li {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 22px;
}
.ld_widget_recent_entries li figure {
  margin-right: 10px;
  width: 80px;
}
.ld_widget_recent_entries li figure img {
  width: 100%;
}
.ld_widget_recent_entries li span {
  display: block;
  font-size: 13px;
  margin-top: 4px;
}
.ld_widget_recent_entries li .ld_entries_contents {
  overflow: hidden;
  -webkit-box-flex: 1;
          flex: 1;
  margin-top: -6px;
}

.ld_widget_popular_entries li {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 26px;
}
.ld_widget_popular_entries li figure {
  width: 100%;
  margin-bottom: 0.5em;
}
.ld_widget_popular_entries li figure img {
  width: 100%;
}
.ld_widget_popular_entries li span {
  display: block;
  margin-top: 0.15em;
  font-size: 15px;
}

.ld_widget_social_icons a:hover {
  color: #fff;
}

.widget_tag_cloud a {
  display: inline-block;
  border: 1px solid #dedede;
  padding: 0.25em 1em;
  margin-bottom: 5px;
  margin-right: 2px;
  font-size: 12px !important;
  line-height: 1.5em;
  font-weight: 500;
  color: #000;
}
.widget_tag_cloud a:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

/* 6.4. Page Frame */
/*
  7. RESPONSIVE
*/
/* 7.1. Header */
@media screen and (min-width: 1200px) {
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
    color: #fff !important;
  }
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav > li > a:hover {
    color: #fff;
  }
  .lqd-stack-active-row-dark .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light {
    opacity: 1;
    visibility: visible;
  }

  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
    color: #000 !important;
  }
  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,
  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav > li > a {
    color: rgba(0, 0, 0, 0.7);
  }
  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,
  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .main-nav > li > a:hover {
    color: #000;
  }
  .lqd-stack-active-row-light .main-header:not(.header-fullscreen):not(.header-side) .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
    opacity: 1;
    visibility: visible;
  }

  .lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .social-icon a,
  .lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,
  .lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .main-nav > li > a,
  .lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light,
  .lqd-stack-moving-down .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
    -webkit-transition-delay: 0.35s;
            transition-delay: 0.35s;
  }
}
@media screen and (max-width: 1199px) {
  .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
    position: absolute;
    top: auto !important;
    left: auto !important;
    right: 20px !important;
    bottom: 40px !important;
    -webkit-transform: none !important;
            transform: none !important;
  }
  .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-wrap button {
    margin: 0 5px !important;
  }
  .lqd-stack-has-prevnext-buttons .lqd-stack-button-labbel,
  .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-button {
    -webkit-transform: none !important;
            transform: none !important;
  }
  .lqd-stack-has-prevnext-buttons .lqd-stack-prevnext-button {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
  .lqd-stack-has-prevnext-buttons .lqd-back-to-top {
    display: none;
  }
  .lqd-stack-has-prevnext-buttons.lqd-stack-buttons-style-1 .lqd-stack-prevnext-wrap {
    bottom: 50px !important;
    left: 20px !important;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }

  #pp-nav {
    display: none;
    right: 20px;
  }

  .lqd-stack-extra {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .header-side {
    width: 375px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  .header-side .mainbar,
  .header-side .mainbar-container,
  .header-side .mainbar-row,
  .header-side .mainbar-wrap {
    width: 100%;
    height: 100%;
  }
  .header-side .mainbar-container {
    padding: 0;
  }
  .header-side .mainbar-wrap {
    padding: 12vh 50px;
    overflow: hidden;
    position: relative;
  }
  .header-side .mainbar {
    overflow: hidden;
  }
  .header-side .mainbar-row {
    width: calc(100% + 20px);
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .header-side .mainbar-row > [class^=col] {
    padding: 0;
    margin: 30px 0;
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex: 1 auto;
  }
  .header-side .mainbar-row > [class^=col]:first-child {
    margin-top: 0;
  }
  .header-side .mainbar-row > [class^=col]:last-child {
    margin-bottom: 0;
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .header-side .mainbar-row > [class^=col]:last-child .header-module {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .header-side .navbar-header {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .header-side .navbar-brand {
    padding: 0;
  }
  .header-side .navbar-collapse {
    width: 100%;
    overflow: hidden !important;
  }
  .header-side .main-nav {
    width: calc(100% + 25px);
    padding-right: 25px;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .header-side .main-nav > li {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .header-side .main-nav > li > a {
    display: block;
    width: 100%;
    padding-left: 0;
  }
  .header-side .nav-item-children {
    padding-right: 15px;
  }
  .header-side .header-module {
    margin-bottom: 15px;
    margin-left: 0 !important;
  }
  .header-side .header-module > h1,
  .header-side .header-module > h2,
  .header-side .header-module > h3,
  .header-side .header-module > h4,
  .header-side .header-module > h5,
  .header-side .header-module > h6 {
    margin-top: 0;
    margin-bottom: 0.25em;
  }
  .header-side .ld-module-dropdown,
  .header-side .ld-dropdown-menu-content {
    background: none;
  }
  .header-side .ld-dropdown-menu-content {
    margin-top: 1em;
    width: auto;
    padding: 0;
    border: none;
  }
  .header-side .ld-module-search .ld-module-trigger {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .header-side .ld-module-search .ld-module-trigger.collapse {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .header-side .ld-module-search .ld-search-form-container {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .header-side .ld-module-search .ld-module-dropdown {
    width: 250px;
    height: auto !important;
    top: 0;
    left: 0;
    right: auto;
    overflow: hidden;
  }
  .header-side .ld-module-search .ld-module-dropdown[aria-expanded=true] .ld-search-form-container {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .header-side .ld-search-form-container {
    width: auto;
    padding: 0;
    border: none;
  }

  .header-side-style-1 .navbar-collapse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    width: 375px;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    background-color: #fdfdfe;
    box-shadow: 0 0 0 #f0f1f6 inset;
    -webkit-transition: all 0.45s cubic-bezier(0.7, 0, 0.2, 1);
    transition: all 0.45s cubic-bezier(0.7, 0, 0.2, 1);
  }
  .header-side-style-1 .navbar-collapse[aria-expanded=true] {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    box-shadow: -70px 0 70px #f0f1f6 inset;
  }
  .header-side-style-1 .main-nav {
    -webkit-box-flex: 0;
            flex-grow: 0;
  }

  .header-side-style-3 .mainbar-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
  }
  .header-side-style-3 .mainbar-row > [class^=col] {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .header-side-style-3 .navbar-header,
  .header-side-style-3 .header-module,
  .header-side-style-3 .navbar-collapse {
    -webkit-box-flex: 1;
            flex: 1 auto;
  }
  .header-side-style-3 .navbar-header {
    margin-bottom: 45px;
  }
  .header-side-style-3 .navbar-collapse {
    margin-bottom: 40px;
  }

  .header-style-side .titlebar,
  .header-style-side #content,
  .header-style-side #wrap > .main-header,
  .header-style-side .main-footer {
    -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.7, 0, 0.2, 1);
    transition: -webkit-transform 0.45s cubic-bezier(0.7, 0, 0.2, 1);
    transition: transform 0.45s cubic-bezier(0.7, 0, 0.2, 1);
    transition: transform 0.45s cubic-bezier(0.7, 0, 0.2, 1), -webkit-transform 0.45s cubic-bezier(0.7, 0, 0.2, 1);
  }

  .side-nav-showing .titlebar,
  .side-nav-showing #content,
  .side-nav-showing #wrap > .main-header,
  .side-nav-showing .main-footer {
    -webkit-transform: translateX(375px);
            transform: translateX(375px);
  }

  .lqd-stack-initiated .header-side {
    width: 200px;
  }
  .lqd-stack-initiated .header-side .mainbar-wrap {
    padding: 10vh 45px;
  }
  .lqd-stack-initiated.header-style-side #wrap {
    padding-left: 0;
  }

  .header-fullscreen-style-1 .navbar-fullscreen {
    width: 100%;
    height: 100% !important;
    padding: 10vh 0 15vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav {
    display: block;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-25%) rotateX(45deg);
            transform: translateY(-25%) rotateX(45deg);
    -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(10) {
    -webkit-transition-delay: 0.0588235294s;
            transition-delay: 0.0588235294s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(9) {
    -webkit-transition-delay: 0.1176470588s;
            transition-delay: 0.1176470588s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(8) {
    -webkit-transition-delay: 0.1764705882s;
            transition-delay: 0.1764705882s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(7) {
    -webkit-transition-delay: 0.2352941176s;
            transition-delay: 0.2352941176s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(6) {
    -webkit-transition-delay: 0.2941176471s;
            transition-delay: 0.2941176471s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(5) {
    -webkit-transition-delay: 0.3529411765s;
            transition-delay: 0.3529411765s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(4) {
    -webkit-transition-delay: 0.4117647059s;
            transition-delay: 0.4117647059s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(3) {
    -webkit-transition-delay: 0.4705882353s;
            transition-delay: 0.4705882353s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(2) {
    -webkit-transition-delay: 0.5294117647s;
            transition-delay: 0.5294117647s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li:nth-child(1) {
    -webkit-transition-delay: 0.5882352941s;
            transition-delay: 0.5882352941s;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .main-nav > li > a {
    padding-left: 0;
    padding-right: 0;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .nav-item-children {
    text-align: center;
    box-shadow: none;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .nav-item-children > li > a {
    padding: 0;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .nav-item-children > li:hover > a {
    background-color: transparent;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .megamenu .nav-item-children {
    display: none;
    visibility: visible;
    left: auto !important;
    right: auto !important;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .megamenu .ld-container,
  .header-fullscreen-style-1 .navbar-fullscreen .megamenu .megamenu-column,
  .header-fullscreen-style-1 .navbar-fullscreen .megamenu .megamenu-container {
    width: 100% !important;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .megamenu section.vc_row {
    padding: 0 !important;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .megamenu .ld-row {
    display: block;
  }
  .header-fullscreen-style-1 .navbar-fullscreen .header-module {
    -webkit-box-align: center;
            align-items: center;
  }
  .header-fullscreen-style-1 .navbar-fullscreen[aria-expanded=true] {
    opacity: 1;
    visibility: visible;
  }
  .header-fullscreen-style-1 .navbar-fullscreen[aria-expanded=true] .main-nav > li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
  }

  .navbar-logo-centered .navbar-brand {
    -webkit-box-ordinal-group: inherit;
            order: inherit;
    padding-left: 35px;
    padding-right: 35px;
    flex-shrink: 0;
  }

  .text-lg-right .header-module {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .text-lg-left .header-module {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .text-lg-center .header-module {
    -webkit-box-align: center;
            align-items: center;
  }
  .navbar-collapse ~ .header-module {
    margin-left: 25px;
  }
  .navbar-collapse:not(.navbar-fullscreen) .header-module {
    display: none;
  }

  .nav-trigger.navbar-toggle {
    display: none;
  }

  .ld-search-form .input-icon {
    pointer-events: none;
  }

  .megamenu {
    position: static !important;
  }
  .main-nav:not(.main-nav-side) .megamenu:not(.position-applied) .nav-item-children {
    display: block !important;
    visibility: hidden;
  }
  .megamenu .megamenu-container.container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .megamenu .megamenu-container .container {
    width: 100%;
  }
  .megamenu .nav-item-children {
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    box-shadow: none;
  }
  .megamenu .megamenu-inner-row {
    background-color: #fff;
    box-shadow: 0 16px 50px rgba(0, 0, 0, 0.07);
  }
  .megamenu .megamenu-inner-row.vc_row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .megamenu .megamenu-inner-row.vc_row:after {
    content: none;
  }
  .megamenu .megamenu-inner-row.vc_row-has-bg:before {
    background-color: inherit;
  }
  .megamenu.megamenu-content-stretch .nav-item-children {
    left: 0 !important;
    right: 0 !important;
  }
  .megamenu.megamenu-fullwidth .nav-item-children {
    width: 100vw;
    max-width: none;
    left: 50% !important;
    right: 50% !important;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
  }
  .megamenu.megamenu-fullwidth .megamenu-container {
    width: 100vw !important;
    max-width: none;
  }
  .megamenu.position-applied .megamenu-column {
    -webkit-box-flex: 1;
            flex: 1 auto;
  }

  .navbar-header {
    flex-basis: auto;
  }
  .navbar-header .mobile-logo-default,
  .navbar-header .header-module {
    display: none;
  }

  .navbar-collapse {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: stretch;
            align-items: stretch;
    height: auto !important;
    flex-basis: 0;
  }
  .navbar-collapse > .nav-trigger {
    display: none !important;
  }

  .navbar-collapse-clone {
    display: none !important;
  }

  .main-nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
            align-items: stretch;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .main-nav > li,
  .main-nav > li > a {
    -webkit-box-align: center;
            align-items: center;
  }
  .main-nav > li:first-child {
    padding-left: 0;
  }
  .main-nav > li:last-child {
    padding-right: 0;
  }
  .main-nav > li.nav-item-cloned {
    display: none;
  }
  .main-nav .submenu-expander {
    display: none !important;
  }

  .main-nav-hover-linethrough > li > a .link-ext,
  .main-nav-hover-underline-1 > li > a .link-ext,
  .main-nav-hover-underline-3 > li > a .link-ext {
    display: inline-block;
    width: 100%;
    height: 0.0625em;
    min-height: 1px;
    position: absolute;
    bottom: -0.1875em;
    left: 0;
    background-color: #000;
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1);
  }
  .main-nav-hover-linethrough > li.is-active > a .link-ext, .main-nav-hover-linethrough > li.active > a .link-ext, .main-nav-hover-linethrough > li.current-menu-item > a .link-ext,
  .main-nav-hover-linethrough > li > a:hover .link-ext,
  .main-nav-hover-underline-1 > li.is-active > a .link-ext,
  .main-nav-hover-underline-1 > li.active > a .link-ext,
  .main-nav-hover-underline-1 > li.current-menu-item > a .link-ext,
  .main-nav-hover-underline-1 > li > a:hover .link-ext,
  .main-nav-hover-underline-3 > li.is-active > a .link-ext,
  .main-nav-hover-underline-3 > li.active > a .link-ext,
  .main-nav-hover-underline-3 > li.current-menu-item > a .link-ext,
  .main-nav-hover-underline-3 > li > a:hover .link-ext {
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

  .main-nav-hover-linethrough > li > a .link-ext {
    width: 114%;
    bottom: 50%;
    left: -7%;
    margin-top: -0.0312em;
  }

  .main-nav-hover-underline-2 > li > a .link-ext {
    display: inline-block;
    width: 107%;
    height: 0.4em;
    position: absolute;
    bottom: 0.25em;
    left: -3.5%;
    background: #f4bcba;
    background: -webkit-gradient(linear, left top, right top, from(#f4bc8b), to(#f1aacc));
    background: linear-gradient(to right, #f4bc8b 0%, #f1aacc 100%);
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .main-nav-hover-underline-2 > li.is-active > a .link-ext, .main-nav-hover-underline-2 > li.active > a .link-ext, .main-nav-hover-underline-2 > li.current-menu-item > a .link-ext,
  .main-nav-hover-underline-2 > li > a:hover .link-ext {
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }

  .main-nav-hover-underline-3 .link-txt {
    position: static;
  }
  .main-nav-hover-underline-3 > li > a .link-ext {
    height: 0.214285714285714em;
    min-height: 2px;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .main-nav-side-style-2 > li > a .link-ext {
    display: inline-block;
    width: 0.25em;
    height: 0.25em;
    min-width: 4px;
    min-height: 4px;
    border-radius: 50em;
    position: absolute;
    top: 50%;
    right: -1em;
    left: auto;
    margin-top: -0.12em;
    background: #181b31;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .main-nav-side-style-2 > li.is-active > a .link-ext, .main-nav-side-style-2 > li.active > a .link-ext, .main-nav-side-style-2 > li.current-menu-item > a .link-ext,
  .main-nav-side-style-2 > li > a:hover .link-ext {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .main-nav-hover-fade-inactive:hover > li > a {
    opacity: 0.35;
  }
  .main-nav-hover-fade-inactive:hover > li:hover > a {
    opacity: 1;
  }

  .navbar-visible-ontoggle {
    padding-right: 5px;
    padding-left: 5px;
  }
  .navbar-visible-ontoggle .main-nav > li {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(1) {
    -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(2) {
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(3) {
    -webkit-transition-delay: 0.15s;
            transition-delay: 0.15s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(4) {
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(5) {
    -webkit-transition-delay: 0.25s;
            transition-delay: 0.25s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(6) {
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(7) {
    -webkit-transition-delay: 0.35s;
            transition-delay: 0.35s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(8) {
    -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(9) {
    -webkit-transition-delay: 0.45s;
            transition-delay: 0.45s;
  }
  .navbar-visible-ontoggle .main-nav > li:nth-child(10) {
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(10) {
    -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(9) {
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(8) {
    -webkit-transition-delay: 0.15s;
            transition-delay: 0.15s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(7) {
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(6) {
    -webkit-transition-delay: 0.25s;
            transition-delay: 0.25s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(5) {
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(4) {
    -webkit-transition-delay: 0.35s;
            transition-delay: 0.35s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(3) {
    -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(2) {
    -webkit-transition-delay: 0.45s;
            transition-delay: 0.45s;
  }
  .navbar-visible-ontoggle[aria-expanded=true] .main-nav > li:nth-child(1) {
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
  }
  .navbar-visible-ontoggle[aria-expanded=false].collapsing .main-nav > li {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }

  .main-nav-side {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .main-nav-side > li, .main-nav-side > li:first-child, .main-nav-side > li:last-child {
    padding-left: 1.666em;
    padding-right: 1.666em;
  }
  .main-nav-side .nav-item-children {
    display: none;
    width: 100%;
    padding: 0.625em 0 0.625em 0;
    border-radius: 0;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    line-height: 1.5em;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .main-nav-side .nav-item-children > li {
    display: block;
    width: 100%;
    font-size: 1em;
    font-weight: 400;
  }
  .main-nav-side .nav-item-children > li > a {
    padding: 0.75em 1.25em;
  }
  .main-nav-side .nav-item-children > li:hover > a {
    background-color: transparent;
  }
  .main-nav-side .nav-item-children .nav-item-children {
    padding-left: 1.25em;
  }
  .main-nav-side .megamenu .nav-item-children {
    display: none;
    padding-left: 1.25em;
    width: auto !important;
    left: auto !important;
    right: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    visibility: visible;
  }
  .main-nav-side .megamenu .ld-container,
  .main-nav-side .megamenu .megamenu-column,
  .main-nav-side .megamenu .megamenu-container {
    width: 100% !important;
    padding: 0;
  }
  .main-nav-side .megamenu section.vc_row {
    padding: 0 !important;
  }
  .main-nav-side .megamenu .vc_row,
  .main-nav-side .megamenu .vc_column-inner,
  .main-nav-side .megamenu .megamenu-column,
  .main-nav-side .megamenu .wpb_wrapper {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
  }
  .main-nav-side .megamenu .ld-row {
    display: block;
    margin: 0;
  }
  .main-nav-side .megamenu .megamenu-inner-row:before {
    content: none !important;
  }

  .main-nav-side-style-1 > li, .main-nav-side-style-1 > li:first-child, .main-nav-side-style-1 > li:last-child {
    padding-left: 50px;
    padding-right: 50px;
  }

  .main-nav-side-style-2 > li {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .main-nav-fullscreen-style-1 {
    width: 50%;
    margin: 5vh auto;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }
  .main-nav-fullscreen-style-1 > li {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 1em;
    overflow: hidden;
  }
  .main-nav-fullscreen-style-1 > li > a {
    width: 100%;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .main-nav-fullscreen-style-1 .nav-item-children {
    width: calc(100% + 24px);
    padding: 0.625em 0;
    position: relative;
    top: auto;
    left: auto;
    font-size: 16px;
    line-height: 1.5em;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .main-nav-fullscreen-style-1 .nav-item-children > li {
    display: block;
    width: 100%;
    padding: 0.75em 1.25em;
    font-size: 1em;
    font-weight: 400;
  }

  .main-header[data-react-to-megamenu=true] .mainbar-wrap .megamenu-hover-bg {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
    transition: opacity 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  .main-header[data-react-to-megamenu=true].megamenu-item-active .megamenu-hover-bg {
    opacity: 1;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .social-icon a {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
    color: #000 !important;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .main-nav > li > a {
    color: rgba(0, 0, 0, 0.7);
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .main-nav > li > a:hover {
    color: #000;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .ld-module-search-visible-form .ld-search-form input {
    border-color: rgba(0, 0, 0, 0.2);
    color: #000;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-light .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light {
    opacity: 0;
    visibility: hidden;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .social-icon a {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .social-icon a:hover {
    color: #fff !important;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger,
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .main-nav > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .header-module .ld-module-trigger:hover,
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .main-nav > li > a:hover {
    color: #fff;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .ld-module-search-visible-form .ld-search-form input {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .ld-module-search-visible-form .input-icon {
    color: rgba(255, 255, 255, 0.7);
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-light {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .main-header[data-react-to-megamenu=true].megamenu-scheme-dark .mainbar-wrap:not(.is-stuck) .navbar-brand .logo-dark {
    opacity: 0;
    visibility: hidden;
  }

  .mainbar-row > [class^=col] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row nowrap;
  }
  .mainbar-row > [class^=col].text-right {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .mainbar-row > [class^=col].text-center {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .mainbar-row > [class^=col].text-left {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  .secondarybar-row > [class^=col].text-right {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .secondarybar-row > [class^=col].text-center {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .secondarybar-row > [class^=col].text-left {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }


  .mainbar-wrap:not(.is-stuck) .visible-when-stuck {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  /*********************************************************
  -------------
  MOBULE HEADER
  -------------
  
  - Nav trigger alignment
  [data-mobile-nav-trigger-alignment]: [left, right]
  
  - Logo alignment
  [data-mobile-logo-alignment]: [default, center]
  
  - Nav Style
  [data-mobile-nav-style]: [classic, minimal, modern]
  
  - Nav scheme
  [data-mobile-nav-scheme]: [gray, light, dark]
  
  - Nav alignment
  [data-mobile-nav-align]: [left, center, right]
  
  - Header Scheme
  [data-mobile-header-scheme]: [light, gray, dark]
  
  *********************************************************/
  .main-header {
    position: relative;
    top: auto;
    left: auto;
  }

  [data-overlay-onmobile=true] .main-header-overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-brand {
    -webkit-box-ordinal-group: 3;
            order: 2;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-brand-inner {
    margin-left: -20px !important;
  }
  [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container {
    -webkit-box-ordinal-group: 4;
            order: 3;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container + .navbar-brand {
    -webkit-box-pack: center;
            justify-content: center;
  }
  [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container + .navbar-brand,
  [data-mobile-nav-trigger-alignment=left] .navbar-header .lqd-mobile-modules-container + .navbar-brand .navbar-brand-inner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-toggle {
    -webkit-box-ordinal-group: 2;
            order: 1;
    margin-left: 0 !important;
  }

  [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand {
    margin-right: 0;
    margin-left: 0 !important;
  }
  [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand-inner {
    margin-right: -20px !important;
  }
  [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-toggle {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  [data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container + .navbar-brand {
    -webkit-box-pack: center;
            justify-content: center;
  }
  [data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container + .navbar-brand,
  [data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container + .navbar-brand .navbar-brand-inner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  [data-mobile-logo-alignment=center] .navbar-header .navbar-brand {
    margin-left: auto !important;
    margin-right: auto !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  [data-mobile-logo-alignment=center] .navbar-header .navbar-brand-inner {
    margin-left: 0;
    margin-right: 0;
  }
  [data-mobile-logo-alignment=center] .navbar-header .navbar-toggle {
    -webkit-box-flex: 0;
            flex: 0 1;
  }
  [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container {
    -webkit-box-ordinal-group: 4;
            order: 3;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container ~ .navbar-brand,
  [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container ~ .navbar-brand .navbar-brand-inner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  [data-mobile-logo-alignment=center] .navbar-header .lqd-mobile-modules-container ~ .navbar-toggle {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
  }
  [data-mobile-logo-alignment=center][data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand {
    -webkit-box-pack: center;
            justify-content: center;
  }
  [data-mobile-logo-alignment=center][data-mobile-nav-trigger-alignment=right] .navbar-header .lqd-mobile-modules-container {
    -webkit-box-ordinal-group: 2;
            order: 1;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  html[dir=rtl] [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-toggle {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  html[dir=rtl] [data-mobile-nav-trigger-alignment=right] .navbar-header .navbar-brand {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  html[dir=rtl] [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-toggle {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  html[dir=rtl] [data-mobile-nav-trigger-alignment=left] .navbar-header .navbar-brand {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  .main-header .header-module {
    display: none;
  }

  .navbar-collapse .header-module {
    display: -webkit-box;
    display: flex;
    margin-left: 0 !important;
    -webkit-box-align: start;
            align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-collapse .header-module:first-of-type {
    margin-top: 20px;
  }

  .navbar-header .header-module {
    display: -webkit-inline-box;
    display: inline-flex;
    position: static;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .navbar-header .header-module + .header-module {
    margin-left: 18px;
  }
  .navbar-header .navbar-brand + .header-module {
    margin-left: auto;
  }

  .lqd-mobile-modules-container {
    display: -webkit-box;
    display: flex;
  }

  [data-mobile-nav-align=left] .navbar-collapse .header-module {
    -webkit-box-align: start;
            align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px;
  }

  .nav-trigger {
    display: -webkit-box;
    display: flex;
  }
  .navbar-header .nav-trigger {
    margin: 0;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  .ld-module-dropdown {
    top: 100%;
  }

  .ld-module-cart .ld-module-trigger-icon {
    display: inline-block;
    position: relative;
  }
  .ld-module-cart .ld-module-trigger-icon:before, .ld-module-cart .ld-module-trigger-icon:after {
    content: '';
    display: inline-block;
    width: 1.5px;
    height: 21px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    background-color: currentColor;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transition: opacity 0.3s 0.05s, -webkit-transform 0.3s;
    transition: opacity 0.3s 0.05s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s 0.05s;
    transition: transform 0.3s, opacity 0.3s 0.05s, -webkit-transform 0.3s;
  }
  .ld-module-cart .ld-module-trigger-icon:before {
    -webkit-transform: rotate(45deg) translate(-4px, -2.5px);
            transform: rotate(45deg) translate(-4px, -2.5px);
  }
  .ld-module-cart .ld-module-trigger-icon:after {
    -webkit-transform: rotate(-45deg) translate(2px, -4px);
            transform: rotate(-45deg) translate(2px, -4px);
    left: auto;
    right: 0;
  }
  .ld-module-cart .ld-module-trigger-icon i {
    display: inline-block;
    font-family: 'liquid-icon' !important;
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  }
  .ld-module-cart .ld-module-trigger-icon i:before {
    content: "\e929";
  }
  .ld-module-cart .ld-module-trigger-count {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  }
  .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon {
    display: inline-block;
    position: relative;
  }
  .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon:before, .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon:after {
    opacity: 0;
    -webkit-transform: rotate(0) scaleY(0.75);
            transform: rotate(0) scaleY(0.75);
  }
  .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-icon i {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .ld-module-cart .ld-module-trigger.collapsed .ld-module-trigger-count {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }

  a.remove.ld-cart-product-remove {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    position: relative;
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
  }

  .ld-module-search .ld-module-dropdown {
    top: 0;
    right: 0;
  }
  .ld-module-search .ld-module-dropdown.in, .ld-module-search .ld-module-dropdown[aria-expanded=true].collapsing {
    height: 100% !important;
  }

  .ld-search-form-container {
    height: 100%;
    width: 100vw;
    padding: 0 0;
    border: none;
  }

  .ld-search-form {
    height: 100%;
  }
  .ld-search-form input {
    height: 100%;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 2px solid #eaeaea;
    border-radius: 0;
    color: inherit;
    background: none;
  }
  .ld-search-form .input-icon {
    display: -webkit-inline-box;
    display: inline-flex;
    width: 50px;
    height: 50px;
    right: 0;
    left: auto;
    color: #000;
    font-size: 36px;
    cursor: pointer;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    border-radius: 3px;
  }
  .ld-search-form .input-icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .ld-search-form .input-icon i:before {
    content: '\e94a';
  }

  .lqd-mobile-modules-container .social-icon {
    font-size: 16px;
  }
  .lqd-mobile-modules-container .social-icon li {
    margin-right: 0.75em;
  }

  .main-header .navbar-brand {
    padding: 22px 0;
    max-width: none !important;
  }
  .main-header .mobile-logo-default ~ .logo-default {
    display: none;
  }
  .main-header .main-nav {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
  }
  .main-header .main-nav > li > a {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
  }

  .navbar-header {
    padding-left: 25px;
    padding-right: 25px;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .navbar-header > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
  }

  .navbar-collapse {
    overflow-x: hidden;
    overflow-y: auto;
    color: #000;
  }
  .navbar-collapse .social-icon li a {
    color: inherit;
    opacity: 0.7;
  }
  .navbar-collapse .social-icon li a:hover {
    opacity: 1;
  }
  .navbar-collapse .btn-naked,
  .navbar-collapse .btn-underlined {
    color: inherit;
    border-color: currentColor;
  }
  .navbar-collapse .btn-naked:before, .navbar-collapse .btn-naked:after,
  .navbar-collapse .btn-underlined:before,
  .navbar-collapse .btn-underlined:after {
    background-color: currentColor;
  }
  .navbar-collapse .btn-naked:before,
  .navbar-collapse .btn-underlined:before {
    opacity: 0.5;
  }
  .navbar-collapse .btn-naked .btn-txt,
  .navbar-collapse .btn-underlined .btn-txt {
    opacity: 0.7;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .navbar-collapse .btn-naked:hover,
  .navbar-collapse .btn-underlined:hover {
    color: inherit;
  }
  .navbar-collapse .btn-naked:hover .btn-txt,
  .navbar-collapse .btn-underlined:hover .btn-txt {
    opacity: 1;
  }

  ul.nav.main-nav > li {
    padding-left: 0;
    padding-right: 0;
  }
  ul.nav.main-nav > li > a {
    display: -webkit-box;
    display: flex;
    padding: 15px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-box-align: center;
            align-items: center;
    color: #000;
  }
  ul.nav.main-nav > li > a:hover {
    color: #000;
  }
  ul.nav.main-nav + .header-module {
    margin-top: 15px;
  }

  .mainbar-row > .navbar-header {
    margin-left: 15px;
    margin-right: 15px;
  }

  [data-mobile-nav-align=center] .navbar-collapse {
    text-align: center;
  }
  [data-mobile-nav-align=center] .navbar-collapse .header-module {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  [data-mobile-nav-align=center] ul.nav.main-nav > li > a {
    -webkit-box-pack: center;
            justify-content: center;
  }

  [data-mobile-nav-align=right] .navbar-collapse .header-module {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }

  [data-mobile-nav-style=classic] .navbar-collapse,
  [data-mobile-nav-style=minimal] .navbar-collapse {
    max-height: 75vh;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }

  [data-mobile-nav-style=modern] ul.nav.main-nav,
  [data-mobile-nav-style=minimal] ul.nav.main-nav {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
  }
  [data-mobile-nav-style=modern] ul.nav.main-nav > li > a,
  [data-mobile-nav-style=minimal] ul.nav.main-nav > li > a {
    border: none;
  }

  [data-mobile-nav-style=modern]:before {
    content: '';
    display: inline-block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
    background-image: -webkit-gradient(linear, right bottom, left top, from(#1DE1BC), to(#DA0BEE));
    background-image: linear-gradient(to top left, #1DE1BC 0%, #DA0BEE 100%);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
  }
  [data-mobile-nav-style=modern] #wrap {
    -webkit-transition: height 0.3s, -webkit-transform 0.55s cubic-bezier(0.23, 1, 0.32, 1);
    transition: height 0.3s, -webkit-transform 0.55s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.55s cubic-bezier(0.23, 1, 0.32, 1), height 0.3s;
    transition: transform 0.55s cubic-bezier(0.23, 1, 0.32, 1), height 0.3s, -webkit-transform 0.55s cubic-bezier(0.23, 1, 0.32, 1);
  }
  [data-mobile-nav-style=modern] .navbar-toggle {
    pointer-events: none;
  }
  [data-mobile-nav-style=modern] .navbar-toggle.mobile-nav-trigger-cloned {
    pointer-events: all;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone {
    display: -webkit-box !important;
    display: flex !important;
    width: 70vw;
    height: 80vh !important;
    padding-top: 20px;
    border: none;
    position: fixed;
    top: 12vh;
    right: 0;
    z-index: 90;
    background: none !important;
    box-shadow: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transform: translate3d(25vw, 0, 0);
            transform: translate3d(25vw, 0, 0);
    opacity: 0;
    visibility: hidden;
    overflow: visible !important;
    -webkit-transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, -webkit-transform;
    transition-property: transform, opacity, visibility;
    transition-property: transform, opacity, visibility, -webkit-transform;
    -webkit-transition-duration: 0.45s;
            transition-duration: 0.45s;
    -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 10;
    -webkit-box-pack: end;
            justify-content: flex-end;
    color: #fff;
    pointer-events: all;
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bars {
    -webkit-box-pack: center;
            justify-content: center;
    padding-left: 8px;
    width: 42px;
    height: 42px;
    border: 2.4px solid rgba(255, 255, 255, 0.4);
    border-radius: 50em;
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar {
    background-color: #fff;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar:first-child, [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar:last-child {
    display: none;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone .nav-trigger .bar:nth-child(2) {
    -webkit-transform: translateY(2px) rotate(135deg) !important;
            transform: translateY(2px) rotate(135deg) !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav {
    -webkit-box-flex: 0;
            flex: 0 auto;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul .nav-item-children > li > a,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul > li > a,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav .nav-item-children > li > a,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav > li > a {
    color: #fff;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul .nav-item-children > li > a:hover,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul > li > a:hover,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav .nav-item-children > li > a:hover,
  [data-mobile-nav-style=modern] .navbar-collapse-clone ul.nav.main-nav > li > a:hover {
    color: #fff;
  }
  [data-mobile-nav-style=modern] .navbar-collapse-inner {
    display: block;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
  [data-mobile-nav-style=modern] .megamenu .megamenu-container {
    padding: 0 35px;
  }
  [data-mobile-nav-style=modern] .megamenu .vc_row,
  [data-mobile-nav-style=modern] .megamenu .ld-row,
  [data-mobile-nav-style=modern] .megamenu .ld-container,
  [data-mobile-nav-style=modern] .megamenu .megamenu-column,
  [data-mobile-nav-style=modern] .megamenu .vc_column-inner {
    width: 100%;
    border: none !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  [data-mobile-nav-style=modern] .megamenu .ld-fancy-heading > * {
    color: #fff;
  }
  .mobile-nav-activated [data-mobile-nav-style=modern]:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  .mobile-nav-activated [data-mobile-nav-style=modern] #wrap {
    overflow: hidden;
    background-color: #fff;
    -webkit-transform: translate3d(-80vw, 0, 0);
            transform: translate3d(-80vw, 0, 0);
  }
  .mobile-nav-activated [data-mobile-nav-style=modern] .navbar-collapse-clone {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible !important;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
  }

  .module-expanding,
  .module-collapsing {
    overflow: hidden;
  }
  .module-expanding [data-mobile-nav-style=modern] #wrap,
  .module-collapsing [data-mobile-nav-style=modern] #wrap {
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform;
    -webkit-transition-duration: 0.45s;
            transition-duration: 0.45s;
    -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    background-color: #fff;
    overflow: hidden;
  }

  .module-collapsing [data-mobile-nav-style=modern] #wrap {
    overflow: hidden;
  }

  [data-mobile-nav-scheme=gray] .navbar-collapse {
    background-color: #f9f9f9;
    color: #000;
  }
  [data-mobile-nav-scheme=gray] .main-nav .lqd-custom-menu > li > a,
  [data-mobile-nav-scheme=gray] ul.nav.main-nav > li > a {
    color: #000;
  }
  [data-mobile-nav-scheme=gray] .main-nav .lqd-custom-menu > li:hover,
  [data-mobile-nav-scheme=gray] ul.nav.main-nav > li:hover {
    color: #000;
  }

  [data-mobile-nav-scheme=dark] .navbar-collapse {
    background-color: #191D18;
    color: #fff;
  }
  [data-mobile-nav-scheme=dark] .main-nav .lqd-custom-menu > li > a,
  [data-mobile-nav-scheme=dark] ul.nav.main-nav > li > a {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  [data-mobile-nav-scheme=dark] .main-nav .lqd-custom-menu > li > a:hover,
  [data-mobile-nav-scheme=dark] ul.nav.main-nav > li > a:hover {
    color: #fff;
  }
  [data-mobile-nav-scheme=dark] .submenu-expander {
    background-color: rgba(255, 255, 255, 0.05);
  }

  [data-mobile-header-scheme=light] .navbar-header {
    background-color: #fff;
  }
  [data-mobile-header-scheme=light] .lqd-mobile-modules-container .social-icon a, [data-mobile-header-scheme=light] .lqd-mobile-modules-container .social-icon a:hover {
    color: #000;
  }
  [data-mobile-header-scheme=light] .lqd-mobile-modules-container .ld-module-trigger {
    color: #000 !important;
  }

  [data-mobile-header-scheme=gray] .main-header .navbar-header {
    background-color: #f6f6f6;
  }
  [data-mobile-header-scheme=gray] .main-header .lqd-mobile-modules-container .social-icon a, [data-mobile-header-scheme=gray] .main-header .lqd-mobile-modules-container .social-icon a:hover {
    color: #000;
  }
  [data-mobile-header-scheme=gray] .main-header .ld-module-trigger {
    color: #000 !important;
  }

  [data-mobile-header-scheme=dark] .navbar-header {
    background-color: #191D18;
  }
  [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .social-icon a, [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .social-icon a:hover {
    color: #000;
  }
  [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-module-trigger {
    color: #fff !important;
  }
  [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form-container {
    background-color: #191D18;
  }
  [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form input {
    border-color: rgba(255, 255, 255, 0.45);
    color: #fff;
  }
  [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form .input-icon {
    color: #fff;
  }
  [data-mobile-header-scheme=dark] .lqd-mobile-modules-container .ld-search-form .input-icon:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [data-mobile-header-scheme=dark] .nav-trigger .bar {
    background-color: #fff;
  }

  html[dir=rtl] [data-mobile-nav-style=modern] .navbar-collapse-clone {
    right: 10vw;
  }

  .main-nav .children,
  .nav-item-children {
    display: none;
    min-width: 0;
    padding: 15px 0;
    border-radius: 0;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    visibility: visible;
    text-align: inherit;
    box-shadow: none;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
  }
  .main-nav .children > li > a,
  .nav-item-children > li > a {
    padding: 8px 35px;
    color: inherit;
  }
  .main-nav .children > li:hover > a,
  .nav-item-children > li:hover > a {
    background: none;
  }
  .main-nav .children .nav-item-children,
  .nav-item-children .nav-item-children {
    padding: 8px 0 8px 15px;
  }

  [data-mobile-nav-scheme=dark] .nav-item-children {
    background-color: #1b201a;
  }
  [data-mobile-nav-scheme=dark] .nav-item-children > li > a {
    opacity: 0.75;
  }
  [data-mobile-nav-scheme=dark] .nav-item-children > li:hover > a, [data-mobile-nav-scheme=dark] .nav-item-children > li.active > a, [data-mobile-nav-scheme=dark] .nav-item-children > li.current-menu-item > a {
    color: inherit;
    opacity: 1;
  }

  [data-mobile-nav-align=center] .nav-item-children {
    text-align: center;
  }
  [data-mobile-nav-align=center] .nav-item-children .nav-item-children {
    padding-left: 15px;
    padding-right: 15px;
  }

  [data-mobile-nav-style=minimal] .nav-item-children {
    font-size: 14px;
  }

  [data-mobile-nav-style=modern] .main-nav .children,
  [data-mobile-nav-style=modern] .nav-item-children {
    background: transparent;
  }

  .megamenu .nav-item-children {
    left: auto !important;
  }
  .megamenu .megamenu-container {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .megamenu .megamenu-column {
    padding-left: 15px;
    padding-right: 15px;
  }
  .megamenu .ld-container,
  .megamenu .vc_column-inner {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .megamenu .ld-row {
    display: block;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .megamenu .vc_row,
  .megamenu .wpb_wrapper {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .megamenu .vc_row {
    border: none !important;
  }
  .megamenu .wpb_single_image.invisible {
    visibility: visible;
  }

  .megamenu-container {
    width: auto !important;
  }

  .main-header .mainbar-wrap {
    padding: 0 !important;
    margin: 0 !important;
  }
  .main-header .mainbar-wrap .mainbar-container {
    width: 100%;
    max-width: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .main-header .mainbar-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .main-header .mainbar-row > [class^=col] {
    -webkit-box-flex: 1;
            flex: 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    min-height: 0;
  }
  .main-header .mainbar-row > [class^=col] > .main-nav {
    display: none;
  }

  .secondarybar-wrap {
    display: none;
  }
  [data-mobile-secondary-bar=true] .secondarybar-wrap {
    display: block;
  }
  [data-mobile-secondary-bar=true] .secondarybar-wrap .header-module {
    display: -webkit-inline-box;
    display: inline-flex;
  }
}
/* 7.2. Elements */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-underline {
  text-decoration: underline;
}

.ltr-sp-0 {
  letter-spacing: 0 !important;
}

.ltr-sp--015 {
  letter-spacing: -0.015em !important;
}

.ltr-sp--025 {
  letter-spacing: -0.025em !important;
}

.ltr-sp--05 {
  letter-spacing: -0.05em !important;
}

.ltr-sp--075 {
  letter-spacing: -0.075em !important;
}

.ltr-sp--1 {
  letter-spacing: -0.1em !important;
}

.ltr-sp--135 {
  letter-spacing: -0.135em !important;
}

.ltr-sp--15 {
  letter-spacing: -0.15em !important;
}

.ltr-sp--175 {
  letter-spacing: -0.175em !important;
}

.ltr-sp--2 {
  letter-spacing: -0.2em !important;
}

.ltr-sp-015 {
  letter-spacing: 0.015em !important;
}

.ltr-sp-025 {
  letter-spacing: 0.025em !important;
}

.ltr-sp-05 {
  letter-spacing: 0.05em !important;
}

.ltr-sp-075 {
  letter-spacing: 0.075em !important;
}

.ltr-sp-1 {
  letter-spacing: 0.1em !important;
}

.ltr-sp-135 {
  letter-spacing: 0.135em !important;
}

.ltr-sp-15 {
  letter-spacing: 0.15em !important;
}

.ltr-sp-175 {
  letter-spacing: 0.175em !important;
}

.ltr-sp-2 {
  letter-spacing: 0.2em !important;
}

.ltr-sp-25 {
  letter-spacing: 0.25em !important;
}

.ltr-sp-3 {
  letter-spacing: 0.3em !important;
}

.ltr-sp-35 {
  letter-spacing: 0.35em !important;
}

.ltr-sp-4 {
  letter-spacing: 0.4em !important;
}

.ltr-sp-5 {
  letter-spacing: 0.5em !important;
}

.lh-05 {
  line-height: 0.5em !important;
}

.lh-55 {
  line-height: 0.55em !important;
}

.lh-75 {
  line-height: 0.75em !important;
}

.lh-85 {
  line-height: 0.85em !important;
}

.lh-1 {
  line-height: 1em !important;
}

.lh-105 {
  line-height: 1.05em !important;
}

.lh-11 {
  line-height: 1.1em !important;
}

.lh-115 {
  line-height: 1.15em !important;
}

.lh-125 {
  line-height: 1.25em !important;
}

.lh-13 {
  line-height: 1.3em !important;
}

.lh-15 {
  line-height: 1.5em !important;
}

.lh-16 {
  line-height: 1.6em !important;
}

.lh-165 {
  line-height: 1.65em !important;
}

.lh-175 {
  line-height: 1.75em !important;
}

.lh-185 {
  line-height: 1.85em !important;
}

.lh-2 {
  line-height: 2em !important;
}

.lh-215 {
  line-height: 2.15em !important;
}

.lh-225 {
  line-height: 2.25em !important;
}

.lh-25 {
  line-height: 2.5em !important;
}

.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extrabold {
  font-weight: 900 !important;
}

.font-style-normal {
  font-style: normal !important;
}

.font-style-italic {
  font-style: italic !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-34 {
  font-size: 34px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-38 {
  font-size: 38px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-42 {
  font-size: 42px !important;
}

.font-size-44 {
  font-size: 44px !important;
}

.font-size-46 {
  font-size: 46px !important;
}

.font-size-48 {
  font-size: 48px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.font-size-1-15x {
  font-size: 1.15em;
}

.font-size-1-25x {
  font-size: 1.25em;
}

.font-size-1-5x {
  font-size: 1.5em;
}

.font-size-1-6x {
  font-size: 1.6em;
}

.font-size-1-75x {
  font-size: 1.75em;
}

.font-size-2x {
  font-size: 2em;
}

.font-size-2-15x {
  font-size: 2.15em;
}

.font-size-2-25x {
  font-size: 2.25em;
}

.font-size-2-5x {
  font-size: 2.5em;
}

.font-size-2-6x {
  font-size: 2.6em;
}

.font-size-2-75x {
  font-size: 2.75em;
}

.font-size-3x {
  font-size: 3em;
}

.font-size-3-15x {
  font-size: 3.15em;
}

.font-size-3-3x {
  font-size: 3.3em;
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-secondary {
  color: var(--color-secondary) !important;
}

.text-tertiary {
  color: var(--color-tertiary) !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-dark {
  color: #181b31 !important;
}

.text-havelock-blue {
  color: #4c93dc !important;
}

.text-turquoise {
  color: #3cd3d1 !important;
}

.text-neon-carrot {
  color: #ff8e32 !important;
}

.text-arapawa {
  color: #120a57 !important;
}

.text-san-marino {
  color: #4A62AC !important;
}

.text-hover-primary:hover {
  color: var(--color-primary) !important;
}

.text-hover-secondary:hover {
  color: var(--color-secondary) !important;
}

.text-hover-white:hover {
  color: #fff !important;
}

.text-hover-black:hover {
  color: #000 !important;
}

.text-hover-dark:hover {
  color: #181b31 !important;
}

.text-fade-white-01 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.text-fade-dark-01 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.text-fade-white-02 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.text-fade-dark-02 {
  color: rgba(0, 0, 0, 0.2) !important;
}

.text-fade-white-03 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.text-fade-dark-03 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.text-fade-white-04 {
  color: rgba(255, 255, 255, 0.4) !important;
}

.text-fade-dark-04 {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-fade-white-05 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-fade-dark-05 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-fade-white-06 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-fade-dark-06 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.text-fade-white-07 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-fade-dark-07 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-fade-white-08 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.text-fade-dark-08 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.text-fade-white-09 {
  color: rgba(255, 255, 255, 0.9) !important;
}

.text-fade-dark-09 {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.pt-205 {
  padding-top: 205px !important;
}

.pb-205 {
  padding-bottom: 205px !important;
}

.mt-205 {
  margin-top: 205px !important;
}

.mb-205 {
  margin-bottom: 205px !important;
}

.pt-210 {
  padding-top: 210px !important;
}

.pb-210 {
  padding-bottom: 210px !important;
}

.mt-210 {
  margin-top: 210px !important;
}

.mb-210 {
  margin-bottom: 210px !important;
}

.pt-215 {
  padding-top: 215px !important;
}

.pb-215 {
  padding-bottom: 215px !important;
}

.mt-215 {
  margin-top: 215px !important;
}

.mb-215 {
  margin-bottom: 215px !important;
}

.pt-220 {
  padding-top: 220px !important;
}

.pb-220 {
  padding-bottom: 220px !important;
}

.mt-220 {
  margin-top: 220px !important;
}

.mb-220 {
  margin-bottom: 220px !important;
}

.pt-225 {
  padding-top: 225px !important;
}

.pb-225 {
  padding-bottom: 225px !important;
}

.mt-225 {
  margin-top: 225px !important;
}

.mb-225 {
  margin-bottom: 225px !important;
}

.pt-230 {
  padding-top: 230px !important;
}

.pb-230 {
  padding-bottom: 230px !important;
}

.mt-230 {
  margin-top: 230px !important;
}

.mb-230 {
  margin-bottom: 230px !important;
}

.pt-235 {
  padding-top: 235px !important;
}

.pb-235 {
  padding-bottom: 235px !important;
}

.mt-235 {
  margin-top: 235px !important;
}

.mb-235 {
  margin-bottom: 235px !important;
}

.pt-240 {
  padding-top: 240px !important;
}

.pb-240 {
  padding-bottom: 240px !important;
}

.mt-240 {
  margin-top: 240px !important;
}

.mb-240 {
  margin-bottom: 240px !important;
}

.pt-245 {
  padding-top: 245px !important;
}

.pb-245 {
  padding-bottom: 245px !important;
}

.mt-245 {
  margin-top: 245px !important;
}

.mb-245 {
  margin-bottom: 245px !important;
}

.pt-250 {
  padding-top: 250px !important;
}

.pb-250 {
  padding-bottom: 250px !important;
}

.mt-250 {
  margin-top: 250px !important;
}

.mb-250 {
  margin-bottom: 250px !important;
}

.pt-255 {
  padding-top: 255px !important;
}

.pb-255 {
  padding-bottom: 255px !important;
}

.mt-255 {
  margin-top: 255px !important;
}

.mb-255 {
  margin-bottom: 255px !important;
}

.pt-260 {
  padding-top: 260px !important;
}

.pb-260 {
  padding-bottom: 260px !important;
}

.mt-260 {
  margin-top: 260px !important;
}

.mb-260 {
  margin-bottom: 260px !important;
}

.pt-265 {
  padding-top: 265px !important;
}

.pb-265 {
  padding-bottom: 265px !important;
}

.mt-265 {
  margin-top: 265px !important;
}

.mb-265 {
  margin-bottom: 265px !important;
}

.pt-270 {
  padding-top: 270px !important;
}

.pb-270 {
  padding-bottom: 270px !important;
}

.mt-270 {
  margin-top: 270px !important;
}

.mb-270 {
  margin-bottom: 270px !important;
}

.pt-275 {
  padding-top: 275px !important;
}

.pb-275 {
  padding-bottom: 275px !important;
}

.mt-275 {
  margin-top: 275px !important;
}

.mb-275 {
  margin-bottom: 275px !important;
}

.pt-280 {
  padding-top: 280px !important;
}

.pb-280 {
  padding-bottom: 280px !important;
}

.mt-280 {
  margin-top: 280px !important;
}

.mb-280 {
  margin-bottom: 280px !important;
}

.pt-285 {
  padding-top: 285px !important;
}

.pb-285 {
  padding-bottom: 285px !important;
}

.mt-285 {
  margin-top: 285px !important;
}

.mb-285 {
  margin-bottom: 285px !important;
}

.branded [class*=fa-behance] {
  background-color: #1769ff;
}

.branded [class*=fa-behance-square] {
  background-color: #1769ff;
}

.branded [class*=fa-codepen] {
  background-color: #0ebeff;
}

.branded [class*=fa-deviantart] {
  background-color: #05cc47;
}

.branded [class*=fa-digg] {
  background-color: #005be2;
}

.branded [class*=fa-dribbble] {
  background-color: #ea4c89;
}

.branded [class*=fa-facebook] {
  background-color: #3b5998;
}

.branded [class*=fa-facebook-square] {
  background-color: #3b5998;
}

.branded [class*=fa-flickr] {
  background-color: #0063dc;
}

.branded [class*=fa-github] {
  background-color: #4078c0;
}

.branded [class*=fa-google] {
  background-color: #4285f4;
}

.branded [class*=fa-google-plus] {
  background-color: #dd4b39;
}

.branded [class*=fa-instagram] {
  background-color: #405de6;
}

.branded [class*=fa-jsfiddle] {
  background-color: #0084FF;
}

.branded [class*=fa-linkedin] {
  background-color: #0077b5;
}

.branded [class*=fa-medium] {
  background-color: #00ab6c;
}

.branded [class*=fa-paypal] {
  background-color: #003087;
}

.branded [class*=fa-pinterest] {
  background-color: #bd081c;
}

.branded [class*=fa-pinterest-p] {
  background-color: #bd081c;
}

.branded [class*=fa-reddit] {
  background-color: #ff4500;
}

.branded [class*=fa-reddit-square] {
  background-color: #ff4500;
}

.branded [class*=fa-skype] {
  background-color: #00aff0;
}

.branded [class*=fa-slack] {
  background-color: #6ecadc;
}

.branded [class*=fa-snapchat] {
  background-color: #fffc00;
}

.branded [class*=fa-soundcloud] {
  background-color: #ff8800;
}

.branded [class*=fa-spotify] {
  background-color: #1db954;
}

.branded [class*=fa-stack-overflow] {
  background-color: #f48024;
}

.branded [class*=fa-telegram] {
  background-color: #0088cc;
}

.branded [class*=fa-trello] {
  background-color: #0079bf;
}

.branded [class*=fa-tumblr] {
  background-color: #35465c;
}

.branded [class*=fa-twitch] {
  background-color: #6441a5;
}

.branded [class*=fa-twitter] {
  background-color: #1da1f2;
}

.branded [class*=fa-twitter-square] {
  background-color: #1da1f2;
}

.branded [class*=fa-vimeo] {
  background-color: #1ab7ea;
}

.branded [class*=fa-wordpress] {
  background-color: #21759b;
}

.branded [class*=fa-youtube] {
  background-color: #ff0000;
}

.branded [class*=fa-youtube-play] {
  background-color: #ff0000;
}

.branded-text [class*=fa-behance] {
  color: #1769ff;
}

.branded-text [class*=fa-behance-square] {
  color: #1769ff;
}

.branded-text [class*=fa-codepen] {
  color: #0ebeff;
}

.branded-text [class*=fa-deviantart] {
  color: #05cc47;
}

.branded-text [class*=fa-digg] {
  color: #005be2;
}

.branded-text [class*=fa-dribbble] {
  color: #ea4c89;
}

.branded-text [class*=fa-facebook] {
  color: #3b5998;
}

.branded-text [class*=fa-facebook-square] {
  color: #3b5998;
}

.branded-text [class*=fa-flickr] {
  color: #0063dc;
}

.branded-text [class*=fa-github] {
  color: #4078c0;
}

.branded-text [class*=fa-google] {
  color: #4285f4;
}

.branded-text [class*=fa-google-plus] {
  color: #dd4b39;
}

.branded-text [class*=fa-instagram] {
  color: #405de6;
}

.branded-text [class*=fa-jsfiddle] {
  color: #0084FF;
}

.branded-text [class*=fa-linkedin] {
  color: #0077b5;
}

.branded-text [class*=fa-medium] {
  color: #00ab6c;
}

.branded-text [class*=fa-paypal] {
  color: #003087;
}

.branded-text [class*=fa-pinterest] {
  color: #bd081c;
}

.branded-text [class*=fa-pinterest-p] {
  color: #bd081c;
}

.branded-text [class*=fa-reddit] {
  color: #ff4500;
}

.branded-text [class*=fa-reddit-square] {
  color: #ff4500;
}

.branded-text [class*=fa-skype] {
  color: #00aff0;
}

.branded-text [class*=fa-slack] {
  color: #6ecadc;
}

.branded-text [class*=fa-snapchat] {
  color: #fffc00;
}

.branded-text [class*=fa-soundcloud] {
  color: #ff8800;
}

.branded-text [class*=fa-spotify] {
  color: #1db954;
}

.branded-text [class*=fa-stack-overflow] {
  color: #f48024;
}

.branded-text [class*=fa-telegram] {
  color: #0088cc;
}

.branded-text [class*=fa-trello] {
  color: #0079bf;
}

.branded-text [class*=fa-tumblr] {
  color: #35465c;
}

.branded-text [class*=fa-twitch] {
  color: #6441a5;
}

.branded-text [class*=fa-twitter] {
  color: #1da1f2;
}

.branded-text [class*=fa-twitter-square] {
  color: #1da1f2;
}

.branded-text [class*=fa-vimeo] {
  color: #1ab7ea;
}

.branded-text [class*=fa-wordpress] {
  color: #21759b;
}

.branded-text [class*=fa-youtube] {
  color: #ff0000;
}

.branded-text [class*=fa-youtube-play] {
  color: #ff0000;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.star-rating {
  padding: 0;
  margin: 0;
  list-style: none;
}
.star-rating li {
  display: inline-block;
  font-size: 1em;
  line-height: 1.5em;
  color: #ffcc00;
  letter-spacing: 0.1em;
  margin: 0;
}
.star-rating.square li {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 30px;
  height: 30px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  border-radius: 2px;
  font-size: 20px;
  color: #fff;
  background-color: #ff7a4b;
}
.star-rating.square.sm li {
  width: 13px;
  height: 13px;
  font-size: 8px;
}

.liquid-overlay-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}

.add-dropcap:first-letter {
  float: left;
  margin-right: 0.1em;
  font-size: 4em;
  line-height: 0.75em;
  font-weight: 700;
  color: #000;
}

.fullwidth {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.fullheight {
  min-height: 100vh;
}
.is-ie .fullheight {
  height: 100vh;
}

[data-hover3d=true] [data-stacking-factor] {
  -webkit-transition: none;
  transition: none;
}

.ld-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.perspective {
  -webkit-perspective: 1800px;
          perspective: 1800px;
}

.will-change {
  will-change: transform, opacity;
}

.transition-none {
  -webkit-transition: none !important;
  transition: none !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute !important;
}

.pos-fix {
  position: fixed !important;
}

.pos-stc {
  position: static !important;
}

.border-gray {
  border: 1px solid #ddd;
}

.border-athens-gray {
  border: 1px solid #E8E9F1;
}

.bb-light {
  border-bottom: 1px solid #ebedf6;
}

.border-fade-white-005 {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.border-fade-black-005 {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.bt-fade-white-005 {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.bb-fade-white-005 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.br-fade-white-005 {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.bl-fade-white-005 {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}

.bt-fade-black-005 {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.bb-fade-black-005 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.br-fade-black-005 {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.bl-fade-black-005 {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.border-fade-white-01 {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.border-fade-black-01 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.bt-fade-white-01 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.bb-fade-white-01 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.br-fade-white-01 {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.bl-fade-white-01 {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.bt-fade-black-01 {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bb-fade-black-01 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.br-fade-black-01 {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.bl-fade-black-01 {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.border-fade-white-015 {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.border-fade-black-015 {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.bt-fade-white-015 {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.bb-fade-white-015 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.br-fade-white-015 {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.bl-fade-white-015 {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.bt-fade-black-015 {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.bb-fade-black-015 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.br-fade-black-015 {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.bl-fade-black-015 {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.border-fade-white-02 {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.border-fade-black-02 {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.bt-fade-white-02 {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bb-fade-white-02 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.br-fade-white-02 {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.bl-fade-white-02 {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.bt-fade-black-02 {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.bb-fade-black-02 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.br-fade-black-02 {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.bl-fade-black-02 {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.border-fade-white-025 {
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.border-fade-black-025 {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.bt-fade-white-025 {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.bb-fade-white-025 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.br-fade-white-025 {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.bl-fade-white-025 {
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}

.bt-fade-black-025 {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.bb-fade-black-025 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.br-fade-black-025 {
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.bl-fade-black-025 {
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}

.border-fade-white-03 {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.border-fade-black-03 {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.bt-fade-white-03 {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.bb-fade-white-03 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.br-fade-white-03 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.bl-fade-white-03 {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.bt-fade-black-03 {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.bb-fade-black-03 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.br-fade-black-03 {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.bl-fade-black-03 {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

.border-fade-white-035 {
  border: 1px solid rgba(255, 255, 255, 0.35);
}

.border-fade-black-035 {
  border: 1px solid rgba(0, 0, 0, 0.35);
}

.bt-fade-white-035 {
  border-top: 1px solid rgba(255, 255, 255, 0.35);
}

.bb-fade-white-035 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
}

.br-fade-white-035 {
  border-right: 1px solid rgba(255, 255, 255, 0.35);
}

.bl-fade-white-035 {
  border-left: 1px solid rgba(255, 255, 255, 0.35);
}

.bt-fade-black-035 {
  border-top: 1px solid rgba(0, 0, 0, 0.35);
}

.bb-fade-black-035 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

.br-fade-black-035 {
  border-right: 1px solid rgba(0, 0, 0, 0.35);
}

.bl-fade-black-035 {
  border-left: 1px solid rgba(0, 0, 0, 0.35);
}

.border-fade-white-04 {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.border-fade-black-04 {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.bt-fade-white-04 {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.bb-fade-white-04 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.br-fade-white-04 {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.bl-fade-white-04 {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.bt-fade-black-04 {
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.bb-fade-black-04 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.br-fade-black-04 {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.bl-fade-black-04 {
  border-left: 1px solid rgba(0, 0, 0, 0.4);
}

.border-fade-white-045 {
  border: 1px solid rgba(255, 255, 255, 0.45);
}

.border-fade-black-045 {
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.bt-fade-white-045 {
  border-top: 1px solid rgba(255, 255, 255, 0.45);
}

.bb-fade-white-045 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
}

.br-fade-white-045 {
  border-right: 1px solid rgba(255, 255, 255, 0.45);
}

.bl-fade-white-045 {
  border-left: 1px solid rgba(255, 255, 255, 0.45);
}

.bt-fade-black-045 {
  border-top: 1px solid rgba(0, 0, 0, 0.45);
}

.bb-fade-black-045 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.br-fade-black-045 {
  border-right: 1px solid rgba(0, 0, 0, 0.45);
}

.bl-fade-black-045 {
  border-left: 1px solid rgba(0, 0, 0, 0.45);
}

.border-fade-white-05 {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.border-fade-black-05 {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.bt-fade-white-05 {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.bb-fade-white-05 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.br-fade-white-05 {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.bl-fade-white-05 {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.bt-fade-black-05 {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.bb-fade-black-05 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.br-fade-black-05 {
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}

.bl-fade-black-05 {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.border-fade-white-055 {
  border: 1px solid rgba(255, 255, 255, 0.55);
}

.border-fade-black-055 {
  border: 1px solid rgba(0, 0, 0, 0.55);
}

.bt-fade-white-055 {
  border-top: 1px solid rgba(255, 255, 255, 0.55);
}

.bb-fade-white-055 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.55);
}

.br-fade-white-055 {
  border-right: 1px solid rgba(255, 255, 255, 0.55);
}

.bl-fade-white-055 {
  border-left: 1px solid rgba(255, 255, 255, 0.55);
}

.bt-fade-black-055 {
  border-top: 1px solid rgba(0, 0, 0, 0.55);
}

.bb-fade-black-055 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.55);
}

.br-fade-black-055 {
  border-right: 1px solid rgba(0, 0, 0, 0.55);
}

.bl-fade-black-055 {
  border-left: 1px solid rgba(0, 0, 0, 0.55);
}

.border-fade-white-06 {
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.border-fade-black-06 {
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.bt-fade-white-06 {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.bb-fade-white-06 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.br-fade-white-06 {
  border-right: 1px solid rgba(255, 255, 255, 0.6);
}

.bl-fade-white-06 {
  border-left: 1px solid rgba(255, 255, 255, 0.6);
}

.bt-fade-black-06 {
  border-top: 1px solid rgba(0, 0, 0, 0.6);
}

.bb-fade-black-06 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.br-fade-black-06 {
  border-right: 1px solid rgba(0, 0, 0, 0.6);
}

.bl-fade-black-06 {
  border-left: 1px solid rgba(0, 0, 0, 0.6);
}

.border-fade-white-065 {
  border: 1px solid rgba(255, 255, 255, 0.65);
}

.border-fade-black-065 {
  border: 1px solid rgba(0, 0, 0, 0.65);
}

.bt-fade-white-065 {
  border-top: 1px solid rgba(255, 255, 255, 0.65);
}

.bb-fade-white-065 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
}

.br-fade-white-065 {
  border-right: 1px solid rgba(255, 255, 255, 0.65);
}

.bl-fade-white-065 {
  border-left: 1px solid rgba(255, 255, 255, 0.65);
}

.bt-fade-black-065 {
  border-top: 1px solid rgba(0, 0, 0, 0.65);
}

.bb-fade-black-065 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.65);
}

.br-fade-black-065 {
  border-right: 1px solid rgba(0, 0, 0, 0.65);
}

.bl-fade-black-065 {
  border-left: 1px solid rgba(0, 0, 0, 0.65);
}

.border-fade-white-07 {
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.border-fade-black-07 {
  border: 1px solid rgba(0, 0, 0, 0.7);
}

.bt-fade-white-07 {
  border-top: 1px solid rgba(255, 255, 255, 0.7);
}

.bb-fade-white-07 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

.br-fade-white-07 {
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}

.bl-fade-white-07 {
  border-left: 1px solid rgba(255, 255, 255, 0.7);
}

.bt-fade-black-07 {
  border-top: 1px solid rgba(0, 0, 0, 0.7);
}

.bb-fade-black-07 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
}

.br-fade-black-07 {
  border-right: 1px solid rgba(0, 0, 0, 0.7);
}

.bl-fade-black-07 {
  border-left: 1px solid rgba(0, 0, 0, 0.7);
}

.border-fade-white-075 {
  border: 1px solid rgba(255, 255, 255, 0.75);
}

.border-fade-black-075 {
  border: 1px solid rgba(0, 0, 0, 0.75);
}

.bt-fade-white-075 {
  border-top: 1px solid rgba(255, 255, 255, 0.75);
}

.bb-fade-white-075 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
}

.br-fade-white-075 {
  border-right: 1px solid rgba(255, 255, 255, 0.75);
}

.bl-fade-white-075 {
  border-left: 1px solid rgba(255, 255, 255, 0.75);
}

.bt-fade-black-075 {
  border-top: 1px solid rgba(0, 0, 0, 0.75);
}

.bb-fade-black-075 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
}

.br-fade-black-075 {
  border-right: 1px solid rgba(0, 0, 0, 0.75);
}

.bl-fade-black-075 {
  border-left: 1px solid rgba(0, 0, 0, 0.75);
}

.border-fade-white-08 {
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.border-fade-black-08 {
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.bt-fade-white-08 {
  border-top: 1px solid rgba(255, 255, 255, 0.8);
}

.bb-fade-white-08 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.br-fade-white-08 {
  border-right: 1px solid rgba(255, 255, 255, 0.8);
}

.bl-fade-white-08 {
  border-left: 1px solid rgba(255, 255, 255, 0.8);
}

.bt-fade-black-08 {
  border-top: 1px solid rgba(0, 0, 0, 0.8);
}

.bb-fade-black-08 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.br-fade-black-08 {
  border-right: 1px solid rgba(0, 0, 0, 0.8);
}

.bl-fade-black-08 {
  border-left: 1px solid rgba(0, 0, 0, 0.8);
}

.border-fade-white-085 {
  border: 1px solid rgba(255, 255, 255, 0.85);
}

.border-fade-black-085 {
  border: 1px solid rgba(0, 0, 0, 0.85);
}

.bt-fade-white-085 {
  border-top: 1px solid rgba(255, 255, 255, 0.85);
}

.bb-fade-white-085 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.85);
}

.br-fade-white-085 {
  border-right: 1px solid rgba(255, 255, 255, 0.85);
}

.bl-fade-white-085 {
  border-left: 1px solid rgba(255, 255, 255, 0.85);
}

.bt-fade-black-085 {
  border-top: 1px solid rgba(0, 0, 0, 0.85);
}

.bb-fade-black-085 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
}

.br-fade-black-085 {
  border-right: 1px solid rgba(0, 0, 0, 0.85);
}

.bl-fade-black-085 {
  border-left: 1px solid rgba(0, 0, 0, 0.85);
}

.bb-gray {
  border-bottom: 1px solid #edecec;
}

.border-color-white {
  border-color: #fff;
}

.border-color-primary {
  border-color: var(--color-primary);
}

.border-color-secondary {
  border-color: var(--color-secondary);
}

.border-none {
  border: none !important;
}

.bordered-section-white {
  border: 2vw solid #fff;
}

.bordered-section-white-3vw {
  border: 3vw solid #fff;
}

.border-width-1 {
  border-width: 1px;
}

.border-width-2 {
  border-width: 2px;
}

.border-width-3 {
  border-width: 3px;
}

.border-width-4 {
  border-width: 4px;
}

.border-width-5 {
  border-width: 5px;
}

.border-width-6 {
  border-width: 6px;
}

.border-width-7 {
  border-width: 7px;
}

.border-width-8 {
  border-width: 8px;
}

.border-width-9 {
  border-width: 9px;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.border-radius-1 {
  border-radius: 1px !important;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-7 {
  border-radius: 7px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-9 {
  border-radius: 9px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-11 {
  border-radius: 11px !important;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.border-radius-13 {
  border-radius: 13px !important;
}

.border-radius-14 {
  border-radius: 14px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.border-radius-17 {
  border-radius: 17px !important;
}

.border-radius-18 {
  border-radius: 18px !important;
}

.border-radius-19 {
  border-radius: 19px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border-radius-21 {
  border-radius: 21px !important;
}

.border-radius-22 {
  border-radius: 22px !important;
}

.border-radius-23 {
  border-radius: 23px !important;
}

.border-radius-24 {
  border-radius: 24px !important;
}

.border-radius-25 {
  border-radius: 25px !important;
}

.semi-round {
  border-radius: 2px;
}

.round {
  border-radius: 4px;
}

.circle {
  border-radius: 50em;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-105 {
  width: 105% !important;
}

.w-110 {
  width: 110% !important;
}

.w-115 {
  width: 115% !important;
}

.w-120 {
  width: 120% !important;
}

.w-125 {
  width: 125% !important;
}

.w-130 {
  width: 130% !important;
}

.w-135 {
  width: 135% !important;
}

.w-140 {
  width: 140% !important;
}

.w-145 {
  width: 145% !important;
}

.w-150 {
  width: 150% !important;
}

.w-155 {
  width: 155% !important;
}

.w-160 {
  width: 160% !important;
}

.w-165 {
  width: 165% !important;
}

.w-170 {
  width: 170% !important;
}

.w-175 {
  width: 175% !important;
}

.w-180 {
  width: 180% !important;
}

.w-185 {
  width: 185% !important;
}

.w-190 {
  width: 190% !important;
}

.w-195 {
  width: 195% !important;
}

.w-200 {
  width: 200% !important;
}

.h-pt-5 {
  padding-top: 5%;
}

.h-5 {
  height: 5%;
}

.h-pt-10 {
  padding-top: 10%;
}

.h-10 {
  height: 10%;
}

.h-pt-15 {
  padding-top: 15%;
}

.h-15 {
  height: 15%;
}

.h-pt-20 {
  padding-top: 20%;
}

.h-20 {
  height: 20%;
}

.h-pt-25 {
  padding-top: 25%;
}

.h-25 {
  height: 25%;
}

.h-pt-30 {
  padding-top: 30%;
}

.h-30 {
  height: 30%;
}

.h-pt-35 {
  padding-top: 35%;
}

.h-35 {
  height: 35%;
}

.h-pt-40 {
  padding-top: 40%;
}

.h-40 {
  height: 40%;
}

.h-pt-45 {
  padding-top: 45%;
}

.h-45 {
  height: 45%;
}

.h-pt-50 {
  padding-top: 50%;
}

.h-50 {
  height: 50%;
}

.h-pt-55 {
  padding-top: 55%;
}

.h-55 {
  height: 55%;
}

.h-pt-60 {
  padding-top: 60%;
}

.h-60 {
  height: 60%;
}

.h-pt-65 {
  padding-top: 65%;
}

.h-65 {
  height: 65%;
}

.h-pt-70 {
  padding-top: 70%;
}

.h-70 {
  height: 70%;
}

.h-pt-75 {
  padding-top: 75%;
}

.h-75 {
  height: 75%;
}

.h-pt-80 {
  padding-top: 80%;
}

.h-80 {
  height: 80%;
}

.h-pt-85 {
  padding-top: 85%;
}

.h-85 {
  height: 85%;
}

.h-pt-90 {
  padding-top: 90%;
}

.h-90 {
  height: 90%;
}

.h-pt-95 {
  padding-top: 95%;
}

.h-95 {
  height: 95%;
}

.h-pt-100 {
  padding-top: 100%;
}

.h-100 {
  height: 100%;
}

.h-pt-105 {
  padding-top: 105%;
}

.h-105 {
  height: 105%;
}

.h-pt-110 {
  padding-top: 110%;
}

.h-110 {
  height: 110%;
}

.h-pt-115 {
  padding-top: 115%;
}

.h-115 {
  height: 115%;
}

.h-pt-120 {
  padding-top: 120%;
}

.h-120 {
  height: 120%;
}

.h-pt-125 {
  padding-top: 125%;
}

.h-125 {
  height: 125%;
}

.h-pt-130 {
  padding-top: 130%;
}

.h-130 {
  height: 130%;
}

.h-pt-135 {
  padding-top: 135%;
}

.h-135 {
  height: 135%;
}

.h-pt-140 {
  padding-top: 140%;
}

.h-140 {
  height: 140%;
}

.h-pt-145 {
  padding-top: 145%;
}

.h-145 {
  height: 145%;
}

.h-pt-150 {
  padding-top: 150%;
}

.h-150 {
  height: 150%;
}

.h-pt-155 {
  padding-top: 155%;
}

.h-155 {
  height: 155%;
}

.h-pt-160 {
  padding-top: 160%;
}

.h-160 {
  height: 160%;
}

.h-pt-165 {
  padding-top: 165%;
}

.h-165 {
  height: 165%;
}

.h-pt-170 {
  padding-top: 170%;
}

.h-170 {
  height: 170%;
}

.h-pt-175 {
  padding-top: 175%;
}

.h-175 {
  height: 175%;
}

.h-pt-180 {
  padding-top: 180%;
}

.h-180 {
  height: 180%;
}

.h-pt-185 {
  padding-top: 185%;
}

.h-185 {
  height: 185%;
}

.h-pt-190 {
  padding-top: 190%;
}

.h-190 {
  height: 190%;
}

.h-pt-195 {
  padding-top: 195%;
}

.h-195 {
  height: 195%;
}

.h-pt-200 {
  padding-top: 200%;
}

.h-200 {
  height: 200%;
}

.fullheight {
  min-height: 100vh;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-gray {
  background-color: #f4f5f8 !important;
}

.bg-gray-2 {
  background-color: #fafafa !important;
}

.bg-gray-3 {
  background-color: #f1f1f1 !important;
}

.bg-light {
  background: #f7f8fb !important;
}

.bg-dark {
  background: #212121 !important;
}

.bg-mirage {
  background: #181b31 !important;
}

.bg-vulcan {
  background: #141622 !important;
}

.bg-charade {
  background: #2D323D !important;
}

.bg-tundora {
  background: #40383C !important;
}

.bg-shark {
  background: #27282a !important;
}

.bg-gigas {
  background: #4439a9 !important;
}

.bg-athens-gray {
  background: #F8F9FA !important;
}

.bg-ebony-clay {
  background: #252c42 !important;
}

.bg-link-water {
  background: #DCE9F6 !important;
}

.bg-swans-down {
  background: #dbf1f1 !important;
}

.bg-old-lace {
  background: #fcece1 !important;
}

.bg-woodsmoke {
  background: #0e0f14 !important;
}

.bg-cape-cod {
  background: #343535 !important;
}

.bg-zircon {
  background: #f8faff !important;
}

.bg-fade-dark-005 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.bg-fade-white-005 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.bg-fade-dark-01 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-fade-white-01 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-fade-dark-015 {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.bg-fade-white-015 {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.bg-fade-dark-02 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.bg-fade-white-02 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-fade-dark-025 {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.bg-fade-white-025 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.bg-fade-dark-03 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.bg-fade-white-03 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.bg-fade-dark-035 {
  background-color: rgba(0, 0, 0, 0.35) !important;
}

.bg-fade-white-035 {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

.bg-fade-dark-04 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.bg-fade-white-04 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.bg-fade-dark-045 {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.bg-fade-white-045 {
  background-color: rgba(255, 255, 255, 0.45) !important;
}

.bg-fade-dark-05 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.bg-fade-white-05 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-fade-dark-055 {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

.bg-fade-white-055 {
  background-color: rgba(255, 255, 255, 0.55) !important;
}

.bg-fade-dark-06 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.bg-fade-white-06 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.bg-fade-dark-065 {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.bg-fade-white-065 {
  background-color: rgba(255, 255, 255, 0.65) !important;
}

.bg-fade-dark-07 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.bg-fade-white-07 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.bg-fade-dark-075 {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.bg-fade-white-075 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.bg-fade-dark-08 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.bg-fade-white-08 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bg-fade-dark-085 {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.bg-fade-white-085 {
  background-color: rgba(255, 255, 255, 0.85) !important;
}
.bg-fade-white-095 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.bg-hover-secondary:hover {
  background-color: var(--color-secondary) !important;
}

.bg-hover-secondary:hover {
  background-color: var(--color-secondary) !important;
}

.bg-hover-white:hover {
  background-color: #fff !important;
}

.bg-hover-light:hover {
  background-color: #f7f8fb !important;
}

.bg-hover-dark:hover {
  background-color: #212121 !important;
}

.bg-hover-charade:hover {
  background-color: #2D323D !important;
}

.bg-hover-tundora:hover {
  background-color: #40383C !important;
}

.bg-gradient-primary-lr {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-rl {
  background: -webkit-gradient(linear, right top, left top, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to left, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-tb {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to bottom, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-bt {
  background: -webkit-gradient(linear, left bottom, left top, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to top, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-tl {
  background: -webkit-gradient(linear, right bottom, left top, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to top left, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-tr {
  background: -webkit-gradient(linear, left bottom, right top, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to top right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-br {
  background: -webkit-gradient(linear, left top, right bottom, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to bottom right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-primary-bl {
  background: -webkit-gradient(linear, right top, left bottom, from(var(--color-gradient-start)), to(var(--color-gradient-stop))) !important;
  background: linear-gradient(to bottom left, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%) !important;
}

.bg-gradient-secondary-lr {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to right, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-rl {
  background: -webkit-gradient(linear, right top, left top, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to left, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-tb {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to bottom, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-bt {
  background: -webkit-gradient(linear, left bottom, left top, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to top, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-tl {
  background: -webkit-gradient(linear, right bottom, left top, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to top left, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-tr {
  background: -webkit-gradient(linear, left bottom, right top, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to top right, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-br {
  background: -webkit-gradient(linear, left top, right bottom, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to bottom right, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-secondary-bl {
  background: -webkit-gradient(linear, right top, left bottom, from(var(--color-gradient-start-secondary)), to(var(--color-gradient-stop-secondary))) !important;
  background: linear-gradient(to bottom left, var(--color-gradient-start-secondary) 0%, var(--color-gradient-stop-secondary) 100%) !important;
}

.bg-gradient-tertiary-lr {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to right, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-rl {
  background: -webkit-gradient(linear, right top, left top, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to left, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-tb {
  background: -webkit-gradient(linear, left top, left bottom, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to bottom, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-bt {
  background: -webkit-gradient(linear, left bottom, left top, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to top, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-tl {
  background: -webkit-gradient(linear, right bottom, left top, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to top left, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-tr {
  background: -webkit-gradient(linear, left bottom, right top, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to top right, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-br {
  background: -webkit-gradient(linear, left top, right bottom, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to bottom right, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-tertiary-bl {
  background: -webkit-gradient(linear, right top, left bottom, from(var(--color-gradient-start-tertiary)), to(var(--color-gradient-stop-tertiary))) !important;
  background: linear-gradient(to bottom left, var(--color-gradient-start-tertiary) 0%, var(--color-gradient-stop-tertiary) 100%) !important;
}

.bg-gradient-black-transparent-tb {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(transparent)) !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, transparent 100%) !important;
}

.bg-gradient-black-transparent-bt {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), to(transparent)) !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 100%) !important;
}

.bg-gradient-black-transparent-lr {
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.7)), to(transparent)) !important;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, transparent 100%) !important;
}

.bg-gradient-black-transparent-rl {
  background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.7)), to(transparent)) !important;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.7) 0%, transparent 100%) !important;
}

.bg-gradient-cream {
  background: -webkit-gradient(linear, left top, left bottom, from(#fff8f5), color-stop(50%, #ffffff));
  background: linear-gradient(180deg, #fff8f5 0%, #ffffff 50%);
}

.bg-gradient-primary-35p {
  background: -webkit-gradient(linear, left top, right top, from(var(--color-primary)), color-stop(35%, var(--color-primary)), color-stop(35%, transparent));
  background: linear-gradient(to right, var(--color-primary) 0%, var(--color-primary) 35%, transparent 35%);
}

.bg-fixed {
  background-attachment: fixed;

}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left-center {
  background-position: left center !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-right-center {
  background-position: right center !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-center-bottom {
  background-position: center bottom !important;
}

.bg-center-top {
  background-position: center top !important;
}

.bg-none {
  background-image: none !important;
}

@media (max-width: 768px) {
  .bg-sm-none {
    background-image: none !important;
  }
}
@media (max-width: 992px) {
  .bg-md-none {
    background-image: none !important;
  }
}
@media (max-width: 1200px) {
  .bg-lg-none {
    background-image: none !important;
  }

}
.opacity-01 {
  opacity: 0.1;
}
.ld-pf-item:hover .opacity-01 {
  opacity: 0.1 !important;
}

.opacity-02 {
  opacity: 0.2;
}
.ld-pf-item:hover .opacity-02 {
  opacity: 0.2 !important;
}

.opacity-03 {
  opacity: 0.3;
}
.ld-pf-item:hover .opacity-03 {
  opacity: 0.3 !important;
}

.opacity-04 {
  opacity: 0.4;
}
.ld-pf-item:hover .opacity-04 {
  opacity: 0.4 !important;
}

.opacity-05 {
  opacity: 0.5;
}
.ld-pf-item:hover .opacity-05 {
  opacity: 0.5 !important;
}

.opacity-06 {
  opacity: 0.6;
}
.ld-pf-item:hover .opacity-06 {
  opacity: 0.6 !important;
}

.opacity-07 {
  opacity: 0.7;
}
.ld-pf-item:hover .opacity-07 {
  opacity: 0.7 !important;
}

.opacity-08 {
  opacity: 0.8;
}
.ld-pf-item:hover .opacity-08 {
  opacity: 0.8 !important;
}

.opacity-09 {
  opacity: 0.9;
}
.ld-pf-item:hover .opacity-09 {
  opacity: 0.9 !important;
}

.opacity-1 {
  opacity: 1;
}
.ld-pf-item:hover .opacity-1 {
  opacity: 1 !important;
}

.reset-opacity-onhover {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.reset-opacity-onhover:hover {
  opacity: 1;
}

.box-shadow-1 {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}

.box-shadow-2 {
  box-shadow: 0px 40px 90px 0px rgba(131, 0, 255, 0.2);
}

/*
 * Name:        One - Responsive Styles
 * Written by: 	LiquidThemes
 */
/*----------------------------------------
 [ TABLE OF CONTENTS ]

 1. BASE
		|- 1.1. Base
		|- 1.2. Header
		|- 1.3. Footer
		|- 1.4. Titlebar

	2. PAGE LAYOUTS
		|- 2.1. Header Side
		|- 2.3. Stack

	3. LIQUID SHORTCODES
		|- 3.1. Buttons
		|- 3.3. Accordions
		|- 3.4. Icon Boxes
		|- 3.5. Tabs
		|- 3.6. Subscribe Forms
		|- 3.9. Pricing Tables
		|- 3.10. Testimonials
		|- 3.11. Carousel
		|- 3.13. Latest Posts
		|- 3.14. Filter Lists
		|- 3.15. Fancy Boxes
		|- 3.19. Reservation Form
		|- 3.24. Instagram Feed
		|- 3.27. Image Groups
		|- 3.31. Masonry
		|- 3.33. Team Members
		|- 3.34. Portfolios
		|- 3.35. Blog
		|- 3.38. Liquid Fancy Heading
		|- 3.45. Shop Banner
		|- 3.48. Modal
		|- 3.50. Process Box
		|- 3.56. Roadmap
		|- 3.57. Countdown
		|- 3.62. Promo

	4. VC SHORTCODES
		|- 4.2. Column

	5. PARTIALS
		|- 5.4. Page Frame

-----------------------------------------*/
/*
  1. BASE
*/
/* 1.1. Base */
.d-flex {
  display: -webkit-box !important;
  display: flex !important;
}
.d-flex-sides{
  justify-content: space-between;
  align-items: center;
}
.d-flex:before, .d-flex:after {
  content: none;
}
.dropdown-up{
  .dropdown-menu{
    bottom: 100%;
    top: auto;
  }
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}
.d-inline-flex:before, .d-inline-flex:after {
  content: none;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
          flex-direction: column-reverse !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
          flex-direction: row !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
          flex-direction: row-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-start {
  -webkit-box-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
          align-items: stretch !important;
}

@media (min-width: 768px) {
  .d-sm-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-sm-flex:before, .d-sm-flex:after {
    content: none;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  .d-sm-inline-flex:before, .d-sm-inline-flex:after {
    content: none;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
}
@media (min-width: 992px) {
  .d-md-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-md-flex:before, .d-md-flex:after {
    content: none;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  .d-md-inline-flex:before, .d-md-inline-flex:after {
    content: none;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
}
@media (min-width: 1200px) {
  .d-lg-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-lg-flex:before, .d-lg-flex:after {
    content: none;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  .d-lg-inline-flex:before, .d-lg-inline-flex:after {
    content: none;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
}
.text-left {
  text-align: left !important;
}
.text-left div.wpb_single_image {
  text-align: inherit;
}
.text-left .liquid-counter-element {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.text-right {
  text-align: right !important;
}
.text-right div.wpb_single_image {
  text-align: inherit;
}
.text-right .liquid-counter-element {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.text-center {
  text-align: center !important;
}
.text-center div.wpb_single_image {
  text-align: inherit;
}
.text-center .liquid-counter-element {
  -webkit-box-pack: center;
          justify-content: center;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-left div.wpb_single_image {
    text-align: inherit;
  }
  .text-sm-left .liquid-counter-element {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-right div.wpb_single_image {
    text-align: inherit;
  }
  .text-sm-right .liquid-counter-element {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }

  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-center div.wpb_single_image {
    text-align: inherit;
  }
  .text-sm-center .liquid-counter-element {
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-left div.wpb_single_image {
    text-align: inherit;
  }
  .text-md-left .liquid-counter-element {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  .text-md-right {
    text-align: right !important;
  }
  .text-md-right div.wpb_single_image {
    text-align: inherit;
  }
  .text-md-right .liquid-counter-element {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }

  .text-md-center {
    text-align: center !important;
  }
  .text-md-center div.wpb_single_image {
    text-align: inherit;
  }
  .text-md-center .liquid-counter-element {
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-left div.wpb_single_image {
    text-align: inherit;
  }
  .text-lg-left .liquid-counter-element {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }

  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-right div.wpb_single_image {
    text-align: inherit;
  }
  .text-lg-right .liquid-counter-element {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }

  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-center div.wpb_single_image {
    text-align: inherit;
  }
  .text-lg-center .liquid-counter-element {
    -webkit-box-pack: center;
            justify-content: center;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.m-8 {
  margin: 5.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 5.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 5.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 5.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 5.5rem !important;
}

.m-9 {
  margin: 6rem !important;
}

.mt-9,
.my-9 {
  margin-top: 6rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 6rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 6rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.p-8 {
  padding: 5.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 5.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 5.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 5.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 5.5rem !important;
}

.p-9 {
  padding: 6rem !important;
}

.pt-9,
.py-9 {
  padding-top: 6rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 6rem !important;
}

.pl-9,
.px-9 {
  padding-left: 6rem !important;
}

.pr-9,
.px-9 {
  padding-right: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .m-sm-6 {
    margin: 4.5rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }

  .m-sm-7 {
    margin: 5rem !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }

  .m-sm-8 {
    margin: 5.5rem !important;
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5.5rem !important;
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5.5rem !important;
  }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5.5rem !important;
  }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5.5rem !important;
  }

  .m-sm-9 {
    margin: 6rem !important;
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important;
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important;
  }

  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important;
  }

  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .p-sm-6 {
    padding: 4.5rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }

  .p-sm-7 {
    padding: 5rem !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }

  .p-sm-8 {
    padding: 5.5rem !important;
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5.5rem !important;
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5.5rem !important;
  }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5.5rem !important;
  }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5.5rem !important;
  }

  .p-sm-9 {
    padding: 6rem !important;
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important;
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important;
  }

  .pl-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important;
  }

  .pr-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .m-md-6 {
    margin: 4.5rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }

  .m-md-7 {
    margin: 5rem !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }

  .m-md-8 {
    margin: 5.5rem !important;
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 5.5rem !important;
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5.5rem !important;
  }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 5.5rem !important;
  }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 5.5rem !important;
  }

  .m-md-9 {
    margin: 6rem !important;
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important;
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important;
  }

  .ml-md-9,
  .mx-md-9 {
    margin-left: 6rem !important;
  }

  .mr-md-9,
  .mx-md-9 {
    margin-right: 6rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .p-md-6 {
    padding: 4.5rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }

  .p-md-7 {
    padding: 5rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }

  .p-md-8 {
    padding: 5.5rem !important;
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 5.5rem !important;
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5.5rem !important;
  }

  .pl-md-8,
  .px-md-8 {
    padding-left: 5.5rem !important;
  }

  .pr-md-8,
  .px-md-8 {
    padding-right: 5.5rem !important;
  }

  .p-md-9 {
    padding: 6rem !important;
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important;
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important;
  }

  .pl-md-9,
  .px-md-9 {
    padding-left: 6rem !important;
  }

  .pr-md-9,
  .px-md-9 {
    padding-right: 6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .m-lg-6 {
    margin: 4.5rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }

  .m-lg-7 {
    margin: 5rem !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }

  .m-lg-8 {
    margin: 5.5rem !important;
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5.5rem !important;
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5.5rem !important;
  }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5.5rem !important;
  }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5.5rem !important;
  }

  .m-lg-9 {
    margin: 6rem !important;
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important;
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important;
  }

  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important;
  }

  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .p-lg-6 {
    padding: 4.5rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }

  .p-lg-7 {
    padding: 5rem !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }

  .p-lg-8 {
    padding: 5.5rem !important;
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5.5rem !important;
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5.5rem !important;
  }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5.5rem !important;
  }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5.5rem !important;
  }

  .p-lg-9 {
    padding: 6rem !important;
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important;
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important;
  }

  .pl-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important;
  }

  .pr-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  [data-fullheight=true] {
    height: 100vh;
  }
}
@media screen and (max-width: 991px) {
  html,
  body,
  #wrap {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 991px) {
  .content h1, .content .h1 {
    font-size: 44px;
  }
  .content h2, .content .h2 {
    font-size: 32px;
  }
  .content h3, .content .h3 {
    font-size: 24px;
  }
  .content h4, .content .h4 {
    font-size: 18px;
  }
  .content h5, .content .h5 {
    font-size: 16px;
  }
  .content h6, .content .h6 {
    font-size: 15px;
  }
}
@media screen and (max-width: 1199px) {
  .lqd-sticky-bg-wrap,
  .lqd-sticky-bg {
    height: 100%;
    min-height: 0;
    max-height: none;
    position: absolute;
    top: 0;
    left: 0;
  }
}
/* 1.2. Header */
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .header-side {
    width: 250px;
  }
  .header-side .mainbar-wrap {
    padding: 12vh 25px;
  }
}
@media screen and (max-width: 767px) {
  .ld-module-cart .ld-cart-contents,
  .ld-module-cart .ld-module-dropdown {
    width: 100%;
  }

  .ld-cart-contents .empty,
  .ld-cart-foot,
  .ld-cart-head,
  .ld-cart-product {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*
	Submenu
*/
/*
	Nav Styles
*/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .mainbar-row > [class^=col] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media screen and (max-width: 782px) {
  .admin-bar .is-stuck {
    top: 46px;
  }
}
@media screen and (max-width: 600px) {
  .admin-bar .is-stuck {
    top: 0;
  }
}
/* 1.3. Footer */
@media screen and (min-width: 1200px) {
  .lqd-sticky-footer-sentinel.height-applied + .footer-stuck {
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
  }
  .lqd-sticky-footer-sentinel.height-applied + .footer-stuck.is-inview {
    opacity: 1;
    visibility: visible;
  }
  .site-boxed-layout .lqd-sticky-footer-sentinel.height-applied + .footer-stuck {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1199px) {
  .lqd-sticky-footer-sentinel {
    display: none;
  }
}
/* 1.4. Titlebar */
@media screen and (max-width: 767px) {
  .titlebar-inner h1 {
    font-size: 48px !important;
  }
}
@media screen and (max-width: 479px) {
  .titlebar-inner h1 {
    font-size: 38px !important;
  }
}
/*
  2. PAGE LAYOUTS
*/
/* 2.1. Header Side */
@media screen and (min-width: 1200px) {
  .header-style-side #wrap {
    padding-left: 375px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .header-style-side #wrap {
    padding-left: 250px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1580px) {
  .header-style-side .vc_row > .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-style-side.lqd-stack-initiated .vc_row .lqd-stack-section-inner > .container {
    width: 970px;
  }
}
/* 2.3. Stack */
.vc_mobile [data-liquid-stack=true] > section {
  height: auto;
}

/*
  3. LIQUID SHORTCODES
*/
/* 3.1. Buttons */
@media all and (max-width: 992px) {
  .btn {
    white-space: normal;
  }

  /* Sizes*/
  .btn-xsm > span {
    padding: 0.5em 0.8em;
  }
  .btn-xsm.wide > span {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .btn-sm > span {
    padding: 0.6em 1.2em;
  }
  .btn-sm.wide > span {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  .btn-md > span {
    padding: 1em 2em;
  }

  .btn-lg > span {
    padding: 1.15em 2.5em;
  }
  .btn-lg.wide > span {
    padding-left: 3.3em;
    padding-right: 3.3em;
  }

  .btn-xlg > span {
    padding: 1.3em 3.1em;
  }
  .btn-xlg.wide > span {
    padding-left: 4.1em;
    padding-right: 4.1em;
  }
}
/* 3.3. Accordions */
@media screen and (max-width: 480px) {
  .accordion-expander {
    right: 15px;
  }

  .accordion-title a {
    padding-right: 50px;
  }
}
/* 3.4. Iconboxes */
@media screen and (max-width: 1199px) {
  .iconbox[data-plugin-animated-icon] > svg,
  .iconbox[data-plugin-animated-icon] object {
    opacity: 1;
  }
}
/* 3.5. Tabs */
@media screen and (min-width: 992px) {
  .tabs-nav-items-bordered .tabs-nav li + li {
    border-left-width: 0;
  }

  .tabs-nav-side {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
  }
  .tabs-nav-side .tabs-nav {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991px) {
  .tabs-nav-side .tabs-nav,
  .tabs-nav-side .tabs-content {
    width: 100%;
  }
  .tabs-nav-side .tabs-nav {
    margin-bottom: 0;
  }
  .tabs-nav-side .tabs-content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .tabs-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .tabs-nav li {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .tabs-nav a {
    width: 100%;
  }

  .tabs-nav-items-bordered .tabs-nav li + li {
    border-top-width: 0;
  }
}
/* 3.6. Subscribe Forms */
@media (max-width: 991px) {
  .ld-sf--button-inline form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .ld-sf--button-inline form p {
    padding-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .ld-sf--button-inside.ld-sf--button-bordered [type=text],
  .ld-sf--button-inside.ld-sf--button-bordered [type=email], .ld-sf--button-inside.ld-sf--button-solid [type=text],
  .ld-sf--button-inside.ld-sf--button-solid [type=email] {
    padding-right: 2.5em !important;
  }
}
/* 3.9. Pricing Tables */
@media screen and (max-width: 1199px) {
  .pricing-table-colorful {
    margin-left: 0;
    margin-right: 0;
  }
  .pricing-table-colorful.featured {
    margin-top: 0;
  }
}
/* 3.10. Testimonials */
@media screen and (min-width: 992px) {
  .is-last .testimonials-details-only .testimonial-info {
    -webkit-box-align: start;
            align-items: flex-start;
  }
}
@media screen and (max-width: 991px) {
  .testimonials-details .carousel-nav {
    text-align: center !important;
  }
}
@media screen and (max-width: 767px) {
  .testimonial-avatar-topleft .avatar,
  .testimonial-avatar-topright .avatar {
    position: relative;
    top: auto;
    left: auto;
    -webkit-transform: none;
            transform: none;
  }
  .testimonial-avatar-topleft.text-left .avatar,
  .testimonial-avatar-topright.text-left .avatar {
    margin-right: 15px !important;
  }
  .testimonial-avatar-topleft.text-right .avatar,
  .testimonial-avatar-topright.text-right .avatar {
    margin-right: 15px !important;
  }
  .testimonial-avatar-topleft.text-center .avatar,
  .testimonial-avatar-topright.text-center .avatar {
    margin-bottom: 15px !important;
  }

  .testimonials-quotes .carousel-nav {
    margin-top: 0 !important;
    text-align: center !important;
  }

  .testimonials-details-only .avatar {
    margin-bottom: 0.5em;
  }
  .testimonials-details-only .testimonial-details {
    text-align: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .testimonials-details-only .testimonial-info {
    -webkit-box-align: center !important;
            align-items: center !important;
    text-align: center !important;
  }

  .testimonials-quote-only .testimonial-quote {
    text-align: center;
    font-size: 22px;
  }
}
/* 3.11. Carousel */
@media screen and (min-width: 1200px) {
  .fancy-title .carousel-nav {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@media screen and (min-width: 992px) {
  /* Nav extra */
  .carousel-nav-line-between .flickity-prev-next-button.previous {
    margin-right: 40px;
  }
  .carousel-nav-line-between .flickity-prev-next-button.previous:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -25px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .carousel-nav-line-between.carousel-nav-vertical .flickity-prev-next-button.previous {
    margin-right: 0;
    margin-bottom: 45px;
  }
  .carousel-nav-line-between.carousel-nav-vertical .flickity-prev-next-button.previous:after {
    width: 100%;
    height: 1px;
    top: auto;
    bottom: -25px;
    right: 0;
  }
}
@media screen and (max-width: 991px) {
  .carousel-nav {
    margin-top: 2em !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
    -webkit-transform: none !important;
            transform: none !important;
  }
  .carousel-nav .flickity-button {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
    -webkit-transform: none !important;
            transform: none !important;
    margin: 0 15px !important;
  }
  .carousel-nav-right .carousel-nav {
    text-align: left !important;
  }
}
@media screen and (max-width: 767px) {
  .carousel-item:not([class*=col-xs-]) {
    width: 100%;
  }
}
/* 3.13. Latest Posts */
@media screen and (min-width: 992px) {
  .liquid-blog-item-split {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .liquid-blog-item-split .liquid-lp-media,
  .liquid-blog-item-split .liquid-blog-item-inner {
    flex-basis: 50%;
  }
  .liquid-blog-item-split .liquid-blog-item-inner {
    padding-left: 50px;
    padding-right: 50px;
  }

  .liquid-blog-item-fullwidth .liquid-blog-item-inner {
    width: 70%;
  }

  .flickity-equal-cells .carousel-item:not(:first-child) .liquid-blog-item-carousel {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 100%;
  }

  .liquid-blog-item-square .liquid-lp-title {
    font-size: 33px;
  }
  .col-md-6 > .liquid-blog-item-square .liquid-blog-item-inner {
    padding-left: 45px;
    padding-right: 45px;
  }
  .col-md-3 > .liquid-blog-item-square .liquid-blog-item-inner {
    padding-bottom: 11%;
  }
  .col-md-3 > .liquid-blog-item-square .liquid-lp-title {
    font-size: 18px;
    line-height: 1.666em;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .liquid-blog-item-masonry {
    min-height: 400px !important;
  }
}
@media screen and (max-width: 1199px) {
  .ld-lp-carousel-filterable .filter-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-flow: column nowrap;
  }
  .ld-lp-carousel-filterable .filter-list li {
    width: 100%;
    text-align: left;
  }
  .ld-lp-carousel-filterable .carousel-items {
    margin-top: 2em;
  }
}
@media screen and (max-width: 991px) {
  .liquid-blog-item-split {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  .liquid-blog-item-fullwidth {
    padding: 0 !important;
  }
  .liquid-blog-item-fullwidth .liquid-blog-item-inner {
    position: relative;
    top: auto;
    left: auto;
  }

  .liquid-lp-sp-block.liquid-lp-sp-instagram h3 {
    margin-left: 0;
    margin-right: 0;
  }
}
/* 3.14. Filter Lists */
@media screen and (min-width: 992px) {
  .liquid-filter-items {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .liquid-filter-items.align-items-center .filter-list {
    margin-bottom: 0;
  }

  .filter-list-inline li + li {
    margin-left: 1.45em;
  }
}
@media screen and (max-width: 991px) {
  .liquid-filter-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }

  .liquid-filter-items-inner {
    width: 100%;
  }
  .liquid-filter-items-inner > * {
    margin-bottom: 30px !important;
  }
  .liquid-filter-items-inner .filter-list {
    width: 100%;
  }
  .liquid-filter-items-inner + .carousel-nav {
    margin-top: 0 !important;
  }

  .liquid-filter-items-label + .filter-list {
    margin-left: 0;
  }

  .filter-list-inline li {
    margin: 0 0.5em;
  }
}
@media screen and (max-width: 767px) {
  .filter-list {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .filter-list li {
    margin-bottom: 1em;
    text-align: center;
  }
}
/* 3.15. Fancy Boxes */
@media screen and (min-width: 992px) {
  .fancy-box-offer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .fancy-box-offer .fancy-box-cell:first-child {
    -webkit-box-flex: 2;
            flex-grow: 2;
  }
  .fancy-box-offer .fancy-box-cell:last-child {
    -webkit-box-align: end;
            align-items: flex-end;
  }
}
@media screen and (max-width: 991px) {
  .fancy-box-offer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }
  .fancy-box-offer .fancy-box-cell {
    flex-basis: auto;
    margin-bottom: 1.5em;
  }
  .fancy-box-offer .fancy-box-cell:before {
    content: attr(data-text);
    margin-bottom: 0.5em;
  }
  .fancy-box-offer .fancy-box-header {
    flex-basis: 100%;
  }

  .fancy-box-offer-header {
    padding-bottom: 0;
  }
  .fancy-box-offer-header .fancy-box-cell:not(.fancy-box-header) {
    display: none;
  }
  .fancy-box-offer-header .fancy-box-header {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .fancy-box-overlay {
    padding-bottom: 90%;
  }

  .fancy-box-travel.fancy-box-wide {
    padding-bottom: 50%;
  }

  .fancy-box-offer .fancy-box-cell {
    flex-basis: 50%;
  }
  .fancy-box-offer .fancy-box-header {
    flex-basis: 100%;
  }
}
/* 3.19. Reservation Form */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .liquid-form-reservation .input-container {
    flex-basis: 50%;
  }
  .liquid-form-reservation .input-container:nth-last-child(2) {
    padding-left: 0;
    border: none;
  }
}
@media screen and (max-width: 991px) {
  .liquid-form-reservation .input-container {
    flex-basis: 100%;
    padding: 0;
    border: none;
  }
}
/* 3.24. Instagram Feed */
@media screen and (max-width: 991px) {
  .liquid-ig-feed .liquid-ig-feed-list > li {
    flex-basis: 33.3333333333%;
  }

  [data-list-columns="1"] .liquid-ig-feed-list > li,
  [data-list-columns="2"] .liquid-ig-feed-list > li {
    flex-basis: 100%;
  }
}
@media screen and (max-width: 767px) {
  .liquid-ig-feed .liquid-ig-feed-list > li {
    flex-basis: 50%;
  }

  [data-list-columns="1"] .liquid-ig-feed-list > li,
  [data-list-columns="2"] .liquid-ig-feed-list > li {
    flex-basis: 100%;
  }
}
/* 3.27. Image Groups */
@media screen and (min-width: 992px) {
  .liquid-img-group-browser {
    margin-left: 3.5em;
  }
  .liquid-img-group-browser figure {
    height: 91%;
    top: 9%;
  }

  .liquid-img-group-content.content-fixed-right, .liquid-img-group-content.content-fixed-left {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
  }
  .liquid-img-group-content.content-fixed-right p, .liquid-img-group-content.content-fixed-left p {
    margin-bottom: 0;
  }
  .liquid-img-group-content.content-fixed-left {
    margin-right: 1.5em;
  }
  .liquid-img-group-content.content-fixed-right {
    -webkit-box-ordinal-group: 2;
            order: 1;
    margin-left: 1.5em;
  }
}
@media screen and (max-width: 991px) {
  .liquid-img-group-img-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  .liquid-img-group-content {
    -webkit-box-ordinal-group: 3;
            order: 2;
    margin-top: 1em;
  }
  .liquid-img-group-content.content-floated-mid-left, .liquid-img-group-content.content-floated-mid-right, .liquid-img-group-content.content-floated-mid {
    margin: 0;
    left: 50%;
  }
  .liquid-img-group-content.content-floated-mid-left > .btn, .liquid-img-group-content.content-floated-mid-right > .btn, .liquid-img-group-content.content-floated-mid > .btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .liquid-img-group-browser {
    margin-bottom: 2em;
  }
  .liquid-img-group-browser .liquid-img-group-img-container {
    margin-bottom: 1.5em;
  }
  .liquid-img-group-browser .liquid-img-group-url {
    height: 7.5%;
  }
}
@media screen and (max-width: 767px) {
  .liquid-img-group-browser .liquid-img-group-url {
    display: none;
  }
}
/* 3.31. Masonry */
@media screen and (max-width: 991px) {
  .masonry-item:not([class*=col-sm-]) {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .masonry-item:not([class*=col-xs-]) {
    width: 100%;
  }
}
/* 3.33. Team Members */
@media screen and (max-width: 479px) {
  .ld-tm-circ .ld-tm-avatar img {
    width: 50px;
  }
}
/* 3.34. Portfolios */
@media screen and (min-width: 1200px) {
  .carousel-item .pf-hover-shadow {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .pf-info hr {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .pf-single-header.bg-solid {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pf-single-header.pull-up {
    margin-top: -60px;
  }
}
/* 3.35. Blog */
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .has-sidebar .blog-single-content.expanded {
    margin-left: -14%;
  }
}
@media screen and (min-width: 1200px) {
  .blog-single-cover[data-fullheight=true] {
    height: 87vh;
  }
  .blog-single-cover[data-fullheight=true] .blog-single-media {
    height: 100%;
  }
  .blog-single-cover[data-fullheight=true] .cover-carousel,
  .blog-single-cover[data-fullheight=true] .cover-carousel .carousel-items,
  .blog-single-cover[data-fullheight=true] .cover-carousel .flickity-viewport,
  .blog-single-cover[data-fullheight=true] .cover-carousel .carousel-item-inner,
  .blog-single-cover[data-fullheight=true] .cover-carousel .carousel-item {
    height: 100% !important;
  }

  .blog-single-cover .blog-single-details {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .blog-single-cover.spaced {
    margin-left: 50px;
    margin-right: 50px;
  }

  .blog-single-content.expanded {
    padding-left: calc(26%);
    padding-right: calc(26%);
    margin-right: calc(-26%);
    margin-left: calc(-26%);
  }
  .blog-single-content.pull-up {
    margin-top: -6.8181818182em;
    background-color: #fff;
  }
  .post-has-no-content .blog-single-content.pull-up {
    margin-top: 0;
  }
  .blog-single-content .stretch-to-sides {
    margin-left: -26%;
    margin-right: -26%;
  }
  .blog-single-content .fullwidth figcaption {
    width: 40%;
  }

  .post-nav,
  .blog-single-footer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }

  .blog-single-footer > ul,
  .blog-single-footer > span {
    max-width: 50%;
  }

  .post-nav .nav-previous,
  .post-nav .nav-next {
    max-width: 40%;
  }

  .has-sidebar .blog-single-content.expanded {
    padding-right: 13%;
    padding-left: 13%;
    margin-right: -13%;
    margin-left: -13%;
  }

  .blog-single-post-has-not-thumbnail .blog-single-content.pull-up {
    margin-top: 0;
    padding-top: 5em;
  }
  .blog-single-post-has-not-thumbnail .lqd-main-header-default + .content .blog-single-content.pull-up {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 992px) {
  .post-meta {
    margin-bottom: 3.75em;
  }

  .blog-single-details-extra h3 {
    margin-bottom: 2em;
  }

  .has-sidebar .blog-single-content .fullwidth {
    width: 100%;
    right: 0;
    left: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .has-sidebar .blog-single-content .fullwidth figcaption {
    width: 70%;
  }
  .has-sidebar .blog-single-content .stretch-to-sides {
    margin-left: -13%;
    margin-right: -13%;
  }
  .has-sidebar .blog-single-content.expanded .fullwidth {
    width: 126%;
    right: -13%;
    left: -13%;
    margin-right: -13%;
  }
}
@media screen and (max-width: 991px) {
  .blog-single-details-extra .carousel-thumbs .carousel-item {
    width: 33.3333333333%;
  }

  .post-meta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .post-meta > span {
    margin: 0 0 1em !important;
  }
  .post-meta > span:last-child {
    margin: 0 !important;
  }

  .post-nav,
  .blog-single-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  .blog-single-cover.spaced {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .blog-single-details-extra .carousel-thumbs .carousel-item {
    width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .pingback .comment-meta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .pingback time {
    margin: 0 0 1em;
  }
}
@media screen and (max-width: 1199px) {
  .blog-single-default .blog-single-cover {
    margin-bottom: 2.5em;
  }
  .blog-single-default .blog-single-media {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blog-single-default .blog-single-details {
    padding: 10em 0;
  }
}
@media screen and (min-width: 1200px) {
  .blog-single-image-left:not(.has-sidebar) .blog-single-cover {
    float: left;
    width: 470px;
    margin-left: -26%;
    margin-right: 17%;
  }
  .blog-single-image-left:not(.has-sidebar) .blog-single-details {
    margin-right: -26%;
  }
}
@media screen and (min-width: 1200px) {
  .blog-single-cover-fade .post-meta {
    margin-bottom: 0;
  }
}
/* 3.38. Liquid Fancy Heading */
@media screen and (min-width: 1200px) {
  .ld-fancy-heading [data-split-text] {
    visibility: hidden;
  }
  .ld-fancy-heading [data-split-text].split-text-applied {
    visibility: visible;
  }
}
/* 3.45. Shop Banner */
@media screen and (min-width: 992px) {
  .ld-shop-banner-style1 {
    padding-bottom: 90.091%;
  }
  .ld-shop-banner-style1 .ld-shop-banner-carousel-main,
  .ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-items,
  .ld-shop-banner-style1 .ld-shop-banner-container,
  .ld-shop-banner-style1 .ld-shop-banner-inner,
  .ld-shop-banner-style1 .ld-shop-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .ld-shop-banner-style1 .ld-shop-banner-carousel-main .flickity-viewport {
    height: 100% !important;
  }
  .ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item-inner,
  .ld-shop-banner-style1 .ld-shop-banner-carousel-main .carousel-item {
    height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .ld-shop-banner-style1 .liquid-overlay-link {
    bottom: 50%;
    height: auto;
    -webkit-transform: none;
            transform: none;
  }
  .ld-shop-banner-style1 .ld-shop-banner-image {
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
  }
  .ld-shop-banner-style1 .ld-shop-banner-carousel-nav {
    width: 100%;
    height: auto;
    margin-top: 1em;
    position: relative;
    bottom: auto;
    right: auto;
    -webkit-transform: none;
            transform: none;
  }
  .ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item {
    width: 25%;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
            transform: none;
  }
  .ld-shop-banner-style1:hover .ld-shop-banner-image {
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
  }
  .ld-shop-banner-style1:hover .ld-shop-banner-inner {
    box-shadow: none;
  }
}
@media screen and (max-width: 767px) {
  .ld-shop-banner-style1 .ld-shop-banner-carousel-nav .carousel-item {
    width: 33.3333333333% !important;
  }
}
/* 3.48. Modal */
@media screen and (min-width: 992px) {
  .featherlight-content > iframe.featherlight-inner {
    min-width: 850px;
  }
}
/* 3.50. Process Box */
@media screen and (max-width: 991px) {
  [class*=vc_col-md] .ld-pb-top:after {
    content: none;
  }
}
@media screen and (max-width: 767px) {
  [class*=vc_col-sm] .ld-pb-top:after {
    content: none;
  }
}
/* 3.56. Roadmap */
@media screen and (max-width: 767px) {
  .one-roadmap {
    padding-left: 25px;
    padding-right: 25px;
  }
  .one-roadmap:before {
    top: 15px;
    right: 15px;
    left: 15px;
    bottom: 15px;
  }

  .one-roadmap-info {
    max-width: 85%;
  }
}
/* 3.57. Countdown */
@media screen and (max-width: 991px) {
  .countdown .countdown-section {
    flex-basis: 45%;
    margin: 0.2352941176em 0;
  }
  .countdown .countdown-section:nth-child(3) + .countdown-sep {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .countdown .countdown-section {
    flex-basis: 100%;
  }
  .countdown .countdown-sep {
    display: none;
  }
}
/* 3.59. Bullet List */
@media screen and (max-width: 767px) {
  .one-bullet-list .inline-nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    -webkit-box-align: center;
            align-items: center;
  }
  .one-bullet-list .inline-nav li {
    margin-left: 0 !important;
    -webkit-box-align: center;
            align-items: center;
    width: 50%;
  }
}
/* 3.62. Promo */
@media screen and (min-width: 992px) {
  .lqd-promo-alt .lqd-promo-cat {
    margin-left: -100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  .lqd-promo-alt .lqd-promo-cat:before {
    margin-left: 1em;
    margin-right: 0;
  }
  .lqd-promo-alt .lqd-promo-content {
    width: 25%;
    text-align: right;
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .lqd-promo-alt .lqd-promo-content h2 {
    margin-right: 0;
    margin-left: -100%;
  }
  .lqd-promo-alt .lqd-promo-img {
    width: 75%;
  }
  .lqd-promo-alt .lqd-promo-img-inner {
    margin-left: -10%;
  }

  .lqd-promo-reverse .lqd-promo-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
  .lqd-promo-reverse.lqd-promo-alt .lqd-promo-cat {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin-left: 0;
    margin-right: -100%;
  }
  .lqd-promo-reverse.lqd-promo-alt .lqd-promo-cat:before {
    margin-left: 0;
    margin-right: 1em;
  }
  .lqd-promo-reverse.lqd-promo-alt .lqd-promo-content {
    text-align: left;
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .lqd-promo-reverse.lqd-promo-alt .lqd-promo-content h2 {
    margin-left: 0;
    margin-right: -100%;
  }
  .lqd-promo-reverse.lqd-promo-alt .lqd-promo-img-inner {
    margin-left: 0;
    margin-right: -10%;
  }
}
@media screen and (min-width: 1200px) {
  .lqd-promo-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }

  .lqd-promo-alt .lqd-promo-content h2 {
    font-size: 72px;
  }

  .lqd-promo-reverse .lqd-promo-cat {
    margin-right: 0;
    margin-left: 15px;
  }
  .lqd-promo-reverse .lqd-promo-content {
    padding-left: 0;
    padding-right: 15px;
  }
  .lqd-promo-reverse:not(.lqd-promo-alt) .lqd-promo-content {
    -webkit-box-align: end;
            align-items: flex-end;
    text-align: right;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1580px) {
  .header-style-side.lqd-stack-initiated .lqd-promo-alt .lqd-promo-content h2 {
    font-size: 60px;
  }
}
@media screen and (max-width: 1199px) {
  .lqd-promo-img {
    width: 58.75%;
  }

  .lqd-promo-content {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .lqd-promo-content h2 {
    font-size: 100px;
  }

  .lqd-promo-alt .lqd-promo-content h2 {
    font-size: 60px;
  }
}
@media screen and (max-width: 991px) {
  .lqd-promo-cat {
    -webkit-writing-mode: inherit;
        -ms-writing-mode: inherit;
            writing-mode: inherit;
  }
  .lqd-promo-cat ul {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    margin: 0;
    margin-bottom: 15px;
  }

  .lqd-promo-content,
  .lqd-promo-cat,
  .lqd-promo-img {
    width: 100%;
  }

  .lqd-promo-cat {
    margin-right: 0;
  }

  .lqd-promo-img-inner {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }

  .lqd-promo-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  .lqd-promo-content {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
  .lqd-promo-content h2 {
    margin: 0 0 0.25em;
    font-size: 62px;
  }
}
@media screen and (max-width: 767px) {
  .lqd-promo-dynamic-shape {
    display: none;
  }

  .lqd-promo-wrap {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .lqd-promo-content {
    text-align: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .lqd-promo-content h2 {
    font-size: 10vw;
  }

  .lqd-promo-cat::before {
    content: none !important;
  }
}
/*
  4. VC SHORTCODES
*/
/* 4.2. Columns */
.col-1\/5 {
  width: 20%;
}

@media (min-width: 768px) {
  .col-sm-1\/5 {
    width: 20%;
  }
}
@media (min-width: 992px) {
  .col-md-1\/5 {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1\/5 {
    width: 20%;
  }
}
@media screen and (min-width: 1200px) {
  .stretch-to-right {
    margin-right: -22vw !important;
  }

  .stretch-to-left {
    margin-left: -22vw !important;
  }
}
@media screen and (max-width: 767px) {
  .d-flex > .lqd-column:not([class*=col-xs-]) {
    width: 100%;
  }
}
/*
  5. PARTIALS
*/
/* 5.4. Page Frame */
@media screen and (min-width: 1200px) {
  .lqd-page-frame {
    position: absolute;
    z-index: 3;
    background-color: var(--color-primary);
  }
  .lqd-page-frame[data-orientation=h] {
    left: 0;
    width: 100%;
    height: 20px;
  }
  .lqd-page-frame[data-orientation=v] {
    top: 0;
    width: 20px;
    height: 100%;
  }

  .lqd-page-frame-top {
    top: 0;
    -webkit-transform-origin: center top;
            transform-origin: center top;
  }

  .lqd-page-frame-right {
    right: 0;
    -webkit-transform-origin: right center;
            transform-origin: right center;
  }

  .lqd-page-frame-bottom {
    bottom: 0;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
  }

  .lqd-page-frame-left {
    left: 0;
    -webkit-transform-origin: left center;
            transform-origin: left center;
  }

  .page-has-frame #wrap {
    padding: 20px;
  }
  .page-has-frame .is-stuck {
    left: 20px;
    right: 20px;
  }
  .page-has-frame .megamenu.megamenu-fullwidth .nav-item-children {
    width: calc( 100vw - 40px );
    left: calc(50% + 10px) !important;
    right: calc(50% + 10px) !important;
    margin-left: calc(-50vw + 10px) !important;
    margin-right: calc(-50vw + 10px) !important;
  }
  .page-has-frame .megamenu.megamenu-fullwidth .megamenu-container {
    width: calc( 100vw - 40px ) !important;
  }
}